import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import ZvjsTabsRouter from "../../../common/components/ZvjsTabsRouter";

export enum ZakladneUdajeOTresteTabs {
  UdajeOTreste = "UdajeOTreste",
  ZastupcoviaAOpatrenia = "ZastupcoviaAOpatrenia",
  Rozhodnutia = "Rozhodnutia",
  TrestyAVazby = "TrestyAVazby",
}

const ZakladneUdajeOTreste = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeTrest")
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabsRouter
            data={[
              {
                label: tui("údaje o treste"),
                route: ZakladneUdajeOTresteTabs.UdajeOTreste,
              },
              {
                label: tui("zástupcovia a opatrenia"),
                route: ZakladneUdajeOTresteTabs.ZastupcoviaAOpatrenia,
              },
              {
                label: tui("rozhodnutia"),
                route: ZakladneUdajeOTresteTabs.Rozhodnutia,
              },
              {
                label: tui("tresty a väzby"),
                route: ZakladneUdajeOTresteTabs.TrestyAVazby,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZakladneUdajeOTreste;
