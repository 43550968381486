import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsButton, ZvjsSummaryTable } from "../../../components";
import {
  RequestActionResult,
  RequestActionsType,
  RequestPages,
} from "../../Request";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../redux";
import questionnaireReducer from "../../redux";
import Link from "@mui/material/Link";
import {
  Params,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { AppDispatch } from "../../../../store/redux";
import { isSubmitting } from "../../../../utils/helpers";
import {
  ZvjsSummaryRequestSection,
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../components/ZvjsSummaryTable";
import { ZvjsSummaryItemType } from "../../redux/selectors/summary";
import { useUITranslation } from "../../../../store/context/translation-context";
import { useSnackbar } from "../../../../store/context/snackbar-context";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { ActionResultTypes } from "../../../../router/ActionResult";
import { ActionBodyWrapper } from "../../../../router/ActionBodyWrapper";

interface SummaryProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

export async function submitNewRequest({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { SZOO_Post, HASURA_Put } = await API_Clients();

  const submitBodies: SummarySubmitData[] = data as SummarySubmitData[];

  console.debug(submitBodies);

  let error = false;
  if (!submitBodies || !Array.isArray(submitBodies)) {
    // if submit is not supported, display success page (should be removed in future)
    return {
      type: ActionResultTypes.SUCCESS,
      action: RequestActionsType.SUBMIT_NEW_REQUEST,
    };
  }

  for (const submitBody of submitBodies) {
    const responseHasura = await HASURA_Put(
      "/api/rest/v1/kiosk-ziadost-create/{id}",
      {
        params: {
          path: {
            id: submitBody.idZiadosti,
          },
        },
        body: { body: submitBody },
      }
    );

    if (responseHasura.error || !responseHasura.response.ok) {
      if (
        responseHasura.response.status !== 503 &&
        responseHasura.response.headers.get("X-Zvjs-Offline") !== "offline"
      ) {
        error = true;
        break;
      }
    }

    const responseSzoo = await SZOO_Post("/api/Ziadosti/Create", {
      body: submitBody,
    });

    // TODO: Mozno zobrazit uzivatelovi inu hlasku ako tu, ze ziadost
    //       podal uspesne, lebo my v offline rezime nevieme povedat,
    //       ze mu ju system akceptoval (vklude to moze skoncit s chybou)
    //       pri neskorsej synchronizacii
    //       mozno riesit pomocou ineho ActionResultTypes (SUCCESS_OFFLINE ?)

    if (responseSzoo.error || !responseSzoo.data.isCreated) {
      if (
        responseSzoo.response.status !== 503 &&
        responseSzoo.response.headers.get("X-Zvjs-Offline") !== "offline"
      ) {
        error = true;
        break;
      }
    }
  }

  if (error) {
    return {
      type: ActionResultTypes.ERROR,
      message: "notifikacie.nepodariloSaOdoslat",
      action: RequestActionsType.SUBMIT_NEW_REQUEST,
    };
  } else {
    return {
      type: ActionResultTypes.SUCCESS,
      action: RequestActionsType.SUBMIT_NEW_REQUEST,
    };
  }
}

export interface SummarySubmitData {
  typZiadosti: string;
  datumPodania: string;
  idZiadosti: string;
  klientId: string;
  zdroj: string;
  ustavId: number;
  // you do not know question ids and values in advance
  [key: string]: unknown;
}

const Summary: React.FC<SummaryProps> = ({ setCurrentPage }) => {
  const { tui, tuiz } = useUITranslation();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { openSnackbar } = useSnackbar();
  const navigation = useNavigation();
  const submitResult = useActionData() as RequestActionResult | undefined;
  const dispatch = useDispatch<AppDispatch>();

  const summaryData = useSelector(
    questionnaireRedux.selectors.getSummaryData()
  );
  const summarySubmitData = useSelector(
    questionnaireRedux.selectors.getSummarySubmitData()
  );
  const hasQuestionnaireAnyItems = useSelector(
    questionnaireRedux.selectors.getHasQuestionnaireAnyItems
  );

  console.debug("SUMMARY RERENDER");

  useEffect(() => {
    // if submit succeeded redirect user to a new step
    if (
      submitResult?.type === ActionResultTypes.SUCCESS &&
      submitResult?.action === RequestActionsType.SUBMIT_NEW_REQUEST
    ) {
      setCurrentPage((prevState) => prevState + 1);
      navigate("#confirmation");
    }

    if (
      submitResult?.type === ActionResultTypes.ERROR &&
      submitResult.message
    ) {
      openSnackbar(tuiz(submitResult.message), "error");
    }
  }, [navigate, openSnackbar, setCurrentPage, submitResult, tuiz]);

  const onSubmitRequestClickHandler = () => {
    if (summarySubmitData) {
      const toSubmit: ActionBodyWrapper = {
        action: RequestActionsType.SUBMIT_NEW_REQUEST,
        data: summarySubmitData,
      };

      submit(JSON.parse(JSON.stringify(toSubmit)), {
        method: "POST",
        encType: "application/json",
      });
    }
  };
  const onLinkClickHandler = (id: string) => {
    setCurrentPage(RequestPages.QUESTIONNAIRE);
    navigate("#questionnaire");
    dispatch(
      questionnaireReducer.sliceActions.setEditQuestionnaire({
        questionId: id,
      })
    );
  };

  return (
    <Stack spacing={5}>
      <ZvjsSummaryTable>
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.osobneUdaje")}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.menoPriezvisko")}
          value={summaryData.userPersonalData.fullName as string}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.datumNarodenia")}
          value={summaryData.userPersonalData.birthDate as string}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.udajeZiadosti")}
        />
        <ZvjsSummaryTableRequestComponent
          label={"Žiadosť"}
          value={summaryData.request.title as string}
          borderBottom={true}
          textAlign={"left"}
        />
        {summaryData.request.items.map((item, index) => {
          if (item.type === ZvjsSummaryItemType.ROW) {
            return (
              <ZvjsSummaryTableRequestComponent
                key={index}
                label={item.label ?? ""}
                value={item.answerText as string}
                borderBottom={index !== summaryData.request.items.length - 1}
              >
                <Link
                  component="button"
                  onClick={() => onLinkClickHandler(item.id)}
                >
                  {"Zmeň"}
                </Link>
              </ZvjsSummaryTableRequestComponent>
            );
          }
          if (item.type === ZvjsSummaryItemType.SECTION) {
            return (
              <ZvjsSummaryRequestSection
                key={index}
                label={item.label}
                onEditClick={
                  item?.id !== undefined
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      () => onLinkClickHandler(item.id!)
                    : undefined
                }
                isBorder={index !== summaryData.request.items.length - 1}
              >
                {item.items.map((sectionItem, sectionIndex) => (
                  <ZvjsSummaryTableRequestComponent
                    key={sectionIndex}
                    label={sectionItem.label ?? ""}
                    value={sectionItem.answerText as string}
                    borderBottom={false}
                    dense
                  />
                ))}
              </ZvjsSummaryRequestSection>
            );
          }
          console.warn("Unhandled summary item type");
          return <></>;
        })}
      </ZvjsSummaryTable>

      <div>
        <Typography fontSize={28} fontWeight={700}>
          {tuiz("ziadostiVseobecne.potvrdenieUdajov")}
        </Typography>
        <Typography>{tuiz("notifikacie.zavaznePotvrdenie")}</Typography>
      </div>

      <Stack direction="row" spacing={2}>
        <ZvjsButton
          disabled={isSubmitting(navigation)}
          zvjsVariant={"secondaryAction"}
          text={capitalize(tui("podať žiadosť"))}
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={onSubmitRequestClickHandler}
        />
        <ZvjsButton
          disabled={isSubmitting(navigation)}
          zvjsVariant={"cancelAction"}
          text={capitalize(tui("zrušiť žiadosť"))}
          startIcon={<ClearIcon style={{ height: 42, width: 42 }} />}
          onClick={() => navigate(hasQuestionnaireAnyItems ? -3 : -2)}
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
