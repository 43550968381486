import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async (id: string) => {
  const { EOO_Post } = await API_Clients();

  const data = await EOO_Post("/api/VazbyATresty/DetailVazbaData", {
    body: {
      id: Number.parseInt(id) ?? 1,
      ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
    },
  });

  return {
    vazba: data.data,
  };
};

export const VazbaDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

interface VazbaDetailProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const VazbaDetail = (props: VazbaDetailProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("detail väzby"))}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={capitalize(tui("spisová značka"))}
              value={props.data.vazba?.spisovaZnacka ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("začiatok väzby"))}
              value={
                props.data.vazba?.zaciatokVazby
                  ? new Date(props.data.vazba?.zaciatokVazby).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("dátum ukončenia"))}
              value={
                props.data.vazba?.datumUkoncenia
                  ? new Date(props.data.vazba?.datumUkoncenia).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("preukázané kolúzne správanie"))}
              value={
                props.data.vazba?.preukazaneKoluzneSpravanie
                  ? tui("áno")
                  : tui("nie")
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("poznámka"))}
              value={props.data.vazba?.poznamka ?? ""}
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(VazbaDetail);
