import {
  UserCalcData,
  ZvjsAnswers,
  ZvjsAnswerValueType,
  ZvjsQuestionnaireTemplate,
  ZvjsValidationError,
} from "../../../redux/model";
import reusable, { ReusableValidationType } from "./validations/reusable";
import custom, { CustomValidationType } from "./validations/custom";

const validations: ValidationType = {
  ...reusable,
  ...custom,
};

export type ValidationType = {
  [key in ReusableValidationType | CustomValidationType]?: ({
    newAnswer,
    questionId,
    questionnaire,
    userCalcData,
    answers,
  }: {
    newAnswer: ZvjsAnswerValueType;
    questionId: string;
    questionnaire: ZvjsQuestionnaireTemplate;
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
  }) => ZvjsValidationError | undefined;
};

export default validations;
