import { Grid, Stack, Typography } from "@mui/material";
import { ZvjsButton, ZvjsNotification } from "../../common";
import React, { useEffect, useMemo, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { defer, useNavigate } from "react-router-dom";
import { withLoader } from "../../hoc/withLoader";
import { API_Clients } from "../../store/context/dataApi/Data";
import moment from "moment";
import { ZvjsNotificationType } from "../../common/components/ZvjsNotification";
import { WebDavFileType } from "../../utils/webDav";
import { useSnackbar } from "../../store/context/snackbar-context";
import { allIntranetFilesFilteredForDevice } from "../../utils/intranetFiles";
import { isKiosk } from "../../store/context/envConfigContext";
import { ReactComponent as DetailIcon } from "../../assets/icons/Other/detail.svg";
import { ReactComponent as AcceptIcon } from "../../assets/icons/Other/accept.svg";
import { INTRANET_FILE_BROWSER_ROUTER_PATH } from "./NastenkaPages/IntranetPrehliadacSuborov";

const loadData = async () => {
  const { CIS_Post, EOO_Get, HASURA_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisUstavZvjs/List", {
      body: {
        filters: [
          {
            aktualny: true,
            kodKontaktneUdajeUstavuId: Number(
              localStorage.getItem("klientUstavId")
            ),
          },
        ],
      },
    }), // 0
    allIntranetFilesFilteredForDevice(
      Number.parseInt(localStorage.getItem("klientUstavId") ?? ""),
      isKiosk()
    ), // 1
    EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
      params: {
        query: {
          Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          UstavZvjsId:
            Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }), // 2
    HASURA_Post("/api/rest/get-kiosk-notifikacie-o-suboroch", {
      body: {
        idKlienta: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
        idUstavu:
          Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
      },
    }), // 3
    //AUDITING_Get("/api/CommonCiselniky/List"),
  ]);

  const ustavKod = data[0].data?.records
    ?.find(
      (i) =>
        i.kodKontaktneUdajeUstavuId ===
        Number(localStorage.getItem("klientUstavId"))
    )
    ?.kod?.trim();

  const allNastenkaFiles = data[1]
    .filter((item) => item.type === WebDavFileType.file)
    .filter((file) => !file.name.includes("/Kniznica/"))
    .filter((file) => !file.name.includes("/Bezpecny_internet/"))
    .filter(
      (file) =>
        !file.name.match(
          ".*/Interne_predpisy_ZVJS_(KIOSK|Tablety)/zoznam_kiosk.json$"
        )
    );

  const nastenkaFiles = allNastenkaFiles.filter(
    (file) =>
      // if the file is not yet stored in hasura
      !data[3].data?.kioskNotifikacieOSuboroch?.find(
        (f) => f.nazovSuboru === file.name
      ) ||
      // if the file is stored in hasura but not yet acknowledged
      data[3].data?.kioskNotifikacieOSuboroch?.find(
        (f) => f.nazovSuboru === file.name
      )?.zobrateNaVedomie === false
  );

  return {
    zakladneUdajeKlienta: data[2],
    ustavKod: ustavKod,
    allNastenkaFiles: allNastenkaFiles,
    nastenkaFiles: nastenkaFiles,
    notifikacieOSuboroch: data[3],
  };
};

export const NotifikacieLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface NotifikacieProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Notifikacie = (props: NotifikacieProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const [windowHeight, setWindowHeight] = useState<string>(
    window.innerWidth <= 920 ? "22em" : "18em"
  );

  const [showAllNotifications, setShowAllNotifications] =
    useState<boolean>(false);

  const nastenkaFiles = useMemo(
    () =>
      showAllNotifications
        ? props.data.allNastenkaFiles
        : props.data.nastenkaFiles,
    [
      props.data.allNastenkaFiles,
      props.data.nastenkaFiles,
      showAllNotifications,
    ]
  );

  const notifikacieOSuboroch = useMemo(
    () => props.data.notifikacieOSuboroch,
    [props.data.notifikacieOSuboroch]
  );

  const vziatNaVedomieVsetky = useMemo(
    () =>
      nastenkaFiles
        // filter away those already acknowledged
        .filter(
          (item) =>
            !notifikacieOSuboroch.data?.kioskNotifikacieOSuboroch?.find(
              (i) => i.nazovSuboru === item.name
            )?.zobrateNaVedomie
        )
        .map((item) => {
          return {
            nazovSuboru: item.name,
            zobrateNaVedomie: true,
            datumZobratiaNaVedomie: new Date().toISOString(),
            idKlienta: props.data.zakladneUdajeKlienta.data?.data?.id,
            idUstavu: props.data.zakladneUdajeKlienta.data?.data?.ustavZvjsId,
            datumModifikacie: item.lastModification,
          };
        }),
    [nastenkaFiles, props.data.zakladneUdajeKlienta]
  );

  useEffect(() => {
    // if there are no notifications, go to dashboard
    if (props.data.nastenkaFiles.length === 0) {
      navigate("/auth");
    }
  }, [props.data.nastenkaFiles]);

  return (
    <Grid container direction={"column"} p={5} pt={2} mb={12} spacing={2}>
      <Grid
        item
        sx={{ maxHeight: "calc(100vh - 350px)", overflowY: "auto" }}
        spacing={2}
      >
        <Stack direction={"column"}>
          <Typography variant={"h1"}>
            {capitalize(
              tui(`Nové oznámenia k ${moment().format("DD.MM.YYYY")}`)
            )}
          </Typography>
          <Typography variant={"body1"}>
            {capitalize(
              // tui(
              //   `Od posledného prihlásenia (${moment().format(
              //     "DD.MM.YYYY"
              //   )}) ste dostali nasledovné oznámenia`
              // )
              tui(`Od posledného prihlásenia ste dostali nasledovné oznámenia`)
            )}
          </Typography>

          {/* <Typography variant={"subtitle1"} sx={{ lineHeight: 1.2 }}>
            {tui("prihlasenie.beriemNaVedomie")}
            {tui("prihlasenie.neprecitaneOznamenia")}
            {tui("prihlasenie.zoznamNovychOznameni")}
            {tui("prihlasenie.notifikacnaUdalosti")}
          </Typography> */}
        </Stack>

        <Typography variant={"h4"} mb={3}>
          {capitalize(tui("Všeobecné oznámenia"))}
        </Typography>
        <Stack spacing={3}>
          {nastenkaFiles.map((file, index) => (
            <ZvjsNotification
              key={index}
              type={ZvjsNotificationType.BOARD}
              title={
                file.name.includes(`/Audionahravky/`)
                  ? tui("Nová audionahrávka")
                  : file.name.includes(`/BalikyAListy/`)
                    ? tui("Nový dokument v Balíky a listy")
                    : file.name.includes(
                          `DennaTlacACasopisy_${props.data.ustavKod}`
                        )
                      ? tui("Nový dokument v Denná tlač a časopisy")
                      : file.name.match(`.*/IneSubory_\\d+/.*`)
                        ? tui("Nový súbor v Iné súbory")
                        : file.name.includes(`/InformacneBrozury/`)
                          ? tui("Nová informačná brožúra")
                          : file.name.includes(
                                `KulturnoOsvetoveAktivity_${props.data.ustavKod}`
                              )
                            ? tui("Nová kultúrno-osvetová aktivita")
                            : file.name.includes(
                                  `OchranaPrav_${props.data.ustavKod}`
                                )
                              ? tui("Nový dokument v sekcii Ochrana práv")
                              : file.name.includes(`/Telefonovanie/`)
                                ? tui("Nový dokument v sekcii Telefonovanie")
                                : file.name.includes(
                                      `Ustavny_poriadok_${props.data.ustavKod}`
                                    )
                                  ? tui("Nový Ústavný poriadok")
                                  : file.name.includes(`/Prirucka/`)
                                    ? tui("Nová príručka")
                                    : file.name.includes(
                                          `/Interne_predpisy_ZVJS_`
                                        )
                                      ? tui("Nový interný predpis ZVJS")
                                      : "Bez kategórie"
              }
              // dateAndTime={"20.04.2022 - 09:41"}
              dateAndTime={moment(file.lastModification).format(
                "DD.MM.YYYY - HH:MM"
              )}
              description={
                <>
                  <Typography variant={"body1"} sx={{ display: "inline" }}>
                    {capitalize(
                      tui(`Na nástenke bol zverejnený nový dokument` + " ")
                    )}
                  </Typography>
                  <Typography variant={"h5"} sx={{ display: "inline" }}>
                    {decodeURIComponent(file.name).match(`[^/]+$`)?.at(0) ?? ""}
                  </Typography>
                </>
              }
              onClickDetail={() => {
                navigate(
                  `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/` +
                    encodeURIComponent(file.name)
                );
              }}
              acknowledgeDisabled={
                notifikacieOSuboroch.data?.kioskNotifikacieOSuboroch?.find(
                  (i) => i.nazovSuboru === file.name
                )?.zobrateNaVedomie
              }
              onClickAcknowledge={async () => {
                const { HASURA_Post } = await API_Clients();
                const notifikaciaResult = await HASURA_Post(
                  "/api/rest/insert-kiosk-notifikacie-o-suboroch",
                  {
                    body: {
                      notifikacieOSuboroch: [
                        {
                          nazovSuboru: file.name,
                          zobrateNaVedomie: true,
                          datumZobratiaNaVedomie: new Date().toISOString(),
                          idKlienta:
                            props.data.zakladneUdajeKlienta.data?.data?.id,
                          idUstavu:
                            props.data.zakladneUdajeKlienta.data?.data
                              ?.ustavZvjsId,
                          datumModifikacie: file.lastModification,
                        },
                      ],
                    },
                  }
                );

                if (notifikaciaResult.response.ok) {
                  openSnackbar(
                    `Dokument ${file.name
                      .match(`[^/]+$`)
                      ?.at(0)} ste zobrali na vedomie.`,
                    "success"
                  );
                } else {
                  if (
                    notifikaciaResult.response.status === 503 &&
                    notifikaciaResult.response.headers.get("X-Zvjs-Offline") ===
                      "offline"
                  ) {
                    openSnackbar(
                      `Požiadavka na vziatie dokumentu ${file.name
                        .match(`[^/]+$`)
                        ?.at(
                          0
                        )} na vedomie bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.`,
                      "success"
                    );
                  } else {
                    openSnackbar(
                      `Dokument ${file.name
                        .match(`[^/]+$`)
                        ?.at(0)} sa nepodarillo zobrať na vedomie`,
                      "error"
                    );
                  }
                }
              }}
            />
          ))}
        </Stack>
      </Grid>

      <Grid item sx={{ backgroundColor: "transparent" }}>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"flex-end"}
          marginRight={15}
          sx={{ backgroundColor: "transparent" }}
        >
          <ZvjsButton
            zvjsVariant="secondaryAction"
            text={capitalize(tui("beriem všetky na vedomie"))}
            startIcon={
              <AcceptIcon style={{ fill: "white", width: 28, height: 28 }} />
            }
            onClick={async () => {
              const { HASURA_Post } = await API_Clients();

              const notifikaciaResult = await HASURA_Post(
                "/api/rest/insert-kiosk-notifikacie-o-suboroch",
                {
                  body: {
                    notifikacieOSuboroch: vziatNaVedomieVsetky,
                  },
                }
              );

              if (notifikaciaResult.response.ok) {
                openSnackbar(
                  `Všetky oznámenia ste zobrali na vedomie.`,
                  "success"
                );
              } else {
                openSnackbar(
                  `Všetky oznámenia sa nepodarillo zobrať na vedomie`,
                  "error"
                );
              }
              navigate("/auth");
            }}
          />
          <ZvjsButton
            zvjsVariant="secondaryAction"
            text={capitalize(tui("zobraziť všetky oznámenia"))}
            startIcon={
              <DetailIcon style={{ fill: "white", width: 28, height: 28 }} />
            }
            onClick={() => {
              setShowAllNotifications(!showAllNotifications);
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(Notifikacie);
