import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";
import { CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod } from "../../specs/countersValues";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050501",
  basicInfo: [
    {
      text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.zakladneInfo",
    },
  ],
  dataCalculations: {
    SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050501_HAS_REFUSED_NEW_DEVICE_OF_TYPE_OTHER_IN_PAST_3_MONTHS: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050501_CAN_REQUEST_DEVICE_REVISION_CONTROL: {
      calculateAt: Calculate.LIVE,
    },
  },
  counters: [
    "/api/CisDruhElektrospotrebica/List",
    "/api/CisDovodPodaniaZiadostiElektrospotrebic/List",
    "/api/CisZdrojNapajania/List",
  ],
  items: [
    {
      id: "dovodPodaniaZiadosti",
      title:
        "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDovodPodaniaZiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodPodaniaZiadostiElektrospotrebic/List",
        textKey: "dovod",
        optValues: [
          {
            id: "POUZ",
          },
          {
            id: "REVIZ",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050501_CAN_REQUEST_DEVICE_REVISION_CONTROL",
                conditionMetWhenValueIs: true,
              },
            ],
          },
        ],
      },
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Chcem začať používať elektrický spotrebič",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodPodaniaZiadosti",
          value: ["POUZ"],
        },
      ],
      items: [
        {
          id: "druhElektrickehoSpotrebica",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica",
          type: ZvjsQuestionTypes.DROPDOWN_NEW,
          isRequired: true,
          options: {
            counterKey: "/api/CisDruhElektrospotrebica/List",
            textKey: "druh",
            optValues: [
              {
                id: "HOLS",
              },
              {
                id: "VLASS",
              },
              {
                id: "PVRK",
              },
              {
                id: "RADIO",
              },
              {
                id: "TV",
              },
              {
                id: "INY",
                conditions: [
                  {
                    type: ZvjsConditionTypes.FUNCTION,
                    conFunc:
                      "SC_KIO_050501_HAS_REFUSED_NEW_DEVICE_OF_TYPE_OTHER_IN_PAST_3_MONTHS",
                    conditionMetWhenValueIs: false,
                  },
                ],
              },
            ],
          },
        },
        {
          id: "NazovInehoElektrickehoSpotrebica",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelInyElektrickySpotrebic",
          type: ZvjsQuestionTypes.TEXT,
          isRequired: true,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: [CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaRadioPrijimac",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: ["RADIO"],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaTV",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: ["TV"],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaInyTypSpotrebica",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: [CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaDuplicitneEvidovanySpotrebic",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "zdrojNapajania",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelZdrojNapajania",
          type: ZvjsQuestionTypes.DROPDOWN_NEW,
          isRequired: true,
          options: {
            counterKey: "/api/CisZdrojNapajania/List",
            textKey: "typ_zdroja",
            optValues: [
              {
                id: "SIET",
              },
              {
                id: "BATER",
              },
            ],
          },
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaZasadyPouzivania",
          flag: ZvjsLabelFlagTypes.INFO,
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title:
        "Potrebujem vykonať opakovanú (revíznu) kontrolu už používaného spotrebiča",
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaNaVedomieRevizia",
          flag: ZvjsLabelFlagTypes.INFO,
        },
        {
          id: "evidovanyElektrickySpotrebicId",
          type: ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID,
          isRequired: true,
          title: "Evidované elektrické spotrebiče",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodPodaniaZiadosti",
          value: ["REVIZ"],
        },
      ],
    },
  ],
};
