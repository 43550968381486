import React, { useCallback, useContext } from "react";

type ModalContextType = {
  modalOpen: boolean;
  modalBody: React.ReactNode;
  openModal: (body: React.ReactNode) => void;
  closeModal: () => void;
};
const ModalContext = React.createContext<ModalContextType | null>(null);

export const useModal = () => useContext(ModalContext) as ModalContextType;

type ModalContextInterface = {
  children: React.ReactNode;
};

// const ModalContextProvider = React.createContext<ModalContextInterface | null>(null);

export const ModalContextProvider: React.FC<ModalContextInterface> = ({
  children,
}) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalBody, setModalBody] = React.useState<React.ReactNode>(<></>);

  const openModal = (body: React.ReactNode) => {
    setModalBody(body);
    setModalOpen(true);
  };

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        modalBody,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
