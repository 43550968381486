import React from "react";
import { ZvjsAlert, ZvjsCheckBox } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { AppDispatch } from "../../../../../../store/redux";
import { ZvjsCheckBoxStateValues } from "../../../../../components/ZvjsCheckBox";
import { Box, Grid } from "@mui/material";
import { getSeverityOfValidationFlag } from "../../../../redux/model";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface CheckBoxQuestionProps {
  location: number[];
}

const SingleCheckBoxQuestion: React.FC<CheckBoxQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(`SINGLE CHECK BOX QUESTION RERENDER ${location.join("-")}`);

  const singleCheckBoxData = useSelector(
    questionnaireRedux.selectors.getSingleCheckBoxDisplayData(location)
  );
  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(singleCheckBoxData.id)
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(singleCheckBoxData.id)
  );

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    dispatch(
      questionnaireRedux.actions.addNewSingleCheckBoxAnswer(
        location,
        singleCheckBoxData.id,
        checked
      )
    );
  };

  return (
    <Grid item xs>
      <ZvjsCheckBox
        label={""}
        onChange={onChangeHandler}
        data={[
          {
            label: tuiz(singleCheckBoxData.title),
            key: singleCheckBoxData.id,
            checked: answerVal as boolean | undefined,
          },
        ]}
        offset={3}
      />
      {validationError && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationError?.type)}
          >
            {validationError.message}
          </ZvjsAlert>
        </Box>
      )}
    </Grid>
  );
};

export default SingleCheckBoxQuestion;
