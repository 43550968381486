import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050802",
  basicInfo: [
    {
      text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.zakladneInfo",
    },
  ],
  dataCalculations: {
    SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050802_FOOD_ALREADY_REQUESTED: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050802_CLOTHING_ALREADY_REQUESTED: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SECTION,
      title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelOdev",
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaOdevAObuv",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_CLOTHING_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
        },
        {
          id: "ziadamOPoskytnutieOdevuObuvi",
          title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelOdev",
          type: ZvjsQuestionTypes.RADIO,
          isRequired: true,
          hideTitle: true,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_CLOTHING_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
          options: [
            {
              id: "SC_KIO_050802_QUES_01_ANS_01",
              label:
                "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelZiadamOposkytnutie",
            },
            {
              id: "SC_KIO_050802_QUES_01_ANS_02",
              label:
                "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelNeziadamOposkytnutie",
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaExistujucaZiadostOdevObuv",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_CLOTHING_ALREADY_REQUESTED",
              conditionMetWhenValueIs: true,
            },
          ],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovne",
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaCestovne",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
        },
        {
          id: "cestovneCestovnyListok",
          type: ZvjsCustomQuestionTypes.SC_KIO_050802_TRAVEL_EXPENSES_RADIO,
          title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovne",
          isRequired: true,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaExistujucaZiadostCestovne",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED",
              conditionMetWhenValueIs: true,
            },
          ],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelStrava",
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaStrava",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_FOOD_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
        },
        {
          id: "ziadostOStravu",
          title: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelStrava",
          type: ZvjsQuestionTypes.RADIO,
          hideTitle: true,
          isRequired: true,
          validations: ["MAX_ANSWER_LENGTH_150"],
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_FOOD_ALREADY_REQUESTED",
              conditionMetWhenValueIs: false,
            },
          ],
          options: [
            {
              id: "SC_KIO_050802_QUES_03_ANS_01",
              label:
                "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelZiadamOposkytnutie",
            },
            {
              id: "SC_KIO_050802_QUES_03_ANS_02",
              label:
                "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelNeziadamOposkytnutie",
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOposkytnutiePomociPriPrepusteni.napovedaExistujucaZiadostStrava",
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050802_FOOD_ALREADY_REQUESTED",
              conditionMetWhenValueIs: true,
            },
          ],
        },
      ],
    },
  ],
};
