import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `Telefonovanie loadData datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { EOO_Get, EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    EPVVVT_Post("/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory", {
      body: {
        klientId: { eq: localStorage.getItem("klientObjectId") },
        datumHovoru:
          !!datumOd || !!datumDo
            ? {
                gte: datumOd,
                lte: datumDo,
              }
            : undefined,
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [telefonicke hovory]");
  }

  const detailData = await Promise.allSettled(
    data[0].data
      .filter((item) => !!item.volanaOsobaId)
      .map((item) => {
        return EOO_Get("/api/FyzickaOsoba/DetailData", {
          params: {
            query: {
              request: item.volanaOsobaId!,
            },
          },
        });
      })
  );

  return {
    telefonaty: data[0],
    details: detailData,
  };
};

export const TelefonovanieLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

interface TelefonovanieProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Telefonovanie = (props: TelefonovanieProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(
      `Telefonovanie onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui("osobneUdaje.kategorieOsobnychUdajov.telefonovanie")
            )}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={tui("vyhľadať v telefonátoch")}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={onSearch}
        />

        <ZvjsTable
          data={{
            header: [
              {
                value: capitalize(tui("dátum hovoru")),
                align: "left",
              },
              {
                value: capitalize(tui("dĺžka hovoru")),
                align: "left",
              },
              {
                value: capitalize(tui("prevolaná suma [EUR]")),
                align: "left",
              },
              {
                value: capitalize(tui("volaná osoba")),
                align: "left",
              },
            ],
            body:
              props.data.telefonaty.data
                .sort(
                  (p1, p2) =>
                    new Date(p2.datumHovoru ?? new Date()).valueOf() -
                    new Date(p1.datumHovoru ?? new Date()).valueOf()
                )
                .map((telefonat) => {
                  const rd: RowData = {
                    row: [
                      {
                        value:
                          new Date(
                            telefonat.datumHovoru ?? new Date()
                          ).zvjsToString() +
                          " " +
                          new Date(
                            telefonat.datumHovoru ?? new Date()
                          ).toLocaleTimeString("sk-SK"),
                        align: "left",
                      },
                      {
                        value: telefonat.dlzkaTrvania ?? "",
                        align: "left",
                      },
                      {
                        value: telefonat.prevolanaSuma?.toFixed(2) ?? "",
                        align: "left",
                      },
                      {
                        value: (() => {
                          const filteredDetails = props.data.details.map(
                            (item) =>
                              item.status === "fulfilled"
                                ? item.value.data?.data
                                : undefined
                          );

                          // const filteredDetails2 = filteredDetails.filter(
                          //   (item) => !!item
                          // );

                          const detailOsoba = telefonat.volanaOsobaId
                            ? filteredDetails.find(
                                (item) =>
                                  item?.fyzickaOsobaId ===
                                  telefonat.volanaOsobaId
                              )
                            : null;

                          return detailOsoba
                            ? `${detailOsoba?.meno ?? ""} ${
                                detailOsoba?.druheMeno ?? ""
                              } ${detailOsoba?.priezvisko ?? ""}`
                            : telefonat.volaneTelefonneCislo ?? "";
                        })(),
                        align: "left",
                      },
                    ],
                  };
                  return rd;
                }) ?? [],
          }}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="20rem">
          <ZvjsButton
            text={capitalize(tui("žiadosť o telefonovanie"))}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/13.00.01`);
            }}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Telefonovanie);
