import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import { capitalize } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export interface TabData {
  label: string;
  route: string;
}

interface ZvjsTabsRouterProps {
  data: TabData[];
  onChange?: (value: number) => unknown;
}

const ZvjsTabsRouter = (props: ZvjsTabsRouterProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(props.data[newValue].route, {
      replace: true,
    });
    props.onChange?.(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 3, borderColor: ZVJS_COLORS.GREY_2 }}>
        <Tabs
          variant="scrollable"
          scrollButtons={false}
          value={props.data.findIndex((item) =>
            location.pathname.includes(item.route)
          )}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {props.data.map((data, index) => (
            <Tab
              key={"tab" + index}
              label={capitalize(data.label)}
              style={{
                background: location.pathname.includes(data.route)
                  ? ZVJS_COLORS.BLUE
                  : ZVJS_COLORS.WHITE,
                marginRight: 8,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
                fontWeight: 700,
                fontSize: 22,
                color: location.pathname.includes(data.route)
                  ? ZVJS_COLORS.WHITE
                  : ZVJS_COLORS.BLACK,
                textTransform: "none",
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </>
  );
};

export default ZvjsTabsRouter;
