import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import questionnaireRedux from "../../../../../../redux";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import {
  RequestEditIcon,
  ZvjsButton,
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  SC_KIO_050101_AddPersonForm as AddPersonForm,
  SC_KIO_050101_EditPersonVideoCallForm as EditPersonVideoCallForm,
} from "../../../forms/index";

import { useModal } from "../../../../../../../../store/context/modal-context";
import { useSubmit } from "react-router-dom";
import { ActionBodyWrapper } from "../../../../../../../../router/ActionBodyWrapper";
import { RequestActionsType } from "../../../../../../Request";

const SC_KIO_050101_VideoCallVisitPeopleGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { openModal } = useModal();
  const { tuiz } = useUITranslation();
  const submit = useSubmit();

  console.debug(
    `SC_KIO_050101 VIDEO CALL VISIT PEOPLE GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getVideoCallVisitPeopleGridDisplayData(
      location
    ),
    shallowEqual
  );

  const closePeopleList = useSelector(
    questionnaireRedux.selectors.getVideoCallVisitClosePeopleList(),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const basicClientData = useSelector(
    questionnaireRedux.selectors.getVideoCallBasicClientData(),
    shallowEqual
  );

  const closePersonRelationCounter = useSelector(
    questionnaireRedux.selectors.getVideoCallClosePersonRelationTypeCounter(),
    shallowEqual
  );

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    if (checked) {
      const answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy.push(key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    } else {
      let answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy = answersCopy.filter((answer) => answer !== key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    }
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
    {
      align: "right",
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tuiz("priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tuiz("ziadost.ziadostOnavstevu.labelVztahKosobe")),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOnavstevu.labelVideohovorKontakt")
      ),
      ...dataStyle[3],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOnavstevu.labelOsobyNaVideonavstevu")
      ),
      ...dataStyle[4],
    },
    {
      value: "",
      ...dataStyle[5],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const personDisplayData of questionData.peopleList) {
    const isChecked = answerVal?.some((answer) => {
      return answer === personDisplayData.id.toString();
    });

    const getRequestEditIcon = () => {
      const closePersonData = closePeopleList.find(
        (closePerson) => closePerson.fyzickaOsobaId === personDisplayData.id
      );

      if (closePersonData) {
        return (
          <RequestEditIcon
            onClick={(event) =>
              openModal(
                <EditPersonVideoCallForm
                  closePersonData={closePersonData}
                  closePersonDisplayData={personDisplayData}
                  // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
                  onSubmit={onEditPersonVideoCallSubmit}
                  closePersonRelationCounter={closePersonRelationCounter}
                />
              )
            }
          />
        );
      }
      return "";
    };

    bodyData.push({
      row: [
        {
          value: personDisplayData.firstname ?? "",
          ...dataStyle[0],
        },
        {
          value: personDisplayData.surname ?? "",
          ...dataStyle[1],
        },
        {
          value: personDisplayData.relationText ?? "",
          ...dataStyle[2],
        },
        {
          value: personDisplayData.videoCallContact?.data ?? "",
          ...dataStyle[3],
        },
        {
          value: (
            <ZvjsCheckBox
              onChange={onChangeHandler}
              data={[
                {
                  key: personDisplayData.id.toString(),
                  checked: isChecked,
                },
              ]}
            />
          ),
          ...dataStyle[4],
        },
        {
          value: getRequestEditIcon(),
          ...dataStyle[5],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  const onEditPersonVideoCallSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  const onAddPersonSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      <ZvjsButton
        zvjsVariant={"requestAction"}
        text={tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby")}
        onClick={() => {
          openModal(
            <AddPersonForm
              // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
              onSubmit={onAddPersonSubmit}
              basicClientData={basicClientData}
              closePersonRelationCounter={closePersonRelationCounter}
            />
          );
        }}
        endIcon={
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />
        }
      />
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050101_VideoCallVisitPeopleGrid;
