import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../../../hoc/withLoader";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../router/LoaderError";

const loadData = async () => {
  const { CIS_Post, FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const typVeritela = await FEOO_Post("/api/Codelist/ListTypVeritela", {
    body: {
      filters: [],
      paging: {
        currentPage: 1,
        recordsPerPage: MAX_NUMBER_OF_ITEMS,
      },
      sorting: [{}],
    },
  });

  const data = await Promise.all([
    FEOO_Post("/api/Pohladavka/List", {
      body: {
        filters: [
          {
            pohladavkaZakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
              typVeritelaID: 1, // TODO: vid typVeritela - 1?
            },
          },
        ],
      },
    }),
    CIS_Post("/api/CisVeritelTyp/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [pohľadávky ústavu]");
  }

  return {
    pohladavky: data[0],
  };
};

export const PohladavkyUstavuLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface PohladavkyUstavuProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PohladavkyUstavu = (props: PohladavkyUstavuProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("pohľadávky ústavu"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("typ pohľadávky")),
              align: "left",
            },
            {
              value: capitalize(tui("pôvodná suma")),
              align: "left",
            },
            {
              value: capitalize(tui("zostatková suma")),
              align: "left",
            },
            {
              value: capitalize(tui("akcia")),
              align: "left",
            },
          ],
          body:
            props.data.pohladavky.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((pohladavka) => {
                const rd: RowData = {
                  row: [
                    {
                      value: pohladavka.typPohladavkyNazov ?? "",
                      align: "left",
                    },
                    {
                      value: pohladavka.povodnaSuma?.toFixed(2) + " Eur",
                      align: "left",
                    },
                    {
                      value: pohladavka.zostatkovaSuma?.toFixed(2) + " Eur",
                      align: "left",
                    },
                    {
                      value: (
                        <ZvjsButton
                          text={capitalize(tui("rozpis splátok"))}
                          zvjsVariant="secondaryAction"
                          startIcon={
                            <DetailIcon
                              style={{ fill: "white", width: 28, height: 28 }}
                            />
                          }
                          onClick={() => {
                            navigate(`${pohladavka.pohladavkaID}`);
                          }}
                        />
                      ),
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(PohladavkyUstavu);
