import { defer, useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { Stack } from "@mui/material";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../../assets/icons/Other/keyboard_arrow_right.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import {
  CiselnikListResultType,
  MAX_NUMBER_OF_ITEMS,
} from "../../../../store/context/dataApi/Data";
import { requestTemplates } from "../../../../common/request/requestTemplates";
import { ZVJS_COLORS } from "../../../../theme/zvjs_theme";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik,
} from "../../../../utils/helpers";
import i18n, { getCiselnikJazyk } from "../../../../locales/i18n";
import { RequestSzooCode } from "../../../../common/request/requestTemplates/requestTemplates";

const loadData = async () => {
  const MONEY_AND_FEES_REQUEST_CATEGORY_CODE = "5";

  const getPeniazeAPoplatkyZiadostiCounter = (
    subRequestsCounter?: CiselnikListResultType<"/api/CisPodkategoriaZiadosti/List">,
    requestsCounter?: CiselnikListResultType<"/api/CisTypZiadosti/List">
  ): CiselnikListResultType<"/api/CisTypZiadosti/List">["data"] => {
    // if any of the counters is undefined (not fetched), display error
    if (subRequestsCounter === undefined || requestsCounter === undefined) {
      throw new LoaderError();
    }

    // filter out all subCategories that belong to the specified category
    const moneyAndFeesSubCategories =
      subRequestsCounter?.data?.records?.filter(
        (subCategory) =>
          subCategory.kategoriaId?.toString() ===
          MONEY_AND_FEES_REQUEST_CATEGORY_CODE
      ) || [];

    const selectedRequestsCounter = {
      records: requestsCounter?.data?.records?.filter(
        (request) =>
          // show only supported requests
          request.kod !== undefined &&
          request.kod !== null &&
          requestTemplates[request.kod as `${RequestSzooCode}`] !== undefined &&
          // show requests that belong to the selected subCategories
          moneyAndFeesSubCategories.some(
            (subCategory) =>
              subCategory.kod === request.podKategoriaId?.toString()
          )
      ),
    };

    return selectedRequestsCounter;
  };

  const { CIS_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisPodkategoriaZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [číselník podkategória žiadosti]"
    );
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [číselník typ žiadosti]");
  }

  return {
    peniazeAPoplatkyZiadostiCounter: getPeniazeAPoplatkyZiadostiCounter(
      data[0],
      data[1]
    ),
  };
};

export const ZiadostiTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ZiadostiTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const ZiadostiTab = (props: ZiadostiTabProps) => {
  const { tui, getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();

  const dataStyleRequestsTable: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
    },
  ];

  const headerDataRequestsInCategory: Array<CellData> = [
    {
      value: capitalize(
        tui(
          "Názov žiadosti" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsTable[0],
    },
    {
      value: capitalize(
        tui(
          "Akcia" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsTable[1],
    },
  ];

  const getTableBody = () => {
    const counterCodes = getUniqueCodesFromCiselnik(
      props.data.peniazeAPoplatkyZiadostiCounter?.records ?? []
    );

    const requests = counterCodes.map((code) => {
      return {
        requestCode: code,
        title: getTranslationByLanguageCode(
          props.data.peniazeAPoplatkyZiadostiCounter?.records ?? [],
          getCiselnikJazyk(i18n.language),
          getFallbackJazyk(),
          code,
          "nazov"
        ),
      };
    });

    const bodyData: Array<RowData> = new Array<RowData>();
    requests.forEach((request) => {
      bodyData.push({
        row: [
          {
            value: request.title,
            ...dataStyleRequestsTable[0],
          },
          {
            value: (
              <ZvjsButton
                // TODO: add translation
                text={capitalize("vybrať žiadosť")}
                zvjsVariant="secondaryAction"
                endIcon={
                  <KeyboardArrowRightIcon
                    style={{ fill: ZVJS_COLORS.WHITE, width: 28, height: 28 }}
                  />
                }
                onClick={() => {
                  navigate(
                    `/auth/${Dashboard_SC_KIO_0200.Requests}/${request.requestCode}`
                  );
                }}
              />
            ),
            ...dataStyleRequestsTable[1],
          },
        ],
      });
    });

    return bodyData;
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={{
          header: headerDataRequestsInCategory,
          body: getTableBody(),
        }}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(ZiadostiTab);
