import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import { Grid, SelectChangeEvent } from "@mui/material";
import { ZvjsSelect } from "../../../../../../../components";
import CustomQuestionProps from "../CustomQuestionProps";

const SC_KIO_051403_PriorityQuestion: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  console.debug(
    `SC_KIO_051403 PRIORITY QUESTION RERENDER ${location.join("-")}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getPriorityQuestionDisplayData(location),
    shallowEqual
  );

  const selectedPriority = useSelector(
    questionnaireRedux.selectors.getSelectedPriority(location),
    shallowEqual
  );

  const onChangeHandler = (event: SelectChangeEvent) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        event.target.value
      )
    );
  };

  return (
    <Grid item xs>
      <Grid item xs>
        <ZvjsSelect
          id={`priority-combo-question-${location.join("-")}`}
          text={questionData.title}
          items={questionData.options}
          answerRequired={questionData.answerRequired}
          controlled={{
            selectedValue: selectedPriority ?? "",
            changeHandler: onChangeHandler,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SC_KIO_051403_PriorityQuestion;
