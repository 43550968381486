import { defer, useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  if (feooKlient.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [feoo klient]");
  }

  const vyzivovaciePovinnosti = await FEOO_Post(
    "/api/VyzivovaciaPovinnost/List",
    {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            },
          },
        ],
      },
    }
  );

  if (vyzivovaciePovinnosti.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [vyživovacie povinnosti]"
    );
  }

  return {
    vyzivovaciePovinnosti: vyzivovaciePovinnosti,
  };
};

export const VyzivovaciePovinnostiTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface VyzivovaciePovinnostiTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const VyzivovaciePovinnostiTab = (props: VyzivovaciePovinnostiTabProps) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={1} spacing={1}>
      <ZvjsTable
        data={{
          label: capitalize(tui("Prehľad vyživovacích povinností")),
          header: [
            {
              value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
              align: "left",
              width: "calc(100% / 2)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")
              ),
              align: "left",
              width: "calc(100% / 2)",
            },
          ],
          body:
            props.data.vyzivovaciePovinnosti.data?.records?.map(
              (vyzivovaciaPovinnost) => {
                const rd: RowData = {
                  row: [
                    {
                      value:
                        vyzivovaciaPovinnost.vyzivovanaOsoba
                          ?.split(" ")
                          .at(0) ?? "",
                      align: "left",
                      width: "calc(100% / 2)",
                    },
                    {
                      value:
                        vyzivovaciaPovinnost.vyzivovanaOsoba
                          ?.split(" ")
                          .at(1) ?? "",
                      align: "left",
                      width: "calc(100% / 2)",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        // TODO add translation
        hintText={" "}
      />
      <ZvjsButton
        text={capitalize(
          tui(
            "žiadosť o vykonanie zrážok z čistej pracovnej odmeny odsúdeného na úhradu výživného nezaopatrených detí"
          )
        )}
        zvjsVariant="secondaryAction"
        endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
        onClick={() => {
          navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/09.01.01`);
        }}
        style={{
          lineHeight: "1",
          textTransform: "none",
          textAlign: "left",
          width: "30rem",
          height: "6rem",
        }}
      />
    </Stack>
  );
};

export default withLoader(VyzivovaciePovinnostiTab);
