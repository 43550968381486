import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ZVJS_COLORS } from "../../../../theme/zvjs_theme";
import { ZvjsButton } from "../../../components";
import { ReactComponent as RequestsCategoriesIcon } from "../../../../assets/icons/Other/requests_categories.svg";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../../store/context/translation-context";

const Confirmation = () => {
  console.debug("CONFIRMATION RERENDER");
  const navigate = useNavigate();
  const { tuiz } = useUITranslation();

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          backgroundColor: ZVJS_COLORS.GREEN_2,
          borderRadius: "0.5rem",
          height: "7rem",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography
          justifyContent={"center"}
          variant={"h3"}
          color={ZVJS_COLORS.WHITE}
        >
          Žiadosť bola úspešne podaná
        </Typography>
      </Box>
      {/*to keep text together*/}
      <div>
        <Typography fontSize={28} fontWeight={700}>
          {tuiz("ziadostiVseobecne.coBudeNasledovat")}
        </Typography>
        <Typography fontSize={24} fontWeight={400}>
          <li>{tuiz("notifikacie.poslanaZiadost")}</li>
        </Typography>
      </div>
      <Stack spacing={2} direction={"row"}>
        <ZvjsButton
          zvjsVariant={"secondaryAction"}
          text={"Prejsť na úvodnú stránku"}
          startIcon={
            <HomeIcon
              style={{ height: 32, width: 32, fill: ZVJS_COLORS.WHITE }}
            />
          }
          onClick={() => navigate("/")}
        />
        <ZvjsButton
          zvjsVariant={"secondaryAction"}
          text={"Zoznam podaných žiadostí"}
          startIcon={
            <RequestsCategoriesIcon
              style={{ height: 27, width: 27, fill: ZVJS_COLORS.WHITE }}
            />
          }
          onClick={() => navigate("/auth/Requests?tab=1")}
        />
      </Stack>
    </Stack>
  );
};

export default Confirmation;
