import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  capitalize,
  Typography,
} from "@mui/material";
import { SynchronizationResultType } from "../store/context/useSynchronization";
import { useUITranslation } from "../store/context/translation-context";

type SynchronizationReportProps = {
  data: SynchronizationResultType;
};

const SynchronizationReport = (props: SynchronizationReportProps) => {
  const theme = useTheme();
  const { tui } = useUITranslation();

  return (
    <TableContainer sx={{ maxHeight: "80vh", maxWidth: "80vw" }}>
      <Table stickyHeader size="small" aria-label="synchronization items table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ bgcolor: "white" }}>
              {capitalize(tui("povinné dáta"))}
            </TableCell>
            <TableCell sx={{ bgcolor: "white" }}>
              {capitalize(tui("dáta"))}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.items.length > 0 ? (
            props.data.items
              .sort((a, b) =>
                a.success === b.success
                  ? a.syncAPI.localeCompare(b.syncAPI)
                  : a.success
                  ? 1
                  : -1
              )
              .map((item) => (
                <TableRow key={item.syncAPI}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={item.success ? {} : { color: "red" }}
                    >
                      {item.mandatory
                        ? capitalize(tui("áno"))
                        : capitalize(tui("nie"))}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={item.success ? {} : { color: "red" }}
                    >
                      {item.syncAPI}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {capitalize(tui("žiadne dáta"))}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SynchronizationReport;
