import { defer, useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  MoneyClaimAndAlimony_SC_KIO_03603,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { Grid, Stack } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { FEOO_Get } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  if (feooKlient.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [feoo klient]");
  }

  return {
    feooKlient: feooKlient,
  };
};

export const PohladavkyTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface PohladavkyTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PohladavkyTab = (props: PohladavkyTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(
                tui(
                  "Názov pohľadávky" // TODO: use proper KEY
                )
              ),
              align: "left",
            },
            {
              value: capitalize(
                tui(
                  "Suma pohľadávky" // TODO: use proper KEY
                )
              ),
              align: "right",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.vyplatnePasky VS osobneUdaje.blokacie
                  "Akcia" // TODO: use proper KEY
                )
              ),
              align: "right",
              width: 170,
            },
          ],
          body:
            [
              MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims,
              MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims,
              MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims,
              MoneyClaimAndAlimony_SC_KIO_03603.Alimonys,
              MoneyClaimAndAlimony_SC_KIO_03603.SummaryClaimAmount,
            ].map((typPohladavky) => {
              const rd: RowData = {
                row: [
                  {
                    value: capitalize(
                      tui(
                        typPohladavky ===
                          MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims
                          ? "osobneUdaje.pohladavky.pohladavkyGRZVJS"
                          : typPohladavky ===
                            MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims
                          ? "Zostatková suma pohľadávok ústavu"
                          : typPohladavky ===
                            MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims
                          ? "osobneUdaje.pohladavky.pohladavkyOstatni"
                          : typPohladavky ===
                            MoneyClaimAndAlimony_SC_KIO_03603.Alimonys
                          ? "osobneUdaje.pohladavky.pohladavkyVyzivne"
                          : "osobneUdaje.pohladavky.zostatkovaSuma"
                      )
                    ),
                    align: "left",
                  },
                  {
                    //value: moneyClaimSummary.amount.toFixed(2) + " Eur",
                    value:
                      typPohladavky ===
                      MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims
                        ? `${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokNP?.toFixed(
                            2
                          )} EUR`
                        : typPohladavky ===
                          MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims
                        ? `${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokGR_Zvjs?.toFixed(
                            2
                          )} EUR`
                        : typPohladavky ===
                          MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims
                        ? `${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokCP?.toFixed(
                            2
                          )} EUR`
                        : typPohladavky ===
                          MoneyClaimAndAlimony_SC_KIO_03603.Alimonys
                        ? `${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaVyzivneho?.toFixed(
                            2
                          )} EUR`
                        : `${(
                            (props.data.feooKlient.data?.data
                              ?.rozsireneUdajeKlienta
                              ?.zostatkovaSumaPohladavokNP ?? 0) +
                            (props.data.feooKlient.data?.data
                              ?.rozsireneUdajeKlienta
                              ?.zostatkovaSumaPohladavokGR_Zvjs ?? 0) +
                            (props.data.feooKlient.data?.data
                              ?.rozsireneUdajeKlienta
                              ?.zostatkovaSumaPohladavokCP ?? 0) +
                            (props.data.feooKlient.data?.data
                              ?.rozsireneUdajeKlienta
                              ?.zostatkovaSumaVyzivneho ?? 0)
                          ).toFixed(2)} EUR`,
                    align: "right",
                    bold: true,
                  },
                  {
                    value:
                      typPohladavky ===
                      MoneyClaimAndAlimony_SC_KIO_03603.SummaryClaimAmount ? (
                        ""
                      ) : (
                        <ZvjsButton
                          text={capitalize(tui("detail"))}
                          zvjsVariant="secondaryAction"
                          startIcon={
                            <DetailIcon
                              style={{ fill: "white", width: 28, height: 28 }}
                            />
                          }
                          onClick={() => {
                            navigate(typPohladavky);
                          }}
                        />
                      ),
                    align: "right",
                    width: 170,
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={540}
        variant={ZvjsTableVariant.NORMAL}
      />

      <Grid width="25rem">
        <ZvjsButton
          text={capitalize(tui("Žiadosť o úhradu"))}
          zvjsVariant="secondaryAction"
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={() =>
            navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/09.01.02`)
          }
        />
      </Grid>
    </Stack>
  );
};

export default withLoader(PohladavkyTab);
