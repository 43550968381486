import {
  UserCalcData,
  ZvjsAnswers,
  ZvjsAnswerValueType,
  ZvjsValidationError,
  ZvjsValidationFlagTypes,
} from "../../../../redux/model";
import { ValidationType } from "../validations";
import {
  CisDovodPodaniaZiadosti_Ine_Dovody_Kod,
  CisDovodPodaniaZiadosti_Rodinne_Dovody_Kod,
  CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod,
  CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
  CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod,
  CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod,
  CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
} from "../../../../../specs/countersValues";
import {
  SC_KIO_050501_ElectricDevice,
  SC_KIO_051403_client_has_refused_request_in_past_6_months,
} from "../calculations/custom";
import { SEND_MONEY_TO_WHOM_QUESTION_ID } from "../../../../requestTemplates/SC_KIO_050204_Ziadost o poukazanie penaznych prostriedkov v prospech osob alebo subjektov";

const SC_KIO_050207_validate_requested_amount = ({
  newAnswer,
  userCalcData,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
}): ZvjsValidationError | undefined => {
  const availableAccountAmount = userCalcData["AVAILABLE_ACCOUNT_AMOUNT"];

  if (availableAccountAmount !== undefined) {
    if (Number(newAnswer) > availableAccountAmount) {
      return {
        type: ZvjsValidationFlagTypes.ERROR,
        message:
          "Vyska ziadanej sumy nesmie presiahnut vysku stavu disponibilneho konta",
      };
    }
  }

  return undefined;
};

const SC_KIO_050208_validate_requested_amount = ({
  newAnswer,
  userCalcData,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
}): ZvjsValidationError | undefined => {
  const availableAccountAmount = userCalcData["AVAILABLE_AMOUNT_TO_SHOP"];

  if (availableAccountAmount !== undefined) {
    if (Number(newAnswer) > availableAccountAmount) {
      return {
        type: ZvjsValidationFlagTypes.ERROR,
        // TODO add translation
        message:
          "Vyska ziadanej sumy nesmie presiahnut vysku moznej sumy na nákup",
      };
    }
  }

  return undefined;
};

const SC_KIO_051403_validate_relocation_reason = ({
  newAnswer,
  userCalcData,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
}): ZvjsValidationError | undefined => {
  const hasRejectedRequest = userCalcData[
    "SC_KIO_051403_CLIENT_HAS_REFUSED_REQUEST_IN_PAST_6_MONTHS"
  ] as Awaited<
    ReturnType<typeof SC_KIO_051403_client_has_refused_request_in_past_6_months>
  >;

  if (
    newAnswer === CisDovodPodaniaZiadosti_Rodinne_Dovody_Kod &&
    hasRejectedRequest.familyReasons
  ) {
    return {
      type: ZvjsValidationFlagTypes.ERROR,
      message:
        "ziadost.ziadostOpremiestnenie.napovedaZakonnaPrekazakPremiestnenia",
    };
  }

  if (
    newAnswer === CisDovodPodaniaZiadosti_Ine_Dovody_Kod &&
    hasRejectedRequest.otherReasons
  ) {
    return {
      type: ZvjsValidationFlagTypes.ERROR,
      message:
        "ziadost.ziadostOpremiestnenie.napovedaZakonnaPrekazakPremiestnenia",
    };
  }

  return undefined;
};

const SC_KIO_050204_validate_amount_to_send = ({
  newAnswer,
  userCalcData,
  answers,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}): ZvjsValidationError | undefined => {
  if (
    (answers[SEND_MONEY_TO_WHOM_QUESTION_ID] ===
      CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod ||
      answers[SEND_MONEY_TO_WHOM_QUESTION_ID] ===
        CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod ||
      answers[SEND_MONEY_TO_WHOM_QUESTION_ID] ===
        CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod) &&
    Number(newAnswer as string) > userCalcData["POSSIBLE_WITHDRAWAL_AMOUNT"]
  ) {
    return {
      type: ZvjsValidationFlagTypes.ERROR,
      // TODO add error validation message
      message: "",
    };
  }

  if (
    (answers[SEND_MONEY_TO_WHOM_QUESTION_ID] ===
      CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod ||
      answers[SEND_MONEY_TO_WHOM_QUESTION_ID] ===
        CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod) &&
    Number(newAnswer as string) > userCalcData["AVAILABLE_ACCOUNT_AMOUNT"]
  ) {
    return {
      type: ZvjsValidationFlagTypes.ERROR,
      // TODO add error validation message
      message: "",
    };
  }

  return undefined;
};

export enum CustomValidationType {
  SC_KIO_050207_VALIDATE_REQUESTED_AMOUNT = "SC_KIO_050207_VALIDATE_REQUESTED_AMOUNT",
  SC_KIO_050208_VALIDATE_REQUESTED_AMOUNT = "SC_KIO_050208_VALIDATE_REQUESTED_AMOUNT",
  SC_KIO_051403_VALIDATE_RELOCATION_REASON = "SC_KIO_051403_VALIDATE_RELOCATION_REASON",
  SC_KIO_050204_VALIDATE_AMOUNT_TO_SEND = "SC_KIO_050204_VALIDATE_AMOUNT_TO_SEND",
}

const custom: ValidationType = {
  SC_KIO_050207_VALIDATE_REQUESTED_AMOUNT:
    SC_KIO_050207_validate_requested_amount,
  SC_KIO_050208_VALIDATE_REQUESTED_AMOUNT:
    SC_KIO_050208_validate_requested_amount,
  SC_KIO_051403_VALIDATE_RELOCATION_REASON:
    SC_KIO_051403_validate_relocation_reason,
  SC_KIO_050204_VALIDATE_AMOUNT_TO_SEND: SC_KIO_050204_validate_amount_to_send,
};

export default custom;

/**
 * helper function (this is not validation on its own) which checks whether user is already using device
 * @param requestedDeviceTypeCode type of new electric device which user wants to start using
 * @param userDevices list of user devices
 */
export const SC_KIO_05050_does_user_already_use_device = (
  requestedDeviceTypeCode: string,
  userDevices: SC_KIO_050501_ElectricDevice[]
) => {
  if (Array.isArray(userDevices) && requestedDeviceTypeCode !== undefined) {
    for (const userDevice of userDevices) {
      if (userDevice.typeCode === requestedDeviceTypeCode) {
        return true;
      }
    }
  }

  return false;
};
