import React from "react";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsTable } from "../../../common";
import {
  ZvjsAudioPlayer,
  ZvjsButton,
  ZvjsImageViewer,
  ZvjsPDFViewer,
} from "../../../common/components";
import { FileApiCallHeaders, targetUiApi } from "../../../utils/targetApi";
import { Module } from "../../../utils/module";
import { WebDavFileType } from "../../../utils/webDav";
import {
  AudioFileOutlined,
  FolderOutlined,
  InsertDriveFileOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import ZvjsTextViewer from "../../../common/components/ZvjsTextViewer";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { isKiosk } from "../../../store/context/envConfigContext";
import {
  allIntranetFilesFilteredForDevice,
  intranetDirectories,
} from "../../../utils/intranetFiles";
import { LoaderError } from "../../../router/LoaderError";
import useFileApi from "../../../store/context/useFileApi";

export const INTRANET_FILE_BROWSER_ROUTER_PATH = "IntranetPrehliadacSuborov";

const loadData = async (path: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getBlob } = useFileApi();

  const allIntranetFiles = await allIntranetFilesFilteredForDevice(
    Number.parseInt(localStorage.getItem("klientUstavId") ?? ""),
    isKiosk()
  );

  const webDavItem = allIntranetFiles.find(
    (i) => i.name === path || decodeURIComponent(i.name) === path
  );

  if (!webDavItem) {
    // TODO add translation
    throw new LoaderError("Informáciu o súbore sa nepodarilo získať.");
  }

  const intranetDirs = await intranetDirectories(
    Number(localStorage.getItem("klientUstavId")),
    isKiosk()
  );

  type InternalRegulationsZVJSMetadataType = {
    nazov: string;
    popis: string;
  }[];
  let metadata: InternalRegulationsZVJSMetadataType | undefined = undefined;

  if (
    decodeURIComponent(webDavItem.name).includes(intranetDirs.internePredpisy)
  ) {
    const metadataUrl = `${intranetDirs.internePredpisy}zoznam_kiosk.json`;
    const metadataBlob = await getBlob(metadataUrl);
    const metadataString = await metadataBlob?.text();
    metadata = JSON.parse(metadataString ?? "");
  }

  const fileApiCallHeaders = await FileApiCallHeaders();

  return {
    path: path,
    webDavItem: webDavItem,
    allIntranetFiles: allIntranetFiles,
    metadata: metadata,
    fileApiCallHeaders: fileApiCallHeaders,
  };
};

export const IntranetPrehliadacSuborovLoader = async (path: string) => {
  return defer({
    data: loadData(path),
  });
};

interface IntranetPrehliadacSuborovProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const IntranetPrehliadacSuborov = (props: IntranetPrehliadacSuborovProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isSupportedContentType = (contentType: string): boolean => {
    return (
      contentType.startsWith("audio/") ||
      contentType.startsWith("image/") ||
      contentType === "text/plain" ||
      contentType === "application/pdf"
    );
  };

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Stack>
            <Typography variant={"h1"}>
              {props.data.path.match(".*/Audionahravky/.*")
                ? capitalize(tui("audionahrávky"))
                : props.data.path.match(".*/BalikyAListy/.*")
                ? capitalize(tui("balíky a listy"))
                : props.data.path.match(".*/DennaTlacACasopisy_\\d+/.*")
                ? capitalize(tui("denná tlač a časopisy"))
                : props.data.path.match(".*/IneSubory_\\d+/.*")
                ? capitalize(tui("iné súbory"))
                : props.data.path.match(".*/InformacneBrozury/.*")
                ? capitalize(tui("informačné brožúry"))
                : props.data.path.match(".*/Interne_predpisy_ZVJS_.*/.*")
                ? capitalize(tui("interné predpisy ZVJS"))
                : props.data.path.match(
                    ".*/Kniznica/Vypozicne_hodiny_\\d+/vypozicne.+hodiny.txt"
                  )
                ? capitalize(tui("výpožičné hodiny knižnice"))
                : props.data.path.match(".*/KulturnoOsvetoveAktivity_\\d+/.*")
                ? capitalize(tui("kultúrno-osvetové aktivity"))
                : props.data.path.match(".*/OchranaPrav_\\d+/.*")
                ? capitalize(tui("ochrana práv"))
                : props.data.path.match(".*/Telefonovanie/.*")
                ? capitalize(tui("telefonovanie"))
                : props.data.path.match(
                    ".*/OrdinacneHodinyVAmbulancii_\\d+.txt"
                  )
                ? capitalize(tui("ordinačné hodiny v ambulancii"))
                : props.data.path.match(".*/Ustavny_poriadok_\\d+/.*")
                ? capitalize(tui("ústavný poriadok"))
                : capitalize("")}
            </Typography>
            {!props.data.path.match(
              ".*/Kniznica/Vypozicne_hodiny_\\d+/vypozicne.+hodiny.txt"
            ) &&
              !props.data.path.match(
                ".*/OrdinacneHodinyVAmbulancii_\\d+.txt"
              ) &&
              !props.data.path.match(".*/Audionahravky/$") &&
              !props.data.path.match(".*/BalikyAListy/$") &&
              !props.data.path.match(".*/DennaTlacACasopisy_\\d+/$") &&
              !props.data.path.match(".*/IneSubory_\\d+/$") &&
              !props.data.path.match(".*/InformacneBrozury/$") &&
              !props.data.path.match(
                ".*/Interne_predpisy_ZVJS_(KIOSK|Tablety)/$"
              ) &&
              !props.data.path.match(".*/KulturnoOsvetoveAktivity_\\d+/$") &&
              !props.data.path.match(".*/OchranaPrav_\\d+/$") &&
              !props.data.path.match(".*/Telefonovanie/$") && (
                <Typography variant="h4">
                  {props.data?.webDavItem?.type === WebDavFileType.file
                    ? props.data.metadata?.find(
                        (i) =>
                          i.nazov ===
                          decodeURIComponent(
                            props.data?.webDavItem?.name
                              .match(`[^/]+$`)
                              ?.at(0) ?? ""
                          )
                      )?.popis ??
                      decodeURIComponent(
                        props.data?.webDavItem?.name.match(`[^/]+$`)?.at(0) ??
                          ""
                      )
                    : "/"}
                </Typography>
              )}
          </Stack>
        </Stack>
      </Grid>

      {props.data.webDavItem?.type === WebDavFileType.directory && (
        <ZvjsTable
          data={{
            header: [
              { value: capitalize(tui("názov")), align: "left" },
              { value: capitalize(tui("akcia")), align: "right" },
            ],
            body:
              props.data.allIntranetFiles
                .filter((item) => item.type === WebDavFileType.file)
                .filter((item) =>
                  item.name.startsWith(
                    props.data.webDavItem?.name ?? "whateverValue"
                  )
                )
                .filter(
                  (file) =>
                    !file.name.match(
                      ".*/Interne_predpisy_ZVJS_(KIOSK|Tablety)/zoznam_kiosk.json$"
                    )
                )
                .map((item) => {
                  const rd: RowData = {
                    row: [
                      {
                        value: (
                          <Stack
                            direction={"row"}
                            spacing={theme.spacing(1)}
                            alignItems="center"
                          >
                            {item.type === WebDavFileType.directory ? (
                              <FolderOutlined />
                            ) : item.contentType.startsWith("audio") ? (
                              <AudioFileOutlined />
                            ) : item.contentType.startsWith("video") ? (
                              <VideoFileOutlined />
                            ) : (
                              <InsertDriveFileOutlined />
                            )}
                            <Typography>
                              {props.data.metadata?.find(
                                (i) =>
                                  i.nazov ===
                                  decodeURIComponent(
                                    item.name.match(`[^/]+$`)?.at(0) ?? ""
                                  )
                              )?.popis ??
                                decodeURIComponent(
                                  item.name.match(`[^/]+$`)?.at(0) ?? ""
                                )}
                            </Typography>
                          </Stack>
                        ),
                        align: "left",
                      },
                      {
                        value: (
                          <ZvjsButton
                            text={capitalize(tui("prihlasenie.detail"))}
                            zvjsVariant="secondaryAction"
                            startIcon={
                              <DetailIcon
                                style={{ fill: "white", width: 28, height: 28 }}
                              />
                            }
                            onClick={() => {
                              navigate(
                                `/auth/${INTRANET_FILE_BROWSER_ROUTER_PATH}/` +
                                  encodeURIComponent(item.name)
                              );
                            }}
                            disabled={!isSupportedContentType(item.contentType)}
                          />
                        ),
                        align: "right",
                      },
                    ],
                  };
                  return rd;
                }) ?? [],
          }}
          height={"calc(100vh - 450px)"}
          variant={ZvjsTableVariant.NORMAL}
        />
      )}
      {props.data.webDavItem?.type === WebDavFileType.file &&
        props.data.webDavItem.contentType.startsWith("audio/") && (
          <ZvjsAudioPlayer
            file={{
              contentType: props.data.webDavItem.contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data.webDavItem.name}`
              ),
              httpHeaders: props.data.fileApiCallHeaders,
            }}
          />
        )}
      {props.data.webDavItem?.type === WebDavFileType.file &&
        props.data.webDavItem.contentType.startsWith("image/") && (
          <ZvjsImageViewer
            file={{
              contentType: props.data.webDavItem.contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data.webDavItem.name}`
              ),
              httpHeaders: props.data.fileApiCallHeaders,
            }}
          />
        )}
      {props.data.webDavItem?.type === WebDavFileType.file &&
        props.data.webDavItem.contentType === "text/plain" && (
          <ZvjsTextViewer
            file={{
              contentType: props.data.webDavItem.contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data.webDavItem.name}`
              ),
              httpHeaders: props.data.fileApiCallHeaders,
            }}
          />
        )}
      {props.data.webDavItem?.type === WebDavFileType.file &&
        props.data.webDavItem.contentType === "application/pdf" && (
          <ZvjsPDFViewer
            file={{
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data.webDavItem.name}`
              ),
              httpHeaders: props.data.fileApiCallHeaders,
            }}
          />
        )}
    </Grid>
  );
};

export default withLoader(IntranetPrehliadacSuborov);
