import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../../../hoc/withLoader";
import { ZvjsTable } from "../../../../../common";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../router/LoaderError";

const loadData = async (pohladavkaId: string) => {
  const { FEOO_Post } = await API_Clients();

  const splatky = await FEOO_Post("/api/Pohladavka/ListSplatkyPohladavky", {
    body: {
      pohladavkaId: Number(pohladavkaId),
    },
  });

  if (splatky.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [splátky pohľadávky]");
  }

  return {
    splatky: splatky,
  };
};

export const SplatkyPohladavokOstatnychOpravnenychLoader = async (
  pohladavkaId: string
) => {
  return defer({
    data: loadData(pohladavkaId),
  });
};

interface SplatkyPohladavokOstatnychOpravnenychProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const SplatkyPohladavokOstatnychOpravnenych = (
  props: SplatkyPohladavokOstatnychOpravnenychProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("rozpis splátok"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("dátum splátky")),
              align: "left",
            },
            {
              value: capitalize(tui("výška splátky")),
              align: "right",
            },
          ],
          body:
            props.data.splatky.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((splatka) => {
                const rd: RowData = {
                  row: [
                    {
                      value: splatka.datumSplatky
                        ? new Date(splatka.datumSplatky).zvjsToString()
                        : "",
                      align: "left",
                    },
                    {
                      value: splatka.suma?.toFixed(2) + " EUR",
                      align: "right",
                      bold: true,
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(SplatkyPohladavokOstatnychOpravnenych);
