import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { withLoader } from "../../../hoc/withLoader";
import { LoaderError } from "../../../router/LoaderError";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const stavVeci = searchParams.get("stavVeci")
    ? decodeURIComponent(searchParams.get("stavVeci") || "")
    : undefined;
  const povolena = searchParams.get("povolena")
    ? decodeURIComponent(searchParams.get("povolena") || "")
    : undefined;

  console.debug(
    `OsobneVeciTab loadData stavVeci[${stavVeci}] povolena[${povolena}]`
  );

  const { EDPOO_Get, EDPOO_Post } = await API_Clients();

  const data = await Promise.all([
    // TODO: this counter values don't match with "/api/Ciselnik/StavVeci" below, so we can't use CIS
    // CIS_Post("/api/CisStavVeci/List", {
    //   body: {
    //     filters: [{ aktualny: true, platny: true }],
    //     paging: {
    //       currentPage: 1,
    //       recordsPerPage: 99999,
    //     },
    //     sorting: [{}],
    //   },
    // }),
    EDPOO_Get("/api/Ciselnik/StavVeci"),
    EDPOO_Post("/api/OsobneVeci/ListOsobnaVec", {
      body: {
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? ""),
            ustavZvjsId: Number.parseInt(
              localStorage.getItem("klientUstavId") ?? ""
            ),
          },
        ],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [číselník stav vecí]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [zoznam osobných vecí]");
  }

  if (stavVeci && !!data[1].data?.records) {
    data[1].data.records = data[1].data?.records?.filter(
      (item) => item.stavVeciId === Number(stavVeci)
    );
  }

  if (povolena && !!data[1].data?.records) {
    if (povolena === "true") {
      data[1].data.records = data[1].data?.records?.filter(
        (item) => item.nepovolena === false
      );
    }
  }

  return {
    stavOsobnejVeci: data[0],
    osobneVeci: data[1],
  };
};

export const OsobneVeciLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

interface OsobneVeciProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OsobneVeci = (props: OsobneVeciProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onSearch = (stavVeci: string, povolena: boolean) => {
    console.debug(
      `OsobneVeci onSearch stavVeci[${stavVeci}] povolena[${povolena}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(stavVeci !== "" && {
            stavVeci: encodeURIComponent(stavVeci),
          }),
          ...(povolena && {
            povolena: encodeURIComponent(`${povolena}`),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant={"h1"} mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.osobneVeci")
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={tui("vyhľadať v osobných veciach")}
          type={ZvjsSearchBarVariant.VARIANT_D_CoCb}
          checkBoxLabel={capitalize(tui("povolená vec"))}
          comboboxLabel={capitalize(tui("stav veci"))}
          items={props.data.stavOsobnejVeci.data.map((item) => {
            return { value: item.id || -1, text: item.nazov || "" };
          })}
          onSubmit={onSearch}
        />

        <ZvjsTable
          data={{
            header: [
              {
                value: capitalize(tui("názov veci")),
                align: "left",
              },
              {
                value: capitalize(tui("stav veci")),
                align: "left",
              },
              {
                value: capitalize(tui("povolená vec")),
                align: "left",
              },
            ],
            body:
              props.data.osobneVeci.data?.records?.map((osobnaVec) => {
                const rd: RowData = {
                  row: [
                    {
                      value: osobnaVec.popis ?? "",
                      align: "left",
                    },
                    {
                      value: osobnaVec.stavVeciNazov ?? "",
                      align: "left",
                    },
                    {
                      value: osobnaVec.nepovolena ? "𐄂" : "✓",
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
          }}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid container spacing={1} width={"100%"}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ZvjsButton
              text={capitalize(
                tui(
                  "žiadosť o vydanie vecí uložených v úschove ústavu alebo vecí v užívaní"
                )
              )}
              zvjsVariant="secondaryAction"
              onClick={() => {
                navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/08.00.01`);
              }}
              endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
              style={{
                lineHeight: "1",
                textAlign: "left",
                textTransform: "none",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ZvjsButton
              text={capitalize(
                tui(
                  "žiadosť o používanie vlastného elektr. spotrebiča a jeho technickú kontrolu"
                )
              )}
              zvjsVariant="secondaryAction"
              onClick={() => {
                navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/08.01.01`);
              }}
              endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
              style={{
                lineHeight: "1",
                textAlign: "left",
                textTransform: "none",
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(OsobneVeci);
