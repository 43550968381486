import React, { useEffect } from "react";
import { ZvjsSelect } from "../../../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { Grid, SelectChangeEvent } from "@mui/material";
import { AppDispatch } from "../../../../../../store/redux";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface DropdownQuestionProps {
  location: number[];
}

const DropdownQuestion_New: React.FC<DropdownQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(`DROPDOWN QUESTION NEW RERENDER ${location.join("-")}`);

  const dropdownQuestionData = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionNewDisplayData(location)
  );

  // use shallowEqual so question is not re-rendered each time selector returns new array instance: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const isOptionDisplayedArray = useSelector(
    questionnaireRedux.selectors.getDropdownNewOptionsDisplayedArray(location),
    shallowEqual
  );
  const answerVal = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionNewAnswerData(
      location,
      dropdownQuestionData.id
    )
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(dropdownQuestionData.id)
  );

  const filteredOptions = dropdownQuestionData.options.filter(
    (_, i) => isOptionDisplayedArray[i]
  );

  const onChangeHandler = (event: SelectChangeEvent) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        dropdownQuestionData.id,
        event.target.value
      )
    );
  };

  // if only one option is available to user, answer is required,
  // and no option is selected yet, then auto-select the only option available
  useEffect(() => {
    if (
      filteredOptions.length === 1 &&
      answerVal === undefined &&
      dropdownQuestionData.isRequired
    ) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          dropdownQuestionData.id,
          filteredOptions[0].value
        )
      );
    }
  }, [
    answerVal,
    dispatch,
    dropdownQuestionData.id,
    dropdownQuestionData.isRequired,
    filteredOptions,
    location,
  ]);

  return (
    <Grid container>
      <ZvjsSelect
        id={`dropdown-question-${location.join("-")}`}
        items={filteredOptions}
        text={tuiz(dropdownQuestionData.title)}
        controlled={{
          selectedValue: answerVal ?? "",
          changeHandler: onChangeHandler,
        }}
        hintText={
          dropdownQuestionData.hintText
            ? tuiz(dropdownQuestionData.hintText)
            : undefined
        }
        hintSize={20}
        answerRequired={dropdownQuestionData.isRequired}
        error={validationError !== undefined}
        validationFlagType={validationError?.type}
        validationErrorMessage={tuiz(validationError?.message ?? "")}
      />
    </Grid>
  );
};

export default DropdownQuestion_New;
