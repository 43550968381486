import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";
import {
  CisPohlavie_MUZ_ID,
  CisPohlavie_ZENA_ID,
} from "../../../common/specs/countersValues";
import moment from "moment";

const loadData = async () => {
  const { ZVJS_MP_Post } = await API_Clients();

  const ustavKod = localStorage.getItem("ustavKod") ?? "faultyUstavKod";

  const oddielId = Number.parseInt(localStorage.getItem("oddielId") ?? "");
  const oddiel = !Number.isNaN(oddielId) ? oddielId.toString() : "2"; // BY DEFAULT CHOOSE 2

  const pohlavieKod =
    Number.parseInt(localStorage.getItem("pohlavie") ?? "") ===
    CisPohlavie_MUZ_ID
      ? "1"
      : Number.parseInt(localStorage.getItem("pohlavie") ?? "") ===
          CisPohlavie_ZENA_ID
        ? "2"
        : "0";

  const pravnePostavenieKlientaKod =
    localStorage.getItem("aktivnaVazba") === "true"
      ? "OBV"
      : localStorage.getItem("aktivnyTrest") === "true"
        ? "ODS"
        : "ODS";

  const casovyRozvrhPracovnyDen = await ZVJS_MP_Post(
    "/casovy-rozvrh-dna/list",
    {
      body: {
        stav: "SCHVALENY",
        typ: "PRACOVNY_DEN",
        ustavKod: ustavKod,
        oddiel: oddiel,
        pravnePostavenieKlientaKod: pravnePostavenieKlientaKod,
        pohlavieKod: pohlavieKod,
        sort: [{ attribute: "datumPlatnostiOd", sortDirection: "DESC" }],
      },
    }
  );

  const casovyRozvrhDenPracovnehoPokoja = await ZVJS_MP_Post(
    "/casovy-rozvrh-dna/list",
    {
      body: {
        stav: "SCHVALENY",
        typ: "DEN_PRACOVNEHO_POKOJA",
        ustavKod: ustavKod,
        oddiel: oddiel,
        pravnePostavenieKlientaKod: pravnePostavenieKlientaKod,
        pohlavieKod: pohlavieKod,
        sort: [{ attribute: "datumPlatnostiOd", sortDirection: "DESC" }],
      },
    }
  );

  const crpd = casovyRozvrhPracovnyDen.data?.casovyRozvrhDnaList
    ?.sort(
      (r1, r2) =>
        moment(r2.datumPlatnostiOd ?? moment(), "YYYY-MM-DD").valueOf() -
        moment(r1.datumPlatnostiOd ?? moment(), "YYYY-MM-DD").valueOf()
    )
    .filter(
      (r) => moment(r.datumPlatnostiOd ?? moment(), "YYYY-MM-DD") < moment()
    )
    .at(0);

  const crdpp = casovyRozvrhDenPracovnehoPokoja.data?.casovyRozvrhDnaList
    ?.sort(
      (r1, r2) =>
        moment(r2.datumPlatnostiOd ?? moment(), "YYYY-MM-DD").valueOf() -
        moment(r1.datumPlatnostiOd ?? moment(), "YYYY-MM-DD").valueOf()
    )
    .filter(
      (r) => moment(r.datumPlatnostiOd ?? moment(), "YYYY-MM-DD") < moment()
    )
    .at(0);

  return {
    casoveRozvrhy: [crpd, crdpp],
  };
};

export const UstavnyPoriadokCasovyRozvrhDnaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface UstavnyPoriadokCasovyRozvrhDnaProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const UstavnyPoriadokCasovyRozvrhDna = (
  props: UstavnyPoriadokCasovyRozvrhDnaProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("časový rozvrh dňa"))}
          </Typography>
        </Stack>
      </Grid>

      <Stack spacing={3}>
        {props.data.casoveRozvrhy.map((rozvrh) => (
          <Stack spacing={1}>
            <Typography variant={"h3"}>
              {rozvrh?.typCasovehoRozvrhu === "PRACOVNY_DEN"
                ? "Pracovný deň"
                : rozvrh?.typCasovehoRozvrhu === "DEN_PRACOVNEHO_POKOJA"
                  ? "Deň pracovného pokoja"
                  : ""}
            </Typography>
            {rozvrh?.zaznamCasovehoRozvrhu
              ?.sort((c1, c2) =>
                (c1.intervalCasuOd ?? "").localeCompare(c2.intervalCasuOd ?? "")
              )
              .map((cinnost) => (
                <Stack direction={"row"} spacing={1} alignItems={"baseline"}>
                  <Typography
                    variant={"h5"}
                  >{`${cinnost.intervalCasuOd}-${cinnost.intervalCasuDo}`}</Typography>
                  <Typography variant={"body1"} flex={1}>
                    {cinnost.poznamka}
                  </Typography>
                </Stack>
              ))}
            {rozvrh?.zaznamCasovehoRozvrhuDalsichCinnosti && (
              <Typography variant="h4">Rozvrh ďalších činností</Typography>
            )}
            {rozvrh?.zaznamCasovehoRozvrhuDalsichCinnosti?.map((cinnost) => (
              <Typography variant={"body1"}>{`${cinnost.cinnost}${
                cinnost.poznamka ? ` - ${cinnost.poznamka}` : ""
              }`}</Typography>
            ))}
          </Stack>
        ))}
      </Stack>
    </Grid>
  );
};

export default withLoader(UstavnyPoriadokCasovyRozvrhDna);
