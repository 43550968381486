import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";
import { SC_KIO_050301_doesClientHaveEnoughMoneyOnAccountToBuyItemsOnHisOwn } from "../../../../utils/calculations/custom";

/**
 * Selectors
 */

const displayClientHasEnoughMoneyOnAccount_SC_KIO_050301 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      return SC_KIO_050301_doesClientHaveEnoughMoneyOnAccountToBuyItemsOnHisOwn(
        state["AVAILABLE_ACCOUNT_AMOUNT"]
      );
    }
  );

const displayClientShoppedLastCalendarMonth_SC_KIO_050301 = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      return state["SHOPPED_LAST_CALENDAR_MONTH"];
    }
  );

export const allSelectors = {
  displayClientHasEnoughMoneyOnAccount_SC_KIO_050301,
  displayClientShoppedLastCalendarMonth_SC_KIO_050301,
};
