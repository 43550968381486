import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { isEmptyString } from "../../../../../../../../utils/helpers";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../../../router/LoaderError";

const CRIMINAL_PROCEEDINGS = "CRIMINAL_PROCEEDINGS";
const OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE =
  "KIOSK_INTERNAL_OTHER_PROCEEDING";
export const CriminalProceedingsGridConstants = {
  CRIMINAL_PROCEEDINGS,
  OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE,
} as const;

/**
 * OnInit
 */

export interface CriminalProceedingType {
  id: number;
  description: string;
}

export const SC_KIO_050301_CriminalProceedingsGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { EOO_Post } = await API_Clients();

  const response = await EOO_Post("/api/Klient/ListTrestneKonanieData", {
    body: {
      filters: [
        {
          id: Number(localStorage.getItem("klientId")),
          ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      ],
    },
  });

  if (response.error || !response.response.ok) {
    console.error("Failed to fetch client criminal proceedings");
    throw new LoaderError();
  }

  calcData[CriminalProceedingsGridConstants.CRIMINAL_PROCEEDINGS] =
    response.data.records
      ?.filter((item) => item.id)
      .map((item) => {
        if (!item.id) {
          // you should never end up here - ensured by filter above
          throw new LoaderError();
        }
        return { id: item.id, description: item.poznamka ?? "" };
      }) ?? [];
};

/**
 * Selectors
 */

const getCriminalProceedingsGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      criminalProceedings: state.userCalcData[
        CriminalProceedingsGridConstants.CRIMINAL_PROCEEDINGS
      ] as CriminalProceedingType[],
    };
  });

export const allSelectors = {
  getCriminalProceedingsGridDisplayData,
};

/**
 * Model
 */

export interface SC_KIO_050301_CriminalProceedingsGrid_answerType {
  criminalProceeding:
    | number
    | typeof CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE;
  otherCriminalProceedingDescription?: string | undefined;
}

/**
 * IsAnswered
 */

export const SC_KIO_050301_CriminalProceedingsGrid_isAnswered = (
  userCalcData: UserCalcData,
  answer?: SC_KIO_050301_CriminalProceedingsGrid_answerType
): boolean => {
  if (
    answer === undefined ||
    (answer.criminalProceeding ===
      CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE &&
      isEmptyString(answer.otherCriminalProceedingDescription))
  ) {
    return false;
  }
  return true;
};

/**
 * CustomSubmitAnswerData
 */

export const SC_KIO_050301_CriminalProceedingsGrid_getCustomSubmitAnswerData = (
  answer: SC_KIO_050301_CriminalProceedingsGrid_answerType,
  questionId: string
): {
  [key: string]: string | number;
} => {
  if (
    answer.criminalProceeding ===
    CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE
  ) {
    return {
      ineTrestneKonanie: answer.otherCriminalProceedingDescription ?? "",
    };
  } else {
    return { [questionId]: answer.criminalProceeding };
  }
};
