import React from "react";
import { capitalize } from "@mui/material/utils";
import { ZvjsHTMLViewer } from "../../../common";
import { defer, useNavigate } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { FileApiCallHeaders, targetUiApi } from "../../../utils/targetApi";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { Module } from "../../../utils/module";
import { intranetDirectories } from "../../../utils/intranetFiles";
import { isKiosk } from "../../../store/context/envConfigContext";

const loadData = async (url: string) => {
  const intranetDirs = await intranetDirectories(
    Number(localStorage.getItem("klientUstavId")),
    isKiosk()
  );

  const fileApiCallHeaders = await FileApiCallHeaders();

  return {
    url: url,
    offlinePagePath: `${intranetDirs.bezpecnyInternet}${url}/index.html`,
    fileApiCallHeaders: fileApiCallHeaders,
  };
};

export const OfflinePageLoader = async (url: string) => {
  return defer({
    data: loadData(url),
  });
};

interface OfflinePageProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OfflinePage = (props: OfflinePageProps) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };
  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={"26px"}>
            {props.data.url}
          </Typography>
        </Stack>
      </Grid>
      <ZvjsHTMLViewer
        file={{
          contentType: "text/html",
          url: targetUiApi(
            `/${Module.INTERNAL}/api/v1/File/${props.data.offlinePagePath}`
          ),
          httpHeaders: props.data.fileApiCallHeaders,
        }}
      />
    </Grid>
  );
};
export default withLoader(OfflinePage);
