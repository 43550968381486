import React, { useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { Paper, Stack } from "@mui/material";
import ZvjsButton from "./ZvjsButton";
import { ArrowForwardIos } from "@mui/icons-material";
import ZvjsTextField from "./ZvjsTextField";

interface ZvjsTesterReaderProps {
  onScan: (qrCode: string) => void;
}

const ZvjsTesterReader: React.FC<ZvjsTesterReaderProps> = ({ onScan }) => {
  const { tui } = useUITranslation();
  const [typedCardId, setTypedCardId] = useState<string>();

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack direction={"column"} alignItems={"center"} spacing={1}>
        <Stack sx={{ m: 1 }} width={"100%"}>
          <ZvjsTextField
            label="Karta (text)"
            value={typedCardId}
            placeholder="číslo karty"
            //hintText="Zadajte číslo prihlasovacej karty"
            onChange={(e) => {
              setTypedCardId(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onScan(typedCardId?.trim() ?? "");
              }
            }}
          />
          <ZvjsButton
            name="typedPrihlasenie"
            text={capitalize(tui("prihlasenie.prihlasitSa"))}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            disabled={typedCardId == undefined || typedCardId?.trim() === ""}
            onClick={() => onScan(typedCardId?.trim() ?? "")}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ZvjsTesterReader;
