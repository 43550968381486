import { capitalize } from "@mui/material";
import { ZvjsNumpad } from "../../common";
import { LoginStepProps } from "../Login";
import { useUITranslation } from "../../store/context/translation-context";
import AuthContext from "../../store/context/auth-context";
import { useContext } from "react";
import { useSnackbar } from "../../store/context/snackbar-context";
import { API_Clients } from "../../store/context/dataApi/Data";

const LoginStep2: React.FC<LoginStepProps> = ({ setStep }) => {
  const { tui } = useUITranslation();
  const { openSnackbar } = useSnackbar();

  const { cisloKarty } = useContext(AuthContext);

  const onCheck = (pin: string) => {
    API_Clients().then((clients) => {
      clients
        .SIDKOO_Post("/api/IdKarta/ListIdKartaAktivna", {
          body: {
            filters: [
              {
                cisloKarty: Number(cisloKarty),
              },
            ],
          },
        })
        .then((result) => {
          if (result.data?.records?.length === 0) {
            const message =
              capitalize(tui("Prístupová karta nie je aktivna")) +
              ` [${cisloKarty}]`;
            console.error(message);
            openSnackbar(message, "error");
          } else {
            const karta = result.data?.records?.at(0);

            const spravnyPin =
              karta?.pin === Number(pin) ||
              karta?.docasnyPin === Number(pin) ||
              ((karta?.pin === undefined || karta?.pin === null) &&
                (karta?.docasnyPin === undefined ||
                  karta?.docasnyPin === null));
            if (spravnyPin) {
              setStep(3);
            } else {
              const message = capitalize(tui("Nesprávny PIN"));
              console.error(message);
              openSnackbar(message, "error");
            }
          }
        })
        .catch((err) => {
          let errorMessage = "";
          if (err instanceof Error) {
            errorMessage = err.message;
          } else {
            errorMessage = String(err);
          }
          console.error(errorMessage);
          openSnackbar(errorMessage, "error");
        });
    });
  };

  return <ZvjsNumpad pinLength={4} onCheck={onCheck} />;
};

export default LoginStep2;
