import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import questionnaireRedux from "./redux";
import store from "../../store/redux";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { Params, useLocation, useNavigate } from "react-router-dom";
import { Questionnaire } from "./requestPages/questionnaire";
import Introduction from "./requestPages/introduction/Introduction";
import Summary, { submitNewRequest } from "./requestPages/summary/Summary";
import Confirmation from "./requestPages/confirmation/Confirmation";
import { defer } from "react-router-dom";
import { useUITranslation } from "../../store/context/translation-context";
import { withLoader } from "../../hoc/withLoader";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../assets/icons/Other/keyboard_arrow_left.svg";
import { REQUEST_PAGE_PADDING } from "./requestPages/requestStyles/requestStyles";
import { LoaderError } from "../../router/LoaderError";
import { SC_KIO_050101_addNewPerson } from "./requestPages/questionnaire/items/forms/SC_KIO_050101_AddPersonForm";
import { ActionResult, ActionResultTypes } from "../../router/ActionResult";
import { ActionBodyWrapper } from "../../router/ActionBodyWrapper";
import { SC_KIO_050101_editPersonContact } from "./requestPages/questionnaire/items/forms/SC_KIO_050101_EditPersonVideoCallForm";
import { SC_KIO_050202_addNewClosePerson } from "./requestPages/questionnaire/items/forms/SC_KIO_050202_AddClosePersonForm";
import { SC_KIO_050202_editClosePerson } from "./requestPages/questionnaire/items/forms/SC_KIO_050202_EditClosePersonForm";

export const enum RequestPages {
  REQUEST_INTRODUCTION,
  QUESTIONNAIRE,
  SUMMARY,
  CONFIRMATION,
}

const RequestPagesHash: { [key in keyof typeof RequestPages]: string } = {
  REQUEST_INTRODUCTION: "#request-introduction",
  QUESTIONNAIRE: "#questionnaire",
  SUMMARY: "#summary",
  CONFIRMATION: "#confirmation",
};

const LoadData = async (requestId?: string) => {
  if (requestId === undefined) {
    throw new LoaderError();
  }

  await store.dispatch(questionnaireRedux.actions.fetchRequest(requestId));
  return null;
};

export const RequestLoader = ({
  request,
  params,
}: {
  request: Request;
  params: Params;
}) => {
  return defer({
    data: LoadData(params.requestCode),
  });
};

export enum RequestActionsType {
  SUBMIT_NEW_REQUEST = "SUBMIT_NEW_REQUEST",
  SC_KIO_050101_ADD_NEW_PERSON = "SC_KIO_050101_ADD_NEW_PERSON",
  SC_KIO_050101_EDIT_PERSON_CONTACT = "SC_KIO_050101_EDIT_PERSON_CONTACT",
  SC_KIO_050202_ADD_NEW_CLOSE_PERSON = "SC_KIO_050202_ADD_NEW_CLOSE_PERSON",
  SC_KIO_050202_EDIT_CLOSE_PERSON = "SC_KIO_050202_EDIT_CLOSE_PERSON",
}

export type RequestActionResult = ActionResult & {
  action: RequestActionsType;
};

export const refetchRequest = async (requestCode: string | undefined) => {
  if (requestCode) {
    await store.dispatch(
      questionnaireRedux.actions.refetchRequest(requestCode)
    );
  } else {
    throw new LoaderError();
  }
};

const requestActionsFunctions: {
  [key in RequestActionsType]: ({
    params,
    data,
  }: {
    params: Params;
    data: unknown;
  }) => Promise<RequestActionResult>;
} = {
  [RequestActionsType.SUBMIT_NEW_REQUEST]: submitNewRequest,
  [RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON]: SC_KIO_050101_addNewPerson,
  [RequestActionsType.SC_KIO_050101_EDIT_PERSON_CONTACT]:
    SC_KIO_050101_editPersonContact,
  [RequestActionsType.SC_KIO_050202_ADD_NEW_CLOSE_PERSON]:
    SC_KIO_050202_addNewClosePerson,
  [RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON]:
    SC_KIO_050202_editClosePerson,
};

export async function RequestActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<RequestActionResult> {
  const body: ActionBodyWrapper = await request.json();
  if ((body.action as keyof RequestActionsType) in RequestActionsType) {
    return await requestActionsFunctions[body.action as RequestActionsType]({
      params: params,
      data: body.data,
    });
  } else {
    console.error("Unsupported action type: ", body.action);
    return {
      type: ActionResultTypes.ERROR,
      action: body.action as RequestActionsType,
    };
  }
}

const Request = () => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<RequestPages>(
    RequestPages.REQUEST_INTRODUCTION
  );

  const requestData = useSelector(
    questionnaireRedux.selectors.getRequestDisplayData()
  );
  const allQuestionsAnswered = useSelector(
    questionnaireRedux.selectors.allQuestionsAnswered
  );
  const hasQuestionnaireAnyItems = useSelector(
    questionnaireRedux.selectors.getHasQuestionnaireAnyItems
  );

  console.debug("REQUEST RERENDER");

  const onHashChangeEventHandler = useCallback(
    (event: HashChangeEvent) => {
      // when user hits back button on confirmation page, display him request category page instead of request summary
      if (
        new URL(event.oldURL).hash === RequestPagesHash.CONFIRMATION &&
        new URL(event.newURL).hash === RequestPagesHash.SUMMARY
      ) {
        navigate(hasQuestionnaireAnyItems ? -3 : -2);
      }

      return;
    },
    [hasQuestionnaireAnyItems, navigate]
  );

  // The pain of browser back button
  //https://stackoverflow.com/questions/1844491/intercepting-call-to-the-back-button-in-my-ajax-application
  useEffect(() => {
    switch (location.hash) {
      case RequestPagesHash.REQUEST_INTRODUCTION:
        setCurrentPage(RequestPages.REQUEST_INTRODUCTION);
        break;
      case RequestPagesHash.QUESTIONNAIRE:
        setCurrentPage(RequestPages.QUESTIONNAIRE);
        break;
      case RequestPagesHash.SUMMARY:
        // When redirecting to the summary page, check if all questions are answered.
        // There might be #summary route stored in router stack when user had request summary opened,
        // then returned to a request category list and then via forward button back to summary.
        // In that case, keep user in page with questionnaire if questions are not answered.
        if (allQuestionsAnswered) {
          setCurrentPage(RequestPages.SUMMARY);
        } else {
          navigate(-1);
        }
        break;
      case RequestPagesHash.CONFIRMATION:
        setCurrentPage(RequestPages.CONFIRMATION);
        break;
      default:
        setCurrentPage(RequestPages.REQUEST_INTRODUCTION);
        break;
    }
  }, [allQuestionsAnswered, location, navigate]);

  useEffect(() => {
    // console.log("--------------------------useEffect");
    window.addEventListener("hashchange", onHashChangeEventHandler);

    return () => {
      window.removeEventListener("hashchange", onHashChangeEventHandler);
    };
  }, [onHashChangeEventHandler]);

  return (
    <Grid p={`${REQUEST_PAGE_PADDING}px`} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="start"
          alignItems="flex-start"
        >
          {currentPage !== RequestPages.CONFIRMATION && (
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
              style={{ minWidth: "5rem", textTransform: "none" }}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
          )}
          <Typography variant={"h1"} mb={3}>
            {capitalize(requestData.title ?? "")}
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        {/*<ReopenOnQuestionDemo/>*/}
        {currentPage === RequestPages.REQUEST_INTRODUCTION && (
          <Introduction setCurrentPage={setCurrentPage} />
        )}
        {currentPage === RequestPages.QUESTIONNAIRE && (
          <Questionnaire setCurrentPage={setCurrentPage} />
        )}
        {currentPage === RequestPages.SUMMARY && (
          <Summary setCurrentPage={setCurrentPage} />
        )}
        {currentPage === RequestPages.CONFIRMATION && <Confirmation />}
      </Grid>
    </Grid>
  );
};

export default withLoader(Request);
