import {
  Calculate,
  LabelType,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051004",
  basicInfo: [
    {
      text: "ziadost.ziadostOnakupVpredajniUstavu.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051004_isAvailableToUser",
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    CREDIT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    MINIMUM_SUBSISTENCE_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOnakupVpredajniUstavu.labelVyskaDisponibilnehoKonta",
      value: "AVAILABLE_ACCOUNT_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOnakupVpredajniUstavu.labelVyskaKreditu",
      value: "CREDIT_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOnakupVpredajniUstavu.labelVyskaEvidovanehoVyzivnehoPohladavok",
      value: "REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOnakupVpredajniUstavu.napovedaStandardnySortiment",
      flag: ZvjsLabelFlagTypes.INFO,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOnakupVpredajniUstavu.napovedaObmedzeneMnozstvo",
      flag: ZvjsLabelFlagTypes.INFO,
    },
  ],
};
