import {
  UserCalcData,
  ZvjsAnswerValueType,
  ZvjsCustomQuestionTypes,
} from "../../../../../redux/model";
import {
  SC_KIO_050206_RegisteredChildrenGrid_answerType,
  SC_KIO_050206_RegisteredChildrenGrid_isAnswered,
} from "./SC_KIO_050206_RegisteredChildrenGrid/redux";
// import { SC_KIO_051003_PersonalHygieneNeedsGrid_isAnswered } from "./SC_KIO_051003_PersonalHygieneNeedsGrid/redux";
import { SC_KIO_051102_CivilianClothesGrid_answerType } from "./SC_KIO_051102_CivilianClothesGrid/redux";
import { SC_KIO_050501_DevicesRevisionControlGrid_answerType } from "./SC_KIO_050501_DevicesRevisionControlGrid/redux";
import {
  SC_KIO_050301_CriminalProceedingsGrid_answerType,
  SC_KIO_050301_CriminalProceedingsGrid_isAnswered,
} from "./SC_KIO_050301_CriminalProceedingsGrid/redux";

/**
 * Custom isAnswered evaluation functions (when standard isAnswered check is not enough, and you need to do additional checks for custom question)
 */
export const customIsAnswered: onCustomIsAnswered = {
  [ZvjsCustomQuestionTypes.SC_KIO_050206_REGISTERED_CHILDREN_GRID]: (
    answer: ZvjsAnswerValueType,
    userCalcData: UserCalcData
  ) =>
    SC_KIO_050206_RegisteredChildrenGrid_isAnswered(
      userCalcData,
      answer as SC_KIO_050206_RegisteredChildrenGrid_answerType
    ),
  [ZvjsCustomQuestionTypes.SC_KIO_050301_CRIMINAL_PROCEEDINGS_GRID]: (
    answer: ZvjsAnswerValueType,
    userCalcData: UserCalcData
  ) =>
    SC_KIO_050301_CriminalProceedingsGrid_isAnswered(
      userCalcData,
      answer as SC_KIO_050301_CriminalProceedingsGrid_answerType
    ),
  // [ZvjsCustomQuestionTypes.SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID]: (
  //   answer: ZvjsAnswerValueType,
  //   userCalcData: UserCalcData
  // ) =>
  //   SC_KIO_051003_PersonalHygieneNeedsGrid_isAnswered(
  //     userCalcData,
  //     answer as string[]
  //   ),
};

type onCustomIsAnswered = {
  [key in ZvjsCustomQuestionTypes]?: (
    answer: ZvjsAnswerValueType,
    userCalcData: UserCalcData
  ) => boolean;
};

/**
 * Custom answer types which can be stored in the answers redux object
 */
export type CustomAnswerTypes =
  | SC_KIO_050206_RegisteredChildrenGrid_answerType
  | SC_KIO_051102_CivilianClothesGrid_answerType[]
  | SC_KIO_050501_DevicesRevisionControlGrid_answerType
  | SC_KIO_050301_CriminalProceedingsGrid_answerType;
