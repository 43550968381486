import { API_Clients } from "../store/context/dataApi/Data";
import { CisPohlavie_ZENA_ID } from "../common/specs/countersValues";
import { Ciselnik, MAX_NUMBER_OF_ITEMS } from "../store/context/dataApi/Data";
import { isEmptyArray } from "./helpers";
import { LoaderError } from "../router/LoaderError";
import { RequestCounter } from "../common/request/redux/model";

export const isClientAccused = async (): Promise<{
  data: { isAccused: boolean } | null | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  return await isAccused({
    klientId: Number(localStorage.getItem("klientId")),
    ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
  });
};

export const isClientSentenced = async (): Promise<{
  data: { isSentenced: boolean } | null | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  return await isSentenced({
    klientId: Number(localStorage.getItem("klientId")),
    ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
  });
};

export const isAccused = async ({
  klientId,
  ustavZvjsId,
}: {
  klientId: number;
  ustavZvjsId: number;
}): Promise<{
  data: { isAccused: boolean } | null | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  const { EOO_Get } = await API_Clients();

  const eoo_klient_DetailData = await EOO_Get("/api/Klient/DetailData", {
    params: {
      query: {
        Id: klientId,
        UstavZvjsId: ustavZvjsId,
      },
    },
  });

  if (eoo_klient_DetailData.error || !eoo_klient_DetailData.response.ok) {
    return { ...eoo_klient_DetailData, data: undefined };
  }

  return {
    ...eoo_klient_DetailData,
    data: { isAccused: eoo_klient_DetailData.data.data?.aktivnaVazba === true },
  };
};

export const isSentenced = async ({
  klientId,
  ustavZvjsId,
}: {
  klientId: number;
  ustavZvjsId: number;
}): Promise<{
  data: { isSentenced: boolean } | null | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  const { EOO_Get } = await API_Clients();

  const eoo_klient_DetailData = await EOO_Get("/api/Klient/DetailData", {
    params: {
      query: {
        Id: klientId,
        UstavZvjsId: ustavZvjsId,
      },
    },
  });

  if (eoo_klient_DetailData.error || !eoo_klient_DetailData.response.ok) {
    return { ...eoo_klient_DetailData, data: undefined };
  }

  return {
    ...eoo_klient_DetailData,
    data: {
      isSentenced: eoo_klient_DetailData.data.data?.aktivnyTrest === true,
    },
  };
};

export const isClientFemale = async (): Promise<{
  data: { isFemale: boolean } | null | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  // check if a client is female
  const { EOO_Get } = await API_Clients();
  const userDetail = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }
  );

  if (userDetail.error || !userDetail.data?.data) {
    return { ...userDetail, data: undefined };
  }

  const userBaseDetail = await EOO_Get("/api/FyzickaOsoba/DetailData", {
    params: {
      query: {
        request: userDetail.data.data.fyzickaOsobaId,
      },
    },
  });

  if (userBaseDetail.error || !userBaseDetail.data?.data) {
    return { ...userDetail, data: undefined };
  }

  return {
    ...userDetail,
    data: {
      isFemale: userBaseDetail.data.data.pohlavieId === CisPohlavie_ZENA_ID,
    },
  };
};

export const getCounter = async (counter: Ciselnik) => {
  const { CIS_Post } = await API_Clients();

  const fetchedCounter = await CIS_Post(counter, {
    body: {
      filters: [{ aktualny: true, platny: true }],
      paging: {
        currentPage: 1,
        recordsPerPage: MAX_NUMBER_OF_ITEMS,
      },
      sorting: [{}],
    },
  });

  if (fetchedCounter.error || !fetchedCounter.response.ok) {
    console.error(`Failed to fetch ${counter} counter`);
    return { ...fetchedCounter, data: undefined };
  }

  return fetchedCounter;
};

export const getCounters = async (
  counters: Ciselnik[]
): Promise<{
  data: RequestCounter | undefined;
  response: Response;
  // TODO determine type
  error?: unknown;
}> => {
  const loadedCounters: RequestCounter = {};
  // fetch needed counters
  for (const counter of counters ?? []) {
    const counterValResponse = await getCounter(counter);

    if (counterValResponse.error || !counterValResponse.response.ok) {
      console.error(`Failed to fetch ${counter} counter`);
      return { ...counterValResponse, data: undefined };
    }

    loadedCounters[counter] = counterValResponse.data;
  }

  return {
    data: loadedCounters,
    response: new Response(undefined, { status: 200 }),
  };
};
