import React from "react";
import { capitalize } from "@mui/material/utils";
import { ZvjsPDFViewer } from "../../../common";
import { defer, useNavigate } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { FileApiCallHeaders, targetUiApi } from "../../../utils/targetApi";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { Module } from "../../../utils/module";
import { LoaderError } from "../../../router/LoaderError";
import { WebDavFileType } from "../../../utils/webDav";
import moment from "moment";
import { allIntranetFilesFilteredForDevice } from "../../../utils/intranetFiles";
import { isKiosk } from "../../../store/context/envConfigContext";

const loadData = async () => {
  const allFiles = await allIntranetFilesFilteredForDevice(
    Number.parseInt(localStorage.getItem("klientUstavId") ?? ""),
    isKiosk(),
    false
  );

  const latestUstavnyPoriadokFile = allFiles
    .filter((item) => item.type === WebDavFileType.file)
    .filter((item) =>
      item.name.match(
        ".*/Ustavny_poriadok_\\d+/UstavnyPoriadok_\\d+_(\\d{2}.\\d{2}.\\d{4}).pdf$"
      )
    )
    .sort(
      (item1, item2) =>
        moment(
          item2.name
            .match(".*/UstavnyPoriadok_\\d+_(\\d{2}.\\d{2}.\\d{4}).pdf$")
            ?.at(1),
          "DD.MM.YYYY"
        ).valueOf() -
        moment(
          item1.name
            .match(".*/UstavnyPoriadok_\\d+_(\\d{2}.\\d{2}.\\d{4}).pdf$")
            ?.at(1),
          "DD.MM.YYYY"
        ).valueOf()
    )
    .at(0);

  if (!latestUstavnyPoriadokFile) {
    throw new LoaderError("Neporadilo sa načítať ústavný poriadok");
  }

  const fileApiCallHeaders = await FileApiCallHeaders();

  return {
    ustavnyPoriadokPath: latestUstavnyPoriadokFile.name,
    fileApiCallHeaders: fileApiCallHeaders,
  };
};

export const UstavnyPoriadokUstavnyPoriadokLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface UstavnyPoriadokUstavnyPoriadokProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const UstavnyPoriadokUstavnyPoriadok = (
  props: UstavnyPoriadokUstavnyPoriadokProps
) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };
  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={"26px"}>
            {capitalize(tui("ústavný poriadok"))}
          </Typography>
        </Stack>
      </Grid>
      <ZvjsPDFViewer
        file={{
          url: targetUiApi(
            `/${Module.INTERNAL}/api/v1/File/${props.data.ustavnyPoriadokPath}`
          ),
          httpHeaders: props.data.fileApiCallHeaders,
        }}
      />
    </Grid>
  );
};
export default withLoader(UstavnyPoriadokUstavnyPoriadok);
