import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Stack } from "@mui/material";
import questionnaireRedux from "../../redux";
import { ZvjsCustomQuestionTypes, ZvjsQuestionTypes } from "../../redux/model";
import { GeneralItem, Question } from "./items";
import QuestionnaireControlBtns from "./QuestionnaireControlBtns";
import {
  RequestActionResult,
  RequestActionsType,
  RequestPages,
} from "../../Request";
import { useActionData } from "react-router-dom";
import { ActionResultTypes } from "../../../../router/ActionResult";
import { useUITranslation } from "../../../../store/context/translation-context";
import { useSnackbar } from "../../../../store/context/snackbar-context";
import { useModal } from "../../../../store/context/modal-context";

interface QuestionnaireProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

const Questionnaire: React.FC<QuestionnaireProps> = ({ setCurrentPage }) => {
  const submitResult = useActionData() as RequestActionResult | undefined;
  const { tuiz } = useUITranslation();
  const { openSnackbar } = useSnackbar();
  const { closeModal } = useModal();

  useEffect(() => {
    if (
      submitResult?.type === ActionResultTypes.ERROR &&
      submitResult.message
    ) {
      openSnackbar(tuiz(submitResult.message), "error");
    }

    if (submitResult?.type === ActionResultTypes.SUCCESS) {
      if (submitResult.action !== RequestActionsType.SUBMIT_NEW_REQUEST) {
        // close form modal once data of the client are updated and dataCalculations are re-fetched
        closeModal();
        if (submitResult.message) {
          // show success snackbar if any message is set
          openSnackbar(tuiz(submitResult.message), "success");
        }
      }
    }
  }, [closeModal, openSnackbar, submitResult, tuiz]);

  const rootItems =
    useSelector(questionnaireRedux.selectors.getRootItems) || [];

  const questionnaireItems = [];
  for (let i = 0; i < rootItems.length; i++) {
    if (
      rootItems[i] in ZvjsQuestionTypes ||
      rootItems[i] in ZvjsCustomQuestionTypes
    ) {
      questionnaireItems.push(<Question key={i} location={[i]} />);
    } else {
      questionnaireItems.push(<GeneralItem key={i} location={[i]} />);
    }
  }

  console.debug("QUESTIONNIARE RERENDER");

  return (
    <Stack spacing={2}>
      {questionnaireItems}
      <Grid item sx={{ pt: 1 }}>
        <QuestionnaireControlBtns setCurrentPage={setCurrentPage} />
      </Grid>
    </Stack>
  );
};

export default Questionnaire;
