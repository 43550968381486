import { FormControl, Grid, TextField, useTheme } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import React, { useEffect, useState } from "react";
import { ChangeHandler, RefCallBack } from "react-hook-form";
import { ZvjsAlert } from "./index";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../request/redux/model";

// currently supported DateSelect modes
export enum DateSelectMode {
  date = "date",
  month = "month",
}

interface ZvjsDateSelectProps {
  id?: string;
  text: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  // min date which can be selected in date select
  minDate?: Date;
  // max date which can be selected in date select
  maxDate?: Date;
  // mode of date select
  mode?: DateSelectMode;
  error?: boolean;
  validationErrorMessage?: string;
  validationFlagType?: ZvjsValidationFlagTypes;
  disabled?: boolean;
  // used in react-hook-form
  register?: {
    onBlur: ChangeHandler;
    ref: RefCallBack;
    min?: string | number;
    onChange: ChangeHandler;
    max?: string | number;
    minLength?: number;
    name: string;
    pattern?: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
  };
}

const ZvjsDateSelect: React.FC<ZvjsDateSelectProps> = ({
  text,
  id,
  onChange,
  value,
  minDate,
  maxDate,
  mode = DateSelectMode.date,
  error,
  validationErrorMessage,
  validationFlagType = ZvjsValidationFlagTypes.ERROR,
  disabled,
  register,
}) => {
  const theme = useTheme();

  // keep local information about local state so you can determine if text placeholder should be visible inside date picker input
  const [selectedDate, setSelectedDate] = useState("");

  const toDateRangeString = (date: Date) => {
    if (mode === DateSelectMode.month) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if needed
      return `${year}-${month}`;
    }
    // mode is "date"
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if needed
    const day = date.getDate().toString().padStart(2, "0"); // add leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDate(event.target.value);
    if (onChange) {
      onChange(event);
    } else {
      if (register?.onChange) {
        await register.onChange(event);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  return (
    <Grid container>
      <FormControl sx={{ textAlign: "start" }} fullWidth>
        <div>{text}</div>
        <TextField
          id="date"
          type={mode}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          variant="outlined"
          sx={{
            "&.MuiTextField-root": {
              "& fieldset": {
                border: `2px solid ${
                  error ? theme.palette.error.main : ZVJS_COLORS.BLACK
                }`,
              },
              "& input": {
                color: selectedDate ? ZVJS_COLORS.BLACK : "transparent",
                background: ZVJS_COLORS.WHITE,
              },
            },
          }}
          InputProps={{
            inputProps: {
              min: minDate ? toDateRangeString(minDate) : undefined,
              max: maxDate ? toDateRangeString(maxDate) : undefined,
            },
            //startAdornment: <DateRangeIcon />
          }}
          value={value}
          disabled={disabled}
          {...register}
          onChange={onChangeHandler}
        />
      </FormControl>
      {error === true && validationErrorMessage && validationFlagType && (
        <Grid item xs>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationFlagType)}
            style={{ marginTop: "1rem" }}
          >
            {validationErrorMessage}
          </ZvjsAlert>
        </Grid>
      )}
    </Grid>
  );
};

export default ZvjsDateSelect;
