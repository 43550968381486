import { Grid } from "@mui/material";
import { ZvjsCard } from "../../../common";
import React, { FunctionComponent, SVGProps } from "react";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Other/alert.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/Other/person.svg";
import { ReactComponent as PrisonIcon } from "../../../assets/icons/Other/prison.svg";
import { ReactComponent as PenaltyIcon } from "../../../assets/icons/Other/penalty.svg";
import { ReactComponent as EducationIcon } from "../../../assets/icons/Other/education.svg";
import { ReactComponent as MoneyIcon } from "../../../assets/icons/Other/money.svg";
import { ReactComponent as LettersIcon } from "../../../assets/icons/Other/letters.svg";
import { ReactComponent as VisitIcon } from "../../../assets/icons/Other/visit.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/Other/phone.svg";
import { ReactComponent as HygieneIcon } from "../../../assets/icons/Other/hygiene.svg";
import { ReactComponent as HealthIcon } from "../../../assets/icons/Other/health.svg";
import { ReactComponent as MealIcon } from "../../../assets/icons/Other/meal.svg";
import { ReactComponent as ClothesIcon } from "../../../assets/icons/Other/clothes.svg";
import { ReactComponent as PsychologyIcon } from "../../../assets/icons/Other/psychology.svg";
import { ReactComponent as RequestIcon } from "../../../assets/icons/Other/request.svg";
import { useNavigate } from "react-router-dom";
import { Requests_SC_KIO_0481 } from "../../../common/specs/sections/Requests_SC_KIO_0481";
import { RequestsLoadedDataType } from "./Requests";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik,
} from "../../../utils/helpers";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import { useUITranslation } from "../../../store/context/translation-context";

interface RequestCategoriesProps {
  requestCategories: RequestsLoadedDataType["data"]["requestCategories"];
}

const RequestCategories = (props: RequestCategoriesProps) => {
  const categoriesCounter = props.requestCategories;
  const navigate = useNavigate();
  const { getFallbackJazyk } = useUITranslation();

  const iconMap: {
    [key: string]: FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
  } = {
    1: LettersIcon,
    2: AlertIcon,
    3: PrisonIcon,
    4: VisitIcon,
    5: ClothesIcon,
    6: PenaltyIcon,
    7: HygieneIcon,
    8: PersonIcon,
    9: MoneyIcon,
    10: PsychologyIcon,
    11: EducationIcon,
    12: MealIcon,
    13: PhoneIcon,
    14: RequestIcon,
    15: HealthIcon,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = (key: string): React.FunctionComponent<any> => {
    return iconMap[key] ?? RequestIcon;
  };

  const uniqueRequestCategoriesCodesSet = getUniqueCodesFromCiselnik(
    categoriesCounter?.data?.records ?? []
  );

  return (
    <Grid container mt={2} spacing={3}>
      {uniqueRequestCategoriesCodesSet
        ?.sort((i1code, i2code) => {
          const i1 = categoriesCounter.data.records?.find(
            (item) => item.kod === i1code
          );
          const i2 = categoriesCounter.data.records?.find(
            (item) => item.kod === i2code
          );
          if (i1?.poradoveCislo && i2?.poradoveCislo) {
            return i1.poradoveCislo - i2.poradoveCislo;
          }
          if (i1?.id && i2?.id) {
            return i1.id - i2.id;
          }
          return 0;
        })
        .map((code, index) => (
          <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
            <ZvjsCard
              title={capitalize(
                getTranslationByLanguageCode(
                  categoriesCounter?.data.records ?? [],
                  getCiselnikJazyk(i18n.language),
                  getFallbackJazyk(),
                  code,
                  "nazov"
                )
              )}
              icon={icon(code ?? "1")}
              sx={{ height: "8em" }}
              onClick={() => {
                navigate({
                  pathname: `../${Requests_SC_KIO_0481.RequestsCategories}/${code}`,
                });
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default RequestCategories;
