import { dbPromise } from "../services/database";
import { KEY_API_KEY, KEY_SVC_URL } from "../store/context/envConfigContext";

export const targetApi = async (apiPath: string) => {
  const database = await dbPromise;
  const svcUrl = await database.get("EnvironmentVariables", KEY_SVC_URL);

  const targetUrl = `${svcUrl}${apiPath}`;
  console.debug(`TARGET API [${targetUrl}]`);

  return targetUrl;
};

export const targetUiApi = (apiPath: string) => {
  const svcUrl = localStorage.getItem(KEY_SVC_URL);

  const targetUrl = `${svcUrl}${apiPath}`;
  console.debug(`TARGET API [${targetUrl}]`);

  return targetUrl;
};

export const BaseApiCallHeaders = async () => {
  const database = await dbPromise;
  const apiKey = (await database.get(
    "EnvironmentVariables",
    KEY_API_KEY
  )) as string;

  return {
    "X-API-Key": apiKey ?? "invalidAPIKey",
  };
};

export const FileApiCallHeaders = async () => {
  return {
    ...(await BaseApiCallHeaders()),
    Accept: "application/*, image/* ,audio/*, video/*, text/*",
  };
};

export const FileDirectoryApiCallHeaders = async () => {
  return {
    ...(await BaseApiCallHeaders()),
    Accept: "application/xml",
  };
};

export const JsonApiCallHeaders = async () => {
  return {
    ...(await BaseApiCallHeaders()),
    Accept: "application/json",
  };
};

export const JsonApiCallWithContentHeaders = async () => {
  return {
    ...(await JsonApiCallHeaders()),
    "Content-Type": "application/json",
  };
};
