import { defer, useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EOO_Post } = await API_Clients();

  const rozhodnutia = await EOO_Post("/api/Rozhodnutie/ListRozhodnutieData", {
    body: {
      filters: [
        {
          klientId: Number(localStorage.getItem("klientId")),
          ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      ],
    },
  });

  if (rozhodnutia.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [rozhodnutia klienta]");
  }

  return {
    rozhodnutia: rozhodnutia,
  };
};

export const RozhodnutiaTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface RozhodnutiaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const RozhodnutiaTab = (props: RozhodnutiaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  return (
    <Stack direction="column" mt={1}>
      <ZvjsTable
        data={{
          label: capitalize(tui("zoznam rozhodnutí OČTK a súdov")),
          header: [
            {
              value: capitalize(tui("názov")),
              align: "left",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("spisová značka")),
              align: "center",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("zrušené")),
              align: "center",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: "calc(100% / 4)",
            },
          ],
          body:
            props.data.rozhodnutia.data.records?.map((rozhodnutie) => {
              const rd: RowData = {
                row: [
                  {
                    value: rozhodnutie.typRozhodnutiaSuduAleboOctkNazov ?? "",
                    align: "left",
                    width: "calc(100% / 4)",
                  },
                  {
                    value: rozhodnutie.spisovaZnacka ?? "",
                    align: "center",
                    width: "calc(100% / 4)",
                  },
                  {
                    // TODO: co znamena stlpec "Aktualny", aku property tam dat?
                    value:
                      (rozhodnutie.zrusene ? tui("áno") : tui("nie")) ?? "",
                    align: "center",
                    width: "calc(100% / 4)",
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(tui("detail"))}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(`Verdict/${rozhodnutie.id}`);
                        }}
                      />
                    ),
                    align: "right",
                    width: "calc(100% / 4)",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={"57vh"}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};
export default withLoader(RozhodnutiaTab);
