import { createSelector } from "@reduxjs/toolkit";
import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { findItem } from "../../../../../../redux/slice";
import { selectSelf } from "../../../../../../redux/selectors";
import {
  BlizkaOsoba,
  DetailZakladneUdajeKlientaData,
} from "../../../../../../../specs/swaggerTypes";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/Data";

const CLOSE_PEOPLE_LIST = "CLOSE_PEOPLE_LIST";
export const SC_KIO_050202_ClosePeopleGridConstants = {
  CLOSE_PEOPLE_LIST,
};

/**
 * Selectors
 */
const getClosePeopleGridDisplayData_SC_KIO_050202 = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      peopleList: state.userCalcData[
        SC_KIO_050202_ClosePeopleGridConstants.CLOSE_PEOPLE_LIST
      ] as BlizkaOsoba[],
    };
  });

const getClosePersonRelationTypeCounter_SC_KIO_050202 = () =>
  createSelector(
    selectSelf,
    (
      state: State
    ): CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"] => {
      if (state.counters["/api/CisTypBlizkejOsoby/List"]) {
        return state.counters["/api/CisTypBlizkejOsoby/List"];
      }
      return {};
    }
  );

const getClosePeopleGridBasicClientData_SC_KIO_050202 = () =>
  createSelector(selectSelf, (state: State): DetailZakladneUdajeKlientaData => {
    return state.userCalcData["BASIC_CLIENT_DATA"];
  });

export const allSelectors = {
  getClosePeopleGridDisplayData_SC_KIO_050202,
  getClosePeopleGridBasicClientData_SC_KIO_050202,
  getClosePersonRelationTypeCounter_SC_KIO_050202,
};
