import { defer } from "react-router-dom";
import { ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EZOO_Post } = await API_Clients();

  const pracovneZaradenia = await EZOO_Post(
    "/api/Integracie/ListKlientNaPracovisku",
    {
      body: {
        filters: [
          {
            klientObjectId: localStorage.getItem("klientObjectId"),
          },
        ],
      },
    }
  );

  if (pracovneZaradenia.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [pracovne zaradenia]");
  }

  return {
    pracovneZaradenia: pracovneZaradenia,
  };
};

export const PracovneZaradenieTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface PracovneZaradenieTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PracovneZaradenieTab = (props: PracovneZaradenieTabProps) => {
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("názov pracoviska")),
              align: "left",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("ústav")),
              align: "center",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("dátum od")),
              align: "right",
              width: "calc(100% / 4)",
            },
            {
              value: capitalize(tui("dátum do")),
              align: "right",
              width: "calc(100% / 4)",
            },
          ],
          body:
            props.data.pracovneZaradenia.data?.records?.map(
              (pracovneZaradenie) => {
                const rd: RowData = {
                  row: [
                    {
                      value: pracovneZaradenie.pracovisko ?? "",
                      align: "left",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: pracovneZaradenie.ustavZvjsId ?? "",
                      align: "center",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: pracovneZaradenie.datumZaradenia
                        ? new Date(
                            pracovneZaradenie.datumZaradenia
                          ).zvjsToString()
                        : "",
                      align: "right",
                      width: "calc(100% / 4)",
                    },
                    {
                      value: pracovneZaradenie.datumVyradenia
                        ? new Date(
                            pracovneZaradenie.datumVyradenia
                          ).zvjsToString()
                        : "",
                      align: "right",
                      width: "calc(100% / 4)",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(PracovneZaradenieTab);
