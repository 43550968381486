import React, { createContext, useContext } from "react";
import { API_Clients } from "./dataApi/Data";

type AuditLogsContextType = {
  auditLog: (message: string) => void;
};

const AuditLogsContext = createContext<AuditLogsContextType | null>(null);

export const useAuditLogs = () =>
  useContext(AuditLogsContext) as AuditLogsContextType;

type Props = {
  children: React.ReactNode;
};

export const AuditLogsProvider: React.FC<Props> = ({ children }) => {
  const auditLog = async (poznamka: string) => {
    console.debug(`auditLog message[${poznamka}]`);

    const { AUDITING_Post } = await API_Clients();

    AUDITING_Post("/api/AuditLog/Create", {
      body: {
        datumACas: Date.now().toString(),
        funkcnyCelokKod: "KIOSK",
        klientId: localStorage.getItem("klientObjectId"),
        ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        poznamka: poznamka,
      },
    });
  };

  return (
    <AuditLogsContext.Provider
      value={{
        auditLog,
      }}
    >
      {children}
    </AuditLogsContext.Provider>
  );
};
