import React, { createContext, useEffect, useState } from "react";
import { dbPromise } from "../../services/database";
import LoadingPage from "../../pages/initialLoad/Loading";
import LoadingErrorPage from "../../pages/initialLoad/LoadingError";

export const KEY_SVC_URL = "svcUrl";
export const KEY_API_KEY = "apiKey";
export const KEY_HOLUP_URL = "holupUrl";
export const KEY_DEVICE_TYPE = "deviceType";

export enum DEVICE_TYPE {
  TABLET = "tablet",
  KIOSK = "kiosk",
}

/**
 * @description Check if device type is tablet
 */
export const isTablet = () => {
  return localStorage.getItem(KEY_DEVICE_TYPE) === DEVICE_TYPE.TABLET;
};

/**
 * @description Check if device type is tablet (for use in Service Worker)
 * @see isTablet
 */
export const isTabletSW = async () => {
  const database = await dbPromise;
  const deviceType = await database.get(
    "EnvironmentVariables",
    KEY_DEVICE_TYPE
  );
  return deviceType === DEVICE_TYPE.TABLET;
};

/**
 * @description Check if device type is kiosk
 */
export const isKiosk = () => {
  // default app mode is kiosk (in situations when KEY_DEVICE_TYPE is not provided in env.json)
  return !isTablet();
};

/**
 * @description Check if device type is kiosk (for use in Service Worker)
 * @see isKiosk
 */
export const isKioskSW = async () => {
  return !(await isTabletSW());
};

export type StringMap = {
  [key: string]: string;
};

export interface IContextWithChildrenProps {
  children: React.ReactNode;
}

interface IEnvConfigContextProps {
  isLoading: boolean;
  // TODO: define special envConfig type which consist only of envVariable keys
  envConfig: StringMap;
}

const defaultEnvConfigProps = {
  isLoading: true,
  envConfig: {},
};

const EnvConfigContext = createContext<IEnvConfigContextProps>(
  defaultEnvConfigProps
);

const EnvConfigContextProvider: React.FC<IContextWithChildrenProps> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [envConfig, setEnvConfig] = useState({});
  const [isLoadError, setIsLoadError] = useState(false);

  useEffect(() => {
    const fetchEnvConfig = async () => {
      const envConfigResponse = await fetch("/env.json");
      const envConfigData: {
        svcUrl: string;
        apiKey: string;
        holupUrl: string;
        deviceType: string;
      } = await envConfigResponse.json();

      localStorage.setItem(KEY_SVC_URL, envConfigData?.svcUrl ?? "");
      localStorage.setItem(KEY_API_KEY, envConfigData?.apiKey ?? "");
      localStorage.setItem(KEY_HOLUP_URL, envConfigData?.holupUrl ?? "");
      localStorage.setItem(KEY_DEVICE_TYPE, envConfigData?.deviceType ?? "");

      const database = await dbPromise;
      await database.put(
        "EnvironmentVariables",
        envConfigData?.svcUrl ?? "",
        KEY_SVC_URL
      );
      await database.put(
        "EnvironmentVariables",
        envConfigData?.apiKey ?? "",
        KEY_API_KEY
      );
      await database.put(
        "EnvironmentVariables",
        envConfigData?.holupUrl ?? "",
        KEY_HOLUP_URL
      );
      await database.put(
        "EnvironmentVariables",
        envConfigData?.deviceType ?? "",
        KEY_DEVICE_TYPE
      );

      console.debug("Environment variables setup done");
      setEnvConfig(envConfigData);
      setIsLoading(false);
    };
    try {
      fetchEnvConfig();
    } catch {
      console.error("Failed to setup Environment variables");
      setIsLoadError(true);
    }
  }, []);

  return (
    <EnvConfigContext.Provider
      value={{
        isLoading,
        envConfig,
      }}
    >
      {isLoadError && <LoadingErrorPage />}
      {isLoading && !isLoadError && (
        <LoadingPage initialValue={0} maxValue={20} />
      )}
      {!isLoading && !isLoadError && children}
    </EnvConfigContext.Provider>
  );
};

export { EnvConfigContextProvider, EnvConfigContext };
