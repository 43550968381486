import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import { Grid, SelectChangeEvent } from "@mui/material";
import { ZvjsSelect } from "../../../../../../../components";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_050204_InstitutionsQuestion: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const { tuiz } = useUITranslation();
  const dispatch = useDispatch<AppDispatch>();

  console.debug(
    `SC_KIO_050204 INSTITUTIONS QUESTION RERENDER ${location.join("-")}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getInstitutionsQuestionDisplayData(location),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as string | undefined;

  const onChangeHandler = (event: SelectChangeEvent) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        event.target.value
      )
    );
  };

  return (
    <Grid item xs>
      <Grid item xs>
        <ZvjsSelect
          id={`group-question-${location.join("-")}`}
          text={tuiz(questionData.title)}
          items={questionData.options}
          answerRequired={questionData.answerRequired}
          controlled={{
            selectedValue: answerVal ?? "",
            changeHandler: onChangeHandler,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SC_KIO_050204_InstitutionsQuestion;
