import React from "react";
import { Button, ButtonProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export type ZvjsButtonVariants =
  | "primaryAction"
  | "secondaryAction"
  | "backAction"
  | "cancelAction"
  // styles used in requests
  | "requestAction";

interface ZvjsButtonProps extends ButtonProps {
  zvjsVariant: ZvjsButtonVariants;
  text?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const BtnVariants = {
  primaryAction: "contained",
  requestAction: "contained",
  secondaryAction: "contained",
  backAction: "outlined",
  cancelAction: "text",
};

const ZvjsButton: React.FC<ZvjsButtonProps> = ({
  zvjsVariant,
  text,
  children,
  isLoading,
  ...rest
}) => {
  const color = zvjsVariant === "secondaryAction" ? "secondary" : undefined;
  const btnVariant = BtnVariants[zvjsVariant] as
    | "text"
    | "outlined"
    | "contained"
    | undefined;

  const sx =
    zvjsVariant === "requestAction" ? { height: 45, fontSize: 19 } : {};

  return (
    <Button color={color} variant={btnVariant} sx={sx} {...rest}>
      {isLoading !== undefined && isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </Button>
  );
};

export default ZvjsButton;
