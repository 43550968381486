import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import { Grid } from "@mui/material";
import { ZvjsRadioGroup, ZvjsSectionUI } from "../../../../../../../components";
import { TravelExpensesRadioQuestionConstants } from "./redux";
import { ZvjsRadioItem } from "../../../../../../../components/ZvjsRadioGroup";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

export const SC_KIO_050802_TEXTS_KEYS: { [key: string]: string } = {
  SC_KIO_050802_QUES_02_ANS_01:
    "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelNeziadamOposkytnutie",
  SC_KIO_050802_QUES_02_ANS_02:
    "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelTrvalyPobyt",
  SC_KIO_050802_QUES_02_ANS_03:
    "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelPrechodnyPobyt",
  SC_KIO_050802_QUES_02_ANS_04:
    "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovneZastupitelskyUrad",
  SC_KIO_050802_QUES_02_ANS_05:
    "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovnePoHranicu",
};

const SC_KIO_050802_TravelExpensesRadio: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const { tuiz } = useUITranslation();
  const dispatch = useDispatch<AppDispatch>();

  console.debug(
    `SC_KIO_050101 TRAVEL EXPENSES RADIO QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getGeneralCustomQuestionDisplayData(location),
    shallowEqual
  );

  const conditions = useSelector(
    questionnaireRedux.selectors.getTravelExpensesRadioQuestionConditions()
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id)
  ) as string | number | undefined;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        event.target.value
      )
    );
  };

  const getResidenceInSlovakiaRadioItems = (): ZvjsRadioItem[] => {
    const items: ZvjsRadioItem[] = [];
    if (
      conditions[
        TravelExpensesRadioQuestionConstants.PERMANENT_RESIDENCE_IN_SLOVAKIA
      ]
    ) {
      items.push({
        value: "SC_KIO_050802_QUES_02_ANS_02",
        text: tuiz(SC_KIO_050802_TEXTS_KEYS.SC_KIO_050802_QUES_02_ANS_02),
      });
    }

    if (
      conditions[
        TravelExpensesRadioQuestionConstants.TEMPORARY_RESIDENCE_IN_SLOVAKIA
      ]
    ) {
      items.push({
        value: "SC_KIO_050802_QUES_02_ANS_03",
        text: tuiz(SC_KIO_050802_TEXTS_KEYS.SC_KIO_050802_QUES_02_ANS_03),
      });
    }

    return items;
  };

  // TODO replace texts with correct translations
  return (
    <Grid item xs>
      <ZvjsRadioGroup
        id={`radio-question-${location.join("-")}-0`}
        // label={questionData.title}
        items={[
          {
            value: "SC_KIO_050802_QUES_02_ANS_01",
            text: tuiz(SC_KIO_050802_TEXTS_KEYS.SC_KIO_050802_QUES_02_ANS_01),
          },
        ]}
        answerRequired={true}
        onChange={onChangeHandler}
        selectedValue={answerVal ?? ""}
      />
      {conditions[
        TravelExpensesRadioQuestionConstants.RESIDENCE_IN_SLOVAKIA
      ] && (
        <ZvjsSectionUI
          sectionTitles={[
            tuiz(
              "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovneVSR"
            ),
          ]}
        >
          <ZvjsRadioGroup
            id={`radio-question-${location.join("-")}-1`}
            items={getResidenceInSlovakiaRadioItems()}
            answerRequired={true}
            onChange={onChangeHandler}
            selectedValue={answerVal ?? ""}
          />
        </ZvjsSectionUI>
      )}
      {conditions[
        TravelExpensesRadioQuestionConstants.RESIDENCE_NOT_IN_SLOVAKIA
      ] && (
        <ZvjsSectionUI
          sectionTitles={[
            tuiz(
              "ziadost.ziadostOposkytnutiePomociPriPrepusteni.labelCestovneMimoSR"
            ),
          ]}
        >
          <ZvjsRadioGroup
            id={`radio-question-${location.join("-")}-2`}
            items={[
              {
                value: "SC_KIO_050802_QUES_02_ANS_04",
                text: tuiz(
                  SC_KIO_050802_TEXTS_KEYS.SC_KIO_050802_QUES_02_ANS_04
                ),
              },
              {
                value: "SC_KIO_050802_QUES_02_ANS_05",
                text: tuiz(
                  SC_KIO_050802_TEXTS_KEYS.SC_KIO_050802_QUES_02_ANS_05
                ),
              },
            ]}
            answerRequired={true}
            onChange={onChangeHandler}
            selectedValue={answerVal ?? ""}
          />
        </ZvjsSectionUI>
      )}
    </Grid>
  );
};

export default SC_KIO_050802_TravelExpensesRadio;
