import moment from "moment";

export enum WebDavFileType {
  directory,
  file,
}

export type WebDavItemType = {
  name: string;
  type: WebDavFileType;
  contentType: string;
  lastModification: string;
};

export type WebDavList = WebDavItemType[];

/**
 * @description get information from the WebDAV document
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns structured information about WebDAV document
 */
export const webDavGetInfo = (webDavDocument: Document): WebDavList => {
  const result: WebDavList = [];

  const responses = webDavDocument.getElementsByTagName("d:response");
  for (let i = 0; i < responses.length; i++) {
    const name: string =
      responses[i].getElementsByTagName("d:href")[0].textContent ?? "";

    const type: WebDavFileType =
      responses[i].getElementsByTagName("d:collection").length > 0
        ? WebDavFileType.directory
        : WebDavFileType.file;

    const contentType: string =
      responses[i].getElementsByTagName("d:getcontenttype").length > 0
        ? responses[i].getElementsByTagName("d:getcontenttype")[0]
            .textContent ?? ""
        : "";

    const lastModification: string =
      responses[i].getElementsByTagName("d:getlastmodified").length > 0
        ? responses[i].getElementsByTagName("d:getlastmodified")[0]
            .textContent ?? ""
        : "";

    result.push({
      name: name,
      type: type,
      contentType: contentType,
      lastModification: moment(lastModification).toISOString(),
    });
  }

  return result;
};

/**
 * @description check if the WebDAV document describes file
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns true if the WebDAV document is describing file
 */
export const webDavIsFile = (webDavDocument: Document): boolean => {
  const info = webDavGetInfo(webDavDocument);
  return info.length === 1 && info[0].type === WebDavFileType.file;
};

/**
 * @description check if the WebDAV document describes directory
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns true if the WebDAV document is describing directory
 */
export const webDavIsDirectory = (webDavDocument: Document): boolean => {
  return !webDavIsFile(webDavDocument);
};

/**
 * @description get ContentType
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns content type in case of file or empty string otherwise
 */
export const webDavFileContentType = (webDavDocument: Document): string => {
  const info = webDavGetInfo(webDavDocument);
  return info.length === 1 && info[0].type === WebDavFileType.file
    ? info[0].contentType
    : "";
};

export const webDavCreate = (webDavList: WebDavList): string => {
  return `<?xml version="1.0"?><d:multistatus xmlns:d="DAV:" xmlns:s="http://sabredav.org/ns" xmlns:oc="http://owncloud.org/ns" xmlns:nc="http://nextcloud.org/ns">`
    .concat(
      ...webDavList.map((item) =>
        "<d:response>"
          .concat(`<d:href>${item.name}</d:href>`)
          .concat(
            item.type === WebDavFileType.directory
              ? `<d:collection></d:collection>`
              : ""
          )
          .concat(`<d:getcontenttype>${item.contentType}</d:getcontenttype>`)
          .concat(
            `<d:getlastmodified>${item.lastModification}</d:getlastmodified>`
          )
          .concat("</d:response>")
      )
    )
    .concat("</d:multistatus>");
};
