import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as ReserveIcon } from "../../../assets/icons/Other/reserve.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsSearchBar, ZvjsTable } from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { API_Clients } from "../../../store/context/dataApi/Data";
import useFileApi from "../../../store/context/useFileApi";
import { useSnackbar } from "../../../store/context/snackbar-context";
import { intranetDirectories } from "../../../utils/intranetFiles";
import { isKiosk } from "../../../store/context/envConfigContext";

// Typ zadefinovany podla struktury JSON suboru v Intranete
type KniznicaKatalog = {
  ustav: {
    nazov: string;
    ico: string;
    dic: string;
    korespondencnaAdresa: {
      ulica: string;
      orientacneCislo: string;
      supisneCislo: string;
      psc: string;
      obec: string;
    };
    adresaSidla: {
      ulica: string;
      orientacneCislo: string;
      supisneCislo: string;
      psc: string;
      obec: string;
    };
    identifikaciaVSp: string;
    kodUstavu: string;
    eschranka: string;
    directoryName: string;
  };
  tituly: [
    {
      zaner: string;
      typSignatury: string;
      signaturneCislo: number;
      kusSignatury: number;
      autor: string;
      nazov: string;
      podnazov: string;
      vydavatelstvo: string;
      miestoVydania: string;
      rokVydania: string;
      jazyk: string;
      tematickeZaradenie: string;
      datumZapisu: string;
      datumVyradenia: string | null;
    },
  ];
};

const loadData = async () => {
  const { REZERVACIA_KNIH_Post } = await API_Clients();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getBlob } = useFileApi();

  const intranetDirs = await intranetDirectories(
    Number(localStorage.getItem("klientUstavId")),
    isKiosk()
  );

  const katalogTitulovFile = await getBlob(intranetDirs.kniznicaKatalogTitulov);

  const katalogTitulovString = await katalogTitulovFile?.text();
  const katalogTitulov: KniznicaKatalog = JSON.parse(
    katalogTitulovString ?? ""
  );

  const rezervacie = await REZERVACIA_KNIH_Post("/rezervacia/list", {
    body: {
      klientId: localStorage.getItem("klientObjectId") ?? "",
      ustavId: Number(localStorage.getItem("klientUstavId")),
    },
  });

  return { katalogTitulov: katalogTitulov, rezervacie: rezervacie };
};

export const KniznicaKatalogTitulovLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface KniznicaKatalogTitulovProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const KniznicaKatalogTitulov = (props: KniznicaKatalogTitulovProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const mySubmitHandler = (
    comboboxValue1: string,
    comboboxValue2: string,
    comboboxValue3: string
  ) => {
    console.log(comboboxValue1);
    console.log(comboboxValue2);
    console.log(comboboxValue3);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("Katalóg titulov knižnice"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsSearchBar
        title={tui("vyhľadať v katalógu")}
        type={ZvjsSearchBarVariant.VARIANT_F_CoCoCo}
        onSubmit={mySubmitHandler}
        comboboxLabel={capitalize(tui("žáner"))}
        items={[
          ...new Set( // Set selects unique "zaner" values
            props.data.katalogTitulov.tituly.map((titul) => titul.zaner)
          ),
        ].map((item) => {
          return { value: item, text: item };
        })}
        comboboxLabel2="Pohyb"
        items2={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel3="Pohyb"
        items3={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("názov titulu")),
              align: "left",
            },
            {
              value: capitalize(tui("autor")),
              align: "left",
            },
            {
              value: capitalize(tui("žáner")),
              align: "left",
            },
            {
              value: capitalize(tui("jazyk")),
              align: "left",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
            },
          ],
          body:
            props.data.katalogTitulov.tituly
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((titul) => {
                const rd: RowData = {
                  row: [
                    {
                      value: `${titul.nazov} ${
                        titul.podnazov ? ` - ${titul.podnazov}` : ""
                      }`,
                      align: "left",
                    },
                    {
                      value: titul.autor,
                      align: "left",
                    },
                    {
                      value: titul.zaner,
                      align: "left",
                    },
                    {
                      value: titul.jazyk,
                      align: "left",
                    },
                    {
                      value: (
                        <ZvjsButton
                          text={capitalize(tui("rezervovať"))}
                          zvjsVariant="secondaryAction"
                          startIcon={<ReserveIcon style={{ fill: "white" }} />}
                          onClick={async () => {
                            const { REZERVACIA_KNIH_Post } =
                              await API_Clients();
                            const rezervaciaResult = await REZERVACIA_KNIH_Post(
                              "/rezervacia",
                              {
                                body: {
                                  klientId:
                                    localStorage.getItem("klientObjectId") ??
                                    "",
                                  ustavId: Number(
                                    localStorage.getItem("klientUstavId")
                                  ),
                                  datumVytvorenia: new Date().toISOString(),
                                  nazovKniha: titul.nazov,
                                  nazovAutor: titul.autor,
                                  tematickeZaradenie: titul.tematickeZaradenie,
                                },
                              }
                            );
                            if (
                              rezervaciaResult.data &&
                              rezervaciaResult.data !== undefined
                            ) {
                              const rezervaciaOdoslatResult =
                                await REZERVACIA_KNIH_Post(
                                  "/rezervacia/{id}/odoslat",
                                  {
                                    params: {
                                      path: {
                                        id: rezervaciaResult.data,
                                      },
                                    },
                                    body: {
                                      datumOdoslania: new Date().toISOString(),
                                    },
                                  }
                                );
                              if (rezervaciaOdoslatResult.response.ok) {
                                openSnackbar(
                                  `Knihu ${titul.nazov} / ${titul.autor} ste si uspesne zarezervovali`,
                                  "success"
                                );
                              } else {
                                openSnackbar(
                                  `Rezervovanie knihy ${titul.nazov} / ${titul.autor} zlyhalo`,
                                  "error"
                                );
                              }
                            } else {
                              if (
                                rezervaciaResult.response.status === 503 &&
                                rezervaciaResult.response.headers.get(
                                  "X-Zvjs-Offline"
                                ) === "offline"
                              ) {
                                openSnackbar(
                                  `Požiadavka na rezerváciu knihy ${titul.nazov} / ${titul.autor} bola zaevidovaná. V systéme sa prejaví po synchronizácií tabletu.`,
                                  "success"
                                );
                              } else {
                                openSnackbar(
                                  `Knihu ${titul.nazov} / ${titul.autor} sa nepodarilo zarezervovat`,
                                  "error"
                                );
                              }
                            }
                          }}
                        />
                      ),
                      align: "right",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={"48vh"}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(KniznicaKatalogTitulov);
