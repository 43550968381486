import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async (id: string) => {
  const { EOO_Post } = await API_Clients();

  const rozhodnutia = await EOO_Post("/api/Rozhodnutie/ListRozhodnutieData", {
    body: {
      filters: [
        {
          klientId: Number(localStorage.getItem("klientId")),
          ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      ],
    },
  });

  return {
    rozhodnutie: rozhodnutia.data?.records?.find(
      (r) => r.id === (Number(id) ?? 0)
    ),
  };
};

export const RozhodnutieDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

export interface RozhodnutieDetailProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const RozhodnutieDetail = (props: RozhodnutieDetailProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("detail rozhodnutia"))}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={capitalize(tui("názov"))}
              value={
                props.data.rozhodnutie?.typRozhodnutiaSuduAleboOctkNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("spisová značka"))}
              value={props.data.rozhodnutie?.spisovaZnacka ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("zrušené"))}
              value={props.data.rozhodnutie?.zrusene ? tui("áno") : tui("nie")}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={tui("ics")}
              value={props.data.rozhodnutie?.ics ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("poznámka"))}
              value={props.data.rozhodnutie?.poznamka ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("odosielateľ"))}
              value={props.data.rozhodnutie?.odosielatel ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("súvisiaca väzba"))}
              value={props.data.rozhodnutie?.suvisiacaVazbaNazov ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("súvisiaci trest"))}
              value={props.data.rozhodnutie?.suvisiaciTrestNazov ?? ""}
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(RozhodnutieDetail);
