import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsAlert,
  ZvjsButton,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsSelect,
} from "../../../../../../../components";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import CustomQuestionProps from "../CustomQuestionProps";
import { Box, SelectChangeEvent, Stack } from "@mui/material";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import {
  CivilianClothesGridConstants,
  SC_KIO_051102_CivilianClothesGrid_answerType,
} from "./redux";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik,
  isEmptyArray,
} from "../../../../../../../../utils/helpers";
import { ZvjsSelectItem } from "../../../../../../../components/ZvjsSelect";
import {
  getSeverityOfZvjsFlag,
  ZvjsLabelFlagTypes,
} from "../../../../../../redux/model";
import ZvjsTable, {
  ZvjsTableVariant,
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
} from "../../../../../../../components/ZvjsTable";

const SC_KIO_051102_CivilianClothesGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const { i18n } = useTranslation();
  const [selectHygienicSafetyOptions, setSelectHygienicSafetyOptions] =
    useState<ZvjsSelectItem[]>([]);

  console.debug(
    `SC_KIO_051102 CIVILIAN CLOTHES GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getCivilianClothesGridDisplayData(location),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | SC_KIO_051102_CivilianClothesGrid_answerType[];

  const getAnswerDisplayValue = (
    itemType: string,
    countOfCurrentlyRegisteredItems?: number,
    clientMethodOfEnsuringHygienicSafety?: string
  ) => {
    for (const answer of answerVal ?? []) {
      if (answer.civilianClothesType === itemType) {
        return {
          currentCountOfRequestedItems: answer.currentCountOfRequestedItems,
          currentClientMethodOfEnsuringHygienicSafety:
            answer.currentClientMethodOfEnsuringHygienicSafety,
        };
      }
    }

    return {
      currentCountOfRequestedItems: countOfCurrentlyRegisteredItems ?? 0,
      currentClientMethodOfEnsuringHygienicSafety:
        clientMethodOfEnsuringHygienicSafety ??
        CivilianClothesGridConstants.DEFAULT_METHOD_OF_ENSURING_HYGIENIC_SAFETY_CODE,
    };
  };

  useEffect(() => {
    let dropdownItems: ZvjsSelectItem[] = [];
    const counterCodes = getUniqueCodesFromCiselnik(
      questionData.methodOfEnsuringHygienicSafetyCounter
    );
    dropdownItems = counterCodes.map((code) => {
      return {
        value: code,
        text: getTranslationByLanguageCode(
          questionData.methodOfEnsuringHygienicSafetyCounter,
          getCiselnikJazyk(i18n.language),
          getFallbackJazyk(),
          code,
          "sposob"
        ),
      };
    });
    setSelectHygienicSafetyOptions(dropdownItems);
  }, [
    getFallbackJazyk,
    i18n.language,
    questionData.methodOfEnsuringHygienicSafetyCounter,
  ]);

  const onIncreaseItemCountHandler = (
    item: (typeof questionData.civilianClothesList)[0]
  ) => {
    let answersCopy = JSON.parse(
      JSON.stringify(answerVal ?? [])
    ) as SC_KIO_051102_CivilianClothesGrid_answerType[];

    const newAnswerValueAlreadyPresent = answersCopy.some(
      (answer) => answer.civilianClothesType === item.civilianClothesType
    );

    if (newAnswerValueAlreadyPresent) {
      answersCopy = answersCopy.map((answer) => {
        if (answer.civilianClothesType === item.civilianClothesType) {
          // update previous answer value
          answer.currentCountOfRequestedItems =
            answer.currentCountOfRequestedItems + 1;
          return answer;
        } else {
          return answer;
        }
      });
      // filter out all answers whose value is now the same as was on request init
      answersCopy = answersCopy.filter((answer) => {
        if (
          answer.currentCountOfRequestedItems ===
            item.countOfCurrentlyRegisteredItems &&
          answer.currentClientMethodOfEnsuringHygienicSafety ===
            item.clientMethodOfEnsuringHygienicSafety
        ) {
          return false;
        }
        return true;
      });
    } else {
      // if the client has not updated answer for this particular item yet, push new answer to answer array
      if (
        (item.countOfCurrentlyRegisteredItems ?? 0) <
        item.maxCountOfRegisteredItems
      ) {
        answersCopy.push({
          civilianClothesType: item.civilianClothesType,
          currentCountOfRequestedItems:
            (item.countOfCurrentlyRegisteredItems ?? 0) + 1,
          currentClientMethodOfEnsuringHygienicSafety:
            item.clientMethodOfEnsuringHygienicSafety ??
            CivilianClothesGridConstants.DEFAULT_METHOD_OF_ENSURING_HYGIENIC_SAFETY_CODE,
        });
      }
    }

    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        answersCopy
      )
    );
  };

  const onDecreaseItemCountHandler = (
    item: (typeof questionData.civilianClothesList)[0]
  ) => {
    let answersCopy = JSON.parse(
      JSON.stringify(answerVal ?? [])
    ) as SC_KIO_051102_CivilianClothesGrid_answerType[];

    const newAnswerValueAlreadyPresent = answersCopy.some(
      (answer) => answer.civilianClothesType === item.civilianClothesType
    );

    if (newAnswerValueAlreadyPresent) {
      answersCopy = answersCopy.map((answer) => {
        if (answer.civilianClothesType === item.civilianClothesType) {
          // update previous answer value
          answer.currentCountOfRequestedItems =
            answer.currentCountOfRequestedItems - 1;
          return answer;
        } else {
          return answer;
        }
      });
      // filter out all answers whose value is now the same as was on request init
      answersCopy = answersCopy.filter((answer) => {
        if (
          answer.currentCountOfRequestedItems ===
            item.countOfCurrentlyRegisteredItems &&
          answer.currentClientMethodOfEnsuringHygienicSafety ===
            item.clientMethodOfEnsuringHygienicSafety
        ) {
          return false;
        }
        return true;
      });
    } else {
      // if the client has not updated answer for this particular item yet, push new answer to answer array
      if ((item.countOfCurrentlyRegisteredItems ?? 0) !== 0) {
        answersCopy.push({
          civilianClothesType: item.civilianClothesType,
          currentCountOfRequestedItems:
            (item.countOfCurrentlyRegisteredItems ?? 0) - 1,
          currentClientMethodOfEnsuringHygienicSafety:
            item.clientMethodOfEnsuringHygienicSafety ??
            CivilianClothesGridConstants.DEFAULT_METHOD_OF_ENSURING_HYGIENIC_SAFETY_CODE,
        });
      }
    }

    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        answersCopy
      )
    );
  };

  const onChangeMethodOfEnsuringHygienicSafetyHandler = (
    event: SelectChangeEvent,
    item: (typeof questionData.civilianClothesList)[0]
  ) => {
    let answersCopy = JSON.parse(
      JSON.stringify(answerVal ?? [])
    ) as SC_KIO_051102_CivilianClothesGrid_answerType[];

    const newAnswerValueAlreadyPresent = answersCopy.some(
      (answer) => answer.civilianClothesType === item.civilianClothesType
    );

    if (newAnswerValueAlreadyPresent) {
      answersCopy = answersCopy.map((answer) => {
        if (answer.civilianClothesType === item.civilianClothesType) {
          // update previous answer value
          answer.currentClientMethodOfEnsuringHygienicSafety =
            event.target.value;
          return answer;
        } else {
          return answer;
        }
      });
      // filter out all answers whose value is now the same as was on request init
      answersCopy = answersCopy.filter((answer) => {
        if (
          answer.currentCountOfRequestedItems ===
            item.countOfCurrentlyRegisteredItems &&
          answer.currentClientMethodOfEnsuringHygienicSafety ===
            item.clientMethodOfEnsuringHygienicSafety
        ) {
          return false;
        }
        return true;
      });
    } else {
      // if the client has not updated answer for this particular item yet, push new answer to answer array
      answersCopy.push({
        civilianClothesType: item.civilianClothesType,
        currentCountOfRequestedItems: item.countOfCurrentlyRegisteredItems ?? 0,
        currentClientMethodOfEnsuringHygienicSafety: event.target.value,
      });
    }

    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        answersCopy
      )
    );
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
      width: 175,
    },
    {
      sx: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("Položka")),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoOdevu.labelPocetAktualneEvidovanychPoloziek"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoOdevu.labelMaximalnyPocetPoloziek"
        )
      ),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOpouzivanieVlastnehoOdevu.labelNovyPocet")
      ),
      ...dataStyle[3],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoOdevu.labelPredpokladaneNakladyPracovna"
        )
      ),
      ...dataStyle[4],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoOdevu.labelHygienickaNezavadnost"
        )
      ),
      ...dataStyle[5],
    },
    {
      value: "",
      ...dataStyle[6],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const item of questionData.civilianClothesList) {
    const answer = getAnswerDisplayValue(
      item.civilianClothesType,
      item.countOfCurrentlyRegisteredItems,
      item.clientMethodOfEnsuringHygienicSafety
    );
    bodyData.push({
      row: [
        {
          value: item.civilianClothesName,
          ...dataStyle[0],
        },
        {
          value: item.countOfCurrentlyRegisteredItems ?? 0,
          ...dataStyle[1],
        },
        {
          value: item.maxCountOfRegisteredItems,
          ...dataStyle[2],
        },
        {
          value: answer.currentCountOfRequestedItems,
          ...dataStyle[3],
        },
        {
          value: `${
            answer.currentCountOfRequestedItems *
            item.estimatedWashingCostPerItem
          } EUR`,
          ...dataStyle[4],
        },
        {
          value: (
            <Box sx={{ maxWidth: dataStyle[5].width }}>
              <ZvjsSelect
                id={`method-of-ensuring-hygienic-safety-question-${location.join(
                  "-"
                )}`}
                items={selectHygienicSafetyOptions}
                controlled={{
                  selectedValue: !isEmptyArray(selectHygienicSafetyOptions)
                    ? answer.currentClientMethodOfEnsuringHygienicSafety
                    : "",
                  changeHandler: (event) =>
                    onChangeMethodOfEnsuringHygienicSafetyHandler(event, item),
                }}
              />
            </Box>
          ),
          ...dataStyle[5],
        },
        {
          value: (
            <Stack direction={"row"} spacing={1}>
              <ZvjsButton
                zvjsVariant={"secondaryAction"}
                text={tuiz(
                  "ziadost.ziadostOpouzivanieVlastnehoOdevu.btnPridatPolozku"
                )}
                onClick={() => onIncreaseItemCountHandler(item)}
                disabled={
                  item.maxCountOfRegisteredItems ===
                  answer.currentCountOfRequestedItems
                }
                sx={{
                  fontSize: 19,
                  height: "inherit",
                  lineHeight: "normal",
                  width: "min-content",
                }}
              />
              <ZvjsButton
                zvjsVariant={"secondaryAction"}
                text={tuiz(
                  "ziadost.ziadostOpouzivanieVlastnehoOdevu.btnOdobratPolozku"
                )}
                disabled={answer.currentCountOfRequestedItems === 0}
                onClick={() => onDecreaseItemCountHandler(item)}
                sx={{
                  fontSize: 19,
                  height: "inherit",
                  lineHeight: "normal",
                  width: "min-content",
                }}
              />
            </Stack>
          ),
          ...dataStyle[6],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      {/*if any row in the table has selected "washing in the institutional laundry" show warning label*/}
      {questionData.civilianClothesList.some(
        (item) =>
          getAnswerDisplayValue(
            item.civilianClothesType,
            item.countOfCurrentlyRegisteredItems,
            item.clientMethodOfEnsuringHygienicSafety
          ).currentClientMethodOfEnsuringHygienicSafety ===
          CivilianClothesGridConstants.METHOD_OF_ENSURING_HYGIENIC_SAFETY_INSTITUTION_LAUNDRY_CODE
      ) && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
          >
            {tuiz(
              "ziadost.ziadostOpouzivanieVlastnehoOdevu.napovedaPoskodenieVeciVpracovni"
            )}
          </ZvjsAlert>
        </Box>
      )}
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_051102_CivilianClothesGrid;
