import { RequestInternalCode } from "./requestTemplates";
import { SummarySubmitData } from "../requestPages/summary/Summary";
import { NestedDropdownAnswerValueType, State } from "../redux/model";
import { getBaseRequestSubmitData } from "../redux/selectors/summary";
import { questionsIteratorWithLocFromDisplayedSections } from "../redux/slice";
import { isItemDisplayed } from "../redux/selectors";
import { isEmptyArray, isEmptyString } from "../../../utils/helpers";
import { CisOblastZdravotnychProblemov_Bolest_Kod } from "../../specs/countersValues";
import {
  PlaceOfRelocationQuestionConstants,
  SC_KIO_051403_PlaceOfRelocationQuestion_getCustomSubmitAnswerData,
} from "../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_051403_PlaceOfRelocationQuestion/redux";
import {
  CustomCalculationType,
  SC_KIO_050101_ClosePerson,
  SC_KIO_050802_AlreadyRequestedItemsStatus,
} from "../requestPages/questionnaire/utils/calculations/custom";
import {
  FORM_OF_VISIT_QUESTION_ID,
  INVITED_PEOPLE_LIST_QUESTION_ID,
  MONTH_OF_VISIT_QUESTION_ID,
  SC_KIO_050101_is_any_selected_person_in_prison,
} from "./SC_KIO_050101_Ziadost o navstevu";
import { SC_KIO_050101_VisitDateQuestion_getCustomSubmitAnswerData } from "../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050101_VisitDateQuestion/redux";

const SC_KIO_050101_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      ...SC_KIO_050101_VisitDateQuestion_getCustomSubmitAnswerData(
        state.answers[MONTH_OF_VISIT_QUESTION_ID] as string,
        MONTH_OF_VISIT_QUESTION_ID
      ),
      [FORM_OF_VISIT_QUESTION_ID]: state.answers[FORM_OF_VISIT_QUESTION_ID],
      dovodMimoriadnejNavstevy: state.answers["dovodMimoriadnejNavstevy"],
      trvanieNavstevy: state.answers["trvanieNavstevy"],
      sposobOdoslaniaPozvanky: state.answers["sposobOdoslaniaPozvanky"],
      emailPrePozvanku: state.answers["emailPrePozvanku"],
      [INVITED_PEOPLE_LIST_QUESTION_ID]:
        state.answers[INVITED_PEOPLE_LIST_QUESTION_ID],
      pozyvanaOsobaVoVVVT: SC_KIO_050101_is_any_selected_person_in_prison(
        state.answers[INVITED_PEOPLE_LIST_QUESTION_ID] as string[],
        state.userCalcData[
          "SC_KIO_050101_CLOSE_PEOPLE_LIST"
        ] as SC_KIO_050101_ClosePerson[]
      ),
    },
  ];
};

const SC_KIO_050207_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      pozadovanaSumaNaVyplatenie: state.answers["pozadovanaSumaNaVyplatenie"],
      disponibilnyStavKonta: state.userCalcData["AVAILABLE_ACCOUNT_AMOUNT"],
    },
  ];
};

const SC_KIO_050208_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      pozadovanaSuma: state.answers["pozadovanaSuma"],
      sumaNaNakup: state.userCalcData["AVAILABLE_AMOUNT_TO_SHOP"],
    },
  ];
};

const SC_KIO_050401_customSubmit = (state: State): SummarySubmitData[] => {
  const iterator = questionsIteratorWithLocFromDisplayedSections(
    state.questionnaire,
    state
  );

  const toSubmit: { [key: string]: unknown } = {};

  for (const question of iterator) {
    if (isItemDisplayed(question.location, state)) {
      if (question.value.id === "oblastZdravotnychProblemov") {
        const answer = state.answers[question.value.id] as
          | NestedDropdownAnswerValueType[]
          | undefined;

        if (answer) {
          const modifiedAnswerForm = [];
          const bolestiCodes: string[] = [];

          for (const answerVal of answer) {
            if (answerVal["bolest"]) {
              // if a selected option is from the "bolest" counter then store it in a single object
              bolestiCodes.push(answerVal["bolest"]);
            } else {
              modifiedAnswerForm.push({ ...answerVal, bolest: [] });
            }
          }

          if (!isEmptyArray(bolestiCodes)) {
            modifiedAnswerForm.push({
              oblastZdravotnychProblemovCis:
                CisOblastZdravotnychProblemov_Bolest_Kod,
              bolest: bolestiCodes,
            });
          }

          toSubmit[question.value.id] = modifiedAnswerForm;
        }
      } else {
        if (!isEmptyString(state.answers[question.value.id])) {
          // use for submit the same answer form as is used inside the request's generator
          toSubmit[question.value.id] = state.answers[question.value.id];
        }
      }
    }
  }

  toSubmit["ockovanie"] = state.answers["ockovanie"] === true;

  return [
    {
      ...getBaseRequestSubmitData(state),
      ...toSubmit,
    },
  ];
};

const SC_KIO_050601_customSubmit = (state: State): SummarySubmitData[] => {
  const toReturn: SummarySubmitData[] = [];
  const baseRequestSubmitData = getBaseRequestSubmitData(state);
  const answer = state.answers["aktivitaId"] as number[] | undefined;

  if (answer) {
    for (const selectedId of answer) {
      toReturn.push({ ...baseRequestSubmitData, aktivitaId: selectedId });
    }
  }

  return toReturn;
};

const SC_KIO_050802_customSubmit = (state: State): SummarySubmitData[] => {
  const toSubmit: { [key: string]: unknown } = {};
  const alreadyRequestedItemsStatus = state.userCalcData[
    CustomCalculationType.SC_KIO_050802_HAS_ALREADY_REQUESTED_ITEMS_STATUS
  ] as SC_KIO_050802_AlreadyRequestedItemsStatus;

  toSubmit["ziadamOPoskytnutieOdevuObuvi"] =
    alreadyRequestedItemsStatus.ziadamOPoskytnutieOdevuObuvi
      ? alreadyRequestedItemsStatus.ziadamOPoskytnutieOdevuObuvi
      : state.answers["ziadamOPoskytnutieOdevuObuvi"] ===
        "SC_KIO_050802_QUES_01_ANS_01";

  toSubmit["cestovneCestovnyListok"] =
    alreadyRequestedItemsStatus.cestovneCestovnyListok
      ? alreadyRequestedItemsStatus.cestovneCestovnyListok
      : state.answers["cestovneCestovnyListok"] !==
          "SC_KIO_050802_QUES_02_ANS_01" &&
        state.answers["cestovneCestovnyListok"] !== undefined;

  toSubmit["trvalyPobyt"] = alreadyRequestedItemsStatus.trvalyPobyt
    ? alreadyRequestedItemsStatus.trvalyPobyt
    : state.answers["cestovneCestovnyListok"] ===
      "SC_KIO_050802_QUES_02_ANS_02";

  toSubmit["prechodnyPobyt"] = alreadyRequestedItemsStatus.prechodnyPobyt
    ? alreadyRequestedItemsStatus.prechodnyPobyt
    : state.answers["cestovneCestovnyListok"] ===
      "SC_KIO_050802_QUES_02_ANS_03";

  toSubmit["najblizsiZastupitelskyUrad"] =
    alreadyRequestedItemsStatus.najblizsiZastupitelskyUrad
      ? alreadyRequestedItemsStatus.najblizsiZastupitelskyUrad
      : state.answers["cestovneCestovnyListok"] ===
        "SC_KIO_050802_QUES_02_ANS_04";

  toSubmit["poHranicu"] = alreadyRequestedItemsStatus.poHranicu
    ? alreadyRequestedItemsStatus.poHranicu
    : state.answers["cestovneCestovnyListok"] ===
      "SC_KIO_050802_QUES_02_ANS_05";

  toSubmit["ziadostOStravu"] = alreadyRequestedItemsStatus.ziadostOStravu
    ? alreadyRequestedItemsStatus.ziadostOStravu
    : state.answers["ziadostOStravu"] === "SC_KIO_050802_QUES_03_ANS_01";

  return [
    {
      ...getBaseRequestSubmitData(state),
      ...toSubmit,
    },
  ];
};

const SC_KIO_051004_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      vyskaDisponibilnehoKonta: state.userCalcData["AVAILABLE_ACCOUNT_AMOUNT"],
      vyskaKreditu: state.userCalcData["CREDIT_AMOUNT"],
      vyskaVyzivnehoAPohladavok:
        state.userCalcData["REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT"],
    },
  ];
};

const SC_KIO_051201_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      pozadovanyDruhStravy: state.answers["pozadovanyDruhStravy"],
      ramadan: state.answers["ramadan"] === true,
    },
  ];
};

const SC_KIO_051403_customSubmit = (state: State): SummarySubmitData[] => {
  const miestoPozadovanehoPremiestneniaAnswer =
    SC_KIO_051403_PlaceOfRelocationQuestion_getCustomSubmitAnswerData(
      state.answers[
        PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID
      ] as NestedDropdownAnswerValueType[],
      PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID
    )[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID];

  return [
    {
      ...getBaseRequestSubmitData(state),
      miestoPozadovanehoPremiestnenia: miestoPozadovanehoPremiestneniaAnswer,
      prioritnyUstav:
        miestoPozadovanehoPremiestneniaAnswer.length > 1
          ? state.answers["prioritnyUstav"]
          : miestoPozadovanehoPremiestneniaAnswer[0],
      dovodPodaniaZiadosti: state.answers["dovodPodaniaZiadosti"],
      doplnujuceInformacie: state.answers["doplnujuceInformacie"],
    },
  ];
};

/**
 * Methods used to prepare a custom submit data object (array) for request submit to szoo.
 * Usage: In some cases you need to prepare a custom request submit object instead of using default/generic one which is being constructed automatically for each request
 */
export const onCustomRequestSubmitData: CustomRequestSubmitDataFunctionType = {
  SC_KIO_050101: SC_KIO_050101_customSubmit,
  SC_KIO_050207: SC_KIO_050207_customSubmit,
  SC_KIO_050208: SC_KIO_050208_customSubmit,
  SC_KIO_050401: SC_KIO_050401_customSubmit,
  SC_KIO_050601: SC_KIO_050601_customSubmit,
  SC_KIO_050802: SC_KIO_050802_customSubmit,
  SC_KIO_051004: SC_KIO_051004_customSubmit,
  SC_KIO_051201: SC_KIO_051201_customSubmit,
  SC_KIO_051403: SC_KIO_051403_customSubmit,
};

type CustomRequestSubmitDataFunctionType = {
  [key in `${RequestInternalCode}`]?: (state: State) => SummarySubmitData[];
};
