import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsAlert,
  ZvjsCustomQuestionFullWidthBox,
} from "../../../../../../../components";
import { capitalize } from "@mui/material/utils";
import {
  getSeverityOfZvjsFlag,
  ZvjsLabelFlagTypes,
} from "../../../../../../redux/model";
import { Box, Radio } from "@mui/material";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { getTranslationByLanguageCode } from "../../../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import { SC_KIO_050501_DevicesRevisionControlGrid_answerType } from "./redux";
import ZvjsTable, {
  ZvjsTableVariant,
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
} from "../../../../../../../components/ZvjsTable";
import { CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod } from "../../../../../../../specs/countersValues";
import { SC_KIO_050501_ElectricDevice } from "../../../../utils/calculations/custom";

const SC_KIO_050501_DevicesRevisionControlGrid: React.FC<
  CustomQuestionProps
> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const [warningText, setWarningText] = useState<undefined | string>(undefined);

  console.debug(
    `SC_KIO_050501 DEVICES REVISION CONTROL GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getDevicesRevisionControlGridDisplayData(
      location
    ),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | SC_KIO_050501_DevicesRevisionControlGrid_answerType;

  const onRadioChangeHandler = (device: SC_KIO_050501_ElectricDevice): void => {
    if (isRadioDisabled(device.controlValidUntil, device.ongoingInspection)) {
      // if a user clicks on disabled radio, display warning text and do not change answer value
      if (device.ongoingInspection) {
        setWarningText(
          "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaReviziaPrebieha"
        );
      } else {
        if (device.controlValidUntil) {
          if (new Date(device.controlValidUntil) >= controlDateThreshold) {
            setWarningText(
              "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaRevizia"
            );
          }
        }
      }
    } else {
      // if radio is enabled, change answer value
      dispatch(
        questionnaireRedux.actions.addNewAnswer(location, questionData.id, {
          deviceId: device.id,
          deviceTypeCode: device.typeCode,
        } as SC_KIO_050501_DevicesRevisionControlGrid_answerType)
      );
      setWarningText(undefined);
    }
  };

  const controlDateThreshold = new Date();
  controlDateThreshold.setDate(controlDateThreshold.getDate() + 30);

  const isRadioDisabled = (
    controlValidUntil?: string,
    ongoingInspection?: boolean
  ) => {
    if (ongoingInspection) return true;
    if (controlValidUntil) {
      return new Date(controlValidUntil) >= controlDateThreshold;
    }
    return false;
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "center",
    },
    {
      align: "right",
      sx: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelTerminPlatnostiKontroly"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelReviznaKontrola"
        )
      ),
      ...dataStyle[2],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const device of questionData.electricDevices) {
    bodyData.push({
      row: [
        {
          value:
            device.typeCode !==
            CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod
              ? getTranslationByLanguageCode(
                  questionData?.electricDevicesCounter?.records ?? [],
                  getCiselnikJazyk(i18n.language),
                  getFallbackJazyk(),
                  device.typeCode,
                  "druh"
                )
              : device.nameOfOtherDevice ?? "",
          ...dataStyle[0],
        },
        {
          value: device?.controlValidUntil
            ? new Date(device.controlValidUntil).zvjsToString()
            : "",
          ...dataStyle[1],
        },
        {
          value: (
            <div onClick={() => onRadioChangeHandler(device)}>
              <Radio
                value={device.id}
                checked={
                  device.id === answerVal?.deviceId &&
                  device.typeCode === answerVal?.deviceTypeCode
                }
                disabled={isRadioDisabled(
                  device.controlValidUntil,
                  device.ongoingInspection
                )}
              />
            </div>
          ),
          ...dataStyle[2],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: questionData.title,
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      {warningText && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
          >
            {tuiz(warningText)}
          </ZvjsAlert>
        </Box>
      )}
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050501_DevicesRevisionControlGrid;
