import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import ZvjsSummaryTable, {
  ZvjsSummaryTableComponent,
} from "../../../../common/components/ZvjsSummaryTable";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const pohyb = searchParams.get("pohyb")
    ? decodeURIComponent(searchParams.get("pohyb") || "")
    : undefined;
  const typBlokacie = pohyb ? Number(pohyb) : undefined;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `KreditABlokacieTab loadData pohyb[${pohyb}] datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { FEOO_Get, FEOO_Post } = await API_Clients();

  const data = await Promise.all([
    FEOO_Post("/api/Codelist/ListBlokaciaTyp", {
      body: {
        filters: [],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    FEOO_Get("/api/Klient/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }),
    FEOO_Post("/api/integracie/Kiosk/ListStavKontaAKreditu", {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [číselník typ blokácie]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [feoo klient]");
  }

  if (data[2].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [stav konta a kreditu klienta]"
    );
  }

  const blokacie = await FEOO_Post("/api/Blokacia/List", {
    body: {
      filters: [
        {
          zakladnyFilterRequest: {
            klientFeooID:
              data[1].data?.data?.zakladneUdajeKlienta?.klientFeooID,
            datumOd: datumOd,
            datumDo: datumDo,
            typBlokacieID: typBlokacie,
          },
        },
      ],
    },
  });

  if (blokacie.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [blokacie klienta]");
  }

  return {
    typBlokacie: data[0],
    stavKontaAKreditu: data[2],
    blokacie: blokacie,
  };
};

export const KreditABlokacieTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface KreditABlokacieTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const KreditABlokacieTab = (props: KreditABlokacieTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date, pohyb: string) => {
    console.debug(
      `KreditABlokacie onSearch pohyb[${pohyb}] dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(pohyb !== "" && {
            pohyb: encodeURIComponent(pohyb),
          }),
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={[tui("kredit"), tui("osobneUdaje.blokacie.blokacie")]}
        hintText2={tui("osobneUdaje.blokacie.napovedaBlokacie")}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaVreckove")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.aktualnyKredit?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui(
            "osobneUdaje.kontoAkredit.napoveda.zostatokVyzivnePohladavky"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieVreckove")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.blokaciaSumaV?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent label={""} value={""} isBorder={true} />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieOsobné")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.blokaciaSumaO?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent label={""} value={""} isBorder={false} />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieSpolu")}
          value={`${
            props.data.stavKontaAKreditu.data
              .at(0)
              ?.blokaciaSpolu?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
        />
      </ZvjsSummaryTable>

      <ZvjsSearchBar
        title={tui("vyhľadať v pohyboch")}
        type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
        onSubmit={onSearch}
        comboboxLabel={capitalize(tui("osobneUdaje.kontoAkredit.pohyb"))}
        items={
          props.data.typBlokacie.data.records?.map((item) => {
            return { value: item.id || -1, text: item.nazov || "" };
          }) || []
        }
      />

      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("osobneUdaje.blokacie.datumPohybu")),
              align: "left",
              width: "26%",
            },
            {
              value: capitalize(tui("osobneUdaje.kontoAkredit.druhPohybu")),
              align: "left",
              width: "37%",
            },
            {
              value: capitalize(
                tui(
                  // TODO: osobneUdaje.vyplatnePasky VS osobneUdaje.blokacie
                  "osobneUdaje.blokacie.sumaPohybu"
                )
              ),
              align: "left",
              width: "37%",
            },
          ],
          body:
            props.data.blokacie.data?.records
              // .sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((blokacia) => {
                const rd: RowData = {
                  row: [
                    {
                      value: blokacia.datum
                        ? new Date(blokacia.datum).zvjsToString()
                        : "",
                      align: "left",
                      width: "26%",
                    },
                    {
                      value: blokacia.typBlokacie ?? "",
                      align: "left",
                      width: "37%",
                    },
                    {
                      value: blokacia.suma?.toFixed(2) + " Eur",
                      align: "left",
                      width: "37%",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.blokacie.napovedaZrealizovanePohyby")}
      />
    </Stack>
  );
};

export default withLoader(KreditABlokacieTab);
