// UserDataContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";

export enum pohlavieEnum {
  CisPohlavie_NEZISTENE_ID = 1,
  CisPohlavie_MUZ_ID = 2,
  CisPohlavie_ZENA_ID = 3,
}

// Define the context value type
interface UserDataContextType {
  ustavKod: string | null;
  ustavKodSet: (value: string | null) => void;
  ustavSkratka: string | null;
  ustavSkratkaSet: (value: string | null) => void;
  meno: string | null;
  menoSet: (value: string | null) => void;
  priezvisko: string | null;
  priezviskoSet: (value: string | null) => void;
  feooKlientId: number | null;
  feooKlientIdSet: (value: number | null) => void;
  fyzickaOsobaId: number | null;
  fyzickaOsobaIdSet: (value: number | null) => void;
  zakladneCislo: number | null;
  zakladneCisloSet: (value: number | null) => void;
  oddielId: number | null;
  oddielIdSet: (value: number | null) => void;
  vystupnyOddiel: boolean;
  vystupnyOddielSet: (value: boolean) => void;
  pohlavie: pohlavieEnum;
  pohlavieSet: (value: pohlavieEnum) => void;
  aktivnyTrest: boolean;
  aktivnyTrestSet: (value: boolean) => void;
  aktivnaVazba: boolean;
  aktivnaVazbaSet: (value: boolean) => void;
  narodnostId: number | null;
  narodnostIdSet: (value: number | null) => void;
  clearUserData: () => void;
}

// Create the context with a default value
const UserDataContext = createContext<UserDataContextType | undefined>(
  undefined
);

// Provide the context
export const UserDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [ustavKod, setUstavKod] = useState<string | null>(null);
  const [ustavSkratka, setUstavSkratka] = useState<string | null>(null);
  const [meno, setMeno] = useState<string | null>(null);
  const [priezvisko, setPriezvisko] = useState<string | null>(null);
  const [feooKlientId, setFeooKlientId] = useState<number | null>(null);
  const [fyzickaOsobaId, setFyzickaOsobaId] = useState<number | null>(null);
  const [zakladneCislo, setZakladneCislo] = useState<number | null>(null);
  const [oddielId, setOddielId] = useState<number | null>(null);
  const [vystupnyOddiel, setVystupnyOddiel] = useState<boolean>(false);
  const [pohlavie, setPohlavie] = useState<pohlavieEnum>(
    pohlavieEnum.CisPohlavie_NEZISTENE_ID
  );
  const [aktivnyTrest, setAktivnyTrest] = useState<boolean>(false);
  const [aktivnaVazba, setAktivnaVazba] = useState<boolean>(false);
  const [narodnostId, setNarodnostId] = useState<number | null>(null);

  const ustavKodSet = (value: string | null) => {
    console.debug(`ustavKodSet [${value}]`);
    setUstavKod(value);
    localStorage.setItem("ustavKod", value ?? "null");
  };

  const ustavSkratkaSet = (value: string | null) => {
    console.debug(`ustavSkratkaSet [${value}]`);
    setUstavSkratka(value);
    localStorage.setItem("ustavSkratka", value ?? "null");
  };

  const menoSet = (value: string | null) => {
    console.debug(`menoSet [${value}]`);
    setMeno(value);
    localStorage.setItem("meno", value ?? "null");
  };

  const priezviskoSet = (value: string | null) => {
    console.debug(`priezviskoSet [${value}]`);
    setPriezvisko(value);
    localStorage.setItem("priezvisko", value ?? "null");
  };

  const feooKlientIdSet = (value: number | null) => {
    console.debug("feooKlientIdSet");
    setFeooKlientId(value);
    localStorage.setItem("feooKlientId", value?.toString() ?? "null");
  };

  const fyzickaOsobaIdSet = (value: number | null) => {
    console.debug("fyzickaOsobaIdSet");
    setFyzickaOsobaId(value);
    localStorage.setItem("fyzickaOsobaId", value?.toString() ?? "null");
  };

  const zakladneCisloSet = (value: number | null) => {
    console.debug("zakladneCisloSet");
    setZakladneCislo(value);
    localStorage.setItem("zakladneCislo", value?.toString() ?? "null");
  };

  const oddielIdSet = (value: number | null) => {
    console.debug("oddielIdSet");
    setOddielId(value);
    localStorage.setItem("oddielId", value?.toString() ?? "null");
  };

  const vystupnyOddielSet = (value: boolean) => {
    console.debug(`vystupnyOddielSet [${value}]`);
    setVystupnyOddiel(value);
    localStorage.setItem("vystupnyOddiel", value.toString());
  };

  const pohlavieSet = (value: pohlavieEnum) => {
    console.debug(`pohlavieSet [${value}]`);
    setPohlavie(value);
    localStorage.setItem("pohlavie", value.toString());
  };

  const aktivnyTrestSet = (value: boolean) => {
    console.debug(`aktivnyTrestSet [${value}]`);
    setAktivnyTrest(value);
    localStorage.setItem("aktivnyTrest", value.toString());
  };

  const aktivnaVazbaSet = (value: boolean) => {
    console.debug(`aktivnaVazbaSet [${value}]`);
    setAktivnaVazba(value);
    localStorage.setItem("aktivnaVazba", value.toString());
  };

  const narodnostIdSet = (value: number | null) => {
    console.debug("narodnostIdSet");
    setNarodnostId(value);
    localStorage.setItem("narodnostId", value?.toString() ?? "null");
  };

  const clearUserData = () => {
    console.debug("clearUserData");
    ustavKodSet(null);
    localStorage.removeItem("ustavKod");
    ustavSkratkaSet(null);
    localStorage.removeItem("ustavSkratka");
    menoSet(null);
    localStorage.removeItem("meno");
    priezviskoSet(null);
    localStorage.removeItem("priezvisko");
    feooKlientIdSet(null);
    localStorage.removeItem("feooKlientId");
    fyzickaOsobaIdSet(null);
    localStorage.removeItem("fyzickaOsobaId");
    zakladneCisloSet(null);
    localStorage.removeItem("zakladneCislo");
    oddielIdSet(null);
    localStorage.removeItem("oddielId");
    vystupnyOddielSet(false);
    localStorage.removeItem("vystupnyOddiel");
    setPohlavie(pohlavieEnum.CisPohlavie_NEZISTENE_ID);
    localStorage.removeItem("pohlavie");
    aktivnyTrestSet(false);
    localStorage.removeItem("aktivnyTrest");
    aktivnaVazbaSet(false);
    localStorage.removeItem("aktivnaVazba");
    narodnostIdSet(null);
    localStorage.removeItem("narodnostId");
  };

  useEffect(() => {
    const localStorage_ustavKod = localStorage.getItem("ustavKod");
    if (localStorage_ustavKod) {
      setUstavKod(
        localStorage_ustavKod === "null" ? null : localStorage_ustavKod
      );
    }
    const localStorage_ustavSkratka = localStorage.getItem("ustavSkratka");
    if (localStorage_ustavSkratka) {
      setUstavSkratka(
        localStorage_ustavSkratka === "null" ? null : localStorage_ustavSkratka
      );
    }
    const localStorage_meno = localStorage.getItem("meno");
    if (localStorage_meno) {
      setMeno(localStorage_meno === "null" ? null : localStorage_meno);
    }
    const localStorage_priezvisko = localStorage.getItem("priezvisko");
    if (localStorage_priezvisko) {
      setPriezvisko(
        localStorage_priezvisko === "null" ? null : localStorage_priezvisko
      );
    }
    const localStorage_feooKlientId = localStorage.getItem("feooKlientId");
    if (localStorage_feooKlientId) {
      setFeooKlientId(
        localStorage_feooKlientId === "null"
          ? null
          : Number.parseInt(localStorage_feooKlientId)
      );
    }
    const localStorage_fyzickaOsobaId = localStorage.getItem("fyzickaOsobaId");
    if (localStorage_fyzickaOsobaId) {
      setFyzickaOsobaId(
        localStorage_fyzickaOsobaId === "null"
          ? null
          : Number.parseInt(localStorage_fyzickaOsobaId)
      );
    }
    const localStorage_zakladneCislo = localStorage.getItem("zakladneCislo");
    if (localStorage_zakladneCislo) {
      setZakladneCislo(
        localStorage_zakladneCislo === "null"
          ? null
          : Number.parseInt(localStorage_zakladneCislo)
      );
    }
    const localStorage_oddielId = localStorage.getItem("oddielId");
    if (localStorage_oddielId) {
      setOddielId(
        localStorage_oddielId === "null"
          ? null
          : Number.parseInt(localStorage_oddielId)
      );
    }
    const localStorage_vystupnyOddiel = localStorage.getItem("vystupnyOddiel");
    if (localStorage_vystupnyOddiel) {
      setVystupnyOddiel(localStorage_vystupnyOddiel === "true");
    }
    const localStorage_pohlavie = localStorage.getItem("pohlavie");
    if (localStorage_pohlavie) {
      setPohlavie(Number.parseInt(localStorage_pohlavie));
    }
    const localStorage_aktivnyTrest = localStorage.getItem("aktivnyTrest");
    if (localStorage_aktivnyTrest) {
      setAktivnyTrest(localStorage_aktivnyTrest === "true");
    }
    const localStorage_aktivnaVazba = localStorage.getItem("aktivnaVazba");
    if (localStorage_aktivnaVazba) {
      setAktivnaVazba(localStorage_aktivnaVazba === "true");
    }
    const localStorage_narodnostId = localStorage.getItem("narodnostId");
    if (localStorage_narodnostId) {
      setNarodnostId(
        localStorage_narodnostId === "null"
          ? null
          : Number.parseInt(localStorage_narodnostId)
      );
    }
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        ustavKod,
        ustavKodSet,
        ustavSkratka,
        ustavSkratkaSet,
        meno,
        menoSet,
        priezvisko,
        priezviskoSet,
        feooKlientId,
        feooKlientIdSet,
        fyzickaOsobaId,
        fyzickaOsobaIdSet,
        zakladneCislo,
        zakladneCisloSet,
        oddielId,
        oddielIdSet,
        vystupnyOddiel,
        vystupnyOddielSet,
        pohlavie,
        pohlavieSet,
        aktivnyTrest,
        aktivnyTrestSet,
        aktivnaVazba,
        aktivnaVazbaSet,
        narodnostId,
        narodnostIdSet,
        clearUserData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

// Custom hook for consuming the context
export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserData must be used within a UserDataProvider");
  }
  return context;
};
