import * as React from "react";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ZvjsHintModalFragment } from "./index";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  Select,
  Stack,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useUITranslation } from "../../store/context/translation-context";

export interface ZvjsNestedDropdownViewOptions {
  answerId: string;
  children: ZvjsNestedDropdownViewOption[];
}

export interface ZvjsNestedDropdownViewOption {
  key: string;
  label: string;
  checked: boolean;
  options?: ZvjsNestedDropdownViewOptions;
}

export interface ZvjsNestedDropDownProp {
  id: string;
  title: string;
  answerRequired?: boolean;
  options: ZvjsNestedDropdownViewOptions;
  onChange: (
    checked: boolean,
    checkedValues: { [key: string]: string }
  ) => void;
  renderValueText: string;
  hintTitle?: string;
  hintText?: string;
  hintColor?: string;
  hintSize?: number;
  selectedOptionsCount: number;
  maxNumberSelect?: number;
  error?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${ZVJS_COLORS.BLACK}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  // borderRadius: "5px !important",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowUp sx={{ transform: "rotate(90deg)" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: ZVJS_COLORS.WHITE,
  // theme.palette.mode === "dark"
  //   ? "rgba(255, 255, 255, .05)"
  //   : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: 0,
  // maxHeight: "42px !important",
  marginLeft: "-3px",
  marginBottom: -6,
  marginTop: -5,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "0.6rem",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: "2rem",
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ZvjsNestedDropDown: React.FC<ZvjsNestedDropDownProp> = ({
  id,
  title,
  answerRequired,
  options,
  hintTitle,
  hintText,
  hintColor,
  hintSize,
  onChange,
  renderValueText,
  maxNumberSelect,
  selectedOptionsCount,
  error,
  ...rest
}) => {
  const { tui } = useUITranslation();
  const selectRef = useRef<HTMLDivElement | null>(null);

  if (maxNumberSelect !== undefined && maxNumberSelect <= 0) {
    throw new Error("maxNumberSelect must be greater than 0 or undefined");
  }

  const handleChangesCheckBox = (
    checked: boolean,
    parentTree: { [key: string]: string }
  ) => {
    onChange(checked, parentTree);
  };

  const renderRecursion = (
    dataP: ZvjsNestedDropdownViewOptions,
    parentTree: { [key: string]: string }
  ) => {
    // console.log(parentTree);
    return dataP.children.map((mapData) => {
      if (mapData.options) {
        return (
          <Accordion key={mapData.key}>
            <AccordionSummary aria-controls={mapData.key} id={mapData.key}>
              <Typography>{mapData.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction={"column"}>
                {renderRecursion(mapData.options, {
                  [dataP.answerId]: mapData.key,
                  ...parentTree,
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        return (
          <FormControlLabel
            key={mapData.key}
            label={mapData.label}
            // style={{ width: "100%" }}
            control={
              maxNumberSelect !== undefined && maxNumberSelect === 1 ? (
                <Radio
                  checked={mapData.checked}
                  onClick={() => {
                    handleChangesCheckBox(!mapData.checked, {
                      [dataP.answerId]: mapData.key,
                      ...parentTree,
                    });
                  }}
                  color={"success"}
                />
              ) : (
                <Checkbox
                  checked={mapData.checked}
                  disabled={
                    !mapData.checked && maxNumberSelect
                      ? selectedOptionsCount >= maxNumberSelect
                      : false
                  }
                  onClick={() => {
                    handleChangesCheckBox(!mapData.checked, {
                      [dataP.answerId]: mapData.key,
                      ...parentTree,
                    });
                  }}
                  color={"success"}
                />
              )
            }
          />
        );
      }
    });
  };

  return (
    <>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography>
          {title}
          {answerRequired && "*"}
        </Typography>
        {hintText && (
          <ZvjsHintModalFragment
            title={hintTitle === undefined ? tui("nápoveda") : hintTitle} //todo change translation
            text={hintText}
            color={hintColor}
            size={hintSize}
          />
        )}
      </Stack>
      <Select
        multiple
        // this must be an empty array
        value={[""]}
        ref={selectRef}
        renderValue={() => renderValueText}
        onClose={() => {
          // Used to overcome issue between mui Select and react-simple-pull-to-refresh lib
          // mouseup event is normally called after you release the left mouse button but this is not happening when you click on mui select - therefore we trigger mouseup event manually via which we simulate releasing of the mouse left button
          // https://github.com/thmsgbrt/react-simple-pull-to-refresh/issues/88
          if (selectRef.current) {
            const event = new MouseEvent("mouseup", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            selectRef.current.dispatchEvent(event);
          }
        }}
        IconComponent={({ className }) => {
          return (
            <KeyboardArrowDown
              className={className}
              style={{ backgroundColor: "white" }}
            />
          );
        }}
        error={error}
        sx={{
          ...{
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                border: `2px solid ${ZVJS_COLORS.BLACK}`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${ZVJS_COLORS.BLACK}`,
              },
            },
            height: 45,
            width: "100%",
            maxWidth: "100%",
          },
        }}
      >
        <Stack style={{ margin: "0.7rem" }}>
          {renderRecursion(options, {})}
        </Stack>
      </Select>
    </>
  );
};

export default ZvjsNestedDropDown;
