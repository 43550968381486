import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050604",
  basicInfo: [
    {
      text: "ziadost.ziadostOzaradenieDoKruzku.zakladneInfo",
    },
  ],
  items: [
    {
      id: "kruzokId",
      type: ZvjsCustomQuestionTypes.SC_KIO_050604_GROUP_QUESTION,
      isRequired: true,
      title: "ziadost.ziadostOzaradenieDoKruzku.labelKruzokKtoryChcem",
    },
  ],
};
