import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as PinCursorIcon } from "../../assets/icons/Other/pin_cursor.svg";
import { ReactComponent as PinEmptyIcon } from "../../assets/icons/Other/pin_empty.svg";
import { ReactComponent as PinFullIcon } from "../../assets/icons/Other/pin_full.svg";
import { useUITranslation } from "../../store/context/translation-context";

interface ZvjsNumpadProps {
  pinLength: number;
  onCheck: (pin: string) => void;
}

const ZvjsNumpad: React.FC<ZvjsNumpadProps> = ({ pinLength, onCheck }) => {
  const { tui } = useUITranslation();

  const [value, setValue] = useState<string>("");

  const updateValue = (val: string) => {
    switch (val) {
      case "CLEAR":
        setValue("");
        break;
      default:
        setValue(value + val);
    }
  };

  useEffect(() => {
    if (value.length === pinLength) {
      onCheck(value);
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    updateValue(event.currentTarget.name);
  };

  const keyPressed = (event: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = (event as any).key;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keyCode = (event as any).keyCode;

    // allow only numbers, enter and delete keys
    if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(key))) {
      updateValue(String(key));
    } else if (keyCode === 8) {
      updateValue("CLEAR");
    } else {
      event.preventDefault();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("keydown", keyPressed, false);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("keydown", keyPressed);
    };
  });

  return (
    <Stack
      spacing={5}
      alignItems="center"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography variant="h3">{tui("prihlasenie.zadajPIN")}</Typography>
      <Stack direction={"row"} spacing={3}>
        {[...Array(pinLength).keys()].map((item: number, index) => {
          if (item < value.length) {
            return <PinFullIcon key={index} />;
          }
          if (item === value.length) {
            return <PinCursorIcon key={index} />;
          }

          return <PinEmptyIcon key={index} />;
        })}
      </Stack>
      <Box width={240}>
        <Grid container spacing={2} alignItems="center" justifyItems="center">
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "CLEAR", "0"].map(
            (item, index) => (
              <Grid key={index} item xs={4}>
                <Button name={item} variant="outlined" onClick={handleClick}>
                  {item === "CLEAR" ? <ClearIcon /> : <>{item}</>}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default ZvjsNumpad;
