import { createTheme } from "@mui/material";

export const ZVJS_COLORS = {
  BLUE: "#0065B3",
  BLUE_2: "#2B8CC4",
  GREEN: "#00703C",
  GREEN_2: "#85994B",
  GREY: "#E1E1E1",
  GREY_2: "#BFC1C3",
  GREY_3: "#C4C4C4",
  GREY_4: "#DEE0E2",
  GREY_5: "#FAFAFA",
  GREY_6: "#949494",
  // disabled color
  GREY_7: "#00000042",
  WHITE: "#FFFFFF",
  WHITE_2: "#FAFAFA",
  BLACK: "#0B0C0C",
  BLACK_2: "#272727",
  RED: "#9c0000",
  ORANGE: "#FFA500",
  PURPLE: "#800080",
  YELLOW: "#e1d988",
};

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    info: true;
    active: true;
  }
}

const zvjs_theme = createTheme({
  palette: {
    primary: {
      main: ZVJS_COLORS.BLUE,
    },
    secondary: {
      main: ZVJS_COLORS.GREEN,
    },
    background: {
      default: ZVJS_COLORS.GREY,
    },
    error: {
      main: ZVJS_COLORS.RED,
    },
  },
  typography: {
    fontFamily: "source-sans-pro",
    allVariants: {
      // rule to enable formatting of a text received from api with the appropriate format (break-lines, tabs...)
      // https://developer.mozilla.org/en-US/docs/Web/CSS/white-space#collapsing_of_white_space
      whiteSpace: "pre-line",
    },
    button: {
      fontSize: 24,
      fontWeight: 400,
    },
    h1: {
      fontWeight: 700,
      fontSize: 42,
    },
    h2: {
      fontWeight: 700,
      fontSize: 40,
    },
    h3: {
      fontWeight: 700,
      fontSize: 36,
    },
    h4: {
      fontWeight: 700,
      fontSize: 25,
    },
    h5: {
      fontWeight: 700,
      fontSize: 19,
    },
    h6: {
      fontWeight: 700,
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 28,
    },
    body1: {
      fontWeight: 400,
      fontSize: 19,
      // disable body1 whiteSpace which is being applied to a body element also and an outcome is broken app ui
      whiteSpace: "unset",
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        style: {
          borderRadius: "10px",
          overflow: "hidden",
        },
      },
      variants: [
        {
          props: { variant: "info" },
          style: {
            background: ZVJS_COLORS.WHITE_2,
          },
        },
        {
          props: { variant: "active" },
          style: {
            background: ZVJS_COLORS.BLUE,
            color: ZVJS_COLORS.WHITE,
          },
        },
      ],
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        style: {
          color: ZVJS_COLORS.BLACK,
          backgroundColor: ZVJS_COLORS.WHITE_2,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: "3px",
            height: "72px",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: "20px",
            border: `2px solid ${ZVJS_COLORS.BLUE}`,
            color: ZVJS_COLORS.BLACK,
            backgroundColor: ZVJS_COLORS.WHITE,
            fontSize: "19px",
            fontWeight: "400",
          },
        },
        {
          props: { variant: "text" },
          style: {
            borderRadius: "3px",
            backgroundColor: ZVJS_COLORS.GREY_6,
            color: ZVJS_COLORS.WHITE,
            "&:hover": {
              backgroundColor: "#636262",
              color: ZVJS_COLORS.WHITE,
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 19,
          fontWeight: 400,
        },
      },
    },
    MuiSelect: {
      // rest of the styles is defined inside custom select component
      defaultProps: {
        style: {
          borderRadius: "5px",
          backgroundColor: ZVJS_COLORS.WHITE,
          textAlign: "start",
        },
        MenuProps: {
          style: {
            marginTop: "4px",
          },
          MenuListProps: {
            style: {
              border: `2px solid ${ZVJS_COLORS.BLACK}`,
              borderRadius: "5px",
              padding: 0,
            },
          },
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        color: ZVJS_COLORS.GREY_2,
      },
    },
  },
});

export default zvjs_theme;
