import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(
    `OdoslanaKorespondenciaTab loadData datumOd[${datumOd}] datumDo[${datumDo}]`
  );

  const { EPVVVT_Get } = await API_Clients();

  const zoznamOdoslanejKorespondencie = await EPVVVT_Get(
    "/api/evidencne-karty/{klientId}/odoslana-korespondencia",
    {
      params: {
        path: {
          klientId: localStorage.getItem("klientObjectId") ?? "faultyId",
        },
        // query:{
        //    // TODO: datumPrijatia ???
        // }
      },
    }
  );

  if (zoznamOdoslanejKorespondencie.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [odoslaná korešpondencia]"
    );
  }

  if (datumOd) {
    zoznamOdoslanejKorespondencie.data =
      zoznamOdoslanejKorespondencie.data?.filter(
        (item) =>
          item.datumOdoslania &&
          datumOd &&
          new Date(item.datumOdoslania) >= new Date(datumOd)
      );
  }

  if (datumDo) {
    zoznamOdoslanejKorespondencie.data =
      zoznamOdoslanejKorespondencie.data?.filter(
        (item) =>
          item.datumOdoslania &&
          datumDo &&
          new Date(item.datumOdoslania) <= new Date(datumDo)
      );
  }

  return {
    zoznamOdoslanejKorespondencie: zoznamOdoslanejKorespondencie,
  };
};

export const OdoslanaKorespondenciaTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface OdoslanaKorespondenciaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OdoslanaKorespondenciaTab = (props: OdoslanaKorespondenciaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(
      `OdoslanaKorespondencia onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={tui("vyhľadať korešpondenciu")}
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={onSearch}
      />
      <ZvjsTable
        data={{
          header: [
            {
              value: capitalize(tui("dátum odoslania")),
              align: "left",
              width: "26%",
            },
            {
              value: capitalize(tui("typ korešpondencie")),
              align: "left",
              width: "37%",
            },
            {
              value: capitalize(tui("typ adresáta")),
              align: "left",
              width: "37%",
            },
          ],
          body:
            props.data.zoznamOdoslanejKorespondencie.data
              ?.sort(
                //TODO: remove optional "?"
                (a, b) =>
                  new Date(b.datumOdoslania ?? new Date()).valueOf() -
                  new Date(a.datumOdoslania ?? new Date()).valueOf()
              )
              .map((odoslanaKorespondencia) => {
                const rd: RowData = {
                  row: [
                    {
                      value: new Date(
                        odoslanaKorespondencia.datumOdoslania ?? new Date()
                      ).zvjsToString(),
                      align: "left",
                      width: "26%",
                    },
                    {
                      value:
                        odoslanaKorespondencia.typKorespondencieId?.text ?? "",
                      align: "left",
                      width: "37%",
                    },
                    {
                      value: odoslanaKorespondencia.typAdresataId?.text ?? "",
                      align: "left",
                      width: "37%",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(OdoslanaKorespondenciaTab);
