import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/Data";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";
import moment from "moment";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const datumOd = searchParams.get("datumOd")
    ? decodeURIComponent(searchParams.get("datumOd") || "")
    : undefined;
  const datumDo = searchParams.get("datumDo")
    ? decodeURIComponent(searchParams.get("datumDo") || "")
    : undefined;

  console.debug(`Baliky loadData datumOd[${datumOd}] datumDo[${datumDo}]`);

  const { CIS_Post, EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisDruhBalika/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    EPVVVT_Post("/api/Baliky/dorucene-baliky-list", {
      body: {
        filters: [
          {
            klientObjectId:
              localStorage.getItem("klientObjectId") ?? "faultyId",
            datumDoruceniaOd: !!datumOd ? datumOd : undefined,
            datumDoruceniaDo: !!datumOd ? datumDo : undefined,
          },
        ],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [druh balika]");
  }

  if (data[1].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [zoznam prijatych balikov]"
    );
  }

  return {
    cisDruhBalika: data[0],
    baliky: data[1],
  };
};

export const BalikyLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

interface BalikyProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Baliky = (props: BalikyProps) => {
  const { tui, getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onSearch = (dateFrom: Date, dateTo: Date) => {
    console.debug(`Baliky onSearch dateFrom[${dateFrom}] dateTo[${dateTo}]`);
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(dateFrom.isValid() && {
            datumOd: encodeURIComponent(moment(dateFrom).toISOString()),
          }),
          ...(dateTo.isValid() && {
            datumDo: encodeURIComponent(moment(dateTo).toISOString()),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("osobneUdaje.kategorieOsobnychUdajov.baliky"))}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={tui("vyhľadať v balíkoch")}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={onSearch}
        />

        <ZvjsTable
          data={{
            header: [
              {
                value: capitalize(tui("dátum prijatia")),
                align: "left",
                width: "calc(100% / 3)",
              },
              {
                value: capitalize(tui("typ balíka")),
                align: "left",
                width: "calc(100% / 3)",
              },
              {
                value: capitalize(tui("odosielateľ")),
                align: "left",
                width: "calc(100% / 3)",
              },
            ],
            body:
              props.data.baliky.data
                ?.sort(
                  (p1, p2) =>
                    new Date(p2.datumDorucenia ?? new Date()).valueOf() -
                    new Date(p1.datumDorucenia ?? new Date()).valueOf()
                )
                // .map((balik) => {
                //   if (loaderData.cisDruhBalika) {
                //     const druhBalika =
                //       loaderData.cisDruhBalika.data?.records?.find(
                //         (i) =>
                //           i.kod === balik.typ &&
                //           i.jazyk === getCiselnikJazyk(i18n.language)
                //       )?.druh_balika ??
                //       loaderData.cisDruhBalika.data?.records?.find(
                //         (i) => i.kod === balik.typ && i.jazyk === getFallbackJazyk()
                //       )?.druh_balika ??
                //       "<unknown>";
                //     return { ...balik, typ: druhBalika };
                //   }
                //   return balik;
                // })
                .map((balik) => {
                  const rd: RowData = {
                    row: [
                      {
                        value: new Date(
                          balik.datumDorucenia ?? new Date()
                        ).zvjsToString(),
                        align: "left",
                        width: "calc(100% / 3)",
                      },
                      {
                        //value: balik.druhBalika?.text ?? "",
                        value:
                          props.data.cisDruhBalika.data?.records?.find(
                            (cdb) =>
                              cdb.kod ===
                              (balik as any)?.balikDruhId?.id?.toString() // TODO: SWAGGER ?
                          )?.druh_balika ?? "",
                        align: "left",
                        width: "calc(100% / 3)",
                      },
                      {
                        value: (balik as any)?.odosielatel ?? "", // TODO: SWAGGER ?
                        align: "left",
                        width: "calc(100% / 3)",
                      },
                    ],
                  };
                  return rd;
                }) ?? [],
          }}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="25rem">
          <ZvjsButton
            text={capitalize(
              tui("žiadosť o vydanie povolenia na prijatie balíka")
            )}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/01.01.01`);
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Baliky);
