import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051001",
  basicInfo: [
    {
      text: "ziadost.ziadostOcastejsieSprchovanie.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051001_isAvailableToUser",
  dataCalculations: {
    SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_JOB: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_HEALTH: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_OTHER: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: ["/api/CisDovodCastejsiehoSprchovania/List"],
  items: [
    {
      id: "dovodZiadosti",
      title: "ziadost.ziadostOcastejsieSprchovanie.labelDovodZiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodCastejsiehoSprchovania/List",
        textKey: "nazov",
        optValues: [
          {
            id: "ZARAD",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_JOB",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "ZDRAVD",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_HEALTH",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "INED",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_051001_CAN_REQUEST_SHOWER_REASON_OTHER",
                conditionMetWhenValueIs: true,
              },
            ],
          },
        ],
      },
    },
    {
      id: "vysvetlenieIneDovody",
      title: "ziadost.ziadostOcastejsieSprchovanie.labelVysvetlenieDovoduIne",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodZiadosti",
          value: ["INED"],
        },
      ],
    },
  ],
};
