import { createSelector } from "@reduxjs/toolkit";
import {
  LabelType,
  NestedDropdownAnswerValueType,
  RequestUserPersonalData,
  State,
  ZvjsAnswerValueType,
  ZvjsCustomQuestionTypes,
  ZvjsDropdownQuestion,
  ZvjsDropdownQuestion_New,
  ZvjsNestedDropdown,
  ZvjsNestedDropdownOptions,
  ZvjsQuestionTypes,
  ZvjsRadioQuestion,
} from "../model";
import {
  questionsIteratorWithLocAndParentTreeNestedDropdown,
  questionsIteratorWithLocFromDisplayedSections,
} from "../slice";
import {
  getTranslationByLanguageCode,
  getUuid,
  isEmptyString,
} from "../../../../utils/helpers";
import {
  getAvailableAnswersForQuestion,
  getRequestCodeFromCounter,
  getRequestTitle,
  isItemDisplayed,
  isNestedOptionChecked,
  selectSelf,
} from "../selectors";
import { SC_KIO_050802_TEXTS_KEYS } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050802_TravelExpensesRadio/SC_KIO_050802_TravelExpensesRadio";
import { PlaceOfRelocationQuestionConstants } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_051403_PlaceOfRelocationQuestion/redux";
import { PlaceOfLocationQuestionConstants } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_051404_PlaceOfLocationQuestion/redux";
import {
  ProgramsGridConstants,
  ProgramType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050602_ProgramsGrid/redux";
import {
  ActivitiesGridConstants,
  ActivityType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050601_ActivitiesGrid/redux";
import {
  BanknotesGridConstants,
  BanknoteType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050201_BanknotesGrid/redux";
import {
  GroupQuestionConstants,
  GroupType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050604_GroupQuestion/redux";
import {
  getChildWithId,
  RegisteredChildGrid,
  RegisteredChildrenGridConstants,
  SC_KIO_050206_RegisteredChildrenGrid_answerType,
  ShippingOptionsCounter,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050206_RegisteredChildrenGrid/redux";
import { SC_KIO_051003_getRequestedItemsTotalEstimatedPrice } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_051003_PersonalHygieneNeedsGrid/redux";
import { useUITranslation } from "../../../../store/context/translation-context";
import { SC_KIO_050501_DevicesRevisionControlGrid_answerType } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050501_DevicesRevisionControlGrid/redux";
import { CiselnikListResultType } from "../../../../store/context/dataApi/Data";
import { getCiselnikJazyk } from "../../../../locales/i18n";
import i18n from "i18next";
import getCustomSubmitAnswerData from "../../requestPages/questionnaire/items/questions/customQuestions/customSubmitAnswerData";
import {
  PersonalHygieneItem,
  SC_KIO_050101_ClosePerson,
  SC_KIO_050501_ElectricDevice,
} from "../../requestPages/questionnaire/utils/calculations/custom";
import { capitalize } from "@mui/material/utils";
import { isTablet } from "../../../../store/context/envConfigContext";
import {
  CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod,
  CisZdrojZiadosti_Kiosk_Kod,
  CisZdrojZiadosti_Tablet_Kod,
} from "../../../specs/countersValues";
import { SummarySubmitData } from "../../requestPages/summary/Summary";
import { onCustomRequestSubmitData } from "../../requestTemplates/customRequestSubmitData";
import {
  ValuableItemsGridConstants,
  ValuableItemType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050202_ValuableItemsGrid/redux";
import { SC_KIO_050202_ClosePeopleGridConstants } from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050202_ClosePeopleGrid/redux";
import { BlizkaOsoba } from "../../../specs/swaggerTypes";
import { SC_KIO_050101_CLOSE_PEOPLE_LIST } from "../../requestTemplates/SC_KIO_050101_Ziadost o navstevu";
import {
  CriminalProceedingsGridConstants,
  CriminalProceedingType,
  SC_KIO_050301_CriminalProceedingsGrid_answerType,
} from "../../requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050301_CriminalProceedingsGrid/redux";

export enum ZvjsSummaryItemType {
  ROW,
  SECTION,
}

interface ZvjsSummaryRowItem {
  label: string;
  type: ZvjsSummaryItemType.ROW;
  answerText: string | number;
  id: string;
}

interface ZvjsSummarySectionItem {
  id?: string;
  type: ZvjsSummaryItemType.SECTION;
  label: string;
  items: {
    label: string;
    answerText?: string | number;
  }[];
}

interface ZvjsSummaryData {
  userPersonalData: RequestUserPersonalData;
  request: {
    title: string | null;
    items: (ZvjsSummaryRowItem | ZvjsSummarySectionItem)[];
  };
}

const getSummaryData = () =>
  createSelector(selectSelf, (state: State): ZvjsSummaryData => {
    const { tuiz, getFallbackJazyk } = useUITranslation();

    const items: (ZvjsSummaryRowItem | ZvjsSummarySectionItem)[] = [];
    const iterator = questionsIteratorWithLocFromDisplayedSections(
      state.questionnaire,
      state
    );

    for (const question of iterator) {
      if (isItemDisplayed(question.location, state)) {
        switch (question.value.type) {
          case ZvjsQuestionTypes.DROPDOWN: {
            const answerKey = state.answers[question.value.id];
            let answerText = "";
            for (const option of (question.value as ZvjsDropdownQuestion)
              .options) {
              if (option.id === answerKey) {
                answerText = tuiz(option.label);
                break;
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerText,
              id: question.value.id,
            });
            break;
          }
          case ZvjsQuestionTypes.DROPDOWN_NEW:
            const answerKey = state.answers[question.value.id];
            const questionVal = question.value as ZvjsDropdownQuestion_New;
            const counter = state.counters[questionVal.options.counterKey];

            let answerText = "";
            for (const option of questionVal.options.optValues) {
              if (option.id === answerKey) {
                answerText = capitalize(
                  getTranslationByLanguageCode(
                    counter?.records ?? [],
                    getCiselnikJazyk(i18n.language),
                    getFallbackJazyk(),
                    option.id,
                    questionVal.options.textKey
                  )
                );
                break;
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerText,
              id: question.value.id,
            });
            break;
          case ZvjsQuestionTypes.TEXT:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: state.answers[question.value.id] as string | number,
              id: question.value.id,
            });
            break;
          case ZvjsQuestionTypes.SINGLE_CHECKBOX:
            if (state.answers[question.value.id] === true) {
              items.push({
                label: tuiz(question.value.title),
                type: ZvjsSummaryItemType.ROW,
                // TODO replace with correct translation
                answerText: "Áno",
                id: question.value.id,
              });
            }
            break;
          case ZvjsQuestionTypes.NESTED_DROPDOWN:
            const myQuestion = question.value as ZvjsNestedDropdown;
            const answers = getAvailableAnswersForQuestion(
              state.answers[question.value.id],
              myQuestion,
              state
            ) as NestedDropdownAnswerValueType[] | undefined;
            const answerTextArr: string[] = [];
            const nestedIterator =
              questionsIteratorWithLocAndParentTreeNestedDropdown(
                myQuestion.options
              );

            for (const nestedQuestion of nestedIterator) {
              if (isNestedOptionChecked(answers, nestedQuestion.parentTree)) {
                const counter = state.counters[nestedQuestion.counterKey];
                answerTextArr.push(
                  capitalize(
                    getTranslationByLanguageCode(
                      counter?.records ?? [],
                      getCiselnikJazyk(i18n.language),
                      getFallbackJazyk(),
                      nestedQuestion.id,
                      nestedQuestion.textKey
                    )
                  )
                );
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          case ZvjsQuestionTypes.RADIO:
            const radioAnswerKey = state.answers[question.value.id];
            let radioAnswerText = "";
            for (const option of (question.value as ZvjsRadioQuestion)
              .options) {
              if (option.id === radioAnswerKey) {
                radioAnswerText = tuiz(option.label);
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: radioAnswerText,
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.VISIT_DATE_PICKER:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: state.answers[question.value.id] as string,
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.SC_KIO_050101_PHYSICAL_VISIT_PEOPLE_GRID: {
            const answerTextArr: string[] = [];
            for (const closePerson of state.userCalcData[
              SC_KIO_050101_CLOSE_PEOPLE_LIST
            ] as SC_KIO_050101_ClosePerson[]) {
              for (const answerVal of state.answers[
                question.value.id
              ] as string[]) {
                if (answerVal === closePerson.fyzickaOsobaId.toString()) {
                  answerTextArr.push(
                    `${closePerson.firstname} ${closePerson.surname}`
                  );
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050101_VIDEO_CALL_VISIT_PEOPLE_GRID: {
            const answerTextArr: string[] = [];
            for (const closePerson of state.userCalcData[
              SC_KIO_050101_CLOSE_PEOPLE_LIST
            ] as SC_KIO_050101_ClosePerson[]) {
              for (const answerVal of state.answers[
                question.value.id
              ] as string[]) {
                if (answerVal === closePerson.fyzickaOsobaId.toString()) {
                  answerTextArr.push(
                    `${closePerson.firstname} ${closePerson.surname}`
                  );
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050802_TRAVEL_EXPENSES_RADIO:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: tuiz(
                SC_KIO_050802_TEXTS_KEYS[
                  state.answers[question.value.id] as string
                ]
              ),
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: (
                state.answers[
                  question.value.id
                ] as NestedDropdownAnswerValueType[]
              )
                .map(
                  (answerKey) =>
                    getTranslationByLanguageCode(
                      state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
                      getCiselnikJazyk(i18n.language),
                      getFallbackJazyk(),
                      answerKey[
                        PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID
                      ],
                      "nazov"
                    ) ?? ""
                )
                .join(", "),
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.SC_KIO_051403_PRIORITY_QUESTION:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: isEmptyString(state.answers[question.value.id])
                ? ""
                : getTranslationByLanguageCode(
                    state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
                    getCiselnikJazyk(i18n.language),
                    getFallbackJazyk(),
                    state.answers[question.value.id].toString(),
                    "nazov"
                  ) ?? "",
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION:
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: (
                state.answers[
                  question.value.id
                ] as NestedDropdownAnswerValueType[]
              )
                .map(
                  (answerKey) =>
                    getTranslationByLanguageCode(
                      state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
                      getCiselnikJazyk(i18n.language),
                      getFallbackJazyk(),
                      answerKey[
                        PlaceOfLocationQuestionConstants.QUESTION_ANSWER_ID
                      ],
                      "nazov"
                    ) ?? ""
                )
                .join(", "),
              id: question.value.id,
            });
            break;
          case ZvjsCustomQuestionTypes.SC_KIO_050602_PROGRAMS_GRID: {
            const answerTextArr: string[] = [];
            for (const program of state.userCalcData[
              ProgramsGridConstants.AVAILABLE_PROGRAMS
            ] as ProgramType[]) {
              for (const answer of state.answers[
                question.value.id
              ] as string[]) {
                if (answer === program.id) {
                  answerTextArr.push(program.name);
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050601_ACTIVITIES_GRID: {
            const answerTextArr: string[] = [];
            for (const activity of state.userCalcData[
              ActivitiesGridConstants.AVAILABLE_ACTIVITIES
            ] as ActivityType[]) {
              for (const answer of state.answers[
                question.value.id
              ] as string[]) {
                if (Number(answer) === activity.id) {
                  answerTextArr.push(activity.name);
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050201_BANKNOTES_GRID: {
            const answerTextArr: string[] = [];
            for (const banknote of state.userCalcData[
              BanknotesGridConstants.BANKNOTES
            ] as BanknoteType[]) {
              for (const answer of state.answers[
                question.value.id
              ] as string[]) {
                if (answer === banknote.id.toString()) {
                  answerTextArr.push(`${banknote.value} ${banknote.currency}`);
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050202_CLOSE_PEOPLE_GRID: {
            const answerTextArr: string[] = [];
            const answerVal = state.answers[question.value.id] as number;
            for (const closePerson of state.userCalcData[
              SC_KIO_050202_ClosePeopleGridConstants.CLOSE_PEOPLE_LIST
            ] as BlizkaOsoba[]) {
              if (answerVal === closePerson.id) {
                answerTextArr.push(
                  `${closePerson.fyzickaOsoba?.meno} ${closePerson.fyzickaOsoba?.priezvisko}`
                );
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050202_VALUABLE_ITEMS_GRID: {
            const answerTextArr: string[] = [];
            for (const item of state.userCalcData[
              ValuableItemsGridConstants.VALUABLE_ITEMS
            ] as ValuableItemType[]) {
              for (const answer of state.answers[
                question.value.id
              ] as string[]) {
                if (answer === item.id.toString()) {
                  answerTextArr.push(`${item.description}`);
                }
              }
            }
            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText: answerTextArr.join(", "),
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050301_CRIMINAL_PROCEEDINGS_GRID: {
            const answer = state.answers[
              question.value.id
            ] as SC_KIO_050301_CriminalProceedingsGrid_answerType;

            const criminalProceedings = state.userCalcData[
              CriminalProceedingsGridConstants.CRIMINAL_PROCEEDINGS
            ] as CriminalProceedingType[];

            items.push({
              label: tuiz(question.value.title),
              type: ZvjsSummaryItemType.ROW,
              answerText:
                answer.criminalProceeding ===
                CriminalProceedingsGridConstants.OTHER_CRIMINAL_PROCEEDING_INTERNAL_CODE
                  ? answer.otherCriminalProceedingDescription ?? ""
                  : criminalProceedings.find(
                      (value) => value.id === answer.criminalProceeding
                    )?.description ?? "",
              id: question.value.id,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050604_GROUP_QUESTION: {
            const answerKey = state.answers[question.value.id];
            let answerText = "";
            for (const group of state.userCalcData[
              GroupQuestionConstants.AVAILABLE_GROUPS
            ] as GroupType[]) {
              if (group.id === answerKey) {
                answerText = group.name;
                break;
              }
            }
            items.push({
              label: tuiz(question.value.title),
              answerText: answerText,
              id: question.value.id,
              type: ZvjsSummaryItemType.ROW,
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_050206_REGISTERED_CHILDREN_GRID:
            const children = state.userCalcData[
              RegisteredChildrenGridConstants.REGISTERED_CHILDREN
            ] as RegisteredChildGrid[];
            const answer = state.answers[
              question.value.id
            ] as SC_KIO_050206_RegisteredChildrenGrid_answerType;

            if (answer.childId !== undefined) {
              const selectedChild = getChildWithId(children, answer.childId);

              let selectedShippingOptionText = "";

              for (const option of state.userCalcData[
                RegisteredChildrenGridConstants.SHIPPING_OPTIONS_COUNTER
              ] as ShippingOptionsCounter[]) {
                if (option.value === answer.shippingOption) {
                  selectedShippingOptionText = option.text;
                  break;
                }
              }

              items.push({
                // TODO replace with correct translations
                label:
                  "Detailné údaje o dieťati, pre ktoré bolo zaevidované výživné",
                type: ZvjsSummaryItemType.SECTION,
                id: question.value.id,
                items: [
                  { label: "Meno", answerText: selectedChild?.name },
                  { label: "Priezvisko", answerText: selectedChild?.surname },
                  {
                    label: "Dátum narodenia",
                    answerText: selectedChild?.dateOfBirth,
                  },
                  {
                    label: "Evidovaná",
                    answerText: selectedChild?.registeredBy,
                  },
                  {
                    label: "Číslo účtu v SR v tvare IBAN",
                    answerText: selectedChild?.ibanInSlovakia,
                  },
                  {
                    label: "Akým spôsobom chcem odoslať",
                    answerText: selectedShippingOptionText,
                  },
                ],
              });
            } else {
              console.warn("SC_KIO_050206 child was not selected");
            }

            break;
          case ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID: {
            const answer = state.answers[
              question.value.id
            ] as SC_KIO_050501_DevicesRevisionControlGrid_answerType;

            const getSectionItems = () => {
              const electricDevicesCounter = state.counters[
                "/api/CisDruhElektrospotrebica/List"
              ] as CiselnikListResultType<"/api/CisDruhElektrospotrebica/List">;

              const getDeviceWithId = (
                deviceId: number,
                deviceTypeId: string
              ) => {
                const allDevices = state.userCalcData[
                  "SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST"
                ] as SC_KIO_050501_ElectricDevice[];

                for (const device of allDevices) {
                  if (
                    device.id === deviceId &&
                    device.typeCode === deviceTypeId
                  ) {
                    return device;
                  }
                }

                throw new Error(
                  `Summary: No device with id ${deviceId} found in loaded device list`
                );
              };

              const device = getDeviceWithId(
                answer.deviceId,
                answer.deviceTypeCode
              );
              return [
                {
                  label: tuiz(
                    "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica"
                  ),
                  answerText:
                    device.typeCode !==
                    CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod
                      ? getTranslationByLanguageCode(
                          electricDevicesCounter.data?.records ?? [],
                          getCiselnikJazyk(i18n.language),
                          getFallbackJazyk(),
                          device?.typeCode,
                          "druh"
                        )
                      : device.nameOfOtherDevice ?? "",
                },
                {
                  label: tuiz(
                    "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelTerminPlatnostiKontroly"
                  ),
                  answerText: device?.controlValidUntil
                    ? new Date(device.controlValidUntil).zvjsToString()
                    : "",
                },
              ];
            };

            items.push({
              // TODO replace with correct translations
              label:
                "Potrebujem vykonať opakovanú (revíznu) kontrolu už používaného spotrebiča",
              type: ZvjsSummaryItemType.SECTION,
              id: question.value.id,
              items: getSectionItems(),
            });
            break;
          }
          case ZvjsCustomQuestionTypes.SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID: {
            const answers = state.answers[question.value.id] as string[];

            const getItemWithId = (id: string) => {
              const allItems = state.userCalcData[
                "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
              ] as PersonalHygieneItem[];

              for (const item of allItems) {
                if (item.typeCode === id) {
                  return item;
                }
              }
              return undefined;
            };

            items.push({
              label: "Požadované potreby osobnej hygieny",
              type: ZvjsSummaryItemType.SECTION,
              id: question.value.id,
              items: answers.flatMap((answer, index) => {
                const item = getItemWithId(answer);

                const toReturn = [
                  {
                    label: tuiz(
                      "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelNazovPolozky"
                    ),
                    answerText: item?.typeName,
                  },
                  {
                    label: tuiz(
                      "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaPolozky"
                    ),
                    answerText: `${item?.estimatedPrice} ${LabelType.EURO}`,
                  },
                ];

                if (answers.length !== index + 1) {
                  // add divider
                  toReturn.push({
                    label: "",
                    answerText: "",
                  });
                }

                return toReturn;
              }),
            });

            items.push({
              label: tuiz(
                "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaSpolu"
              ),
              type: ZvjsSummaryItemType.SECTION,
              items: [
                {
                  label: tuiz(
                    "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelSuma"
                  ),
                  answerText: `${SC_KIO_051003_getRequestedItemsTotalEstimatedPrice(
                    state.userCalcData[
                      "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
                    ],
                    answers
                  )} ${LabelType.EURO}`,
                },
              ],
            });
            break;
          }
          default:
            console.warn("Not implemented question summary");
            break;
        }
      }
    }

    const requestCode = getRequestCodeFromCounter(state.requestCounter);

    return {
      userPersonalData: state.userPersonalData,
      request: {
        title: getRequestTitle(
          state.requestCounter,
          requestCode,
          getFallbackJazyk()
        ),
        items: items,
      },
    };
  });

const getSummarySubmitData = () =>
  createSelector(selectSelf, (state: State): SummarySubmitData[] => {
    let answers: {
      [key: string]: ZvjsAnswerValueType | unknown;
    } = {};

    // if there is onCustomRequestSubmitData function defined then use it instead of a standard summarySubmitData generation below
    const onCustomRequestSubmitDataFunc = state.questionnaire.id
      ? onCustomRequestSubmitData[state.questionnaire.id]
      : undefined;
    if (onCustomRequestSubmitDataFunc) {
      return onCustomRequestSubmitDataFunc(state);
    }

    const iterator = questionsIteratorWithLocFromDisplayedSections(
      state.questionnaire,
      state
    );

    for (const question of iterator) {
      if (isItemDisplayed(question.location, state)) {
        let modifiedByCustomSubmitFunction = false;
        // check if the displayed question is custom
        if (question.value.type in ZvjsCustomQuestionTypes) {
          const questCustomType = question.value
            .type as ZvjsCustomQuestionTypes;
          // custom question might have custom getCustomSubmitData function
          const getCustomSubmitData =
            getCustomSubmitAnswerData[questCustomType];
          if (getCustomSubmitData !== undefined) {
            // if there is such getCustomSubmitData function, use it to modify a submit object
            // (if not, continue in standard way)
            answers = {
              ...answers,
              ...getCustomSubmitData(
                state.answers[question.value.id],
                question.value.id,
                state.userCalcData
              ),
            };
            modifiedByCustomSubmitFunction = true;
          }
        }
        if (!modifiedByCustomSubmitFunction) {
          if (question.value.type === ZvjsQuestionTypes.SINGLE_CHECKBOX) {
            // when question is single checkbox type make sure that you send false to szoo in situations when question has no answer
            answers[question.value.id] =
              state.answers[question.value.id] === true;
          } else {
            if (!isEmptyString(state.answers[question.value.id])) {
              const answer = getAvailableAnswersForQuestion(
                state.answers[question.value.id],
                question.value,
                state
              );
              if (question.value.type === ZvjsQuestionTypes.NESTED_DROPDOWN) {
                const questionReference = question.value as ZvjsNestedDropdown;

                const nestedDropdownMode = determineNestedDropdownMode(
                  questionReference.options,
                  questionReference.maxNumberSelect
                );

                switch (nestedDropdownMode) {
                  case NestedDropdownModes.MultipleSelectWithoutNestedChildren: {
                    // for this mode transform internal answer form
                    // from
                    // fyzickePrejavyProblemu: [{fyzickePrejavy: "2"}, {fyzickePrejavy: "5"}]
                    // to form which szoo is expecting
                    // fyzickePrejavyProblemu: ["2", "5"]
                    const answerToQuestion =
                      answer as NestedDropdownAnswerValueType[];
                    const toSubmit: string[] = [];

                    for (const answer of answerToQuestion) {
                      toSubmit.push(answer[questionReference.options.answerId]);
                    }

                    answers[question.value.id] = toSubmit;
                    break;
                  }
                  case NestedDropdownModes.SingleSelectWithNestedChildren: {
                    // for this mode transform internal answer form
                    // from
                    // oblastPocitujucichProblemov: [{psychickyStav: "HLASY"}, {oblastPocitujucichProblemov: "4"}]
                    // to form which szoo is expecting
                    // psychickyStav: "HLASY", oblastPocitujucichProblemov: "4"

                    const answerToQuestion =
                      answer as NestedDropdownAnswerValueType[];
                    // if nested dropdown is in single select mode there is only one (or no) option selected
                    for (const key of Object.keys(answerToQuestion[0])) {
                      answers[key] = answerToQuestion[0][key];
                    }
                    break;
                  }
                  default: {
                    answers[question.value.id] = answer;
                  }
                }
              } else {
                // use for submit the same answer form as is used inside the request's generator
                answers[question.value.id] = answer;
              }
            }
          }
        }
      }
    }

    return [
      {
        ...getBaseRequestSubmitData(state),
        ...answers,
      },
    ];
  });

export const getBaseRequestSubmitData = (state: State) => {
  return {
    typZiadosti: getRequestCodeFromCounter(state.requestCounter),
    datumPodania: new Date().toISOString(),
    // generates uuid for identification of this request (not used for now but required by api)
    idZiadosti: getUuid(),
    klientId: localStorage.getItem("klientObjectId") ?? "",
    zdroj: isTablet()
      ? CisZdrojZiadosti_Tablet_Kod
      : CisZdrojZiadosti_Kiosk_Kod,
    ustavId: Number(localStorage.getItem("klientUstavId")),
  };
};

export const allSummarySelectors = {
  getSummarySubmitData,
  getSummaryData,
};

export enum NestedDropdownModes {
  MultipleSelectWithNestedChildren,
  SingleSelectWithNestedChildren,
  MultipleSelectWithoutNestedChildren,
}

export const determineNestedDropdownMode = (
  options: ZvjsNestedDropdownOptions,
  maxNumberSelect?: number
) => {
  if (maxNumberSelect === 1) {
    return NestedDropdownModes.SingleSelectWithNestedChildren;
  }
  for (const child of options.children) {
    if (child.options !== undefined) {
      return NestedDropdownModes.MultipleSelectWithNestedChildren;
    }
  }
  return NestedDropdownModes.MultipleSelectWithoutNestedChildren;
};
