import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../../../router/LoaderError";
import { CisCCMStav_Evidovane_ID } from "../../../../../../../specs/countersValues";

const VALUABLE_ITEMS = "VALUABLE_ITEMS";
export const ValuableItemsGridConstants = {
  VALUABLE_ITEMS,
};

/**
 * OnInit
 */

export interface ValuableItemType {
  id: number;
  description: string;
}

export const SC_KIO_050202_ValuableItemsGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { FEOO_Post } = await API_Clients();

  // fetch all valuable items of user with state "Evidované"
  const response = await FEOO_Post("/api/integracie/Kiosk/ListCeniny", {
    body: {
      idKlienta: localStorage.getItem("klientObjectId") ?? "",
      stav: CisCCMStav_Evidovane_ID,
    },
  });

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  const valuableItemsList: ValuableItemType[] = response.data
    // filter out blocked valuable items and items which do not have id set (or id is not number)
    .filter(
      (item) =>
        item.idCenina && !isNaN(Number(item.idCenina)) && !item.blokaciaOctk
    )
    .map((item) => {
      if (item.idCenina)
        return { description: item.popis ?? "", id: Number(item.idCenina) };
      // you should never end up here because of the filter check above (compilation is the reason why error throw is here)
      throw new LoaderError();
    });

  calcData[ValuableItemsGridConstants.VALUABLE_ITEMS] = valuableItemsList;
};

/**
 * Selectors
 */

const getValuableItemsGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      valuableItems: state.userCalcData[
        ValuableItemsGridConstants.VALUABLE_ITEMS
      ] as ValuableItemType[],
    };
  });

export const allSelectors = {
  getValuableItemsGridDisplayData,
};
