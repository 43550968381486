import React, { Suspense, useEffect, useRef, useState } from "react";
import { Outlet, useActionData, useNavigation } from "react-router-dom";
import { Footer } from "../footer";
import { Header } from "../header";
import { LinearProgress } from "@mui/material";
import { FooterRef } from "../footer/Footer";
import { isSubmitting } from "../utils/helpers";
import AppActivityGuard from "../utils/AppActivityGuard";
import { ZvjsChatBot, ZvjsModal, ZvjsPullToRefresh } from "../common";
import { useSnackbar } from "../store/context/snackbar-context";
import { useUITranslation } from "../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { isKiosk } from "../store/context/envConfigContext";
import { ActionResult } from "../router/ActionResult";

export const AppLayout = () => {
  const navigation = useNavigation();
  const submitResult = useActionData() as ActionResult | undefined;
  const footerRef = useRef<FooterRef>(null);
  const { openSnackbar } = useSnackbar();
  const { tui } = useUITranslation();

  const [scaleState, setScaleState] = useState<number>(1);

  useEffect(() => {
    if (submitResult?.message !== undefined) {
      openSnackbar(capitalize(tui(submitResult.message)), submitResult.type);
    }
  }, [openSnackbar, submitResult, tui]);

  const getFooterHeight = () => {
    return footerRef.current?.getHeight() ?? 0;
  };

  const handleScale = () => {
    console.log("handleScale");
    if (scaleState >= 1.3) {
      setScaleState(1);
    } else {
      setScaleState(scaleState + 0.1);
    }
  };

  return (
    <AppActivityGuard>
      <>
        <Header />
        <div
          style={{
            marginTop: "8rem",
            // marginBottom: getFooterHeight(),
            // maxHeight: "65.4rem",
            overflow: "auto",
            transform: `scale(${scaleState})`,
            transformOrigin: "0 0",
            // width: `${100 * (1 / scaleState)}%`,
          }}
        >
          <ZvjsPullToRefresh>
            {/*This is a root general loading indicator.*/}
            {/*When there is any child with Await component waiting for resolving of variable LinearProgress is displayed.*/}
            {/*In case you want a custom loading indicator, or you do not want to wait till all Await components are resolved you can define own Suspense inside child components*/}
            <Suspense fallback={<LinearProgress />}>
              {/*if action is submitting display linear progress*/}
              {isSubmitting(navigation) && <LinearProgress />}
              <Outlet />
            </Suspense>
          </ZvjsPullToRefresh>
        </div>
        <ZvjsModal />
        {isKiosk() && <ZvjsChatBot />}
        <Footer ref={footerRef} zoomIn={handleScale} />
      </>
    </AppActivityGuard>
  );
};
