import { UserCalcData, ZvjsAnswers } from "../../../redux/model";
import reusableCalculations from "./calculations/reusable";
import customCalculations from "./calculations/custom";

const calculations: CalculationType = {
  ...reusableCalculations,
  ...customCalculations,
};

export type CalculationType = {
  [key: string]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default calculations;
