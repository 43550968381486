import { useContext } from "react";
import { EnvConfigContext } from "./envConfigContext";

const useEnvConfig = () => {
  const { envConfig, isLoading } = useContext(EnvConfigContext);

  return {
    envConfig,
    isLoading,
  };
};

export default useEnvConfig;
