import { ValidationType } from "../validations";
import {
  ZvjsAnswerValueType,
  ZvjsValidationError,
  ZvjsValidationFlagTypes,
} from "../../../../redux/model";

const validateMaximalAnswerLength = (
  answer: string,
  maxLength: number
): ZvjsValidationError | undefined => {
  if (answer !== undefined && answer.length > maxLength) {
    return {
      type: ZvjsValidationFlagTypes.ERROR,
      message: `Maximalna povolena dlzka odpovede je ${maxLength} znakov!`,
    };
  }
  return undefined;
};

export enum ReusableValidationType {
  MAX_ANSWER_LENGTH_150 = "MAX_ANSWER_LENGTH_150",
  MAX_ANSWER_LENGTH_500 = "MAX_ANSWER_LENGTH_500",
}

const reusable: ValidationType = {
  MAX_ANSWER_LENGTH_150: ({ newAnswer }: { newAnswer: ZvjsAnswerValueType }) =>
    validateMaximalAnswerLength(newAnswer as string, 150),
  MAX_ANSWER_LENGTH_500: ({ newAnswer }: { newAnswer: ZvjsAnswerValueType }) =>
    validateMaximalAnswerLength(newAnswer as string, 500),
};

export default reusable;
