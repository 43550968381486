import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable, ZvjsTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsTableVariant } from "../../../common/components/ZvjsTable";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async () => {
  const { EOO_Post, EPVVVT_Post } = await API_Clients();

  const vazby = await EOO_Post("/api/VazbyATresty/ListVazbaData", {
    body: {
      filters: [
        {
          id: Number(localStorage.getItem("klientId")),
          ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      ],
    },
  });

  const aktualnaVazbaId = vazby.data?.records?.find(
    (vazba) => vazba.aktivna
  )?.id;

  const data = await Promise.all([
    EOO_Post("/api/VazbyATresty/DetailVazbaData", {
      body: {
        id: aktualnaVazbaId ?? -1,
        ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
      },
    }),
    EOO_Post("/api/VazbyATresty/ListDovodovyParagrafVazbyData", {
      body: {
        filters: [
          {
            id: aktualnaVazbaId ?? -1,
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }),
    EPVVVT_Post("/api/stitkovnicaklientov/ListVsetciKlienti", {
      body: {
        ustavId: Number(localStorage.getItem("klientUstavId")),
      },
    }),
  ]);

  return {
    aktualnaVazba: data[0],
    obvinenia: data[1],
    epvvvtKlient: data[2].data
      ?.filter(
        (klient) =>
          klient.klientObjectId === localStorage.getItem("klientObjectId")
      )
      .at(0),
  };
};

export const ZakladneUdajeOVazbeLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface ZakladneUdajeOVazbeProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const ZakladneUdajeOVazbe = (props: ZakladneUdajeOVazbeProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("osobneUdaje.kategorie.zakladneUdajeVazba"))}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable header={tui("osobneUdaje.udajeOvazbe.udajeVaza")}>
            <ZvjsSummaryTableComponent
              label={tui("osobneUdaje.udajeOvazbe.zaciatokVazby")}
              value={
                loaderData.aktualnaVazba.data?.zaciatokVazby
                  ? new Date(
                      loaderData.aktualnaVazba.data?.zaciatokVazby
                    ).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
              hintText={tui("osobneUdaje.udajeOvazbe.napovedaNastupVazby")}
            />
            <ZvjsSummaryTableComponent
              label={tui("osobneUdaje.udajeOvazbe.dovodVazby")}
              value={loaderData.epvvvtKlient?.dovodVazby ?? ""}
              isBorder={true}
              fullWidth={true}
              hintText={tui(
                "osobneUdaje.udajeOvazbe.napovedaOznacenieTrestCinu"
              )}
            />
          </ZvjsSummaryTable>
          <ZvjsTable
            data={{
              label: capitalize(
                tui("osobneUdaje.obvinenieZaSpachanieTrCinu.obvinenie")
              ),
              header: [
                {
                  value: capitalize(tui("osobneUdaje.udajeOvazbe.paragraf")),
                  align: "left",
                },
              ],
              body:
                loaderData.obvinenia.data?.records?.map((obvinenie) => {
                  return {
                    row: [
                      {
                        value: obvinenie.paragrafLabel ?? "",
                        align: "left",
                      },
                    ],
                  };
                }) ?? [],
            }}
            height={360}
            variant={ZvjsTableVariant.NORMAL}
            hintText={tui(
              "osobneUdaje.obvinenieZaSpachanieTrCinu.napovedaObvinenie"
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(ZakladneUdajeOVazbe);
