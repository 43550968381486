import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { useModal } from "../../../../../../store/context/modal-context";
import {
  ZvjsButton,
  ZvjsSelect,
  ZvjsTextField,
} from "../../../../../components";
import {
  getTranslationByLanguageCode,
  isSubmitting,
} from "../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../locales/i18n";
import i18n from "i18next";
import { Params, useNavigation } from "react-router-dom";
import { ActionResultTypes } from "../../../../../../router/ActionResult";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import ZvjsDateSelect from "../../../../../components/ZvjsDateSelect";
import { DetailZakladneUdajeKlientaData } from "../../../../../specs/swaggerTypes";
import {
  CisPohlavie_NEZISTENE_ID,
  EooCiselnikTypKontaktu_Skype_ID,
} from "../../../../../specs/countersValues";
import { CiselnikListResultType } from "../../../../../../store/context/dataApi/Data";
import {
  refetchRequest,
  RequestActionResult,
  RequestActionsType,
} from "../../../../Request";

interface AddNewPersonSubmitData {
  basicClientData: DetailZakladneUdajeKlientaData;
  formData: AddPersonFormData;
}
export async function SC_KIO_050101_addNewPerson({
  params,
  data,
}: {
  params: Params;
  data: unknown;
}): Promise<RequestActionResult> {
  const { EOO_Post } = await API_Clients();

  const dataToCreateClosePerson = data as AddNewPersonSubmitData;

  const resultNovaFyzickaOsoba = await EOO_Post(
    "/api/FyzickaOsoba/CreateFyzickaOsoba",
    {
      body: {
        meno: dataToCreateClosePerson.formData.firstname,
        priezvisko: dataToCreateClosePerson.formData.surname,
        datumNarodenia: dataToCreateClosePerson.formData.dateOfBirth,
        pohlavieId: CisPohlavie_NEZISTENE_ID,
      },
    }
  );

  if (resultNovaFyzickaOsoba.error || !resultNovaFyzickaOsoba.response.ok) {
    if (
      resultNovaFyzickaOsoba.response.status === 503 &&
      resultNovaFyzickaOsoba.response.headers.get("X-Zvjs-Offline") ===
        "offline"
    ) {
      return {
        type: ActionResultTypes.ERROR,
        // TODO add translation
        message: "Akcia nie je na tablete podporovaná",
        action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
      };
    }

    return {
      type: ActionResultTypes.ERROR,
      // TODO add translation
      message: "Osobu sa nepodarilo pridať",
      action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
    };
  }

  const resultPridanaBlizkaOsoba = await EOO_Post(
    "/api/DalsieOsobneUdaje/BlizkaOsobaPridaj",
    {
      body: {
        klientId: Number(localStorage.getItem("klientId")),
        fyzickaOsobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId,
        vztahKFyzickejOsobeId:
          dataToCreateClosePerson.basicClientData.data?.fyzickaOsobaId,
        typId: dataToCreateClosePerson.formData.relation,
        ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
        poznamka: "voľba klienta na návštevu",
      },
    }
  );

  if (resultPridanaBlizkaOsoba.error || !resultPridanaBlizkaOsoba.response.ok) {
    return {
      type: ActionResultTypes.ERROR,
      message: "Osobu sa nepodarilo pridať",
      action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
    };
  }

  const resultNovyKontakt = await EOO_Post("/api/FyzickaOsoba/CreateKontakt", {
    body: {
      typId: EooCiselnikTypKontaktu_Skype_ID,
      osobaId: resultNovaFyzickaOsoba.data.data?.fyzickaOsobaId,
      data: dataToCreateClosePerson.formData.videoCallContact,
      platnostOd: new Date().toISOString(),
      primarny: true,
    },
  });
  // there is no need to show error if contact creation has failed, user will see that contact is missing in the table

  // re-fetch request dataCalculations
  await refetchRequest(params.requestCode);

  return {
    type: ActionResultTypes.SUCCESS,
    action: RequestActionsType.SC_KIO_050101_ADD_NEW_PERSON,
  };
}

const generateSchema = (tuiz: (key: string) => string) =>
  yup
    .object({
      firstname: yup
        .string()
        .required(capitalize(tuiz("Napíšte meno")))
        .default(""), // todo add translation
      surname: yup
        .string()
        .required(capitalize(tuiz("Napíšte priezvisko")))
        .default(""), // todo add translation
      dateOfBirth: yup
        .string()
        .required(capitalize(tuiz("Napíšte dátum narodenia")))
        .default(""),
      relation: yup
        .number()
        // valid ids start from 0
        .min(0, capitalize(tuiz("Vyberte vzťah k osobe")))
        .default(-1),
      videoCallContact: yup
        .string()
        .required(capitalize(tuiz("Napíšte kontakt na videohovor")))
        .default(""), // todo add translation
    })
    .required();
type AddPersonFormData = yup.InferType<ReturnType<typeof generateSchema>>;

interface SC_KIO_050101_AddPersonFormProps {
  onSubmit: (toSubmit: unknown) => void;
  basicClientData: DetailZakladneUdajeKlientaData;
  closePersonRelationCounter: CiselnikListResultType<"/api/CisTypBlizkejOsoby/List">["data"];
}

const SC_KIO_050101_AddPersonForm: React.FC<
  SC_KIO_050101_AddPersonFormProps
> = ({ onSubmit, basicClientData, closePersonRelationCounter }) => {
  const { closeModal } = useModal();
  const { tuiz, getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  const schema = generateSchema(tuiz);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (formData: AddPersonFormData) => {
    const toSubmit: AddNewPersonSubmitData = {
      formData: formData,
      basicClientData: basicClientData,
    };
    onSubmit(toSubmit);
  };

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        {capitalize(tuiz("ziadost.ziadostOnavstevu.labelDoplnenieInejOsoby"))}
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(handleOnSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/*/!* todo add translation *!/*/}
          <ZvjsTextField
            label={capitalize(tuiz("Meno"))}
            register={{ ...register("firstname") }}
            error={errors.firstname !== undefined}
            validationErrorMessage={errors.firstname?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Priezvisko"))}
            register={{ ...register("surname") }}
            error={errors.surname !== undefined}
            validationErrorMessage={errors.surname?.message}
          />
          <ZvjsDateSelect
            text={capitalize(tuiz("Dátum narodenia"))}
            register={{ ...register("dateOfBirth") }}
            error={errors.dateOfBirth !== undefined}
            validationErrorMessage={errors.dateOfBirth?.message}
          />
          <ZvjsSelect
            text={capitalize(tuiz("Vzťah k osobe"))}
            register={{ ...register("relation") }}
            items={
              closePersonRelationCounter?.records
                ?.filter((relation) => relation.id)
                .map((relation) => {
                  if (relation.id && relation.kod) {
                    return {
                      value: relation.id,
                      text: getTranslationByLanguageCode(
                        closePersonRelationCounter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        relation.kod,
                        "nazov"
                      ),
                    };
                  }
                  throw new Error();
                }) ?? []
            }
            uncontrolled={{ defaultValue: "" }}
            error={errors.relation !== undefined}
            validationErrorMessage={errors.relation?.message}
          />
          <ZvjsTextField
            label={capitalize(tuiz("Kontakt na videohovor"))}
            register={{ ...register("videoCallContact") }}
            error={errors.videoCallContact !== undefined}
            validationErrorMessage={errors.videoCallContact?.message}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={
              Object.keys(errors).length > 0 || isSubmitting(navigation)
            }
            text={capitalize(tuiz("Pridať osobu"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            disabled={isSubmitting(navigation)}
            text={capitalize(tuiz("Zrušiť"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default SC_KIO_050101_AddPersonForm;
