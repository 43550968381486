import React, { ReactNode } from "react";
import { ZvjsSummaryTableRequestComponent } from "../../../common/components/ZvjsSummaryTable";
import { capitalize } from "@mui/material/utils";
import { useUITranslation } from "../../../store/context/translation-context";
import {
  getTranslationByLanguageCode,
  isEmptyArray,
  isEmptyString,
} from "../../../utils/helpers";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import {
  questionsIteratorWithLocAndParentTreeNestedDropdown,
  questionsIteratorWithLocation,
} from "../../../common/request/redux/slice";
import {
  NestedDropdownAnswerValueType,
  ZvjsCustomQuestion,
  ZvjsCustomQuestionTypes,
  ZvjsDropdownQuestion,
  ZvjsDropdownQuestion_New,
  ZvjsNestedDropdown,
  ZvjsQuestionTypes,
  ZvjsSingleCheckBoxQuestion,
  ZvjsTextQuestion,
} from "../../../common/request/redux/model";
import { CreateZiadostRequestBody } from "../../../common/specs/swaggerTypes";
import { getClientRequestContent } from "./RequestDetail";
import {
  BanknotesGridConstants,
  BanknoteType,
} from "../../../common/request/requestPages/questionnaire/items/questions/customQuestions/SC_KIO_050201_BanknotesGrid/redux";
import { isNestedOptionChecked } from "../../../common/request/redux/selectors";
import {
  determineNestedDropdownMode,
  NestedDropdownModes,
} from "../../../common/request/redux/selectors/summary";

export interface RequestClientContentDetailProps {
  clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>;
}

const RequestClientContentDetail = (props: RequestClientContentDetailProps) => {
  const { getFallbackJazyk, tuiz } = useUITranslation();

  const getDropdownQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsDropdownQuestion
  ) => {
    const answerKey =
      clientRequestContent.requestHasuraBody[
        question.id as keyof CreateZiadostRequestBody
      ];
    if (answerKey) {
      let answerText = "";
      for (const option of question.options) {
        if (option.id === answerKey) {
          answerText = tuiz(option.label);
          return (
            <ZvjsSummaryTableRequestComponent
              key={question.id}
              label={tuiz(question.title)}
              value={answerText}
              textAlign={"left"}
              borderTop
            />
          );
        }
      }

      return (
        <ZvjsSummaryTableRequestComponent
          key={question.id}
          label={tuiz(question.title)}
          // TODO add translation
          value={"Údaj nie je dostupný"}
          textAlign={"left"}
          borderTop
        />
      );
    }
    return <React.Fragment key={question.id} />;
  };

  const getDropdownNewQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsDropdownQuestion_New
  ) => {
    const counter = clientRequestContent.counters[question.options.counterKey];
    const answerKey =
      clientRequestContent.requestHasuraBody[
        question.id as keyof CreateZiadostRequestBody
      ];
    if (answerKey) {
      if (counter) {
        let answerText = "";
        for (const option of question.options.optValues) {
          if (option.id === answerKey) {
            answerText = capitalize(
              getTranslationByLanguageCode(
                counter?.records ?? [],
                getCiselnikJazyk(i18n.language),
                getFallbackJazyk(),
                option.id,
                question.options.textKey
              )
            );
            return (
              <ZvjsSummaryTableRequestComponent
                key={question.id}
                label={tuiz(question.title)}
                value={answerText}
                textAlign={"left"}
                borderTop
              />
            );
          }
        }
        return (
          <ZvjsSummaryTableRequestComponent
            key={question.id}
            label={tuiz(question.title)}
            // TODO add translation
            value={"Údaj nie je dostupný"}
            textAlign={"left"}
            borderTop
          />
        );
      }
    }
    return <React.Fragment key={question.id} />;
  };

  const getTextQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsTextQuestion
  ) => {
    const answerText =
      clientRequestContent.requestHasuraBody[
        question.id as keyof CreateZiadostRequestBody
      ];
    if (!isEmptyString(answerText)) {
      return (
        <ZvjsSummaryTableRequestComponent
          key={question.id}
          label={tuiz(question.title)}
          // TODO add translation
          value={answerText?.toString() ?? ""}
          textAlign={"left"}
          borderTop
        />
      );
    }
    return <React.Fragment key={question.id} />;
  };

  const getSingleCheckboxQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsSingleCheckBoxQuestion
  ) => {
    const answerVal =
      clientRequestContent.requestHasuraBody[
        question.id as keyof CreateZiadostRequestBody
      ];
    if (answerVal === true) {
      return (
        <ZvjsSummaryTableRequestComponent
          key={question.id}
          label={tuiz(question.title)}
          // TODO replace with correct translation
          value={"Áno"}
          textAlign={"left"}
          borderTop
        />
      );
    }
    return <React.Fragment key={question.id} />;
  };

  const getNestedDropdownQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsNestedDropdown
  ) => {
    const nestedDropdownMode = determineNestedDropdownMode(
      question.options,
      question.maxNumberSelect
    );

    switch (nestedDropdownMode) {
      case NestedDropdownModes.SingleSelectWithNestedChildren: {
        const nestedIterator =
          questionsIteratorWithLocAndParentTreeNestedDropdown(question.options);
        for (const nestedQuestion of nestedIterator) {
          for (const answerKey in clientRequestContent.requestHasuraBody) {
            if (
              answerKey === nestedQuestion.answerId &&
              clientRequestContent.requestHasuraBody[
                answerKey as keyof CreateZiadostRequestBody
              ] === nestedQuestion.id
            ) {
              const counter =
                clientRequestContent.counters[nestedQuestion.counterKey];
              if (counter) {
                const answerText = capitalize(
                  getTranslationByLanguageCode(
                    counter?.records ?? [],
                    getCiselnikJazyk(i18n.language),
                    getFallbackJazyk(),
                    nestedQuestion.id,
                    nestedQuestion.textKey
                  )
                );
                return (
                  <ZvjsSummaryTableRequestComponent
                    key={question.id}
                    label={tuiz(question.title)}
                    value={answerText}
                    textAlign={"left"}
                    borderTop
                  />
                );
              }
              return (
                <ZvjsSummaryTableRequestComponent
                  key={question.id}
                  label={tuiz(question.title)}
                  // TODO add translation
                  value={"Údaj nie je dostupný"}
                  textAlign={"left"}
                  borderTop
                />
              );
            }
          }
        }
        return <React.Fragment key={question.id} />;
      }
      case NestedDropdownModes.MultipleSelectWithoutNestedChildren: {
        const answerTextArr: string[] = [];
        const answers = clientRequestContent.requestHasuraBody[
          question.id as keyof CreateZiadostRequestBody
        ] as string[] | undefined;

        if (answers) {
          for (const answer of answers) {
            let answerTextFound = false;
            for (const option of question.options.children) {
              if (option.id === answer) {
                const counter =
                  clientRequestContent.counters[question.options.counterKey];
                if (counter) {
                  answerTextArr.push(
                    capitalize(
                      getTranslationByLanguageCode(
                        counter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        option.id,
                        question.options.textKey
                      )
                    )
                  );
                  answerTextFound = true;
                  break;
                }
              }
            }
            if (!answerTextFound) {
              answerTextArr.push("Údaj nie je dostupný");
            }
          }
          return (
            <ZvjsSummaryTableRequestComponent
              key={question.id}
              label={tuiz(question.title)}
              value={answerTextArr.join(", ")}
              borderTop
              textAlign={"left"}
            />
          );
        }
        return <React.Fragment key={question.id} />;
      }
      case NestedDropdownModes.MultipleSelectWithNestedChildren: {
        const answerTextArr: string[] = [];
        const answers = clientRequestContent.requestHasuraBody[
          question.id as keyof CreateZiadostRequestBody
        ] as NestedDropdownAnswerValueType[] | undefined;

        if (answers) {
          for (const answer of answers) {
            let answerTextFound = false;
            const nestedIterator =
              questionsIteratorWithLocAndParentTreeNestedDropdown(
                question.options
              );

            for (const nestedQuestion of nestedIterator) {
              if (isNestedOptionChecked([answer], nestedQuestion.parentTree)) {
                const counter =
                  clientRequestContent.counters[nestedQuestion.counterKey];
                if (counter) {
                  answerTextArr.push(
                    capitalize(
                      getTranslationByLanguageCode(
                        counter?.records ?? [],
                        getCiselnikJazyk(i18n.language),
                        getFallbackJazyk(),
                        nestedQuestion.id,
                        nestedQuestion.textKey
                      )
                    )
                  );
                  answerTextFound = true;
                  break;
                }
              }
            }
            if (!answerTextFound) {
              answerTextArr.push("Údaj nie je dostupný");
            }
          }
          return (
            <ZvjsSummaryTableRequestComponent
              key={question.id}
              label={tuiz(question.title)}
              value={answerTextArr.join(", ")}
              borderTop
              textAlign={"left"}
            />
          );
        }

        return <React.Fragment key={question.id} />;
      }
    }
  };

  const SC_KIO_050201_getBanknotesGridQuestionSummary = (
    clientRequestContent: Awaited<ReturnType<typeof getClientRequestContent>>,
    question: ZvjsCustomQuestion
  ) => {
    const answerVal = clientRequestContent.requestHasuraBody[
      question.id as keyof CreateZiadostRequestBody
    ] as string[] | undefined | null;
    if (!isEmptyArray(answerVal)) {
      const answerTextArr: string[] = [];
      for (const answer of answerVal ?? []) {
        let banknoteFound = false;
        for (const banknote of clientRequestContent.precalculatedData[
          BanknotesGridConstants.BANKNOTES
        ] as BanknoteType[]) {
          if (answer === banknote.id.toString()) {
            answerTextArr.push(`${banknote.value} ${banknote.currency}`);
            banknoteFound = true;
            break;
          }
        }

        if (!banknoteFound) {
          // if banknote for answer was not found display error message for particular banknote
          // TODO add translation
          answerTextArr.push("Údaj nie je dostupný");
        }
      }
      return (
        <ZvjsSummaryTableRequestComponent
          key={question.id}
          label={tuiz(question.title)}
          // TODO add translation
          value={answerTextArr.join(", ")}
          borderTop
          textAlign={"left"}
        />
      );
    }

    return <React.Fragment key={question.id} />;
  };

  const getClientRequestContentBody = () => {
    const toReturn: ReactNode[] = [];

    const questionsIterator = questionsIteratorWithLocation(
      props.clientRequestContent.requestTemplate,
      []
    );

    for (const question of questionsIterator) {
      switch (question.value.type) {
        case ZvjsQuestionTypes.DROPDOWN: {
          toReturn.push(
            getDropdownQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsDropdownQuestion
            )
          );
          break;
        }
        case ZvjsQuestionTypes.DROPDOWN_NEW: {
          toReturn.push(
            getDropdownNewQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsDropdownQuestion_New
            )
          );
          break;
        }
        case ZvjsQuestionTypes.TEXT: {
          toReturn.push(
            getTextQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsTextQuestion
            )
          );
          break;
        }
        case ZvjsQuestionTypes.SINGLE_CHECKBOX: {
          toReturn.push(
            getSingleCheckboxQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsSingleCheckBoxQuestion
            )
          );
          break;
        }
        case ZvjsQuestionTypes.NESTED_DROPDOWN: {
          toReturn.push(
            getNestedDropdownQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsNestedDropdown
            )
          );
          break;
        }
        case ZvjsCustomQuestionTypes.SC_KIO_050201_BANKNOTES_GRID: {
          toReturn.push(
            SC_KIO_050201_getBanknotesGridQuestionSummary(
              props.clientRequestContent,
              question.value as ZvjsCustomQuestion
            )
          );
          break;
        }
        default:
          console.warn(
            `Request content body not implemented for question type ${question.value.type}`
          );
      }
    }

    return toReturn;
  };

  return <>{getClientRequestContentBody()}</>;
};

export default RequestClientContentDetail;
