import { Navigation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// used to determine if any react router action is currently running
export const isSubmitting = (navigation: Navigation) => {
  return navigation.state === "submitting" || navigation.state === "loading";
};

export const isEmptyString = (value: unknown): boolean => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

export function isEmptyArray(variable: unknown): boolean {
  return Array.isArray(variable) && variable.length === 0;
}

export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// check whether you are running inside electron
// https://github.com/electron/electron/issues/2288
export const isElectron = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  console.log(userAgent);
  if (userAgent.indexOf(" electron/") > -1) {
    return true;
    // Electron-specific code
  }
  return false;
};

export const getUniqueCodesFromCiselnik = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>
): string[] => {
  const uniqueCodesSet = new Set<string>();

  items.forEach((item) => {
    uniqueCodesSet.add(item.kod);
  });

  return Array.from(uniqueCodesSet);
};

export const getTranslationByLanguageCode = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>,
  language: string,
  fallbackLanguage: string,
  code: string,
  translationInField: string
): string => {
  const matchingItem = items.find(
    (item) => item.jazyk === language && item.kod === code
  );

  if (matchingItem) {
    return matchingItem[translationInField];
  }

  const fallbackItem = items.find(
    (item) => item.jazyk === fallbackLanguage && item.kod === code
  );

  if (fallbackItem) {
    return fallbackItem[translationInField];
  }

  // if all items in the counter do not have jazyk field provided, then the counter is not translated
  // in that case check code field only
  if (items.every((item) => item.jazyk === undefined)) {
    const matchingNotTranslatedItem = items.find((item) => item.kod === code);

    if (matchingNotTranslatedItem) {
      return matchingNotTranslatedItem[translationInField];
    }
  }

  return "";
};

export const getTranslationByLanguageId = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>,
  language: string,
  fallbackLanguage: string,
  id: number,
  translationInField: string
): string => {
  const matchingItem = items.find(
    (item) => item.jazyk === language && item.id === id
  );

  if (matchingItem) {
    return matchingItem[translationInField];
  }

  const fallbackItem = items.find(
    (item) => item.jazyk === fallbackLanguage && item.id === id
  );

  if (fallbackItem) {
    return fallbackItem[translationInField];
  }

  // if all items in the counter do not have jazyk field provided, then the counter is not translated
  // in that case check code field only
  if (items.every((item) => item.jazyk === undefined)) {
    const matchingNotTranslatedItem = items.find((item) => item.id === id);

    if (matchingNotTranslatedItem) {
      return matchingNotTranslatedItem[translationInField];
    }
  }

  return "";
};

export const getUuid = () => {
  return uuidv4();
};

/**
 * @description Vytvori query string z atributov daneho objektu
 * @param query (objekt)
 * @example
 * const Obj = {
 *  propA: "A"
 *  propB: "B"
 * }
 * queryString(Obj)
 * // propA=A&propB=B

 * @returns query string
 */
export const queryString = <T extends Record<string, unknown>>(
  query: T
): string => {
  return (Object.keys(query) as Array<keyof T>)
    .map((key) => `${String(key)}=${query[key]}`)
    .join("&");
};

declare global {
  interface String {
    /**
     * Remove diacritics from string
     * @returns {string} - string without diacritics
     */
    removeDiacritics(): string;

    /**
     * Remove diacritics and blank spaces from string
     * @returns {string} - string without diacritics and blank spaces
     */
    removeDiacriticsAndBlanks(): string;

    /**
     * Replace slovak chars to numeric, like +ľščťžýáíé
     */
    zvjsReplaceSlovakDiacriticsToNumeric(): string;
  }

  interface Date {
    /**
     * Custom date to string method for ZVJS
     * @returns {string} - date in format dd.mm.yyyy
     */
    zvjsToString(): string;

    /**
     * Check if Date is valid
     * @returns {boolean} true if date is valid
     */
    isValid(): boolean;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.removeDiacritics = function (): string {
  return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// eslint-disable-next-line no-extend-native
String.prototype.removeDiacriticsAndBlanks = function (): string {
  return this.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w]/g, "");
};

// eslint-disable-next-line no-extend-native
Date.prototype.zvjsToString = function (): string {
  //todo add language support
  return this.toLocaleDateString("sk-SK", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

// eslint-disable-next-line no-extend-native
Date.prototype.isValid = function (): boolean {
  return !isNaN(this.getFullYear());
};

// eslint-disable-next-line no-extend-native
String.prototype.zvjsReplaceSlovakDiacriticsToNumeric = function (): string {
  return this.replace(/[+]/g, "1")
    .replace(/ľ/g, "2")
    .replace(/š/g, "3")
    .replace(/č/g, "4")
    .replace(/ť/g, "5")
    .replace(/ž/g, "6")
    .replace(/ý/g, "7")
    .replace(/á/g, "8")
    .replace(/í/g, "9")
    .replace(/é/g, "0");
};

export const getAllSubdirectories = (filePath: string): string[] => {
  const subdirectories: string[] = [];
  const parts = filePath.split("/"); // Split the file path into parts

  for (let i = 0; i < parts.length - 1; i++) {
    const subPath = parts.slice(0, i + 1).join("/") + "/";
    subdirectories.push(subPath);
  }

  return subdirectories;
};
