import React from "react";
import {
  RequestPagesZmenaUdajovZiadost,
  ZmenaUdajovZiadostData,
} from "./ZmenaUdajovZiadost";
import { ZvjsButton, ZvjsSummaryTable } from "../../../../common";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../../store/context/translation-context";
import { getUuid } from "../../../../utils/helpers";
import { isTablet } from "../../../../store/context/envConfigContext";
import {
  CisZdrojZiadosti_Kiosk_Kod,
  CisZdrojZiadosti_Tablet_Kod,
} from "../../../../common/specs/countersValues";
import { API_Clients } from "../../../../store/context/dataApi/Data";

interface ZvjsOsobneUdajeSummaryProps {
  setCurrentPage: React.Dispatch<
    React.SetStateAction<RequestPagesZmenaUdajovZiadost>
  >;
  data: ZmenaUdajovZiadostData;
}

const Summary: React.FC<ZvjsOsobneUdajeSummaryProps> = ({
  setCurrentPage,
  data,
}) => {
  const navigate = useNavigate();
  const { tui, tuiz } = useUITranslation();

  const onContiune = async () => {
    const { SZOO_Post, HASURA_Put } = await API_Clients();

    // TODO reimplement using router actions
    const submitBody = {
      typZiadosti: "14.00.01",
      datumPodania: new Date().toISOString(),
      // generates uuid for identification of this request
      idZiadosti: getUuid(),
      klientId: localStorage.getItem("klientObjectId") ?? "",
      zdroj: isTablet()
        ? CisZdrojZiadosti_Tablet_Kod
        : CisZdrojZiadosti_Kiosk_Kod,
      ustavId: Number(localStorage.getItem("klientUstavId")),
      predmetZiadosti: data.nameRequest,
      obsahZiadosti: data.data,
    };

    const responseHasura = await HASURA_Put(
      "/api/rest/v1/kiosk-ziadost-create/{id}",
      {
        params: {
          path: {
            id: submitBody.idZiadosti,
          },
        },
        body: { body: submitBody },
      }
    );

    if (responseHasura.error || !responseHasura.response.ok) {
      //   TODO
    }

    const responseSzoo = await SZOO_Post("/api/Ziadosti/Create", {
      body: submitBody,
    });

    if (responseSzoo.error || !responseSzoo.data.isCreated) {
      //   TODO
    }

    navigate("#confirmation", { replace: true });
    setCurrentPage(RequestPagesZmenaUdajovZiadost.CONFIRMATION);
  };

  return (
    <Stack spacing={5}>
      <ZvjsSummaryTable>
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.osobneUdaje")}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.menoPriezvisko")}
          value={data.name as string}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.datumNarodenia")}
          value={data.date as string}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.udajeZiadosti")}
        />
        {/*TODO get name of request from request counter*/}
        <ZvjsSummaryTableRequestComponent
          label={"Žiadosť"}
          value={"Univerzálna žiadosť"}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadost.vseobecnaZiadost.labelNazovAleboPredmet")}
          value={data.nameRequest as string}
          borderBottom={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={"Obsah žiadosti"}
          value={data.data as string}
          borderBottom={false}
          textAlign={"left"}
          longText={true}
          applyJsFromat={true}
        />
      </ZvjsSummaryTable>
      <div>
        <Typography fontSize={28} fontWeight={700}>
          {tuiz("ziadostiVseobecne.potvrdenieUdajov")}
        </Typography>
        <Typography>{tuiz("notifikacie.zavaznePotvrdenie")}</Typography>
      </div>
      <Stack direction={"row"} spacing={2}>
        <ZvjsButton
          onClick={() => onContiune()}
          zvjsVariant={"secondaryAction"}
          text={capitalize(tui("podať žiadosť"))}
          endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
        />
        <ZvjsButton
          zvjsVariant={"cancelAction"}
          text={capitalize(tui("zrušiť žiadosť"))}
          startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
          onClick={() =>
            navigate("/auth/MyData/BasicClientData", { replace: true })
          }
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
