/**
 * Actions interfaces / types
 */
export enum ActionResultTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface ActionResultAbstract {
  type: ActionResultTypes;
  message?: string;
}

export type ActionResult = ErrorActionResult | SuccessActionResult;

export interface ErrorActionResult extends ActionResultAbstract {
  type: ActionResultTypes.ERROR;
  message?: string;
}

export interface SuccessActionResult extends ActionResultAbstract {
  type: ActionResultTypes.SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}
