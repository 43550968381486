import React, { useContext } from "react";
import { capitalize, Grid, Typography } from "@mui/material";
import { LoginStepProps } from "../Login";
import ZvjsQrReader from "../../common/components/ZvjsQrReader";
import ZvjsKeyboardReader from "../../common/components/ZvjsKeyboardReader";
import ZvjsTesterReader from "../../common/components/ZvjsTesterReader";
import { useUITranslation } from "../../store/context/translation-context";
import AuthContext from "../../store/context/auth-context";
import { useSnackbar } from "../../store/context/snackbar-context";
import { API_Clients } from "../../store/context/dataApi/Data";
import { isKiosk, isTablet } from "../../store/context/envConfigContext";
import { isEmptyString } from "../../utils/helpers";

const LoginStep1: React.FC<LoginStepProps> = ({ setStep }) => {
  const { tui } = useUITranslation();
  const { openSnackbar } = useSnackbar();

  const { setCisloKarty } = useContext(AuthContext);

  const onScan = (qrCode: string) => {
    if (!isEmptyString(qrCode) && !Number.isNaN(Number(qrCode))) {
      API_Clients().then((clients) => {
        clients
          .SIDKOO_Post("/api/IdKarta/ListIdKartaAktivna", {
            body: {
              filters: [
                {
                  cisloKarty: Number(qrCode),
                },
              ],
            },
          })
          .then((result) => {
            if (!result.response.ok && isTablet()) {
              const message =
                capitalize(
                  tui(
                    "Prístupová karta nie je rozpoznaná tabletom (offline režim) alebo nie je aktivna"
                  )
                ) + ` [${qrCode}]`;
              console.error(message);
              openSnackbar(message, "error");
            } else if (result.data?.records?.length === 0) {
              const message =
                capitalize(tui("Prístupová karta nie je aktivna")) +
                ` [${qrCode}]`;
              console.error(message);
              openSnackbar(message, "error");
            } else {
              setCisloKarty(Number(qrCode));
              setStep(2);
            }
          })
          .catch((err) => {
            let errorMessage = "";
            if (err instanceof Error) {
              errorMessage = err.message;
            } else {
              errorMessage = String(err);
            }
            console.error(errorMessage);
            openSnackbar(errorMessage, "error");
          });
      });
    } else {
      const message =
        capitalize(tui("Číslo karty je v nesprávnom formáte")) + ` [${qrCode}]`;
      console.error(message);
      openSnackbar(message, "error");
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="75vh"
      maxWidth="70rem"
      spacing={5}
      mb={25}
    >
      <Grid item>
        <Typography variant="h3" width="50rem" textAlign="center">
          {tui("prihlasenie.prilozitKartu")}
        </Typography>
      </Grid>
      <Grid item>
        <ZvjsQrReader onScan={onScan} />
      </Grid>

      {isKiosk() && (
        <Grid item>
          <ZvjsKeyboardReader onScan={onScan} />
        </Grid>
      )}
      {/* TODO use isDevelopment var once camera on windows is fixed*/}
      {true && (
        <Grid item>
          <ZvjsTesterReader onScan={onScan} />
        </Grid>
      )}
    </Grid>
  );
};

export default LoginStep1;
