import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import ZvjsTabsRouter from "../../../common/components/ZvjsTabsRouter";

export enum ZakladneUdajeKlientaTabs {
  OsobneUdaje = "OsobneUdaje",
  TrvalyPobyt = "TrvalyPobyt",
  PrechodnyPobyt = "PrechodnyPobyt",
  PotravinoveIntolerancie = "PotravinoveIntolerancie",
  VyzivovaciePovinnosti = "VyzivovaciePovinnosti",
}

const ZakladneUdajeKlienta = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeKlienta")
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabsRouter
            data={[
              {
                label: tui(
                  "osobneUdaje.kategorieOsobnychUdajov.osobneUdaje"
                ).toLowerCase(),
                route: ZakladneUdajeKlientaTabs.OsobneUdaje,
              },
              {
                label: tui("osobneUdaje.poskytovanaSluzba.trvalyPobyt"),
                route: ZakladneUdajeKlientaTabs.TrvalyPobyt,
              },
              {
                label: tui("Prechodný pobyt"),
                route: ZakladneUdajeKlientaTabs.PrechodnyPobyt,
              },
              {
                label: tui(
                  "osobneUdaje.potravinoveIntolerancie.potravinoveIntolerancie"
                ),
                route: ZakladneUdajeKlientaTabs.PotravinoveIntolerancie,
              },
              {
                label: tui("Vyživovacia povinnosť"),
                route: ZakladneUdajeKlientaTabs.VyzivovaciePovinnosti,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZakladneUdajeKlienta;
