import {
  NestedDropdownAnswerValueType,
  State,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { ZvjsSelectItem } from "../../../../../../../components/ZvjsSelect";
import {
  getInstitutionWithId,
  PlaceOfRelocationQuestionConstants,
} from "../SC_KIO_051403_PlaceOfRelocationQuestion/redux";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { getTranslationByLanguageCode } from "../../../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";

/**
 * Selectors
 */

const getPriorityQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { getFallbackJazyk } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answersOfRelatedQuestion: NestedDropdownAnswerValueType[] = state
      .answers[
      "miestoPozadovanehoPremiestnenia"
    ] as NestedDropdownAnswerValueType[];

    const returnList: ZvjsSelectItem[] = [];
    if (Array.isArray(answersOfRelatedQuestion) && answersOfRelatedQuestion) {
      for (const answer of answersOfRelatedQuestion) {
        const institution = getInstitutionWithId(
          state.userCalcData,
          answer[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID]
        );
        if (institution !== undefined) {
          returnList.push({
            value: institution.id,
            text: getTranslationByLanguageCode(
              state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
              getCiselnikJazyk(i18n.language),
              getFallbackJazyk(),
              institution.id,
              "nazov"
            ),
          });
        }
      }
    }

    return {
      id: question.id,
      title: question.title,
      answerRequired: question.isRequired,
      options: returnList,
    };
  });

const getSelectedPriority = (location: number[]) =>
  createSelector(selectSelf, (state: State): string | undefined => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return state.answers[question.id] as string | undefined;
  });

export const allSelectors = {
  getPriorityQuestionDisplayData,
  getSelectedPriority,
};
