import {
  Calculate,
  LabelType,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";
import {
  CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod,
  CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
  CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod,
  CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod,
  CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
} from "../../specs/countersValues";

export const SEND_MONEY_TO_WHOM_QUESTION_ID = "typOdoslaniaProstriedkov";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050204",
  basicInfo: [
    {
      text: "Obvinený alebo odsúdený môže, okrem peňažných prostriedkov blokovaných na konte, poukázať peňažné prostriedky blízkej osobe, na úhradu nákladov, ktoré by obvinenému/ odsúdenému spôsobili ujmu, na úhradu nákladov za sprístupnenie informácií či úhradu súdnych a správnych poplatkov. Ak sú peňažné prostriedky zasielané prostredníctvom Slovenskej pošty, tak poštovné znáša obvinený a odsúdený.",
    },
  ],
  counters: ["/api/CisTypOdoslaniaProstriedkov/List", "/api/CisUstavZvjs/List"],
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    POSSIBLE_WITHDRAWAL_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_DESIGNATED_PERSON: {
      calculateAt: Calculate.LIVE,
    },
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050204_REQUESTS_THIS_MONTH_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE: {
      calculateAt: Calculate.LIVE,
    },
    SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      id: SEND_MONEY_TO_WHOM_QUESTION_ID,
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelOdoslatKomu",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisTypOdoslaniaProstriedkov/List",
        textKey: "typ_odoslania",
        optValues: [
          {
            id: CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod,
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON",
                conditionMetWhenValueIs: true,
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod,
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED",
                conditionMetWhenValueIs: true,
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod,
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_DOES_CLIENT_HAVE_AVAILABLE_ACCOUNT_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_CAN_TRANSFER_FUNDS_TO_DESIGNATED_PERSON",
                conditionMetWhenValueIs: true,
              },
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc:
                  "SC_KIO_050204_DOES_CLIENT_HAVE_POSSIBLE_WITHDRAWAL_AVAILABLE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
        ],
      },
    },
    {
      id: "SC_KIO_050204_QUES_02",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelSposobOdoslania",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_23_01",
          label: "Na účet v banke",
        },
        {
          id: "E_KIO_23_02",
          label: "Poštová poukážka",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          hasValue: true,
        },
      ],
    },
    {
      id: "SC_KIO_050204_QUES_03",
      title: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelSuma",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          hasValue: true,
        },
      ],
      validations: ["SC_KIO_050204_VALIDATE_AMOUNT_TO_SEND"],
    },
    {
      id: "SC_KIO_050204_QUES_04",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVariabilnySymbol",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod],
        },
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_02",
          value: ["E_KIO_23_01"],
        },
      ],
    },
    {
      id: "SC_KIO_050204_QUES_04",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVariabilnySymbol",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [
            CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod,
            CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
            CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod,
            CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
          ],
        },
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_02",
          value: ["E_KIO_23_01"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Blízkej osobe",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMoznaSumaNaCerpanie",
          value: "POSSIBLE_WITHDRAWAL_AMOUNT",
          valueType: LabelType.EURO,
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [CisTypOdoslaniaProstriedkov_Blizkej_Osobe_Kod],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Inej osobe alebo určenej osobe",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMoznaSumaNaCerpanie",
          value: "POSSIBLE_WITHDRAWAL_AMOUNT",
          valueType: LabelType.EURO,
        },
        {
          id: "SC_KIO_050204_QUES_05",
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelAkeNakladyUhradit",
          type: ZvjsQuestionTypes.TEXT,
          isRequired: true,
        },
        {
          type: ZvjsItemTypes.SECTION,
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelKomuPoslat",
          items: [
            {
              id: "SC_KIO_050204_QUES_06",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMenoPriezviskoPravnickaOsoba",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              id: "SC_KIO_050204_QUES_07",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVyhladatPOpodlaICO",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Adresa",
              items: [
                {
                  id: "SC_KIO_050204_QUES_08",
                  title: "Štát",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_09",
                  title: "Kraj",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_10",
                  title: "Okres",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_11",
                  title: "Obec",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_12",
                  title: "PSČ",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_13",
                  title: "Súpisné číslo",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_14",
                  title: "Orientačné číslo",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_15",
                  title: "Ulica",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Bankový účet vedený v SR",
              items: [
                {
                  id: "SC_KIO_050204_QUES_16",
                  title: "ziadostiVseobecne.IBAN",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [
            CisTypOdoslaniaProstriedkov_Inej_Osobe_Alebo_Sposobena_Ujma_Kod,
            CisTypOdoslaniaProstriedkov_Urcenej_Osobe_Kod,
          ],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Na úhradu nákladov za sprístupnenie informácií",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadostiVseobecne.disponibilnaSuma",
          value: "AVAILABLE_ACCOUNT_AMOUNT",
          valueType: LabelType.EURO,
        },
        {
          id: "komuPoslatOrganVerejnejMoci",
          type: ZvjsQuestionTypes.DROPDOWN,
          isRequired: true,
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelKomuPoslat",
          options: [
            {
              id: "komuPoslatOrganVerejnejMociUstav",
              label:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelUstavNaVykonVazbyTrestu",
            },
            {
              id: "komuPoslatOrganVerejnejMociGRZVJS",
              label:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelGRZVJS",
            },
            {
              id: "komuPoslatOrganVerejnejMociInemuOrganu",
              label:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelInemuOrganu",
            },
          ],
        },
        {
          id: "SC_KIO_050204_QUES_17",
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelUstavNaVykonVazbyTrestu",
          type: ZvjsCustomQuestionTypes.SC_KIO_050204_INSTITUTIONS_QUESTION,
          isRequired: true,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "komuPoslatOrganVerejnejMoci",
              value: ["komuPoslatOrganVerejnejMociUstav"],
            },
          ],
        },
        {
          type: ZvjsItemTypes.SECTION,
          title: "Iný orgán",
          items: [
            {
              id: "SC_KIO_050204_QUES_18",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelNazovInehoOrganu",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Bankový účet vedený v SR",
              items: [
                {
                  id: "SC_KIO_050204_QUES_19",
                  title: "ziadostiVseobecne.IBAN",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
          ],
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "komuPoslatOrganVerejnejMoci",
              value: ["komuPoslatOrganVerejnejMociInemuOrganu"],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [CisTypOdoslaniaProstriedkov_Organu_Verejnej_Moci_Kod],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Súdu alebo inému správnemu orgánu",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadostiVseobecne.disponibilnaSuma",
          value: "AVAILABLE_ACCOUNT_AMOUNT",
          valueType: LabelType.EURO,
        },
        {
          type: ZvjsItemTypes.SECTION,
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelKomuPoslat",
          items: [
            {
              id: "SC_KIO_050204_QUES_TODO_01",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelNazovSudu",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              id: "SC_KIO_050204_QUES_TODO_02",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVyhladatPOpodlaICO",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            // {
            //   id: "XY",
            //   type: ZvjsCustomQuestionTypes.TABLE,
            //   title: "Bankový účet vedený v SR",
            // },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Bankový účet vedený v SR",
              items: [
                {
                  id: "SC_KIO_050204_QUES_TODO_03",
                  title: "ziadostiVseobecne.IBAN",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: SEND_MONEY_TO_WHOM_QUESTION_ID,
          value: [
            CisTypOdoslaniaProstriedkov_Sudu_Alebo_Inemu_Spravnemu_Organu_Kod,
          ],
        },
      ],
    },
  ],
};
