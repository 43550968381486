import { Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import {
  createSearchParams,
  defer,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../store/context/translation-context";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import { API_Clients } from "../../../../store/context/dataApi/Data";

const loadData = async (params: Params, request: Request) => {
  const searchParams = new URL(request.url).searchParams;
  const oblast = searchParams.get("oblast")
    ? decodeURIComponent(searchParams.get("oblast") || "")
    : undefined;
  const stavPlnenia = searchParams.get("stavPlnenia")
    ? decodeURIComponent(searchParams.get("stavPlnenia") || "")
    : undefined;

  console.debug(
    `ProgramZaobchadzania loadData oblast[${oblast}] stavPlnenia[${stavPlnenia}]`
  );

  const { CIS_Post, EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisProgramZaobOblastiProgramuZaob/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisProgramZaobchadzaniaStavHodnoteniaPz/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
    EPVVVT_Post(
      "/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
        },
      }
    ),
  ]);

  if (data[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [číselník oblasti programu zaobchádzania]"
    );
  }

  if (data[1].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [číselník stav plnenia programu zaobchádzania]"
    );
  }

  if (data[2].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [program zaobchádzania]");
  }

  if (oblast && !!data[2].data) {
    data[2].data = data[2].data.filter(
      (item) => item.oblastProgramuZaobchadzaniaKod === oblast
    );
  }

  if (stavPlnenia && !!data[2].data) {
    data[2].data = data[2].data.filter(
      (item) => item.ulohaStavHodnoteniaKod === stavPlnenia
    );
  }

  return {
    oblast: data[0],
    stavPlnenia: data[1],
    programZaobchadzaniaCieleSUlohami: data[2],
  };
};

export const ProgramZaobchadzaniaTabLoader = async ({
  params,
  request,
}: {
  params: Params;
  request: Request;
}) => {
  return defer({
    data: loadData(params, request),
  });
};

export interface ProgramZaobchadzaniaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const ProgramZaobchadzaniaTab = (props: ProgramZaobchadzaniaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onSearch = (oblast: string, stavPlnenia: string) => {
    console.debug(
      `ProgramZaobchadzania onSearch oblast[${oblast}] stavPlnenia[${stavPlnenia}]`
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...(oblast !== "" && {
            oblast: encodeURIComponent(oblast),
          }),
          ...(stavPlnenia !== "" && {
            stavPlnenia: encodeURIComponent(stavPlnenia),
          }),
        }).toString()}`,
      },
      { replace: true }
    );
  };

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsSearchBar
        title={tui("filter - program zaobchádzania")}
        type={ZvjsSearchBarVariant.VARIANT_E_CoCo}
        comboboxLabel={capitalize(
          tui("osobneUdaje.programZaobchadzania.oblast")
        )}
        items={
          props.data.oblast.data.records?.map((item) => {
            return { value: item.kod || -1, text: item.nazov || "" };
          }) || []
        }
        comboboxLabel2={capitalize(
          tui("osobneUdaje.programZaobchadzania.stavPlnenia")
        )}
        items2={
          props.data.stavPlnenia.data.records?.map((item) => {
            return { value: item.kod || -1, text: item.nazov || "" };
          }) || []
        }
        onSubmit={onSearch}
      />
      <ZvjsTable
        data={{
          label: tui("osobneUdaje.programZaobchadzania.programZaobchadzania"),
          header: [
            {
              value: capitalize(
                tui("osobneUdaje.programZaobchadzania.ulohyProgramu")
              ),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.programZaobchadzania.cielProgramu")
              ),
              align: "center",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.programZaobchadzania.stavPlnenia")
              ),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.programZaobchadzaniaCieleSUlohami.data?.map(
              (programZaobchadzaniaCielSUlohami) => {
                const rd: RowData = {
                  row: [
                    {
                      value:
                        programZaobchadzaniaCielSUlohami.programZaobchadzaniaTypUlohyNazov ??
                        "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value:
                        programZaobchadzaniaCielSUlohami.cieleProgramuZaobchadzaniaNazov ??
                        "",
                      align: "center",
                      width: "calc(100% / 3)",
                    },
                    {
                      value:
                        programZaobchadzaniaCielSUlohami.ulohaStavHodnoteniaNazov ??
                        "",
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.programZaobchadzania.napovedaProgram")}
      />
    </Stack>
  );
};

export default withLoader(ProgramZaobchadzaniaTab);
