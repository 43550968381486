import { defer, useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList", {
      body: {
        filters: [
          {
            klientId: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
        paging: {
          currentPage: 1,
          recordsPerPage: 999999,
        },
        // sorting: [{}],
      },
    }),
    EOO_Post("/api/OchranneOpatrenie/ListOchranneOpatrenieData", {
      body: {
        //TODO: Uistit sa, ze filter skutocne filtruje cez klientId (podla vysledkov sa zda, ze ano)
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
        paging: {
          currentPage: 1,
          recordsPerPage: 999999,
        },
        // sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [právni zástupcovia klienta]"
    );
  }

  if (data[1].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [ochranné opatrenia klienta]"
    );
  }

  return {
    pravniZastupcovia: data[0],
    ochranneOpatrenia: data[1],
  };
};

export const ZastupcoviaAOpatreniaTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ZastupcoviaAOpatreniaTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const ZastupcoviaAOpatreniaTab = (props: ZastupcoviaAOpatreniaTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={{
          label: capitalize(tui("právni zástupcovia")),
          header: [
            {
              value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")
              ),
              align: "center",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("Postavenie")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.pravniZastupcovia.data.records?.map((pravnyZastupca) => {
              const rd: RowData = {
                row: [
                  {
                    value: pravnyZastupca.meno ?? "",
                    align: "left",
                    width: "calc(100% / 3)",
                  },
                  {
                    value: pravnyZastupca.priezvisko ?? "",
                    align: "center",
                    width: "calc(100% / 3)",
                  },
                  {
                    value:
                      pravnyZastupca.postaveniePravnehoZastupcuString ?? "",
                    align: "right",
                    width: "calc(100% / 3)",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={{
          label: capitalize(tui("ochranné opatrenia")),
          header: [
            {
              value: capitalize(tui("názov")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("dátum")),
              align: "center",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.ochranneOpatrenia.data.records?.map(
              (ochranneOpatrenie) => {
                const rd: RowData = {
                  row: [
                    {
                      value:
                        ochranneOpatrenie.typOchrannehoOpatreniaNazov ?? "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: "<API?>", //TODO: kde ziskat datum new Date(ochranneOpatrenie.datum).zvjsToString(),
                      align: "center",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: (
                        <ZvjsButton
                          text={capitalize(tui("detail"))}
                          zvjsVariant="secondaryAction"
                          startIcon={
                            <DetailIcon
                              style={{ fill: "white", width: 28, height: 28 }}
                            />
                          }
                          onClick={() => {
                            navigate(
                              `ProtectiveMeasure/${ochranneOpatrenie.id}`
                            );
                          }}
                        />
                      ),
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }
            ) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(ZastupcoviaAOpatreniaTab);
