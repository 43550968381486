import React, { useEffect, useState } from "react";

import { StringMap } from "../../store/context/envConfigContext";

interface ZvjsHTMLViewerProps {
  file: { url: string; contentType: string; httpHeaders?: StringMap };
}

const ZvjsHTMLViewer: React.FC<ZvjsHTMLViewerProps> = ({ file }) => {
  const [htmlObjectUrl, setHtmlObjectUrl] = useState<string | undefined>();

  useEffect(() => {
    fetch(file.url, {
      headers: file.httpHeaders,
      method: "GET",
    }).then((response) => {
      response
        .blob()
        .then((blob) => setHtmlObjectUrl(window.URL.createObjectURL(blob)));
    });
  }, [file]);

  return (
    <div style={{ justifyContent: "center", display: "flex", height: "65vh" }}>
      <iframe src={htmlObjectUrl} width={"100%"} height={"100%"} />
    </div>
  );
};

export default ZvjsHTMLViewer;
