import React, { useEffect } from "react";
import { ZvjsAlert, ZvjsNestedDropDown } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { AppDispatch } from "../../../../../../store/redux";
import { Box, Grid } from "@mui/material";
import {
  getSeverityOfValidationFlag,
  NestedDropdownAnswerValueType,
} from "../../../../redux/model";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface NestedDropdownQuestionProps {
  location: number[];
}

const NestedDropdownQuestion: React.FC<NestedDropdownQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(`NESTED DROPDOWN QUESTION RERENDER ${location.join("-")}`);

  const {
    id,
    answerRequired,
    title,
    options,
    selectedOptionsText,
    hintText,
    maxNumberSelect,
    selectedOptionsCount,
  } = useSelector(
    questionnaireRedux.selectors.getNestedDropdownQuestionDisplayData(location)
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(id)
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(id)
  ) as undefined | NestedDropdownAnswerValueType[];

  const onChangeHandler = (
    checked: boolean,
    checkedValue: NestedDropdownAnswerValueType
  ) => {
    if (maxNumberSelect === 1) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(location, id, [checkedValue])
      );
    } else {
      if (checked) {
        if (answerVal) {
          const newAnswersArray = Array.from(answerVal);
          newAnswersArray.push(checkedValue);
          dispatch(
            questionnaireRedux.actions.addNewAnswer(
              location,
              id,
              newAnswersArray
            )
          );
        } else {
          dispatch(
            questionnaireRedux.actions.addNewAnswer(location, id, [
              checkedValue,
            ])
          );
        }
      } else {
        if (answerVal) {
          const shouldRemoveAnswer = (
            oldAnswer: NestedDropdownAnswerValueType
          ) => {
            let shouldNotRemove = false;
            for (const key of Object.keys(oldAnswer)) {
              if (oldAnswer[key] !== checkedValue[key]) {
                shouldNotRemove = true;
              }
            }
            return shouldNotRemove;
          };

          let newAnswersArray = Array.from(answerVal);
          newAnswersArray = newAnswersArray.filter((oldAnswer) =>
            shouldRemoveAnswer(oldAnswer)
          );

          if (newAnswersArray.length > 0) {
            dispatch(
              questionnaireRedux.actions.addNewAnswer(
                location,
                id,
                newAnswersArray
              )
            );
          } else {
            dispatch(
              questionnaireRedux.sliceActions.removeAnswer({
                questionId: id,
              })
            );
          }
        }
      }
    }
  };

  // if only one option is available to user (and answer is required)
  // (at root nested dropdown level - without any nested options) and no option is selected yet,
  // then auto-select the only option available
  useEffect(() => {
    if (
      options.children.length === 1 &&
      options.children[0].options === undefined &&
      answerVal === undefined &&
      answerRequired
    ) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(location, id, [
          { [options.answerId]: options.children[0].key },
        ])
      );
    }
  }, [
    answerRequired,
    answerVal,
    dispatch,
    id,
    location,
    options.answerId,
    options.children,
  ]);

  return (
    <Grid item xs>
      <ZvjsNestedDropDown
        id={id}
        title={tuiz(title)}
        answerRequired={answerRequired}
        options={options}
        renderValueText={selectedOptionsText}
        onChange={onChangeHandler}
        hintText={hintText ? tuiz(hintText) : undefined}
        hintSize={20}
        error={validationError !== undefined}
        maxNumberSelect={maxNumberSelect}
        selectedOptionsCount={selectedOptionsCount}
      />
      {/*{validationError && (*/}
      {/*  <SimpleValidationText*/}
      {/*    text={validationError.message}*/}
      {/*    flag={validationError.type}*/}
      {/*  />*/}
      {/*)}*/}
      {validationError && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationError?.type)}
          >
            {validationError.message}
          </ZvjsAlert>
        </Box>
      )}
    </Grid>
  );
};

export default NestedDropdownQuestion;
