import React, { useContext, useEffect, useSyncExternalStore } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/context/auth-context";
import { ZvjsIframe } from "../../common";
import {
  JsonApiCallWithContentHeaders,
  targetApi,
} from "../../utils/targetApi";
import { defer } from "react-router-dom";
import { withLoaderHolup } from "../../hoc/withLoaderHolup";
import { Module } from "../../utils/module";
import { LoaderError } from "../../router/LoaderError";
import { useUserData } from "../../store/context/userData-context";
import useEnvConfig from "../../store/context/useEnvConfig";
import { KEY_HOLUP_URL } from "../../store/context/envConfigContext";

const HolupUstavMap: { [key: string]: string } = {
  "40": "Bratislava",
  "41": "Prešov",
  "42": "Leopoldov",
  "43": "Želiezovce",
  "44": "Banská Bystrica",
  "45": "Košice - Šaca",
  "46": "Košice",
  "47": "Ilava",
  "51": "Žilina",
  "53": "Nitra",
  "54": "Nitra - Chrenová",
  "55": "Dubnica nad Váhom",
  "56": "Sučany",
  "57": "Ružomberok",
  "60": "Banská Bystrica - Kráľová",
  "61": "Trenčín",
  "62": "Hrnčiarovce nad Parnou",
  "63": "Levoča",
};

const LoadData = async () => {
  const param_klientId = localStorage.getItem("zakladneCislo");
  const param_ustav = HolupUstavMap[localStorage.getItem("ustavKod") ?? ""];

  if (!param_klientId) {
    throw new LoaderError("Klient nemá vyplnené základné číslo");
  }

  if (!param_ustav) {
    throw new LoaderError("Klient má neznámy ústav");
  }

  try {
    const data = await fetch(
      await targetApi(`/${Module.INTERNAL}/api/v1/encrypt`),
      {
        headers: await JsonApiCallWithContentHeaders(),
        body: JSON.stringify({
          klientId: param_klientId,
          ustav: param_ustav,
        }),
        method: "POST",
      }
    );

    if (!data.ok) {
      throw new Error("Nepodarilo sa načítať prihlasovacie údaje do Holup");
    }

    const json = await data.json();
    return {
      data: json.data,
    };
  } catch (err: any) {
    const errorMessage = err?.message ?? `Zlyhalo načítanie údajov do Holup`;
    console.error(errorMessage);
    throw new LoaderError(errorMessage);
  }
};

export const HolupLoader = async () => {
  return defer({
    data: LoadData(),
  });
};

interface HolupProps {
  data?: any | undefined;
}

const Holup = (data: HolupProps) => {
  const navigate = useNavigate();
  const { onLogout } = useContext(AuthContext);
  const { clearUserData } = useUserData();
  const { envConfig } = useEnvConfig();

  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      if (ev.data.type === "holup-kiosk" && ev.data.message === "logout") {
        clearUserData();
        onLogout();
      }
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ZvjsIframe
        header={false}
        iframeTitle={"holup"}
        id={"iframe"}
        url={`${envConfig[KEY_HOLUP_URL]}/?data=${data.data.data}`}
        onClose={() => navigate("/auth")}
      />
    </>
  );
};

export default withLoaderHolup(Holup);
