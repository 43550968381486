import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import {
  InstitutionalOrder_SC_KIO_0410,
  ZvjsButton,
  ZvjsTable,
} from "../../../common";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";

export const loadData = async () => {
  return [
    {
      name: InstitutionalOrder_SC_KIO_0410.InstitutionalOrder,
      path: InstitutionalOrder_SC_KIO_0410.InstitutionalOrder,
    },
    {
      name: InstitutionalOrder_SC_KIO_0410.DailySchedule,
      path: InstitutionalOrder_SC_KIO_0410.DailySchedule,
    },
    {
      name: InstitutionalOrder_SC_KIO_0410.ClinicOfficeHours,
      path: InstitutionalOrder_SC_KIO_0410.ClinicOfficeHours,
    },
  ];
};

export const UstavnyPoriadokLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface UstavnyPoriadokProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const UstavnyPoriadok = (props: UstavnyPoriadokProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("ústavný poriadok"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          header: [
            { value: capitalize(tui("názov dokumentu")), align: "left" },
            { value: capitalize(tui("akcia")), align: "right" },
          ],
          body:
            props.data.map((item) => {
              const rd: RowData = {
                row: [
                  {
                    value: capitalize(
                      item.name ===
                        InstitutionalOrder_SC_KIO_0410.InstitutionalOrder
                        ? capitalize(tui("ústavný poriadok"))
                        : item.name ===
                          InstitutionalOrder_SC_KIO_0410.DailySchedule
                        ? capitalize(tui("časový rozvrh dňa"))
                        : capitalize(tui("ordinačné hodiny v ambulancii"))
                    ),
                    align: "left",
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(tui("prihlasenie.detail"))}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={async () => {
                          navigate(item.path);
                        }}
                      />
                    ),
                    align: "right",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={"calc(100vh - 450px)"}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(UstavnyPoriadok);
