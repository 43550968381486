import React from "react";
import { Grid, Stack } from "@mui/material";
import { ZvjsAlert } from "../../../../../../../components";
import {
  getAlertIconOfZvjsFlag,
  getSeverityOfZvjsFlag,
  ZvjsLabelFlagTypes,
} from "../../../../../../redux/model";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_051403_NotAllowedToSendRequestLabel: React.FC<
  CustomQuestionProps
> = ({ location }) => {
  const { tuiz } = useUITranslation();

  console.debug(
    `SC_KIO_051403 NOT ALLOWED TO SEND REQUEST LABEL RERENDER ${location.join(
      "-"
    )}`
  );

  return (
    <Grid item xs>
      <Stack spacing={2}>
        {
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
              iconMapping={getAlertIconOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
            >
              {/*TODO add correct translations*/}
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOpremiestnenie.napovedaRpzhodnutieOpremiestneni"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        }
      </Stack>
    </Grid>
  );
};

export default SC_KIO_051403_NotAllowedToSendRequestLabel;
