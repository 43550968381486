import React from "react";
import { ZvjsSelect } from "../common";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { JazykoveUdaje } from "../locales/i18n";
import { ZvjsSelectItem } from "../common/components/ZvjsSelect";

function Language() {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (e: SelectChangeEvent) => {
    i18n.changeLanguage(e.target.value);
  };

  const items: ZvjsSelectItem[] = [];

  JazykoveUdaje.forEach((value, key) => {
    items.push({
      value: key,
      text: value.nazov,
      icon: {
        src: value.icon,
        alt: `${value.nazov} flag icon`,
      },
    });
  });

  return (
    <ZvjsSelect
      id={"language-select"}
      items={items}
      controlled={{
        selectedValue: i18n.resolvedLanguage ?? "",
        changeHandler: changeLanguageHandler,
      }}
      muiSelectSx={{ height: 40, minWidth: 192 }}
    />
  );
}

export default Language;
