import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";
import { LoaderError } from "../../../../../../../../router/LoaderError";
import { CisCCMStav_Evidovane_Kod } from "../../../../../../../specs/countersValues";

const BANKNOTES = "BANKNOTES";
export const BanknotesGridConstants = {
  BANKNOTES,
};

/**
 * OnInit
 */

export interface BanknoteType {
  id: string;
  value: number;
  currency: string;
}

export const SC_KIO_050201_BanknotesGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post("/api/integracie/Kiosk/ListCudziaMena", {
    body: {
      idKlienta: localStorage.getItem("klientObjectId") ?? "",
      stav: CisCCMStav_Evidovane_Kod,
    },
  });

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  const listOfBanknotes: BanknoteType[] = response.data
    .filter(
      (x) =>
        x.zamenitelnaMena === true &&
        x.blokaciaOctk === false &&
        x.cudziaMena === true &&
        x.nepovolena === false &&
        x.idPlatidla &&
        x.hodnota &&
        x.jednotkaMeny
    )
    .map((x) => {
      if (x.idPlatidla && x.hodnota && x.jednotkaMeny)
        return { id: x.idPlatidla, value: x.hodnota, currency: x.jednotkaMeny };
      // you should never end up here because of the filter check above (compilation is the reason why error throw is here)
      throw new LoaderError();
    });

  calcData[BanknotesGridConstants.BANKNOTES] = listOfBanknotes;
};

/**
 * Selectors
 */

const getBanknotesGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      banknotes: state.userCalcData[
        BanknotesGridConstants.BANKNOTES
      ] as BanknoteType[],
    };
  });

export const allSelectors = {
  getBanknotesGridDisplayData,
};
