import { useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import ZvjsTabsRouter from "../../../common/components/ZvjsTabsRouter";

export enum PeniazeAPoplatkyTabs {
  Konto = "Konto",
  KreditABlokacie = "KreditABlokacie",
  Pohladavky = "Pohladavky",
  VyplatnePasky = "VyplatnePasky",
  Ziadosti = "Ziadosti",
}

const PeniazeAPoplatky = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const [mainLabel, setMainLabel] = useState<string>(
    "osobneUdaje.kategorieOsobnychUdajov.peniazePoplatky"
  );

  const onChangeHandler = (value: number) => {
    switch (value) {
      case 0:
        setMainLabel("osobneUdaje.kategorieOsobnychUdajov.peniazePoplatky");
        break;
      case 1:
        setMainLabel("Kredit a blokácie");
        break;
      case 2:
        setMainLabel("osobneUdaje.pohladavky.pohladavky");
        break;
      case 3:
        setMainLabel("osobneUdaje.vyplatnePasky.vyplatnePasky");
        break;
      case 4:
        setMainLabel("tlacidla.ziadosti");
        break;
    }
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui(mainLabel))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabsRouter
            onChange={onChangeHandler}
            data={[
              {
                label: tui("osobneUdaje.kontoAkredit.konto"),
                route: PeniazeAPoplatkyTabs.Konto,
              },
              {
                label: tui("Kredit a blokácie"),
                route: PeniazeAPoplatkyTabs.KreditABlokacie,
              },
              {
                label: tui("osobneUdaje.pohladavky.pohladavky"),
                route: PeniazeAPoplatkyTabs.Pohladavky,
              },
              {
                label: tui("osobneUdaje.vyplatnePasky.vyplatnePasky"),
                route: PeniazeAPoplatkyTabs.VyplatnePasky,
              },
              {
                label: tui("tlacidla.ziadosti"),
                route: PeniazeAPoplatkyTabs.Ziadosti,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeniazeAPoplatky;
