import React from "react";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { Grid, Stack } from "@mui/material";
import { ZvjsAlert } from "../../../../../../../components";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../../../../../../redux/model";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_050301_NotAllowedToSendRequestLabels: React.FC<
  CustomQuestionProps
> = ({ location }) => {
  const { tuiz } = useUITranslation();

  console.debug(
    `SC_KIO_050301 NOT ALLOWED TO SEND REQUEST LABELS RERENDER ${location.join(
      "-"
    )}`
  );

  const displayClientHasEnoughMoneyOnAccount = useSelector(
    questionnaireRedux.selectors.displayClientHasEnoughMoneyOnAccount_SC_KIO_050301()
  );

  const displayClientShoppedLastCalendarMonth = useSelector(
    questionnaireRedux.selectors.displayClientShoppedLastCalendarMonth_SC_KIO_050301()
  );

  return (
    <Grid item xs>
      <Stack spacing={2}>
        {displayClientHasEnoughMoneyOnAccount && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOodoslanieKorespondencie.napovedaNizkyDisponibilnyZostatok"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}
        {displayClientShoppedLastCalendarMonth && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOodoslanieKorespondencie.napovedaNakupMinulyMesiac"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}
      </Stack>
    </Grid>
  );
};

export default SC_KIO_050301_NotAllowedToSendRequestLabels;
