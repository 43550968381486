import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ZvjsButton,
  ZvjsCard,
  ZvjsCheckBox,
  ZvjsHintModalFragment,
  ZvjsLinkTextForTranslation,
  ZvjsNestedDropDown,
  ZvjsNotification,
  ZvjsSelect,
  ZvjsTable,
  ZvjsTabs,
  ZvjsTextField,
} from "../../common";
import AddIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SendIcon from "@mui/icons-material/Send";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Psychology } from "../../assets/icons/Other/psychology.svg";
import { ReactComponent as RequestsCategories } from "../../assets/icons/Other/requests_categories.svg";
import ZvjsSearchBar, {
  ZvjsSearchBarVariant,
} from "../../common/components/ZvjsSearchBar";
import { ZvjsCheckBoxStateValues } from "../../common/components/ZvjsCheckBox";
import { delay, isElectron, isSubmitting } from "../../utils/helpers";
import {
  Params,
  useActionData,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { defer } from "react-router-dom";
import { withLoader } from "../../hoc/withLoader";
import {
  ZvjsTableVariant,
  TableDataInfinityScroll,
  RowData,
  TableData,
  TableStyle,
  RequestFontSizes,
} from "../../common/components/ZvjsTable";
import { useUITranslation } from "../../store/context/translation-context";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useModal } from "../../store/context/modal-context";
import { useSnackbar } from "../../store/context/snackbar-context";
import { ZvjsNotificationType } from "../../common/components/ZvjsNotification";
import { useOnlineStatus } from "../../store/context/onlineStatus-context";
import { LoaderError } from "../../router/LoaderError";
import { capitalize } from "@mui/material/utils";
import {
  ActionResult,
  ActionResultTypes,
  SuccessActionResult,
} from "../../router/ActionResult";

const options = {
  method: "GET",
  headers: {
    accept: "application/json",
    Authorization: "tmdbApiKey", // todo add real api key
  },
};

/**
 * Example how to use loader for infinite scroll
 * @param page in real use is not required
 */
const LoadData = async (page?: string) => {
  // await delay(5000);

  try {
    console.log(
      "-----------------------EXAMPLE LOAD START-----------------------"
    );
    const url = `https://api.themoviedb.org/3/tv/popular?language=en-US&page=${
      page === undefined ? 1 : page
    }`;
    const data = await fetch(url, options);
    if (!data.ok) {
      throw new LoaderError();
    }
    const json = await data.json();
    console.log(
      "-----------------------EXAMPLE LOAD DONE-----------------------"
    );
    return {
      test: json,
    };
  } catch (e) {
    // example how to throw exception if loader
    console.log(e, "Loader: ", "You dont have access to TMDB API");
    return {
      type: ActionResultTypes.ERROR,
      message: "You dont have access to TMDB API",
    };
  }
  // example how to throw exception if loader
  //   throw new LoaderError()
};

export const ExamplesLoader = async () => {
  return defer({
    //only for my in real use is not required
    data: LoadData("1"),
  });
};

export async function ExamplesActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<ActionResult> {
  try {
    console.log(
      "-----------------------EXAMPLE ACTION START-----------------------"
    );
    // const formData = await request.formData();
    // const username = formData.get("username");
    // console.log(username);
    const formData = await request.formData();
    const dataParam = formData.get("page")?.toString() ?? "";
    const processDataParam = JSON.parse(dataParam);
    // console.log(processDataParam);
    const url = `https://api.themoviedb.org/3/tv/popular?language=en-US&page=${processDataParam.page}`;
    const data = await fetch(url, options);
    console.log(data);
    if (!data.ok) {
      throw new LoaderError();
    }
    const json = await data.json();
    await delay(5000);
    console.log(
      "-----------------------EXAMPLE ACTION DONE-----------------------"
    );
    return { type: ActionResultTypes.SUCCESS, data: json };
    // return { type: ActionResultTypes.SUCCESS, message: "All good" };
  } catch (e) {
    console.log(e, "Action: ", "You dont have access to TMDB API");
    return {
      type: ActionResultTypes.ERROR,
      message: "You dont have access to TMDB API",
    };
  }
}

interface ExamplesPageCategoriesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseData = (data: any) => {
  const bodyData = new Array<RowData>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((element: any) => {
    const rowData: RowData = {
      row: [
        {
          value: element.id,
          align: "left",
          width: "50%",
        },
        {
          value: element.name,
          align: "left",
          width: "50%",
        },
      ],
    };
    bodyData.push(rowData);
  });
  return bodyData;
};

const ExamplesPage = (props: ExamplesPageCategoriesProps) => {
  const { t } = useTranslation();
  const [age, setAge] = React.useState("");
  const [name, setName] = React.useState("");
  const submit = useSubmit();
  const navigation = useNavigation();
  const actionData = useActionData() as SuccessActionResult | undefined;
  const { tui } = useUITranslation();
  const { isOnline } = useOnlineStatus();
  const { openSnackbar } = useSnackbar();

  // alternative to submit
  // const fetchers = useFetchers()
  // const fetcher = useFetcher()

  const changeAgeHandler = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const changeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const theme = useTheme();

  const onChangeHandler = (value: number) => {
    console.debug("tab " + value);
  };

  const onChangeCheckBox = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    console.log(
      "checked box label: " +
        label +
        ", key: " +
        key +
        ", checked " +
        checked +
        ", arr of values "
    );
    console.log(checkedValues);
  };

  const onChangeItemCheckBox = (value: boolean) => {
    console.log("checkbox item value: " + value);
  };

  const onActionClickHandler = () => {
    const formData = new FormData();
    formData.append("username", "JohnDoe");
    formData.append("email", "johndoe@example.com");
    submit(formData, { method: "POST" });
  };

  const [tableData, setTableData] = useState<TableDataInfinityScroll>({
    header: [
      {
        value: "ID",
        align: "left",
        width: "50%",
      },
      {
        value: "NAME",
        align: "left",
        width: "50%",
      },
    ],
    pageNumber: 0,
    totalPageNumber: 0,
    body: Array<RowData>(),
  });

  useEffect(() => {
    if (props.data.test) {
      const bodyData = parseData(props.data.test.results);
      setTableData({
        header: tableData.header,
        pageNumber: props.data.test.page,
        totalPageNumber: 3, //from real fetch
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    console.log(actionData);
    if (actionData?.data) {
      // get data from request to variable
      const bodyData = parseData(actionData.data.results);
      setTableData({
        header: tableData.header,
        pageNumber: actionData.data.page,
        totalPageNumber: 3, //from real fetch
        body: [...tableData.body, ...bodyData],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData]);

  const onLoadMoreHandler = (page: number, rowOnPage: number) => {
    const fomrData = new FormData();
    fomrData.append(
      "page",
      JSON.stringify({ page: page.toString(), rowOnPage: rowOnPage.toString() })
    );
    submit(fomrData, { method: "POST" });
  };

  //---------------------------------------------------------

  const { openModal } = useModal();
  const openModalFunction = () => {
    console.log("test function");
    openModal(<>Your modal body</>);
  };

  const dataStyleKategorizovatelnaTabulka: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "center",
    },
  ];

  const tableKategorizovatelnaTabulka: TableData = {
    header: [
      {
        value: capitalize(
          tui(
            "Typ finančného záväzku" // TODO: use proper KEY
          )
        ),
        ...dataStyleKategorizovatelnaTabulka[0],
      },
      {
        value: capitalize(
          tui(
            "Výška záväzku" // TODO: use proper KEY
          )
        ),
        ...dataStyleKategorizovatelnaTabulka[1],
      },
    ],
    body: [
      {
        row: [
          {
            value: "Výživné",
            ...dataStyleKategorizovatelnaTabulka[0],
          },
          {
            value: "285,00 EUR",
            ...dataStyleKategorizovatelnaTabulka[1],
          },
        ],
        rowDetail: {
          header: [
            { value: "Výživovaná osoba" },
            { value: "Výška záväzku" },
            { value: "Vaša výška úhrady (v EUR)" },
          ],
          body: [
            {
              row: [
                { value: "Marika Testerová" },
                { value: "200,00 EUR" },
                {
                  value: (
                    <Box mr={"50px"}>
                      <ZvjsTextField label={""} />
                    </Box>
                  ),
                  width: 300,
                },
              ],
            },
            {
              row: [
                { value: "Pamela Testerová" },
                { value: "45,00 EUR" },
                {
                  value: (
                    <Box mr={"50px"}>
                      <ZvjsTextField label={""} />
                    </Box>
                  ),
                  width: 300,
                },
              ],
            },
            {
              row: [
                { value: "Jarmila Testerová" },
                { value: "40,00 EUR" },
                {
                  value: (
                    <Box mr={"50px"}>
                      <ZvjsTextField label={""} />
                    </Box>
                  ),
                  width: 300,
                },
              ],
            },
          ],
        },
      },
      {
        row: [
          {
            value: "Pohľadávka v správe ústavu",
            ...dataStyleKategorizovatelnaTabulka[0],
          },
          {
            value: "78,19 EUR",
            ...dataStyleKategorizovatelnaTabulka[1],
          },
        ],
      },
      {
        row: [
          {
            value: "Pohľadávka ostatných oprávnených",
            ...dataStyleKategorizovatelnaTabulka[0],
          },
          {
            value: "35,90 EUR",
            ...dataStyleKategorizovatelnaTabulka[1],
          },
        ],
        rowDetail: {
          header: [
            { value: "Výživovaná osoba" },
            { value: "Výška záväzku" },
            { value: "Vaša výška úhrady (v EUR)" },
          ],
          body: [
            {
              row: [
                { value: "ZVJS" },
                { value: "10,90 EUR" },
                { value: "Text Input" },
              ],
            },
            {
              row: [
                { value: "GR ZVJS" },
                { value: "10,00 EUR" },
                { value: "Text Input" },
              ],
            },
            {
              row: [
                { value: "ZVJS" },
                { value: "10,00 EUR" },
                { value: "Text Input" },
              ],
            },
          ],
        },
      },
    ],
  };

  return (
    <Stack m={theme.spacing(1)} spacing={2} mb={13} marginTop={1}>
      <Box p={1}>
        <ZvjsTable
          data={tableKategorizovatelnaTabulka}
          variant={ZvjsTableVariant.NORMAL}
          fontSizes={RequestFontSizes}
        />
        <ZvjsNestedDropDown
          id={"id"}
          title={
            "Nested dropdown example - bez moznosti menit vybrate odpovede"
          }
          options={{
            answerId: "test",
            children: [
              { label: "Test1", key: "1", checked: false },
              { label: "Test2", key: "2", checked: false },
              {
                label: "Test3",
                key: "3",
                checked: false,
                options: {
                  answerId: "test2",
                  children: [
                    { label: "Test 3 1", key: "31", checked: false },
                    { label: "Test 3 2", key: "32", checked: true },
                    { label: "Test 3 3", key: "33", checked: false },
                  ],
                },
              },
              { label: "Test4", key: "4", checked: true },
            ],
          }}
          onChange={() => undefined}
          renderValueText={"Test"}
          selectedOptionsCount={0}
        />
      </Box>
      <ZvjsNotification
        type={ZvjsNotificationType.REQUEST}
        title={"Schválená žiadosť"}
        dateAndTime={"20.04.2022 - 09:41"}
        description={
          "Žiadosť o častejšie sprchovanie, podaná dňa bla bla bola schválená."
        }
      />
      <ZvjsNotification
        type={ZvjsNotificationType.BOARD}
        title={"Schválená žiadosť"}
        dateAndTime={"20.04.2022 - 09:41"}
        description={
          "Žiadosť o častejšie sprchovanie, podaná dňa bla bla bola schválená."
        }
      />
      <ZvjsNotification
        type={ZvjsNotificationType.LESSONS}
        title={"Schválená žiadosť"}
        dateAndTime={"20.04.2022 - 09:41"}
        description={
          "Žiadosť o častejšie sprchovanie, podaná dňa bla bla bola schválená."
        }
      />
      <ZvjsNotification
        type={ZvjsNotificationType.COMPLAINT}
        title={"Schválená žiadosť"}
        dateAndTime={"20.04.2022 - 09:41"}
        description={
          "Žiadosť o častejšie sprchovanie, podaná dňa bla bla bola schválená."
        }
      />
      <ZvjsNotification
        type={ZvjsNotificationType.MONEY_AND_FEES}
        title={"Schválená žiadosť"}
        dateAndTime={"20.04.2022 - 09:41"}
        description={
          "Žiadosť o častejšie sprchovanie, podaná dňa bla bla bola schválená."
        }
      />
      <ZvjsButton
        zvjsVariant={"primaryAction"}
        onClick={() => openSnackbar("toto je test", "success")}
        text={"Open snackbar"}
        style={{ width: "10rem", textTransform: "none", lineHeight: "1" }}
      />
      <Typography variant="body1">
        Connection status: <b>{isOnline ? "true" : "false"}</b>
      </Typography>
      <Stack spacing={2} direction={"row"} alignContent={"center"} m={1}>
        <Typography variant="h4">Hint: </Typography>
        <ZvjsHintModalFragment
          title={tui("nápoveda")}
          text={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaDochodok")}
          color={ZVJS_COLORS.BLUE_2}
        />
      </Stack>
      <Button
        onClick={openModalFunction}
        variant={"contained"}
        style={{ width: "10rem", textTransform: "none" }}
      >
        Open modal
      </Button>
      <ZvjsTable
        height={500}
        data={tableData}
        variant={ZvjsTableVariant.INFINITE_SCROLL}
        onLoadMore={onLoadMoreHandler}
        pageSize={20}
      />
      <ZvjsSearchBar
        title="Variant A"
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={(dateFrom, dateTo) => console.log(dateFrom, dateTo)}
      />
      <ZvjsSearchBar
        title="Variant B"
        type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
        onSubmit={(dateFrom: Date, dateTo: Date, comboboxLabel: string) =>
          console.log(dateFrom, dateTo, comboboxLabel)
        }
        comboboxLabel="Pohyb"
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        maxDate1={new Date()}
      />
      <ZvjsSearchBar
        title="Variant C"
        type={ZvjsSearchBarVariant.VARIANT_C_DpDpCb}
        onSubmit={(dateFrom, dateTo, isChecked: boolean) =>
          console.log(dateFrom, dateTo, isChecked)
        }
        checkBoxLabel="Pohyb a nieco ine"
      />
      <ZvjsSearchBar
        title="Variant D"
        type={ZvjsSearchBarVariant.VARIANT_D_CoCb}
        onSubmit={(comboboxLabel, isChecked) =>
          console.log(comboboxLabel, isChecked)
        }
        comboboxLabel="Pohyb"
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        checkBoxLabel="Pohyb a nieco ine"
      />
      <ZvjsSearchBar
        title="Variant E"
        type={ZvjsSearchBarVariant.VARIANT_E_CoCo}
        onSubmit={(comboboxLabel1: string, comboboxLabel2: string) =>
          console.log(comboboxLabel1, comboboxLabel2)
        }
        comboboxLabel="Pohyb"
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel2="Pohyb"
        items2={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />
      <ZvjsSearchBar
        title="Variant F"
        type={ZvjsSearchBarVariant.VARIANT_F_CoCoCo}
        onSubmit={(
          comboboxLabel1: string,
          comboboxLabel2: string,
          comboboxLabel3
        ) => console.log(comboboxLabel1, comboboxLabel2, comboboxLabel3)}
        comboboxLabel="Pohyb"
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel2="Pohyb"
        items2={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel3="Pohyb"
        items3={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />
      <div style={{ marginLeft: "2rem" }}>
        <Grid sx={{ border: "solid 1px" }} p={2}>
          <Button
            disabled={isSubmitting(navigation)}
            onClick={onActionClickHandler}
          >
            Demo akcia
          </Button>
        </Grid>
      </div>
      <ZvjsCheckBox
        onChange={onChangeCheckBox}
        label={"test"}
        data={[
          {
            label: "test1",
            key: "test1",
            children: [
              {
                label: "children1",
                key: "children1",
              },
              {
                label: "children2",
                key: "children2",
                children: [
                  {
                    label: "children2.1",
                    key: "children2.1",
                  },
                  {
                    label: "children2.2",
                    key: "children2.2",
                  },
                ],
              },
            ],
          },
          {
            label: "test2",
            key: "test2",
            onChange: onChangeItemCheckBox,
            checked: true,
          },
        ]}
        offset={3}
      />
      <Typography variant="subtitle1">
        <Trans
          i18nKey="test_format"
          components={[<ZvjsLinkTextForTranslation href={"/auth"} />]}
        />
      </Typography>
      <ZvjsTabs
        onChange={onChangeHandler}
        data={[
          {
            label: "toto je test",
            element: <Typography variant="h1">Toto je len test</Typography>,
          },
          {
            label: "Toto je test 2",
            element: <Typography variant="h2">Toto je len test</Typography>,
          },
          {
            label: "Toto je test 3",
            element: <Typography variant="h3">Toto je len test</Typography>,
          },
          {
            label: "Toto je test 4",
            element: <Typography variant="h4">Toto je len test</Typography>,
          },
        ]}
      />
      <Grid display={"flex"} justifyContent={"space-evenly"}>
        <ZvjsButton
          zvjsVariant={"primaryAction"}
          text={"Zväčšiť písmo"}
          startIcon={<AddIcon />}
        />
        <ZvjsButton
          zvjsVariant={"secondaryAction"}
          text={"Podať žiadosť"}
          endIcon={<SendIcon />}
          onClick={() => {
            // fetch(targetApi(`/${Module.INTERNAL}/api/v1/Order`), {
            //   headers: {
            //     "X-API-Key": "KioskZVJSApiKey",
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            //   },
            //   method: "POST",
            //   body: JSON.stringify({
            //     name: name,
            //     age: age,
            //   }),
            // })
            //   .then((res: Response) => {
            //     res
            //       .json()
            //       .then((data) =>
            //         console.debug(`Data: ${JSON.stringify(data)}`)
            //       );
            //   })
            //   .catch((error: Error) => {
            //     console.debug(`Error: ${error}`);
            //   });
          }}
        />
        <ZvjsButton
          zvjsVariant={"backAction"}
          text={"Späť"}
          startIcon={<KeyboardArrowLeftIcon />}
        />
        <ZvjsButton
          zvjsVariant={"cancelAction"}
          text={"Zrušiť žiadosť"}
          startIcon={<ClearIcon />}
        />
      </Grid>
      <Grid sx={{ ml: "10px", mr: "10px" }}>
        <ZvjsTextField
          id="TextField-example"
          label="Priklad Textfield"
          value={name}
          onChange={changeNameHandler}
        />
        <ZvjsSelect
          id={"age-select"}
          text={"Priklad Dropdownu"}
          items={[
            { value: 3, text: "Tri" },
            { value: 5, text: "Pat" },
            { value: 10, text: "Desat" },
          ]}
          controlled={{ selectedValue: age, changeHandler: changeAgeHandler }}
        />
      </Grid>
      <Paper
        variant={"info"}
        sx={{
          width: 250,
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Hello World Info Paper <AddIcon />
      </Paper>
      <Paper
        variant={"active"}
        sx={{
          width: 250,
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Hello World Active Paper <AddIcon />
      </Paper>
      <Card>
        <CardActionArea>
          Hello World Card <AddIcon />
        </CardActionArea>
      </Card>
      <ZvjsCard
        title={"Psychologické, sociálne a duchovné služby"}
        icon={Psychology}
        sx={{
          width: 298,
          height: 110,
        }}
      />
      <ZvjsCard
        title={"Kategórie žiadostí"}
        description={
          "Podanie žiadanky napríklad o telefonovanie, návštevu, ostrihanie a iné."
        }
        icon={RequestsCategories}
        sx={{
          width: 460,
          height: 265,
        }}
      />
      <Typography variant={"h2"}>{t("example")}</Typography>
      <p>{t("item_example", { count: 0 })}</p>
      <p>{t("item_example", { count: 1 })}</p>
      <p>{t("item_example", { count: 2 })}</p>
      <p>{t("item_example", { count: 5 })}</p>
      <p>0 - priznak kluca: {t("key", { count: 0 })}</p>
      <p>1 - priznak kluca: {t("key", { count: 1 })}</p>
      <p>2 - priznak kluca: {t("key", { count: 2 })}</p>
      <Typography variant={"body1"}>
        5 - priznak kluca: {t("key", { count: 5 })}
      </Typography>
      <Typography variant={"h1"}>Fonts:</Typography>
      <Typography variant={"h1"}>H1 Text example</Typography>
      <Typography variant={"h2"}>Aplikácia Kiosk (H2)</Typography>
      <Typography variant={"h3"}>Moje žiadosti (H3)</Typography>
      <Typography variant={"h4"}>Ústavný poriadok (H4)</Typography>
      <Typography variant={"h5"}>H5 Text example</Typography>
      <Typography variant={"h6"}>H6 Text example</Typography>
      <Typography variant={"subtitle1"}>
        Konkrétne dokumenty z nástenky si vyberte cez jednotlivé kategórie
        nižšie. (Subtitle 1)
      </Typography>
      <Typography variant={"subtitle2"}>Subtitle 2 Text example</Typography>
      <Typography variant={"body1"}>
        Žiadosť o poskytnutie potrieb osobnej hygieny (Body 1)
      </Typography>
      <Typography variant={"body2"}>Body 2 Text example</Typography>
      <Typography variant={"caption"}>Caption Text example</Typography>
      <Typography>Default Typography Text example</Typography>
      Default Text example
      <Divider />
      {isElectron() ? (
        <>
          "Si v electrone"
          <ZvjsButton
            zvjsVariant={"primaryAction"}
            onClick={() => {
              const { ipcRenderer } = window.require("electron");
              ipcRenderer.send("showDialog", "Alert dialog test");
            }}
            text={"Show alert dialog"}
          />
        </>
      ) : (
        "Nie si v electrone"
      )}
    </Stack>
  );
};

export default withLoader(ExamplesPage);
