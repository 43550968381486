import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import {
  selectSelf,
  selectUserCalcData,
} from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { isEmptyString } from "../../../../../../../../utils/helpers";

const REGISTERED_CHILDREN = "REGISTERED_CHILDREN";
const SHIPPING_OPTIONS_COUNTER = "SHIPPING_OPTIONS_COUNTER";
export const RegisteredChildrenGridConstants = {
  REGISTERED_CHILDREN,
  SHIPPING_OPTIONS_COUNTER,
};

/**
 * OnInit
 */

export interface RegisteredChild {
  id: string;
  name?: string;
  surname?: string;
  dateOfBirth?: string;
  registeredBy?: string;
  ibanInSlovakia?: string;
  custodyPersonAddress?: {
    state?: string;
    region?: string;
    district?: string;
    municipality?: string;
    zip?: string;
    registerNumber?: string;
    orientationNumber?: string;
    street?: string;
  };
  custodyPersonForeignAccount?: {
    iban?: string;
    swift?: string;
    bankName?: string;
    bankAddress?: string;
    accountCurrency?: string;
    nameSurnameAddressOfAccountHolder?: string;
  };
}

export interface RegisteredChildGrid extends RegisteredChild {
  isRegistered: boolean;
  addressInForeign: boolean;
  completeAddress: boolean;
  hasBankAccount: boolean;
}

export interface ShippingOptionsCounter {
  value: string;
  text: string;
}

export const SC_KIO_050206_RegisteredChildrenGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const shippingOptionsCounter: ShippingOptionsCounter[] = [
    { value: "E_KIO_23_01", text: "Na účet v banke" },
    { value: "E_KIO_23_02", text: "Poštová poukážka" },
  ];
  calcData[RegisteredChildrenGridConstants.SHIPPING_OPTIONS_COUNTER] =
    shippingOptionsCounter;

  // TODO replace with actual fetch - filter out activities which client cannot attend
  const listOfRegisteredChildren: RegisteredChild[] = [
    {
      id: "1",
      name: "Jana",
      surname: "Nováková",
      dateOfBirth: "2005-08-15",
      ibanInSlovakia: "SK1234567890",
      custodyPersonAddress: {
        state: "Bratislavský kraj",
        region: "Bratislava",
        district: "Bratislava I",
        municipality: "Bratislava",
        zip: "81101",
        registerNumber: "R12345",
        orientationNumber: "O6789",
        street: "Hlavná ulica 123",
      },
    },
    {
      id: "2",
      name: "Marek",
      surname: "Šimko",
      dateOfBirth: "2008-11-20",
      registeredBy: "Exekúcia",
      custodyPersonAddress: {
        state: "Trnavský kraj",
        region: "Trnava",
        district: "Trnava",
        municipality: "Trnava",
        zip: "91701",
        registerNumber: "R54321",
        orientationNumber: "O9876",
        street: "Dubová ulica 456",
      },
    },
    {
      id: "3",
      name: "Lukáš",
      dateOfBirth: "2010-07-08",
      custodyPersonAddress: {
        state: "Banskobystrický kraj",
        region: "Banská Bystrica",
        district: "Banská Bystrica",
        municipality: "Banská Bystrica",
        zip: "97501",
        registerNumber: "R67890",
        street: "Horská ulica 789",
      },
      custodyPersonForeignAccount: {
        iban: "DE9876543210",
        swift: "DEUTDEFF",
        bankName: "Deutsche Bank",
        bankAddress: "Berlin",
        accountCurrency: "EUR",
        nameSurnameAddressOfAccountHolder:
          "Lukáš Horák, Horská ulica 789, Banská Bystrica",
      },
    },
  ];

  calcData[RegisteredChildrenGridConstants.REGISTERED_CHILDREN] =
    listOfRegisteredChildren.map(
      (child: RegisteredChild): RegisteredChildGrid => {
        return {
          id: child.id,
          name: child.name,
          surname: child.surname,
          dateOfBirth: child.dateOfBirth,
          registeredBy: child.registeredBy,
          ibanInSlovakia: child.ibanInSlovakia,
          custodyPersonAddress: child.custodyPersonAddress,
          custodyPersonForeignAccount: child.custodyPersonForeignAccount,
          // TODO replace with real logic
          addressInForeign: false,
          isRegistered: child.registeredBy !== undefined,
          completeAddress: true,
          hasBankAccount: false,
        };
      }
    );
};

/**
 * Selectors
 */

const getRegisteredChildrenGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      registeredChildren: state.userCalcData[
        RegisteredChildrenGridConstants.REGISTERED_CHILDREN
      ] as RegisteredChildGrid[],
    };
  });

const getShippingOptionsCounter = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): ShippingOptionsCounter[] => {
      return state[RegisteredChildrenGridConstants.SHIPPING_OPTIONS_COUNTER];
    }
  );

const getAnswer = (questionId: string) =>
  createSelector(
    selectSelf,
    (
      state: State
    ): {
      answer?: SC_KIO_050206_RegisteredChildrenGrid_answerType;
      selectedChild?: RegisteredChildGrid;
    } => {
      const selectedChildId = (
        state.answers[questionId] as
          | SC_KIO_050206_RegisteredChildrenGrid_answerType
          | undefined
      )?.childId;
      // find selected child if child was selected
      if (selectedChildId !== undefined) {
        const selectedChild = getChildWithId(
          state.userCalcData[
            RegisteredChildrenGridConstants.REGISTERED_CHILDREN
          ] as RegisteredChildGrid[],
          selectedChildId
        );

        if (selectedChild) {
          return {
            answer: state.answers[questionId] as
              | SC_KIO_050206_RegisteredChildrenGrid_answerType
              | undefined,
            selectedChild: selectedChild,
          };
        }
      }

      return {
        answer: state.answers[questionId] as
          | SC_KIO_050206_RegisteredChildrenGrid_answerType
          | undefined,
      };
    }
  );

export const allSelectors = {
  getRegisteredChildrenGridDisplayData,
  getShippingOptionsCounter,
  getAnswer,
};

/**
 * IsAnswered
 */

export const SC_KIO_050206_RegisteredChildrenGrid_isAnswered = (
  userCalcData: UserCalcData,
  answer?: SC_KIO_050206_RegisteredChildrenGrid_answerType
): boolean => {
  if (
    !isEmptyString(answer?.childId) &&
    !isEmptyString(answer?.shippingOption)
  ) {
    if (answer !== undefined && answer?.childId !== undefined) {
      const selectedChild = getChildWithId(
        userCalcData[
          RegisteredChildrenGridConstants.REGISTERED_CHILDREN
        ] as RegisteredChildGrid[],
        answer.childId
      );

      if (selectedChild) {
        if (
          // TODO replace with real counter code
          answer?.shippingOption === "E_KIO_23_01" &&
          !selectedChild.hasBankAccount
        ) {
          return false;
        }
        if (
          // TODO replace with real counter code
          answer?.shippingOption === "E_KIO_23_02" &&
          !selectedChild.completeAddress
        ) {
          return false;
        }
      }
    }

    return true;
  }
  return false;
};

/**
 * Model
 */
export interface SC_KIO_050206_RegisteredChildrenGrid_answerType {
  childId?: string;
  shippingOption?: string;
}

/**
 * Helpers
 */
export const getChildWithId = (
  children: RegisteredChildGrid[],
  childId: string
): RegisteredChildGrid | undefined => {
  for (const child of children) {
    if (child.id === childId) {
      return child;
    }
  }
};
