import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/Data";
import { SC_KIO_050501_ElectricDevice } from "../../../../utils/calculations/custom";

/**
 * Selectors
 */

const getDevicesRevisionControlGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      electricDevices: state.userCalcData[
        "SC_KIO_050501_ALL_USER_ELECTRIC_DEVICES_LIST"
      ] as SC_KIO_050501_ElectricDevice[],
      electricDevicesCounter: state.counters[
        "/api/CisDruhElektrospotrebica/List"
      ] as CiselnikListResultType<"/api/CisDruhElektrospotrebica/List">["data"],
    };
  });

export const allSelectors = {
  getDevicesRevisionControlGridDisplayData,
};

/**
 * Model
 */
export interface SC_KIO_050501_DevicesRevisionControlGrid_answerType {
  deviceId: number;
  deviceTypeCode: string;
}

/**
 * CustomSubmitAnswerData
 */
export const SC_KIO_050501_DevicesRevisionControlGrid_getCustomSubmitAnswerData =
  (
    answer: SC_KIO_050501_DevicesRevisionControlGrid_answerType,
    questionId: string
  ): {
    [key: string]: string | number;
  } => {
    return {
      [questionId]: answer.deviceId,
      druhElektrickehoSpotrebica: answer.deviceTypeCode,
    };
  };
