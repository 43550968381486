import React, { useCallback, useContext } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Fade } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import { ActionResultTypes } from "../../router/ActionResult";

type SnackbarContextType = {
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarTransition: React.ComponentType<
    TransitionProps & {
      children: React.ReactElement;
    }
  >;
  snackbarFlag: ActionResultTypes | "error" | "success" | "info" | "warning";
  openSnackbar: (
    message: string,
    flag: ActionResultTypes | "error" | "success" | "info" | "warning"
  ) => void;
  closeSnackbar: () => void;
};
const SnackbarContext = React.createContext<SnackbarContextType | null>(null);

export const useSnackbar = () =>
  useContext(SnackbarContext) as SnackbarContextType;

type SnackbarContextInterface = {
  children: React.ReactNode;
};

export const SnackbarContextProvider: React.FC<SnackbarContextInterface> = ({
  children,
}) => {
  const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="up" children={props.children} />;
  };

  const [snackbarData, setSnackbarData] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement;
      }
    >;
    message: string;
    flag: ActionResultTypes | "error" | "success" | "info" | "warning";
  }>({
    open: false,
    Transition: Fade,
    message: "",
    flag: "success",
  });

  const openSnackbar = useCallback(
    (
      message: string,
      flag: ActionResultTypes | "error" | "success" | "info" | "warning"
    ) => {
      setSnackbarData({
        open: true,
        Transition: SlideTransition,
        message: message,
        flag: flag,
      });
    },
    []
  );

  const closeSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbarOpen: snackbarData.open,
        snackbarMessage: snackbarData.message,
        snackbarFlag: snackbarData.flag,
        snackbarTransition: snackbarData.Transition,
        openSnackbar,
        closeSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
