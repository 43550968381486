import { Grid, Typography } from "@mui/material";
import { Dashboard_SC_KIO_0200, ZvjsCard } from "../../common";
import { useEffect, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as RequestsCategoriesIcon } from "../../assets/icons/Other/requests_categories.svg";
import { ReactComponent as HolupIcon } from "../../assets/icons/Other/holup.svg";
import { ReactComponent as MyDataIcon } from "../../assets/icons/Other/my_data.svg";
import { ReactComponent as NoticeBoardIcon } from "../../assets/icons/Other/notice_board.svg";
import { ReactComponent as SafeInternetIcon } from "../../assets/icons/Other/safe_internet.svg";
import { useNavigate } from "react-router-dom";
import { isKiosk } from "../../store/context/envConfigContext";
import { useUserData } from "../../store/context/userData-context";

const Dashboard = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { meno } = useUserData();
  const [windowHeight, setWindowHeight] = useState<string>(
    window.innerWidth <= 920 ? "22em" : "18em"
  );
  const [smGrid, setSmGrid] = useState<number>(6);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 920 && window.innerWidth > 770) {
        setWindowHeight("22em");
        setSmGrid(6);
        return;
      } else if (window.innerWidth <= 770) {
        setSmGrid(12);
        return;
      } else if (window.innerWidth > 920) {
        setWindowHeight("18em");
        setSmGrid(6);
        return;
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [smGrid, windowHeight]);

  return (
    <Grid p={5} pt={2} mb={12}>
      <Grid mb={3}>
        <div>
          <Typography variant={"h1"}>
            {capitalize(tui("dashboard.dobryDen"))}
            {", "}
            {meno}
          </Typography>
          <Typography variant={"subtitle1"} sx={{ lineHeight: 1.2 }}>
            {tui("dashboard.uvodnyClaim")}
          </Typography>
        </div>
      </Grid>
      <Typography variant={"h2"} mb={3}>
        {capitalize(tui("dashboard.ponukaSluzieb"))}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={smGrid} md={smGrid} lg={6}>
          <ZvjsCard
            title={capitalize(tui("tlacidla.ziadosti"))}
            description={tui(
              "Podanie novej žiadanky, prehľad už podaných žiadaniek."
            )}
            icon={RequestsCategoriesIcon}
            sx={{ height: windowHeight }}
            onClick={() => {
              navigate(Dashboard_SC_KIO_0200.Requests);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={smGrid} md={smGrid} lg={6}>
          <ZvjsCard
            title={capitalize(tui("dashboard.mojeUdaje"))}
            description={tui(
              "Moje osobné údaje, údaje o vlastných peňažných prostriedkoch a ďalšie údaje o mojej osobe. Prístup k poučeniam, údajom z konta iné."
            )}
            icon={MyDataIcon}
            sx={{ height: windowHeight }}
            onClick={() => {
              navigate(Dashboard_SC_KIO_0200.MyData);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={smGrid} md={smGrid} lg={6}>
          <ZvjsCard
            title={capitalize(tui("dashboard.nastenka"))}
            description={tui(
              "Informácie o ústavnom poriadku, aktivitách, jednálnom listku a ďalšie informácie."
            )}
            icon={NoticeBoardIcon}
            sx={{ height: windowHeight }}
            onClick={() => {
              navigate(Dashboard_SC_KIO_0200.NoticeBoard);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={smGrid} md={smGrid} lg={6}>
          <ZvjsCard
            title={capitalize(tui("dashboard.bezpecnyInternet"))}
            description={tui(
              "Obmedzený prístup k zákonom a informáciám dostupným na webových stránkach štátnej správy."
            )}
            icon={SafeInternetIcon}
            sx={{ height: windowHeight }}
            onClick={() => {
              navigate(Dashboard_SC_KIO_0200.SafeInternet);
            }}
          />
        </Grid>
        {isKiosk() &&
          (localStorage.getItem("vystupnyOddiel") ?? "") === "true" && (
            <Grid item xs={12} sm={smGrid} md={smGrid} lg={6}>
              <ZvjsCard
                title={capitalize(tui("dashboard.holup"))}
                description={tui(
                  "Aplikácia pre prístp k projektu Šanca na návrat."
                )}
                icon={HolupIcon}
                sx={{ height: windowHeight }}
                onClick={() => {
                  navigate(Dashboard_SC_KIO_0200.Holup);
                }}
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
