import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { PersonalHygieneItem } from "../../../../utils/calculations/custom";

/**
 * Selectors
 */

const getPersonalHygieneNeedsGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      personalHygieneList: state.userCalcData[
        "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
      ] as PersonalHygieneItem[],
      // clientAvailableBalance: state.userCalcData["AVAILABLE_ACCOUNT_AMOUNT"],
    };
  });

export const allSelectors = {
  getPersonalHygieneNeedsGridDisplayData,
};

/**
 * IsAnswered
 */

// export const SC_KIO_051003_PersonalHygieneNeedsGrid_isAnswered = (
//   userCalcData: UserCalcData,
//   answer?: string[]
// ): boolean => {
//   if (Array.isArray(answer) && answer.length > 0) {
//     return !SC_KIO_051003_isSelectedItemsPriceGreaterAsAvailableBalance(
//       userCalcData[
//         "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
//       ] as PersonalHygieneItem[],
//       userCalcData["AVAILABLE_ACCOUNT_AMOUNT"],
//       answer
//     );
//   }
//
//   return false;
// };

/**
 * Helpers
 */
export const SC_KIO_051003_isSelectedItemsPriceGreaterAsAvailableBalance = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  personalHygieneList: any,
  availableBalance: number,
  answers: string[]
): boolean => {
  return (
    SC_KIO_051003_getRequestedItemsTotalEstimatedPrice(
      personalHygieneList,
      answers
    ) > availableBalance
  );
};

export const SC_KIO_051003_getRequestedItemsTotalEstimatedPrice = (
  personalHygieneList: PersonalHygieneItem[],
  answers: string[]
) => {
  let totalPrice = 0;
  if (Array.isArray(answers)) {
    for (const answer of answers) {
      for (const hygieneItem of personalHygieneList) {
        if (hygieneItem.typeCode === answer) {
          totalPrice += hygieneItem.estimatedPrice;
        }
      }
    }
  }

  return totalPrice;
};
