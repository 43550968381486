import react from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { Noticeboard_SC_KIO_0400 } from "../../../common/specs/sections/Noticeboard_SC_KIO_0400";
import { ZvjsTable } from "../../../common";
import { API_Clients } from "../../../store/context/dataApi/Data";
import moment from "moment";
import { LoaderError } from "../../../router/LoaderError";

const loadData = async () => {
  const { ZVJS_MP_Post, HASURA_Get } = await API_Clients();

  const cisPrevadzky = await HASURA_Get("/api/rest/getcisprevadzky");
  const prevadzky = cisPrevadzky.data?.cisPrevadzky?.filter(
    (item) => item.ustavId === Number(localStorage.getItem("klientUstavId"))
  );

  if (!prevadzky || prevadzky.length === 0) {
    throw new LoaderError();
  }

  const jedalneListky = await ZVJS_MP_Post("/jedalnyListok/list", {
    body: {
      datumOd: moment().format("YYYY-MM-DD"),
      datumDo: moment().add(1, "days").format("YYYY-MM-DD"),
      idPrevadzkaList: prevadzky.map((prevadzka) => prevadzka.id ?? 0),
      stavSchvalovania: "SCHVALENY",
      typJedalnehoListka: "VK - jedálny lístok",
      sort: [{ attribute: "datum", sortDirection: "ASC" }],
    },
  });

  return {
    prevadzky: prevadzky,
    jedalneListky: jedalneListky,
  };
};

export const StravovanieLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface StravovanieProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Stravovanie = (props: StravovanieProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("stravovanie"))}
          </Typography>
        </Stack>
      </Grid>

      <Stack spacing={2}>
        {props.data.jedalneListky.data?.jedalnyListokList
          ?.sort(
            (jl1, jl2) =>
              new Date(jl1.datum ?? "").valueOf() -
              new Date(jl2.datum ?? "").valueOf()
          )
          .filter(
            (jl) =>
              // OFFLINE filtrovanie
              // offline response ma vsetky jedalne listky (7 dni)
              // do 16. hodiny zobrazujeme len dnesny
              // po 16. hodine zobrazujeme dnesny a zajtrajsi
              moment(jl.datum).add(1, "seconds") > moment().startOf("day") &&
              (moment().hour() < 17
                ? moment(jl.datum) < moment().endOf("day")
                : moment(jl.datum) < moment().add(1, "days").endOf("day"))
          )
          ?.map((jedalnyListok) => (
            <>
              <Typography variant={"h3"}>
                {
                  props.data.prevadzky.find(
                    (prevadzka) => prevadzka.id === jedalnyListok.idPrevadzka
                  )?.nazov
                }
              </Typography>
              <ZvjsTable
                data={{
                  label: new Date(jedalnyListok.datum ?? "").zvjsToString(),
                  header: [{ value: "Druh", width: 120 }, { value: "Jedlo" }],
                  body:
                    jedalnyListok.denneJedla?.map((denneJedlo) => {
                      const rd: RowData = {
                        row: [
                          {
                            value: denneJedlo.udalostNazov ?? "",
                            width: 120,
                          },
                          {
                            value: (
                              <>
                                {denneJedlo.ponukyStravnejDavky?.map(
                                  (ponukaStravnejDavky) => {
                                    return (
                                      <>
                                        <Typography
                                          variant="h5"
                                          display={"inline"}
                                        >
                                          {ponukaStravnejDavky.typStravnejDavky}
                                        </Typography>
                                        <>
                                          <>
                                            <Typography
                                              variant={"body1"}
                                              display={"inline"}
                                            >
                                              {" - "}
                                              {ponukaStravnejDavky.ponuky
                                                ?.sort(
                                                  (p1, p2) =>
                                                    (p1.cisloPonuky ?? 0) -
                                                    (p2.cisloPonuky ?? 0)
                                                )
                                                .map((ponuka) => {
                                                  return ponuka.jedlo;
                                                })
                                                .join(", ")}
                                            </Typography>
                                          </>
                                          <br />
                                        </>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ),
                          },
                        ],
                      };
                      return rd;
                    }) ?? [],
                }}
                height={360}
                variant={ZvjsTableVariant.NORMAL}
              />
            </>
          ))}
      </Stack>
    </Grid>
  );
};

export default withLoader(Stravovanie);
