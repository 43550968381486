import React from "react";
import { ZvjsTextField } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { AppDispatch } from "../../../../../../store/redux";
import { Grid } from "@mui/material";
import { useUITranslation } from "../../../../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";

interface TextQuestionProps {
  location: number[];
}

const TextQuestion: React.FC<TextQuestionProps> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.debug(`TEXT QUESTION RERENDER ${location.join("-")}`);

  const textQuestionData = useSelector(
    questionnaireRedux.selectors.getTextQuestionDisplayData(location)
  );
  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(textQuestionData.id)
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(textQuestionData.id)
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        textQuestionData.id,
        event.target.value as string
      )
    );
  };

  return (
    <Grid container>
      {/*<Grid item md={textQuestionData.inputType === "number" ? 6 : 8} sm={12}>*/}
      <ZvjsTextField
        id={`text-question-${location.join("-")}`}
        label={capitalize(tuiz(textQuestionData.title))}
        value={answerVal ?? ""}
        onChange={onChangeHandler}
        type={textQuestionData.inputType}
        multiline={textQuestionData.multiline}
        minRows={textQuestionData.minRows}
        hintText={
          textQuestionData.hintText
            ? tuiz(textQuestionData.hintText)
            : undefined
        }
        hintSize={20}
        answerRequired={textQuestionData.isRequired}
        error={validationError !== undefined}
        validationErrorMessage={tuiz(validationError?.message ?? "")}
        validationFlagType={validationError?.type}
      />
      {/*{validationError && (*/}
      {/*  <SimpleValidationText*/}
      {/*    text={validationError.message}*/}
      {/*    flag={validationError.type}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{validationError && (*/}
      {/*  <Grid item xs mt={1}>*/}
      {/*    <ZvjsAlert*/}
      {/*      severity={getSeverityOfValidationFlag(validationError?.type)}*/}
      {/*    >*/}
      {/*      {validationError.message}*/}
      {/*    </ZvjsAlert>*/}
      {/*  </Grid>*/}
      {/*)}*/}
    </Grid>
  );
};

export default TextQuestion;
