import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import questionnaireRedux from "../../../../../../redux";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import {
  RequestEditIcon,
  ZvjsButton,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  SC_KIO_050202_EditClosePersonForm as EditClosePersonForm,
  SC_KIO_050202_AddClosePersonForm as AddClosePersonForm,
} from "../../../forms/index";
import { useModal } from "../../../../../../../../store/context/modal-context";
import { useSubmit } from "react-router-dom";
import { ActionBodyWrapper } from "../../../../../../../../router/ActionBodyWrapper";
import { RequestActionsType } from "../../../../../../Request";
import { Radio } from "@mui/material";

const SC_KIO_050202_ClosePeopleGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { openModal } = useModal();
  const { tuiz } = useUITranslation();
  const submit = useSubmit();

  console.debug(
    `SC_KIO_050202 CLOSE PEOPLE GRID QUESTION RERENDER ${location.join("-")}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getClosePeopleGridDisplayData_SC_KIO_050202(
      location
    ),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | number;

  const basicClientData = useSelector(
    questionnaireRedux.selectors.getClosePeopleGridBasicClientData_SC_KIO_050202(),
    shallowEqual
  );

  const closePersonRelationCounter = useSelector(
    questionnaireRedux.selectors.getClosePersonRelationTypeCounter_SC_KIO_050202(),
    shallowEqual
  );

  const onRadioChangeHandler = (personId: number): void => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        questionData.id,
        personId
      )
    );
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
    {
      align: "right",
      sx: {
        display: "grid",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tuiz("priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOvydanieCeniny.labelCisloDokladu")
      ),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOvydanieCeniny.labelOdovzdatOsobe")
      ),
      ...dataStyle[3],
    },
    {
      value: "",
      ...dataStyle[4],
    },
  ];

  const onEditClosePersonSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050202_EDIT_CLOSE_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const personDisplayData of questionData.peopleList) {
    bodyData.push({
      row: [
        {
          value: personDisplayData.fyzickaOsoba?.meno ?? "",
          ...dataStyle[0],
        },
        {
          value: personDisplayData.fyzickaOsoba?.priezvisko ?? "",
          ...dataStyle[1],
        },
        {
          value: personDisplayData.fyzickaOsoba?.cisloObcianskehoPreukazu ?? "",
          ...dataStyle[2],
        },
        {
          value: (
            <div
              onClick={() => onRadioChangeHandler(personDisplayData.id ?? -1)}
            >
              <Radio
                value={personDisplayData.id}
                checked={personDisplayData.id === answerVal}
              />
            </div>
          ),
          ...dataStyle[3],
        },
        {
          value: (
            <RequestEditIcon
              onClick={(event) =>
                openModal(
                  <EditClosePersonForm
                    closePersonData={personDisplayData}
                    // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
                    onSubmit={onEditClosePersonSubmit}
                    basicClientData={basicClientData}
                  />
                )
              }
            />
          ),
          ...dataStyle[4],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  const onSubmit = (toSubmit: unknown) => {
    const submitBody: ActionBodyWrapper = {
      action: RequestActionsType.SC_KIO_050202_ADD_NEW_CLOSE_PERSON,
      data: toSubmit,
    };
    submit(JSON.parse(JSON.stringify(submitBody)), {
      method: "POST",
      encType: "application/json",
    });
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      <ZvjsButton
        zvjsVariant={"requestAction"}
        text={tuiz("ziadost.ziadostOvydanieCeniny.labelPridatInuOsobu")}
        onClick={() => {
          openModal(
            <AddClosePersonForm
              // On submit method cannot be defined directly inside dialog, as dialog itself is mounted in the root of application (via context) which means that router action (registered to specific route) would not catch this submit action
              onSubmit={onSubmit}
              basicClientData={basicClientData}
              closePersonRelationCounter={closePersonRelationCounter}
            />
          );
        }}
        endIcon={
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />
        }
      />
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050202_ClosePeopleGrid;
