import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050402",
  basicInfo: [
    {
      text: "ziadost.ziadostOpovolenieZdravPomocky.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050402_isAvailableToUser",
  items: [
    {
      id: "zdravotnickaPomocka",
      title: "ziadost.ziadostOpovolenieZdravPomocky.labelZdravotnickePomocky",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "1",
          label: "Dioptrické okuliare",
        },
        {
          id: "2",
          label: "Ortopedické vložky",
        },
        {
          id: "3",
          label: "Ortopedické topánky",
        },
        {
          id: "4",
          label: "Barle",
        },
        {
          id: "5",
          label: "Dýchací prístroj",
        },
        {
          id: "6",
          label: "Diabetické pero",
        },
        {
          id: "7",
          label: "Kardio prístroj",
        },
        {
          id: "8",
          label: "Inhalačný sprej",
        },
        {
          id: "9",
          label: "Iné",
        },
      ],
    },
    {
      id: "dovodPreIne",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "zdravotnickaPomocka",
          value: ["E_KIO_03_09"],
        },
      ],
    },
  ],
};
