import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./locales/i18n";
import { Provider } from "react-redux";
import store from "./store/redux";
import { AuthContextProvider } from "./store/context/auth-context";
import { OnlineStatusProvider } from "./store/context/onlineStatus-context";
import { TranslationProvider } from "./store/context/translation-context";
import { ModalContextProvider } from "./store/context/modal-context";
import { SnackbarContextProvider } from "./store/context/snackbar-context";
import { AuditLogsProvider } from "./store/context/auditLogs-context";
import { CssBaseline, ThemeProvider } from "@mui/material";
import zvjs_theme from "./theme/zvjs_theme";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router/Router";
import { isElectron } from "./utils/helpers";
import { EnvConfigContextProvider } from "./store/context/envConfigContext";
import AppConnectivityGuard from "./utils/AppConnectivityGuard";
import ZvjsSnackbar from "./common/components/ZvjsSnackbar";
import { UserDataProvider } from "./store/context/userData-context";
import { Workbox } from "workbox-window";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <EnvConfigContextProvider>
        <OnlineStatusProvider>
          <AuthContextProvider>
            <UserDataProvider>
              <ModalContextProvider>
                <SnackbarContextProvider>
                  <AuditLogsProvider>
                    <TranslationProvider>
                      <ThemeProvider theme={zvjs_theme}>
                        <CssBaseline enableColorScheme />
                        <AppConnectivityGuard>
                          <RouterProvider router={Router} />
                        </AppConnectivityGuard>
                      </ThemeProvider>
                    </TranslationProvider>
                    <ZvjsSnackbar />
                  </AuditLogsProvider>
                </SnackbarContextProvider>
              </ModalContextProvider>
            </UserDataProvider>
          </AuthContextProvider>
        </OnlineStatusProvider>
      </EnvConfigContextProvider>
    </Provider>
  </React.StrictMode>
);

// see https://developer.chrome.com/docs/workbox/modules/workbox-window
if ("serviceWorker" in navigator) {
  const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);

  wb.addEventListener("waiting", (event) => {
    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control.
    wb.addEventListener("controlling", () => {
      // At this point, reloading will ensure that the current
      // tab is loaded under the control of the new service worker.
      // Depending on your web app, you may want to auto-save or
      // persist transient state before triggering the reload.
      window.location.reload();
    });

    // When `event.wasWaitingBeforeRegister` is true, a previously
    // updated service worker is still waiting.
    // You may want to customize the UI prompt accordingly.
    if (isElectron()) {
      // if you are running inside electron let electron to display alert dialog (to avoid bug of not working input fields when using alert dialog directly in react)
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("showDialog", "Je dostupná aktualizácia aplikácie");
    } else {
      alert("Je dostupná aktualizácia aplikácie");
    }

    const updateAccepted = true;

    if (updateAccepted) {
      wb.messageSkipWaiting();
    }
  });

  wb.register();

  const swVersion = await wb.messageSW({ type: "GET_VERSION" });
  console.log("Service Worker version:", swVersion);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
