import { useContext, useEffect, useState } from "react";
import {
  Box,
  capitalize,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ZVJS_COLORS } from "../theme/zvjs_theme";
import { getUuid } from "../utils/helpers";
import TableCell from "@mui/material/TableCell";
import AppActivityGuard from "../utils/AppActivityGuard";
import { ZvjsButton, ZvjsModal, ZvjsPullToRefresh } from "../common";
import { API_Clients } from "../store/context/dataApi/Data";
import { SynchronizationResultType } from "../store/context/useSynchronization";
import SynchronizationReport from "./SynchronizationReport";
import { useModal } from "../store/context/modal-context";
import { useUITranslation } from "../store/context/translation-context";
import AuthContext from "../store/context/auth-context";
import { useUserData } from "../store/context/userData-context";

const SynchronizationStatusPage = () => {
  const theme = useTheme();
  const { tui } = useUITranslation();
  const { openModal } = useModal();
  const { onLogout } = useContext(AuthContext);
  const { clearUserData } = useUserData();

  const [syncStatusText, setSyncStatusText] = useState("");
  const [nazovUstavu, setNazovUstavu] = useState("Ústav");
  const [nazvyObjektov, setNazvyObjektov] = useState("Objekt");
  const [nazvySektorov, setNazvySektorov] = useState("Sektor");
  const [nazvyBlokov, setNazvyBlokov] = useState("Blok");
  const [nazvyUbytovacichPriestorov, setNazvyUbytovacichPriestorov] =
    useState("Ubytovaci priestor");
  const [menaKlientov, setMenaKlientov] = useState("Mena klientov");

  const [syncStatus, setSyncStatus] = useState<SynchronizationResultType>({
    progress: 0,
    finished: false,
    items: [],
  });

  const getDeviceUuid = () => {
    let tabletUUID = localStorage.getItem("tabletUUID");
    if (!tabletUUID) {
      tabletUUID = getUuid();
      localStorage.setItem("tabletUUID", tabletUUID);
    }

    return tabletUUID;
  };

  const invokeSynchronization = async () => {
    const { CIS_Post, EOO_Post, ZVJS_MP_Post } = await API_Clients();

    const tabletData = await ZVJS_MP_Post("/administracia-tabletu", {
      body: {
        datumPripojenia: new Date().toISOString(),
        kioskTabletId: getDeviceUuid(),
      },
    });

    console.debug("Tablet data:");
    console.debug(tabletData.data);

    if (!tabletData.data?.ustavId) {
      const errorText =
        "Pre tablet ešte nebol definovaný ústav v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    if (!tabletData.data?.ubytovaciaJednotkaList) {
      const errorText =
        "Pre tablet ešte nebola definovaná ubytovacia jednotka/y v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    setSyncStatusText("");

    console.info(
      `Synchronizacia: ustavId[${
        tabletData.data.ustavId
      }], ubytovaciaJednotkaList[${tabletData.data.ubytovaciaJednotkaList.join(
        ","
      )}]`
    );

    const resultArray = tabletData.data.ubytovaciaJednotkaList
      ? await Promise.all(
          tabletData.data?.ubytovaciaJednotkaList?.map((ubytovaciaJednotka) =>
            EOO_Post("/api/Klient/List", {
              body: {
                filters: [
                  {
                    ustavID: tabletData.data.ustavId,
                    ubytovaciPriestorId: ubytovaciaJednotka,
                  },
                ],
              },
            })
          )
        )
      : [];

    const klientIds: number[] = resultArray
      .flat(1)
      .map((result) => result.data?.records)
      .flat(1)
      .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
      .filter((record) =>
        tabletData.data.ubytovaciaJednotkaList?.includes(
          record?.ubytovaciPriestorId ?? 99999
        )
      )
      .map((record) => record?.id)
      .filter((id): id is number => !!id);

    const cisUstav = await CIS_Post("/api/CisUstavZvjs/List", {
      body: {
        filters: [
          {
            aktualny: true,
            kodKontaktneUdajeUstavuId: tabletData.data.ustavId,
          },
        ],
      },
    });

    setNazovUstavu(
      cisUstav.data?.records
        ?.find((i) => i.kodKontaktneUdajeUstavuId === tabletData.data.ustavId)
        ?.skratka?.trim() ?? ""
    );

    setNazvyObjektov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.objektNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setNazvySektorov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.sektorNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ") ?? ""
    );

    setNazvyBlokov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.blokNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setNazvyUbytovacichPriestorov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.ubytovaciPriestorNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setMenaKlientov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .sort((k1, k2) =>
          (k1?.priezvisko ?? "").localeCompare(k2?.priezvisko ?? "")
        )
        .map(
          (record) =>
            `${record?.meno ?? ""} ${record?.druheMeno ?? ""} ${
              record?.priezvisko
            }`
        )
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    navigator.serviceWorker.controller?.postMessage({
      type: "SYNCHRONIZE_DATA",
      klientIds: klientIds,
      ustavZvjsId: tabletData.data.ustavId,
    });
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.type === "SYNCHRONIZATION_STATUS") {
      setSyncStatus(event.data.syncData);
    }
  };

  enum SyncResultEnum {
    NOT_FINISHED,
    OK,
    OK_VYHRADY,
    ERROR,
  }

  const evaluateSyncStatus = (
    syncStatus: SynchronizationResultType
  ): SyncResultEnum => {
    if (!syncStatus.finished) {
      return SyncResultEnum.NOT_FINISHED;
    }

    if (syncStatus.items.every((item) => item.success)) {
      return SyncResultEnum.OK;
    }

    if (syncStatus?.items.some((item) => item.mandatory && !item.success)) {
      return SyncResultEnum.ERROR;
    }

    return SyncResultEnum.OK_VYHRADY;
  };

  const channel = new BroadcastChannel("synchronization_status");
  channel.onmessage = handleMessage;

  useEffect(() => {
    // refresh environment variables
    fetch("/env.json");
    // logout any user
    clearUserData();
    onLogout();
    // start synchronization on a synchronization status page load
    invokeSynchronization();
  }, []);

  return (
    <AppActivityGuard>
      <>
        <ZvjsModal />
        <ZvjsPullToRefresh>
          <Grid container bgcolor={ZVJS_COLORS.GREY} justifyContent={"center"}>
            <Grid
              container
              item
              xs={12}
              mt={theme.spacing(2)}
              justifyContent={"center"}
            >
              <Grid item sx={{ width: "80vw" }}>
                <Paper elevation={1} sx={{ padding: theme.spacing(1) }}>
                  <Table size={"small"}>
                    <TableHead>
                      <TableCell colSpan={2} align="center">
                        <Typography variant={"h4"}>
                          {"Administrácia"}
                        </Typography>
                        <Typography variant="body2">
                          {getDeviceUuid()}
                        </Typography>
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>{"Ústav"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {nazovUstavu}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>{"Objekt"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {nazvyObjektov}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>{"Sektor"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {nazvySektorov}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>{"Blok"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {nazvyBlokov}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>
                            {"Ubytovací priestor"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {nazvyUbytovacichPriestorov}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          <Typography variant={"h6"}>{"Klient"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"body2"}>
                            {menaKlientov}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="center"
                          style={{ borderBottom: "none" }}
                        >
                          <Stack
                            alignItems={"center"}
                            spacing={1}
                            mt={theme.spacing(1)}
                            mb={theme.spacing(1)}
                          >
                            <ZvjsButton
                              style={{ width: "60%" }}
                              disabled={
                                !syncStatus.finished && syncStatusText === ""
                              }
                              zvjsVariant={"requestAction"}
                              text={capitalize(
                                tui("spustiť aktualizáciu znova")
                              )}
                              onClick={() => {
                                invokeSynchronization();
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box sx={{ width: "100%", mr: 1 }}>
                                <LinearProgress
                                  variant="determinate"
                                  style={{ height: 8, borderRadius: 4 }}
                                  value={syncStatus.progress}
                                />
                              </Box>
                              <Box sx={{ minWidth: 35 }}>
                                <Typography
                                  variant="body1"
                                  sx={{ color: "text.secondary" }}
                                >{`${Math.round(
                                  syncStatus.progress
                                )}%`}</Typography>
                              </Box>
                            </Box>
                            <Typography variant="body1">
                              {syncStatusText !== ""
                                ? syncStatusText
                                : tui(
                                    evaluateSyncStatus(syncStatus) ===
                                      SyncResultEnum.NOT_FINISHED
                                      ? "Prebieha aktualizácia dát."
                                      : evaluateSyncStatus(syncStatus) ===
                                          SyncResultEnum.OK
                                        ? "Všetky dáta sú aktualizované."
                                        : evaluateSyncStatus(syncStatus) ===
                                            SyncResultEnum.OK_VYHRADY
                                          ? "Všetky dáta sú aktualizované (s výhradami)."
                                          : "Zlyhala aktualizácia povinných dát."
                                  )}
                            </Typography>
                            <ZvjsButton
                              zvjsVariant="requestAction"
                              text={
                                syncStatusText !== ""
                                  ? capitalize(tui("chyba"))
                                  : capitalize(
                                      tui(
                                        evaluateSyncStatus(syncStatus) ===
                                          SyncResultEnum.NOT_FINISHED
                                          ? "..."
                                          : evaluateSyncStatus(syncStatus) ===
                                              SyncResultEnum.OK
                                            ? "ok"
                                            : evaluateSyncStatus(syncStatus) ===
                                                SyncResultEnum.OK_VYHRADY
                                              ? "ok s výhradami"
                                              : "chyba"
                                      )
                                    )
                              }
                              style={
                                syncStatusText !== ""
                                  ? { backgroundColor: "red", width: "60%" }
                                  : evaluateSyncStatus(syncStatus) ===
                                      SyncResultEnum.NOT_FINISHED
                                    ? {
                                        backgroundColor: "lightgrey",
                                        color: "black",
                                        width: "60%",
                                      }
                                    : evaluateSyncStatus(syncStatus) ===
                                        SyncResultEnum.OK
                                      ? {
                                          backgroundColor: "green",
                                          width: "60%",
                                        }
                                      : evaluateSyncStatus(syncStatus) ===
                                          SyncResultEnum.OK_VYHRADY
                                        ? {
                                            backgroundColor: "orange",
                                            width: "60%",
                                          }
                                        : {
                                            backgroundColor: "red",
                                            width: "60%",
                                          }
                              }
                              onClick={() => {
                                openModal(
                                  <SynchronizationReport data={syncStatus} />
                                );
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </ZvjsPullToRefresh>
      </>
    </AppActivityGuard>
  );
};

export default SynchronizationStatusPage;
