import { Button, Grid, Stack, Typography } from "@mui/material";
import { PersonalDataCategory_SC_KIO_0313, ZvjsCard } from "../../common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as PersonIcon } from "../../assets/icons/Other/person.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/Other/lock.svg";
import { ReactComponent as PrisonIcon } from "../../assets/icons/Other/prison.svg";
import { ReactComponent as PenaltyIcon } from "../../assets/icons/Other/penalty.svg";
import { ReactComponent as EducationIcon } from "../../assets/icons/Other/education.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/Other/money.svg";
import { ReactComponent as LettersIcon } from "../../assets/icons/Other/letters.svg";
import { ReactComponent as PacketsIcon } from "../../assets/icons/Other/package.svg";
import { ReactComponent as VisitIcon } from "../../assets/icons/Other/visit.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Other/phone.svg";
import { ReactComponent as PersonalItemsIcon } from "../../assets/icons/Other/personal_items.svg";
import { ReactComponent as Detail2Icon } from "../../assets/icons/Other/detail2.svg";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../assets/icons/Other/keyboard_arrow_left.svg";
import { ListyTabs } from "./MojeUdajePages/Listy";
import { ResocializacneAVolnocasoveAktivityTabs } from "./MojeUdajePages/ResocializacneAVolnocasoveAktivity";
import { ZakladneUdajeKlientaTabs } from "./MojeUdajePages/ZakladneUdajeKlienta";
import { ZakladneUdajeOTresteTabs } from "./MojeUdajePages/ZakladneUdajeOTreste";
import { PeniazeAPoplatkyTabs } from "./MojeUdajePages/PeniazeAPoplatky";
import { useUserData } from "../../store/context/userData-context";

const MojeUdaje = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { aktivnaVazba, aktivnyTrest } = useUserData();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const icon = (
    key: PersonalDataCategory_SC_KIO_0313
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): React.FunctionComponent<any> => {
    const iconMap = new Map<
      PersonalDataCategory_SC_KIO_0313,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      React.FunctionComponent<any>
    >();
    iconMap.set(PersonalDataCategory_SC_KIO_0313.BasicClientData, PersonIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.BasicPunishmentData, LockIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.BasicArrestData, LockIcon);
    iconMap.set(
      PersonalDataCategory_SC_KIO_0313.PlaceOfCustodyAndExecutionOfSentence,
      PrisonIcon
    );
    iconMap.set(
      PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions,
      PenaltyIcon
    );
    iconMap.set(
      PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities,
      EducationIcon
    );

    iconMap.set(PersonalDataCategory_SC_KIO_0313.MoneyAndFees, MoneyIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.Letters, LettersIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.Packets, PacketsIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.Visits, VisitIcon);
    iconMap.set(PersonalDataCategory_SC_KIO_0313.Telephony, PhoneIcon);
    iconMap.set(
      PersonalDataCategory_SC_KIO_0313.PersonalBelongings,
      PersonalItemsIcon
    );
    iconMap.set(PersonalDataCategory_SC_KIO_0313.Guidances, Detail2Icon);
    return iconMap.get(key) ?? PersonIcon;
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("dashboard.mojeUdaje"))}
          </Typography>
        </Stack>
        <Typography variant={"subtitle1"} sx={{ lineHeight: 1.2 }}>
          {tui(
            "Konkrétne Vaše údaje si vyberte cez jednotlivé kategórie nižšie."
          )}
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <>
          {Object.keys(PersonalDataCategory_SC_KIO_0313)
            .filter(
              (item) =>
                (item === PersonalDataCategory_SC_KIO_0313.BasicArrestData &&
                  aktivnaVazba) ||
                (item ===
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData &&
                  aktivnyTrest) ||
                (item !== PersonalDataCategory_SC_KIO_0313.BasicArrestData &&
                  item !== PersonalDataCategory_SC_KIO_0313.BasicPunishmentData)
            )
            .map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                <ZvjsCard
                  title={capitalize(
                    item === PersonalDataCategory_SC_KIO_0313.BasicClientData
                      ? tui(
                          "osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeKlienta"
                        )
                      : item ===
                          PersonalDataCategory_SC_KIO_0313.BasicPunishmentData
                        ? tui(
                            "osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeTrest"
                          )
                        : item ===
                            PersonalDataCategory_SC_KIO_0313.BasicArrestData
                          ? tui("osobneUdaje.kategorie.zakladneUdajeVazba")
                          : item ===
                              PersonalDataCategory_SC_KIO_0313.PlaceOfCustodyAndExecutionOfSentence
                            ? tui(
                                "osobneUdaje.kategorieOsobnychUdajov.miestoVykonuVazbyVykonuTrestu"
                              )
                            : item ===
                                PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions
                              ? tui(
                                  "osobneUdaje.kategorieOsobnychUdajov.disciplinarneTresty"
                                )
                              : item ===
                                  PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities
                                ? tui(
                                    "osobneUdaje.kategorieOsobnychUdajov.resocializacneAktivity"
                                  )
                                : item ===
                                    PersonalDataCategory_SC_KIO_0313.MoneyAndFees
                                  ? tui(
                                      "osobneUdaje.kategorieOsobnychUdajov.peniazePoplatky"
                                    )
                                  : item ===
                                      PersonalDataCategory_SC_KIO_0313.Letters
                                    ? tui(
                                        "osobneUdaje.kategorieOsobnychUdajov.listy"
                                      )
                                    : item ===
                                        PersonalDataCategory_SC_KIO_0313.Packets
                                      ? tui(
                                          "osobneUdaje.kategorieOsobnychUdajov.baliky"
                                        )
                                      : item ===
                                          PersonalDataCategory_SC_KIO_0313.Visits
                                        ? tui(
                                            "osobneUdaje.kategorieOsobnychUdajov.navstevy"
                                          )
                                        : item ===
                                            PersonalDataCategory_SC_KIO_0313.Telephony
                                          ? tui(
                                              "osobneUdaje.kategorieOsobnychUdajov.telefonovanie"
                                            )
                                          : item ===
                                              PersonalDataCategory_SC_KIO_0313.PersonalBelongings
                                            ? tui(
                                                "osobneUdaje.kategorieOsobnychUdajov.osobneVeci"
                                              )
                                            : item ===
                                                PersonalDataCategory_SC_KIO_0313.Guidances
                                              ? tui("poucenia.poucenia")
                                              : tui("---")
                  )}
                  icon={icon(
                    PersonalDataCategory_SC_KIO_0313[
                      item as keyof typeof PersonalDataCategory_SC_KIO_0313
                    ]
                  )}
                  sx={{ height: "8em" }}
                  onClick={() => {
                    if (
                      item === PersonalDataCategory_SC_KIO_0313.BasicClientData
                    ) {
                      navigate(
                        `${PersonalDataCategory_SC_KIO_0313.BasicClientData}/${ZakladneUdajeKlientaTabs.OsobneUdaje}`
                      );
                    } else if (
                      item ===
                      PersonalDataCategory_SC_KIO_0313.BasicPunishmentData
                    ) {
                      navigate(
                        `${PersonalDataCategory_SC_KIO_0313.BasicPunishmentData}/${ZakladneUdajeOTresteTabs.UdajeOTreste}`
                      );
                    } else if (
                      item === PersonalDataCategory_SC_KIO_0313.MoneyAndFees
                    ) {
                      navigate(
                        `${PersonalDataCategory_SC_KIO_0313.MoneyAndFees}/${PeniazeAPoplatkyTabs.Konto}`
                      );
                    } else if (
                      item === PersonalDataCategory_SC_KIO_0313.Letters
                    ) {
                      navigate(
                        `${PersonalDataCategory_SC_KIO_0313.Letters}/${ListyTabs.PrijataKorespondencia}`
                      );
                    } else if (
                      item ===
                      PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities
                    ) {
                      navigate(
                        `${PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities}/${ResocializacneAVolnocasoveAktivityTabs.PracovneZaradenie}`
                      );
                    } else {
                      navigate(item);
                    }
                  }}
                />
              </Grid>
            ))}
        </>
      </Grid>
    </Grid>
  );
};

export default MojeUdaje;
