import React from "react";
import { ZvjsAlert, ZvjsRadioGroup } from "../../../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { AppDispatch } from "../../../../../../store/redux";
import { Box, Grid } from "@mui/material";
import { getSeverityOfValidationFlag } from "../../../../redux/model";

interface RadioQuestionProps {
  location: number[];
}

const RadioQuestion: React.FC<RadioQuestionProps> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();

  console.debug(`RADIO QUESTION RERENDER ${location.join("-")}`);

  const radioQuestionData = useSelector(
    questionnaireRedux.selectors.getRadioQuestionDisplayData(location)
  );
  // use shallowEqual so question is not re-rendered each time selector returns new array instance: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const isOptionDisplayedArray = useSelector(
    questionnaireRedux.selectors.getRadioOptionsDisplayedArray(location),
    shallowEqual
  );
  const answerVal = useSelector(
    questionnaireRedux.selectors.getRadioQuestionAnswerData(
      location,
      radioQuestionData.id
    )
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(radioQuestionData.id)
  );

  const filteredOptions = radioQuestionData.options.filter(
    (_, i) => isOptionDisplayedArray[i]
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        radioQuestionData.id,
        event.target.value as string
      )
    );
  };

  return (
    <Grid item xs>
      <ZvjsRadioGroup
        id={`radio-question-${location.join("-")}`}
        label={
          radioQuestionData.hideTitle ? undefined : radioQuestionData.title
        }
        items={filteredOptions}
        hintText={radioQuestionData.hintText}
        hintSize={20}
        answerRequired={radioQuestionData.isRequired}
        onChange={onChangeHandler}
        selectedValue={answerVal ?? ""}
        validationErrorMessage={validationError?.message}
        validationFlatType={validationError?.type}
      />
      {validationError && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationError?.type)}
          >
            {validationError.message}
          </ZvjsAlert>
        </Box>
      )}
    </Grid>
  );
};

export default RadioQuestion;
