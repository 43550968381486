import { defer, useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsOsobneUdajeFormular,
  ZvjsSummaryTable,
} from "../../../../common";
import { Grid, Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { useModal } from "../../../../store/context/modal-context";

const loadData = async () => {
  const { EOO_Get, EOO_Post, EPVVVT_Post, FEOO_Get } = await API_Clients();

  const data = await Promise.all([
    EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }),
    EOO_Get("/api/Klient/XDetailOstatneUdajeData", {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }),
    EOO_Post("/api/Klient/ListRodinnyStavKlientaData", {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
        sorting: [
          {
            columnName: "od",
            direction: "desc",
          },
        ],
      },
    }),
    EOO_Get("/api/Ciselnik/StupenDosiahnutehoVzdelania"),
    EPVVVT_Post("/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
    FEOO_Get("/api/Klient/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }),
    FEOO_Get("/api/KlientUni/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }),
  ]);

  if (data[0].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [údaje klienta detail]");
  }

  if (data[1].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [údaje klienta ostatne]");
  }

  if (data[2].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [rodninný stav klienta]");
  }

  if (data[4].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [sociálna diagnostika klienta]"
    );
  }

  if (data[5].error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [feoo údaje klienta]");
  }

  if (data[6].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [feoo uni údaje klienta]"
    );
  }

  const data2 = await Promise.all([
    EOO_Get("/api/FyzickaOsoba/DetailData", {
      params: {
        query: {
          request: data[0].data?.data?.fyzickaOsobaId,
        },
      },
    }),
  ]);

  if (data2[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [fyzicka osoba klienta detail]"
    );
  }

  return {
    eoo_klient_DetailZakladneUdajeKlientaData: data[0],
    xDetailOstatneUdajeData: data[1],
    rodinnyStav: data[2].data?.records?.at(0)?.rodinnyStavNazov ?? "",
    ciselnikStupenDosiahnutehoVzdelania: data[3],
    socialnaDiagnostikaOdsudeneho: data[4],
    feooKlient: data[5],
    klientUni: data[6],
    eoo_fyzickaOsoba_DetailData: data2[0],
  };
};

export const OsobneUdajeTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface OsobneUdajeTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OsobneUdajeTab = (props: OsobneUdajeTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={
          tui("osobneUdaje.vseobecneOsobneUdaje.vseobecneOsobneUdaje") as string
        }
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.meno")}
          value={`${
            props.data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.meno ?? ""
          } ${
            props.data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.druheMeno ?? ""
          }`}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")}
          value={
            props.data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.priezvisko ?? ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.datumNarodenia")}
          value={
            props.data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.datumNarodenia
              ? new Date(
                  props.data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data?.datumNarodenia
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.miestoNarodenia")}
          value={
            props.data.eoo_fyzickaOsoba_DetailData?.data?.data
              ?.miestoNarodenia ?? ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("Rodinný stav")}
          value={props.data.rodinnyStav ?? ""}
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecne.napovedaPravdivyUaj")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.najvyssieVzdelanie")}
          value={
            props.data.ciselnikStupenDosiahnutehoVzdelania.data?.find(
              (i) =>
                i.id ===
                props.data.xDetailOstatneUdajeData.data.data
                  ?.stupenDosiahnutehoVzdelaniaId
            )?.nazov ?? ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaVzdelanie")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.poberanyDochodok")}
          value={
            (props.data.feooKlient?.data?.data?.zakladneUdajeKlienta?.dochodca
              ? tui("áno")
              : tui("nie")) +
            (props.data.feooKlient.data?.data?.rozsireneUdajeKlienta
              ?.typDochodku1
              ? ` ${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku1}`
              : "") +
            (props.data.feooKlient.data?.data?.rozsireneUdajeKlienta
              ?.typDochodku2
              ? ` ${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku2}`
              : "") +
            (props.data.feooKlient.data?.data?.rozsireneUdajeKlienta
              ?.typDochodku3
              ? ` ${props.data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku3}`
              : "")
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaDochodok")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.poskytovanyDruhStravy")}
          value={
            props.data.klientUni.data?.data?.rozsireneUdajeKlienta
              ?.druhStravnejDavky ?? ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaDruhStravy")}
        />
        <ZvjsSummaryTableComponent
          label={tui("fajčiar")}
          value={
            props.data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.fajciar
              ? tui("áno")
              : tui("nie")
          }
          isBorder={false}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecne.napovedaPravdivyUaj")}
        />
      </ZvjsSummaryTable>
      <Grid container spacing={1} width={"100%"} justifyContent={"flex-start"}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.ziadostOzmenuUdaja")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              openModal(<ZvjsOsobneUdajeFormular data={props.data} />);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.zmenaStravnejDavky")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/12.00.01`);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.pohovorSocPracovnik")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/10.02.01`);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default withLoader(OsobneUdajeTab);
