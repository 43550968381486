import { Button, Grid, Stack, Typography } from "@mui/material";
import { ZvjsCard } from "../../common";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as BookIcon } from "../../assets/icons/Other/book.svg";
import { ReactComponent as Book2Icon } from "../../assets/icons/Other/book2.svg";
import { ReactComponent as LettersIcon } from "../../assets/icons/Other/letters.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Other/phone.svg";
import { ReactComponent as MealIcon } from "../../assets/icons/Other/meal.svg";
import { ReactComponent as EducationIcon } from "../../assets/icons/Other/education.svg";
import { ReactComponent as NewspapersIcon } from "../../assets/icons/Other/newspapers.svg";
import { ReactComponent as PenaltyIcon } from "../../assets/icons/Other/penalty.svg";
import { ReactComponent as InformationsIcon } from "../../assets/icons/Other/informations.svg";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { Noticeboard_SC_KIO_0400 } from "../../common";
import { useUITranslation } from "../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../hoc/withLoader";

export const loadData = async () => {
  return [
    {
      name: Noticeboard_SC_KIO_0400.ConstitutionalOrder,
      path: Noticeboard_SC_KIO_0400.ConstitutionalOrder,
      icon: Book2Icon,
    },
    {
      name: Noticeboard_SC_KIO_0400.PacketsAndLetters,
      path: Noticeboard_SC_KIO_0400.PacketsAndLetters,
      icon: LettersIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.Telephony,
      path: Noticeboard_SC_KIO_0400.Telephony,
      icon: PhoneIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.Meal,
      path: Noticeboard_SC_KIO_0400.Meal,
      icon: MealIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities,
      path: Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities,
      icon: EducationIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.Library,
      path: Noticeboard_SC_KIO_0400.Library,
      icon: BookIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.DailyPressAndMagazines,
      path: Noticeboard_SC_KIO_0400.DailyPressAndMagazines,
      icon: NewspapersIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.ProtectionOfRights,
      path: Noticeboard_SC_KIO_0400.ProtectionOfRights,
      icon: PenaltyIcon,
    },
    {
      name: Noticeboard_SC_KIO_0400.GeneralInformation,
      path: Noticeboard_SC_KIO_0400.GeneralInformation,
      icon: InformationsIcon,
    },
  ];
};

export const NastenkaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface NastenkaProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Nastenka = (props: NastenkaProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("dashboard.nastenka"))}
          </Typography>
        </Stack>
      </Grid>
      <Grid container spacing={3}>
        <>
          {props.data.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
              <ZvjsCard
                title={capitalize(
                  item.name === Noticeboard_SC_KIO_0400.ConstitutionalOrder
                    ? capitalize(tui("ústavný poriadok"))
                    : item.name ===
                      Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities
                    ? capitalize(tui("kultúrno-osvetové aktivity"))
                    : item.name ===
                      Noticeboard_SC_KIO_0400.DailyPressAndMagazines
                    ? capitalize(tui("denná tlač a časopisy"))
                    : item.name === Noticeboard_SC_KIO_0400.GeneralInformation
                    ? capitalize(tui("všeobecné informácie"))
                    : item.name === Noticeboard_SC_KIO_0400.Library
                    ? capitalize(tui("knižnica"))
                    : item.name === Noticeboard_SC_KIO_0400.Meal
                    ? capitalize(tui("stravovanie"))
                    : item.name === Noticeboard_SC_KIO_0400.PacketsAndLetters
                    ? capitalize(tui("balíky a listy"))
                    : item.name === Noticeboard_SC_KIO_0400.ProtectionOfRights
                    ? capitalize(tui("ochrana práv"))
                    : capitalize(tui("telefonovanie"))
                )}
                icon={item.icon}
                sx={{ height: "8em" }}
                onClick={() => {
                  navigate(item.path);
                }}
              />
            </Grid>
          ))}
        </>
      </Grid>
    </Grid>
  );
};

export default withLoader(Nastenka);
