import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../store/context/auth-context";
import { ScreenSaverPage } from "../pages";
import { useUserData } from "../store/context/userData-context";

const LOGOUT_THRESHOLD =
  process.env.NODE_ENV === "development" ? 60 * 1440 * 1000 : 60 * 1440 * 1000; // interval of logout (24h/24h)
const SCREENSAVER_THRESHOLD =
  process.env.NODE_ENV === "development" ? 60 * 1000 * 10 : 60 * 1000 * 10; // interval of logout (10m/10m)
const LAST_ACTIVITY_TIME = "LAST_ACTIVITY_TIME";

const events: string[] = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

interface AppActivityGuardProps {
  children: React.ReactElement;
}

const AppActivityGuard: React.FC<AppActivityGuardProps> = ({ children }) => {
  const { onLogout, isLoggedIn } = useContext(AuthContext);
  const { clearUserData } = useUserData();
  const [displayScreenSaver, setDisplayScreenSaver] = useState<boolean>(false);

  // Handle activity events listeners
  // When the component mounts, it adds an event listeners to the window.
  // Each time any of the event is triggered, i.e., on mouse move, click, scroll, keypress etc., the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the events is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    const activityCapturedHandler = () => {
      localStorage.setItem(LAST_ACTIVITY_TIME, Date.now().toString());
      if (displayScreenSaver) {
        console.debug("ScreenSaver was deactivated");
        setDisplayScreenSaver(false);
      }
    };

    for (const event of events) {
      window.addEventListener(event, activityCapturedHandler);
    }

    return () => {
      for (const event of events) {
        window.removeEventListener(event, activityCapturedHandler);
      }
    };
  }, [displayScreenSaver]);

  // Checking if user is active, otherwise logout is fired / screensaver is displayed
  useEffect(() => {
    const logoutAction = () => {
      if (window.location === window.parent.location) {
        console.debug("You have been logged out");
        clearUserData();
        onLogout();
      } else {
        window.parent.postMessage(
          {
            type: "logout",
            message: "logout",
          },
          "*"
        );
      }
    };

    const timer = setInterval(() => {
      const lastActivityTime = localStorage.getItem(LAST_ACTIVITY_TIME);
      if (lastActivityTime) {
        // check if user should be logged out
        if (+lastActivityTime + LOGOUT_THRESHOLD < Date.now() && isLoggedIn) {
          logoutAction();
        }

        // check if screenSaver should be shown
        if (
          +lastActivityTime + SCREENSAVER_THRESHOLD < Date.now() &&
          !displayScreenSaver
        ) {
          console.debug("ScreenSaver was activated");
          setDisplayScreenSaver(true);
        }
      }
    }, 1000); // check if user is active (1s)

    return () => {
      clearInterval(timer);
    };
  }, [displayScreenSaver, isLoggedIn, clearUserData, onLogout]);

  return displayScreenSaver ? <ScreenSaverPage /> : children;
};

export default AppActivityGuard;
