import { defer, useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { Grid, Stack } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";

import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EDPOO_Post, EOO_Get } = await API_Clients();

  const eoo_klient_DetailZakladneUdajeKlientaData = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }
  );

  if (eoo_klient_DetailZakladneUdajeKlientaData.error) {
    throw new LoaderError("Nepodarilo sa načítať dáta [údaje klienta detail]");
  }

  const stravovanie = await EDPOO_Post("/api/Stravovanie/List", {
    body: {
      filters: [
        {
          zakladneCislo:
            eoo_klient_DetailZakladneUdajeKlientaData.data?.data?.zakladneCislo,
        },
      ],
    },
  });

  if (stravovanie.error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [potravinové intolerancie]"
    );
  }

  return {
    stravovanie: stravovanie,
  };
};

export const PotravinoveIntolerancieTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface PotravinoveIntolerancieTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const PotravinoveIntolerancieTab = (props: PotravinoveIntolerancieTabProps) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  return (
    <Stack direction="column" mt={1} spacing={1}>
      <ZvjsTable
        data={{
          label: capitalize(
            tui("osobneUdaje.poskytovanaSluzba.evidovanPotravinoveIntolerancie")
          ),
          header: [
            {
              value: capitalize(tui("názov")),
              align: "left",
            },
          ],
          body:
            props.data.stravovanie.data?.records
              // ?.sort((p1, p2) =>
              //   p1.name > p2.name)
              ?.map((strava) => {
                const rd: RowData = {
                  row: [
                    {
                      value: strava.intolerancia ?? "",
                      align: "left",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        hintText={tui(
          "osobneUdaje.potravinoveIntolerancie.napovedaZdravotnyStav"
        )}
      />
      <Grid width="20rem">
        <ZvjsButton
          text={capitalize(
            tui("osobneUdaje.potravinoveIntolerancie.zdravotnaStarostlivost")
          )}
          zvjsVariant="secondaryAction"
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={() => {
            navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/15.00.01`);
          }}
          style={{
            lineHeight: "1",
            textTransform: "none",
            textAlign: "left",
          }}
        />
      </Grid>
    </Stack>
  );
};

export default withLoader(PotravinoveIntolerancieTab);
