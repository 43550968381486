import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051301",
  basicInfo: [
    {
      text: "ziadost.hlaseniePoruchy.zakladneInfo",
    },
  ],
  counters: [
    "/api/CisMiestoNedostatku/List",
    "/api/CisDruhNedostatkuUpresnenie/List",
  ],
  items: [
    {
      id: "miestoNedostatku",
      title: "ziadost.hlaseniePoruchy.labelMiestoNedostatku",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      hintText: "ziadost.hlaseniePoruchy.napovedaJednaMoznostZoZoznamu",
      options: {
        counterKey: "/api/CisMiestoNedostatku/List",
        textKey: "nazov",
        optValues: [
          { id: "1" },
          { id: "2" },
          { id: "3" },
          { id: "4" },
          { id: "5" },
          { id: "6" },
          { id: "7" },
          { id: "8" },
        ],
      },
    },
    {
      id: "dovodPreIneMiesto",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "miestoNedostatku",
          value: ["8"],
        },
      ],
    },
    {
      id: "druhNedostatku",
      title: "ziadost.hlaseniePoruchy.labelDruhNedostatku",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDruhNedostatkuUpresnenie/List",
        textKey: "nazov",
        optValues: [
          {
            id: "8",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["3"],
              },
            ],
          },
          {
            id: "10",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1", "3"],
              },
            ],
          },
          {
            id: "12",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1"],
              },
            ],
          },
          {
            id: "11",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1"],
              },
            ],
          },
          {
            id: "2",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1"],
              },
            ],
          },
          {
            id: "9",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1", "5"],
              },
            ],
          },
          {
            id: "6",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["5"],
              },
            ],
          },
          {
            id: "7",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1", "2", "4"],
              },
            ],
          },
          {
            id: "13",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["3"],
              },
            ],
          },
          {
            id: "4",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["7"],
              },
            ],
          },
          {
            id: "5",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["7"],
              },
            ],
          },
          {
            id: "1",
            conditions: [
              {
                type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
                questionId: "miestoNedostatku",
                value: ["1", "2", "3", "4", "5", "7"],
              },
            ],
          },
        ],
      },
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "miestoNedostatku",
          value: ["1", "2", "3", "4", "5", "7"],
        },
      ],
    },
    {
      id: "dovodPreInePoskodenie",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "druhNedostatku",
          value: ["1"],
        },
      ],
    },
    {
      id: "blizsiPopisNedostatku",
      title: "ziadost.hlaseniePoruchy.labelPopisNedostatku",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: false,
      validations: ["MAX_ANSWER_LENGTH_500"],
    },
  ],
};
