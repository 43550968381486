import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { SC_KIO_050101_can_request_visit_all_checks } from "../../../../../../requestTemplates/SC_KIO_050101_Ziadost o navstevu";

/**
 * Selectors
 */

const getVisitDateQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const canRequestVisitChecks = SC_KIO_050101_can_request_visit_all_checks({
      userCalcData: state.userCalcData,
    });

    return {
      id: question.id,
      title: tuiz(question.title),
      thisMonthRequestAvailable: canRequestVisitChecks.thisMonthAny,
      nextMonthRequestAvailable: canRequestVisitChecks.nextMonthAny,
      isRequired: question.isRequired,
    };
  });

export const allSelectors = {
  getVisitDateQuestionDisplayData,
};

/**
 * CustomSubmitAnswerData
 */
export const SC_KIO_050101_VisitDateQuestion_getCustomSubmitAnswerData = (
  answer: string,
  questionId: string
): {
  [key: string]: number;
} => {
  // get month number from date string with format YYYY-MM
  return { [questionId]: new Date(answer).getMonth() + 1 };
};
