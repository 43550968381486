import React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useSnackbar } from "../../store/context/snackbar-context";
import { ActionResultTypes } from "../../router/ActionResult";

const ZvjsSnackbar = () => {
  let severity: AlertColor | undefined = undefined;
  const {
    snackbarMessage,
    closeSnackbar,
    snackbarFlag,
    snackbarOpen,
    snackbarTransition,
  } = useSnackbar();

  switch (snackbarFlag) {
    case ActionResultTypes.ERROR:
      severity = "error";
      break;
    case ActionResultTypes.SUCCESS:
      severity = "success";
      break;
    case "error":
      severity = "error";
      break;
    case "success":
      severity = "success";
      break;
    case "info":
      severity = "info";
      break;
    case "warning":
      severity = "warning";
      break;
  }

  return (
    <Snackbar
      open={snackbarOpen}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ bottom: { xs: 112 } }}
      TransitionComponent={snackbarTransition}
      autoHideDuration={4000}
      key={snackbarTransition.name}
    >
      <Alert severity={severity} onClose={closeSnackbar}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default ZvjsSnackbar;
