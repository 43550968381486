import { API_Clients } from "../store/context/dataApi/Data";
import useFileApi from "../store/context/useFileApi";
import { WebDavList, webDavGetInfo } from "./webDav";
import { DOMParser } from "xmldom";

export const INTERFACEADMIN = "interfaceadmin";
export const INTRANET_PREFIX = "intranet";
export const INTRANET_BASE_PATH = `${INTRANET_PREFIX}/NAVIS_priecinky/KIOSK`;

const INTERNE_PREDPISY_ZVJS_KIOSK_PATH = "/Interne_predpisy_ZVJS_KIOSK";
const INTERNE_PREDPISY_ZVJS_TABLET_PATH = "/Interne_predpisy_ZVJS_Tablety";

const filesInDirectory = async (path: string): Promise<WebDavList> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getDirectory } = useFileApi();
  console.debug(`filesInDirectory path[${path}]`);

  const webDawXMLText = await getDirectory(path, true);
  if (!webDawXMLText) {
    return [];
  }

  const doc = new DOMParser().parseFromString(webDawXMLText, "application/xml");

  return webDavGetInfo(doc).map((item) => {
    return {
      ...item,
      name: item.name
        .substring(item.name.indexOf(INTERFACEADMIN))
        .replace(INTERFACEADMIN, INTRANET_PREFIX),
    };
  });
};

/**
 * @description return intranetDirectories
 * @param klientUstavId Ustav ID
 * @param isKiosk KIOSK (true), TABLET (false)
 * @returns true if the WebDAV document is describing file
 */
export const intranetDirectories = async (
  klientUstavId: number,
  isKiosk: boolean
) => {
  const { CIS_Post } = await API_Clients();

  const cisUstavZvjs = await CIS_Post("/api/CisUstavZvjs/List", {
    body: {
      filters: [
        {
          aktualny: true,
          kodKontaktneUdajeUstavuId: klientUstavId,
        },
        {
          aktualny: true,
          kod: "75", // GR ZVJS
        },
      ],
    },
  });

  const ustavKod = cisUstavZvjs.data?.records
    ?.find((i) => i.kodKontaktneUdajeUstavuId === klientUstavId)
    ?.kod?.trim();

  const ustavSkratka = cisUstavZvjs.data?.records
    ?.find((i) => i.kodKontaktneUdajeUstavuId === klientUstavId)
    ?.skratka?.trim()
    .removeDiacritics();

  const ustavKodGR = cisUstavZvjs.data?.records
    ?.find((i) => i.kod === "75")
    ?.kod?.trim();

  const ustavSkratkaGR = cisUstavZvjs.data?.records
    ?.find((i) => i.kod === "75")
    ?.skratka?.trim()
    .removeDiacritics();

  const ustavPath = `${INTRANET_PREFIX}/NAVIS_priecinky/KIOSK/${ustavKod}_${ustavSkratka}`;
  const ustavPathGR = `${INTRANET_PREFIX}/NAVIS_priecinky/KIOSK/${ustavKodGR}_${ustavSkratkaGR}`;

  return {
    // GR ZVJS zdielane
    ustavPathGR: ustavPathGR,
    audionahravky: `${ustavPathGR}/Audionahravky/`,
    bezpecnyInternet: `${ustavPathGR}/Bezpecny_internet/`,
    balikyAListy: `${ustavPathGR}/BalikyAListy/`,
    telefonovanie: `${ustavPathGR}/Telefonovanie/`,
    informacneBrozury: `${ustavPathGR}/InformacneBrozury/`,
    internePredpisy: `${ustavPathGR}/Interne_predpisy_ZVJS_${
      isKiosk ? "KIOSK" : "Tablety"
    }/`,
    prirucka: `${ustavPathGR}/Prirucka/Prirucka.pdf`,

    // Ustav specificke
    ustavPath: ustavPath,
    dennaTlacACasopisy: `${ustavPath}/DennaTlacACasopisy_${ustavKod}/`,
    kulturnoOsvetoveAktivity: `${ustavPath}/KulturnoOsvetoveAktivity_${ustavKod}/`,

    kniznicaVypozicneHodiny: `${ustavPath}/Kniznica/Vypozicne_hodiny_${ustavKod}/vypozicne hodiny.txt`,
    kniznicaKatalogTitulov: `${ustavPath}/Kniznica/Katalog_titulov_kniznice_${ustavKod}/Katalog_titulov_kniznice.json`,
    ordinacneHodinyVAmbulancii: `${ustavPath}/Ustavny_poriadok_${ustavKod}/OrdinacneHodinyVAmbulancii_${ustavKod}.txt`,

    // GR + USTAV
    ochranaPrav: `${ustavPath}/OchranaPrav_${ustavKod}/`,
    ineSubory: `${ustavPath}/IneSubory_${ustavKod}/`,
  };
};

/**
 * @description return all Intranet files
 * @param klientUstavId Ustav ID
 * @param includeGR include files from GR ZVJS
 * @returns true if the WebDAV document is describing file
 */
const allIntranetFiles = async (
  klientUstavId: number,
  isKiosk: boolean,
  includeGR: boolean
): Promise<WebDavList> => {
  const intranetPaths = await intranetDirectories(klientUstavId, isKiosk);

  const filesResult = await Promise.allSettled([
    filesInDirectory(intranetPaths.ustavPath),
    includeGR
      ? filesInDirectory(intranetPaths.ustavPathGR)
      : Promise.resolve([]),
  ]);

  const ustavFiles =
    filesResult[0].status === "fulfilled" ? filesResult[0].value : [];

  const ustavFilesGR =
    filesResult[1].status === "fulfilled" ? filesResult[1].value : [];

  const allFiles = ustavFiles.concat(ustavFilesGR);

  return allFiles;
};

/**
 * @description return all Intranet files
 * @param klientUstavId Ustav ID
 * @param isKiosk KIOSK (true), TABLET (false)
 * @param includeGR include files from GR ZVJS
 * @returns filtered list of Intranet files for given Ustav and device type
 */
export const allIntranetFilesFilteredForDevice = async (
  klientUstavId: number,
  isKiosk: boolean,
  includeGR = true
): Promise<WebDavList> => {
  const allFiles = await allIntranetFiles(klientUstavId, isKiosk, includeGR);

  const filteredFiles = allFiles.filter(
    (item) =>
      !item.name.includes(
        isKiosk
          ? INTERNE_PREDPISY_ZVJS_TABLET_PATH
          : INTERNE_PREDPISY_ZVJS_KIOSK_PATH
      )
  );

  return filteredFiles;
};
