import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async (id: string) => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/OchranneOpatrenie/ListOchranneOpatrenieData", {
      body: {
        //TODO: Uistit sa, ze filter skutocne filtruje cez klientId (podla vysledkov sa zda, ze ano)
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
        paging: {
          currentPage: 1,
          recordsPerPage: 999999,
        },
        // sorting: [{}],
      },
    }),
  ]);

  return {
    ochranneOpatrenia: data[0],
    ochranneOpatrenieId: id,
  };
};

export const OchranneOpatrenieDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

interface OchranneOpatrenieDetailProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const OchranneOpatrenieDetail = (props: OchranneOpatrenieDetailProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("detail ochranného opatrenia"))}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={capitalize(tui("názov"))}
              value={
                loaderData.ochranneOpatrenia.data?.records
                  ?.filter(
                    (ochranneOpatrenie) =>
                      ochranneOpatrenie.id ===
                      Number(loaderData.ochranneOpatrenieId)
                  )
                  .at(0)?.typOchrannehoOpatreniaNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("dátum"))}
              value={"<API?>"}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("nariadené inštitúciou"))}
              value={
                loaderData.ochranneOpatrenia.data?.records
                  ?.filter(
                    (ochranneOpatrenie) =>
                      ochranneOpatrenie.id ===
                      Number(loaderData.ochranneOpatrenieId)
                  )
                  .at(0)?.nariadilInstituciaNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={capitalize(tui("poznámka"))}
              value={
                loaderData.ochranneOpatrenia.data?.records
                  ?.filter(
                    (ochranneOpatrenie) =>
                      ochranneOpatrenie.id ===
                      Number(loaderData.ochranneOpatrenieId)
                  )
                  .at(0)?.poznamka ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(OchranneOpatrenieDetail);
