import { defer } from "react-router-dom";
import { ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { withLoader } from "../../../../hoc/withLoader";
import { LoaderError } from "../../../../router/LoaderError";

const loadData = async () => {
  const { EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    EPVVVT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
    EPVVVT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
    EPVVVT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit",
      {
        body: {
          oraKlientVvvtId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
  ]);

  if (data[0].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [absolvované voľnočasové aktivity - krúžky]"
    );
  }

  if (data[1].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [absolvované voľnočasové aktivity ostatné]"
    );
  }

  if (data[2].error) {
    throw new LoaderError(
      "Nepodarilo sa načítať dáta [absolvované výchovno-osvetové aktivity]"
    );
  }

  return {
    absolvovaneVolnocasoveAktivityKruzky: data[0],
    absolvovaneVolnocasoveAktivityOstatne: data[1],
    absolvovaneVychovnoOsvetoveAktivity: data[2],
  };
};

export const AbsolvovaneAktivityTabLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface AbsolvovaneAktivityTabProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const AbsolvovaneAktivityTab = (props: AbsolvovaneAktivityTabProps) => {
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={{
          label: capitalize(tui("absolvované voľnočasové aktivity - krúžky")),
          header: [
            {
              value: capitalize(tui("typ krúžku")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("termín začiatku")),
              align: "right",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("termín ukončenia")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.absolvovaneVolnocasoveAktivityKruzky.data
              ?.sort(
                (a, b) =>
                  new Date(b.behTerminZaciatku ?? new Date()).valueOf() -
                  new Date(a.behTerminZaciatku ?? new Date()).valueOf()
              )
              .map((item) => {
                const rd: RowData = {
                  row: [
                    {
                      value: item.typKruzkuNazov ?? "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: new Date(
                        item.behTerminZaciatku ?? new Date()
                      ).zvjsToString(),
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: new Date(
                        item.behTerminUkoncenia ?? new Date()
                      ).zvjsToString(),
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={{
          label: capitalize(tui("absolvované voľnočasové aktivity ostatné")),
          header: [
            {
              value: capitalize(tui("druh voľnočasovej aktivity")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("typ voľnočasovej aktivity")),
              align: "right",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("dátum konania")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.absolvovaneVolnocasoveAktivityOstatne.data
              ?.sort(
                (a, b) =>
                  new Date(b.datumKonania ?? new Date()).valueOf() -
                  new Date(a.datumKonania ?? new Date()).valueOf()
              )
              .map((item) => {
                const rd: RowData = {
                  row: [
                    {
                      value: item.druhVolnocasovejAktivityNazov ?? "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: item.typVolnocasovejAktivityNazov ?? "",
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: new Date(
                        item.datumKonania ?? new Date()
                      ).zvjsToString(),
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={{
          label: capitalize(tui("absolvované výchovno-osvetové aktivity")),
          header: [
            {
              value: capitalize(tui("miesto realizácie v ústave")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("tématické zameranie")),
              align: "right",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("dátum konania")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.absolvovaneVychovnoOsvetoveAktivity.data
              ?.sort(
                (a, b) =>
                  new Date(b.datumKonania ?? new Date()).valueOf() -
                  new Date(a.datumKonania ?? new Date()).valueOf()
              )
              .map((item) => {
                const rd: RowData = {
                  row: [
                    {
                      value: item.miestoRealizacie ?? "",
                      align: "left",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: item.tematickeZameranieNazov ?? "",
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                    {
                      value: new Date(
                        item.datumKonania ?? new Date()
                      ).zvjsToString(),
                      align: "right",
                      width: "calc(100% / 3)",
                    },
                  ],
                };
                return rd;
              }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default withLoader(AbsolvovaneAktivityTab);
